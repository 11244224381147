import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import {
  EXPOSITION_SHAPE,
  EXPOSITION_TOUR_KIND,
  EXPOSITION_TICKETS_KIND,
  EXPOSITION_PUBLICATIONS_KIND,
  EXPOSITION_SELECT_DEFAULT_OPTION,
  EXPOSITION_IMAGES_KIND
} from "../../../constants/expositionConstants";
import Truncated from "../../../components/ui/trancated";
import Tours from "../tours";
import Images from "../images";
import Publications from "../publications";


class Content extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sections: [],
      options: []
    }
  }

  componentDidMount() {
    this.buildSections();
    this.buildSelectOptions();
  }

  buildSections = () => {
    const { item } = this.props;

    const sections = [
      'about',
    ]

    if (item.booking_available && item.actual) { sections.push(EXPOSITION_TICKETS_KIND) }
    if (!isEmpty(item.images)) { sections.push(EXPOSITION_IMAGES_KIND) }
    if (!isEmpty(item.posts)) { sections.push(EXPOSITION_PUBLICATIONS_KIND) }

    this.setState({sections});
  }

  buildSelectOptions = () => {
    const { item } = this.props;

    const options = [
      EXPOSITION_SELECT_DEFAULT_OPTION,
    ]

    if (!isEmpty(item.tours) && item.actual) {
      options.push({ value: EXPOSITION_TOUR_KIND, label: i18n.t(`pages.exposition.aside.${EXPOSITION_TOUR_KIND}.title`) });
    }
    if (!isEmpty(item.images) || !isEmpty(item.posts)) {
      options.push({ value: EXPOSITION_PUBLICATIONS_KIND, label: i18n.t(`pages.exposition.aside.${EXPOSITION_PUBLICATIONS_KIND}.title`) });
    }

    this.setState({options});
  }

  render() {
    const { item, fetchExposition } = this.props;
    const { sections } = this.state;

    return(
      <div className="p-exhibition__body">
        <div className="p-exhibition__body-sidebar">
          <div className="p-exhibition__body-sidebar-menu">
            <ul className="p-exhibition__body-sidebar-menu-list">
              {sections.map((item, index) => {
                const className = cn(
                  'p-picture__info-sidebar-menu-link',
                  {
                    'state-selected': (location.hash === `#${item}`) || (item === 'about' && isEmpty(location.hash))
                  }
                )
                return (
                  <li key={index} className="p-picture__info-sidebar-menu-item">
                    <a
                      href={`#${item}`}
                      title={i18n.t(`pages.exposition.aside.${item}.title`)}
                      className={className}
                    >
                      {i18n.t(`pages.exposition.aside.${item}.title`)}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="p-exhibition__body-description">
          <div className="p-exhibition__body-section p-exhibition__body-about" id="#about">
            <Truncated
              className="p-exhibition__body-description-text"
              annotation={item.annotation}
              text={item.description}
            />
          </div>
          {item.actual && item.booking_available &&
            <Tours
              item={item}
              fetchExposition={fetchExposition}
            />
          }
          {!isEmpty(item.images) &&
            <Images item={item} />
          }
          <Publications
            item={item}
            fetchExposition={fetchExposition}
          />
        </div>
      </div>
    )
  }
}

Content.propTypes = {
  item: PropTypes.shape(EXPOSITION_SHAPE).isRequired,
  fetchExposition: PropTypes.func.isRequired,
};

export default compose(withRouter)(Content)
