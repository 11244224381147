import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";
import * as QueryString from "query-string";
import isEmpty from "lodash/isEmpty";

import { API, PAINTINGS_PER_PAGE } from "../config";
import {
  PAINTING_PREVIEWS,
  PAINTING_ITEM,
  PAINTINGS_BY_PROPERTIES_FILTER,
  PAINTINGS_BY_NOVELTY_FILTER,
  PAINTINGS_FILTER_RESET,
  PAINTINGS_BY_ARTISTS_FILTER,
  PAINTINGS_FILTER_UPDATE_HISTORY,
} from "../constants/paintingConstants";
import {
  paintingPreviewSchema,
  paintingSchema
} from "../schemas";
import { DEFAULT_COLLECTION } from "../selectors/paintingPreviews";
import { FUNC_REPLACE } from "../reducers/entities/collectionsReducer";
import { history } from "../store";


export const defaultArtists = QueryString.stringify({
  artists: []
}, {arrayFormat: 'bracket'});

export const defaultProperties = QueryString.stringify({
  properties: []
}, {arrayFormat: 'bracket'});

export const defaultNovelties = QueryString.stringify({
  novelties: []
}, {arrayFormat: 'bracket'});


export function fetchItems(
    artists = defaultArtists,
    properties = defaultProperties,
    novelties = defaultNovelties,
    century = null,
    page = 1,
    perPage = PAINTINGS_PER_PAGE,
    fetchOptions = {}
  ) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE,
  };

  let endpoint = `${API}/paintings?page=${page}&per_page=${perPage}`
  if (artists) {
    endpoint = `${endpoint}&${artists}`
  }
  if (properties) {
    endpoint = `${endpoint}&${properties}`
  }
  if (novelties) {
    endpoint = `${endpoint}&${novelties}`
  }
  if (century) {
    endpoint = `${endpoint}&century=${century}`
  }

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: endpoint,
        types: PAINTING_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { paintings, meta } = await res.json();

      return {
        ...normalize(paintings, [paintingPreviewSchema]),
        pagination: meta,
      };
    }
  };
}


export function fetchItem(id, fetchOptions = {}) {
  const meta = {
    key: id,
  };
  const include = fetchOptions.include || {};

  return function fetchItemThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/paintings/${id}`,
        types: PAINTING_ITEM.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { painting } = await res.json();

      return {
        ...normalize(painting, paintingSchema),
      }
    }
  }
}


export function fetchSimilar(id, page = 1, perPage = PAINTINGS_PER_PAGE, fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE,
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/paintings/${id}/similar?page=${page}&per_page=${perPage}`,
        types: PAINTING_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { paintings, meta } = await res.json();

      return {
        ...normalize(paintings, [paintingPreviewSchema]),
        pagination: meta,
      };
    }
  }
}

function updateHistory({artists, properties}) {
  return function(dispatch) {
    const artistParams = QueryString.stringify({
      artists: artists.map((s) => s.id)
    }, {arrayFormat: 'bracket'});

    const propertyParams = QueryString.stringify({
      properties: properties.map((s) => s.id)
    }, {arrayFormat: 'bracket'});

    console.log(location.pathname)
    if (!isEmpty(artistParams) && !isEmpty(propertyParams)) {
      history.push(`${location.pathname}?${artistParams}&${propertyParams}`);
    } else if (!isEmpty(artistParams)) {
      history.push(`${location.pathname}?${artistParams}`);
    } else if (!isEmpty(propertyParams)) {
      history.push(`${location.pathname}?${propertyParams}`);
    }

    return dispatch({
      type: PAINTINGS_FILTER_UPDATE_HISTORY,
      payload: {}
    });
  }
}


export function filterBy(selectedArtists = [], selectedProperties = []) {
  return function(dispatch) {
    dispatch(updateHistory({artists: selectedArtists, properties: selectedProperties}));
    dispatch(filterByArtists(selectedArtists));
    dispatch(filterByProperties(selectedProperties));
  }
}


export function filterByArtists(options) {
  return function(dispatch) {
    return dispatch({
      type: PAINTINGS_BY_ARTISTS_FILTER,
      payload: options
    });
  }
}


export function filterByProperties(options) {
  return function(dispatch) {
    return dispatch({
      type: PAINTINGS_BY_PROPERTIES_FILTER,
      payload: options
    });
  }
}


export function fiterByNovelty(options) {
  return function(dispatch) {
    return dispatch({
      type: PAINTINGS_BY_NOVELTY_FILTER,
      payload: options
    });
  }
}


export function resetFilter() {
  return function(dispatch) {
    dispatch(fetchItems());

    history.push("/paintings");

    return dispatch({
      type: PAINTINGS_FILTER_RESET,
      payload: {}
    });
  }
}
