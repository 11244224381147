import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import shuffle from "lodash/shuffle";

import i18n from "../../../translations/i18n";
import Slide from "./slide";
import { POST_SHAPE } from "../../../constants/postConstants";
import Link from "../../../components/ui/link";

const SETTINGS = {
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  pauseOnHover: true,
  easing: "linear",
  responsive: [
    {
      breakpoint: 1106,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: "unslick"
    }
  ]
}


class Gallery extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { posts, withLink } = this.props;

    return (
      <div className="container">
        <div className="b-journal-carousel__content">
          <Slider
            className="b-journal-carousel__row"
            ref={slider => (this.slider = slider)}
            {...SETTINGS}
          >
            {shuffle(posts).map((post) => <Slide key={post.id} item={post} />)}
          </Slider>
        </div>
        {withLink &&
          <div className="button__wrapper b-journal-carousel__button-wrapper">
            <Link
              to="/posts"
              className="button button-more"
              title={i18n.t("pages.post.similar.more.title")}
            >
              {i18n.t("pages.post.similar.more.title")}
            </Link>
          </div>
        }
      </div>
    );
  }
}

Gallery.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape(POST_SHAPE)),
  withLink: PropTypes.bool
};

Gallery.defaultProps = {
  withLink: false,
};

export default Gallery
