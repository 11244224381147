import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import i18n from "../../../translations/i18n";
import Icon from "../../ui/icon";


class Social extends Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { items, onClick, wrapperClassName } = this.props;

    return (
      <div className={cn('social-group', wrapperClassName)}>
        {(items || []).map((item, index) =>
          <div className="social-group__item" key={index}>
            <a
              href={i18n.t(`social.${item}.link`)}
              target="_blank"
              title={i18n.t(`social.${item}.title`)}
              className="social-group__link"
              onClick={()=>onClick(i18n.t(`social.${item}.title`))}
              rel="noopener"
            >
              <Icon
                icon={item}
                className={cn('social-group__icon', `social-group__icon-${item}`)}
              />
            </a>
          </div>
        )}
      </div>
    );
  }
}

Social.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func
};

export default Social
