import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../translations/i18n";
import { POST_SHAPE } from "../../../../constants/postConstants";
import Favorite from "../../../layout/favorite";
import Link from "../../../../components/ui/link";


class Item extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item, onFavorite } = this.props;
    const postPath = `/posts/${item.slug}`

    return (
      <>
        <div
          className="p-journal__photos"
          style={{backgroundImage: `url(${item.poster.main})`}}
        >
          <Link
            to={postPath}
            className="p-journal__photos-link"
          >
          </Link>
          <div className="p-journal__photos-content">
            <div className="p-journal__photos-date">
              {item.date}
            </div>
            <div className="p-journal__photos-title">
              <Link to={postPath}>
                <span dangerouslySetInnerHTML={{__html: item.name}} />
              </Link>
            </div>
            <div
              className="p-journal__photos-description"
              dangerouslySetInnerHTML={{__html: item.annotation}} />
            <div className="p-journal__photos-media">
              <Link
                to={postPath}
                className="p-journal__photos-read-more"
                title={i18n.t('pages.posts.actions.view.title')}
              >
                {i18n.t('pages.posts.actions.view.title')}
              </Link>
              <Favorite
                favorite={item.favorite}
                isShort={true}
                onFavorite={onFavorite}
              />
            </div>
          </div>
        </div>
        <div></div>
      </>
    );
  }
}

Item.propTypes = {
  item: PropTypes.shape(POST_SHAPE).isRequired,
  onFavorite: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)
