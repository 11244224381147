import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../../translations/i18n";
import { POST_SHAPE } from "../../../../constants/postConstants";
import Favorite from "../../../../components/layout/favorite";
import Share from "../../../../components/post/share";


class Head extends React.Component {

  constructor(props) {
    super(props);
  }

  handleFavorite = () => {
    this.props.fetchPost();
  }

  render () {
    const { item } = this.props;

    return (
      <div className="p-photos__head">
        <div className="p-photos__head-content">
          <div className="container">
            <div className="p-photos__head-date">
              <div
                className="p-photos__head-date-item"
                dangerouslySetInnerHTML={{__html: i18n.t('pages.posts.title')}}
              />
              <div className="p-photos__head-date-item">
                {item.date}
              </div>
            </div>
            <h1 className="p-photos__head-title">
              {item.name}
            </h1>
            <div className="p-photos__head-media">
              <div className="p-photos__head-media-favorite">
                <Favorite
                  favorite={item.favorite}
                  onFavorite={this.handleFavorite}
                />
              </div>
              <div className="p-photos__head-media-share">
                <div className="p-photos__head-media-share-name">
                  {i18n.t('helpers.share.title')}
                </div>
                <Share slug={item.slug} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Head.propTypes = {
  item: PropTypes.shape(POST_SHAPE).isRequired,
  fetchPost: PropTypes.func.isRequired,
};

export default Head