import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../translations/i18n";
import {
  closeModal,
} from "../../../../actions/modalActions";
import { getExpositionOrder } from "../../../../selectors/expositionOrders";
import {
  EXPOSITION_ORDER_ITEM_EXPOSITION_TYPE,
  EXPOSITION_ORDER_ITEM_TOUR_TYPE
} from "../../../../constants/expositionOrderConstants";
import { EXPOSITION_ORDER_SUCESS_MODAL } from "../../../../constants/modalConstants";
import BaseModal from "../../../ui/modal/base";
import Link from "../../../ui/link";
import Icon from "../../../ui/icon";
import Info from "../../../exposition/info";


class ExpositionOrderSuccessModal extends Component {

  constructor(props) {
    super(props);
  }

  handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.closeModal();
  }

  renderPrice(item) {
    const isMultiple = item.count > 1;

    return(
      <>
        {isMultiple &&
          <>
            {item.count}&nbsp;X&nbsp;
          </>
        }
        <>
          {i18n.t('pages.exposition_order.item.price.amount', {amount: item.price.amount})}
        </>
      </>
    )
  }

  renderFree(item) {
    const isMultiple = item.count > 1;

    return(
      <>
        {isMultiple &&
          <>
            {item.count}&nbsp;X&nbsp;
          </>
        }
        <span>
          {i18n.t('pages.exposition_order.item.price.free')}
        </span>
      </>
    )
  }

  render() {
    const { modal, expositionOrder } = this.props;

    return (
      <BaseModal
        className="modal modal-exbititonsCart"
        isOpen={modal.name === EXPOSITION_ORDER_SUCESS_MODAL}
        onClose={this.handleClose}
      >
        {expositionOrder &&
          <>
            <div className="modal-exbititonsCart-title modal-exbititonsCart-title-1">
              {i18n.t('modal.exposition_order.success.title', {id: expositionOrder.id})}
            </div>
            <div className="modal-exbititonsCart-list">
              <ul>
                {(expositionOrder.items || []).map((item) =>
                  <li
                    key={item.id}
                    className="modal-exbititonsCart-list-item"
                  >
                    <div className="modal-exbititonsCart-list-item-type">
                      <div className="modal-exbititonsCart-list-item-title">
                        <span>
                          {item && item.type === EXPOSITION_ORDER_ITEM_EXPOSITION_TYPE &&
                            <>
                              {i18n.t('modal.exposition_order.success.type.exposition', {title: item.price.title})}
                            </>
                          }
                          {item && item.type === EXPOSITION_ORDER_ITEM_TOUR_TYPE &&
                            <>
                              {i18n.t('modal.exposition_order.success.type.tour', {title: item.price.title})}
                            </>
                          }
                        </span>
                        {item.name}
                        <div className="modal-exbititonsCart-list-item-info">
                          <Info
                            date={item.info.date}
                            place={item.info.place}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-exbititonsCart-cost">
                      {item.price.amount === 0 ? this.renderFree(item) : this.renderPrice(item)}
                    </div>
                  </li>
                )}
              </ul>
            </div>
            <div className="modal-exbititonsCart-total">
              {i18n.t('modal.exposition_order.success.total.title', {total: expositionOrder.total})}
            </div>
            <div className="modal-exbititonsCart-title modal-exbititonsCart-title-2">
              {i18n.t('modal.exposition_order.success.details.title')}
              <Link to="/account">
                {i18n.t('modal.exposition_order.success.details.link')}
              </Link>
            </div>
            <div>
              <a
                href=""
                className="button modal-exbititonsCart-button"
                onClick={this.handleClose}
              >
                {i18n.t('modal.exposition_order.success.button.title')}
              </a>
            </div>
            <div className="modal-exbititonsCart-title modal-exbititonsCart-title-3">
              {i18n.t('modal.exposition_order.success.help.title')}
            </div>
            <div className="modal-ecursion__contacts">
              <ul>
                <li>
                  <a
                    href={`tel:${i18n.t('footer.contacts.phone.title')}`}
                    className="modal-ecursion__contacts-phone"
                  >
                    <Icon icon="phone" />
                  </a>
                </li>
                <li>
                  <a
                    href={`mailto:${i18n.t('footer.contacts.email.title')}`}
                    className="modal-ecursion__contacts-email"
                  >
                    <Icon icon="email" />
                  </a>
                </li>
              </ul>
            </div>
          </>
        }
      </BaseModal>
    )
  }
}

ExpositionOrderSuccessModal.propTypes = {
  modal: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { layout } = state;
  const expositionOrderId = layout.modal.expositionOrderId;

  return {
    modal: layout.modal,
    expositionOrder: getExpositionOrder(state, expositionOrderId),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpositionOrderSuccessModal)
