import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { BANNER_PREVIEWS } from "../../../constants/bannerConstants";
import { makeCollectionsReducer } from "../collectionsReducer";
import { bannerPreviewSchema } from "../../../schemas";


export default combineReducers({
  byId: makeByIdReducer(bannerPreviewSchema.key, [BANNER_PREVIEWS.SUCCESS]),
  collections: makeCollectionsReducer(BANNER_PREVIEWS.typeNames()),
});