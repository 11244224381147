import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import i18n from "../../../../translations/i18n";
import { withFavoritePaintings } from "../../../../hocs/entities/withFavoritePaintings";
import { PAINTING_SHAPE } from "../../../../constants/paintingConstants";
import { FAVORITE_PAINTINGS_PER_PAGE } from "../../../../config";
import Empty from "../../../../components/account/empty";
import List from "./list";


class FavoritePaintings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    }
  }

  componentDidMount() {
    this.props.fetchFavoritePaintings();
  }

  handleLoad = () => {
    const { pagination, fetchFavoritePaintings } = this.props;
    if (event) {
      event.preventDefault();
    }

    if (!isNil(pagination.next_page)) {
      this.setState({currentPage:  pagination.next_page});
      fetchFavoritePaintings(pagination.next_page);
    }
  }

  handleAppointment = () => {
    const { currentPage } = this.state;
    this.props.fetchFavoritePaintings(1, currentPage * FAVORITE_PAINTINGS_PER_PAGE);
  }

  handleFavorite = () => {
    const { currentPage } = this.state;
    this.props.fetchFavoritePaintings(1, currentPage * FAVORITE_PAINTINGS_PER_PAGE);
  }

  render () {
    const { paintings, pagination } = this.props;

    return (
      <div className="p-profile__paintings">
        <h3 className="p-profile__title">
          {i18n.t("pages.account.paintings.title")}
        </h3>
        {isEmpty(paintings)
          ? <Empty
              to="/paintings"
              title={i18n.t('pages.account.paintings.empty.title')}
              button={i18n.t('pages.account.paintings.empty.button')}
            />
          : <>
              <List
                items={paintings}
                onAppointment={this.handleAppointment}
                onFavorite={this.handleFavorite}
              />
              {!isNil(pagination.next_page) &&
                <div className="button__wrapper">
                  <a
                    href="#"
                    className="button button-more"
                    title={i18n.t('pages.account.paintings.more.title')}
                    onClick={this.handleLoad}
                  >
                    {i18n.t('pages.account.paintings.more.title')}
                  </a>
                </div>
              }
            </>
        }
      </div>
    );
  }
}

FavoritePaintings.propTypes = {
  paintings: PropTypes.arrayOf(PropTypes.shape(PAINTING_SHAPE)),
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withFavoritePaintings,
  connect(mapStateToProps, mapDispatchToProps)
)(FavoritePaintings)
