import React, { createRef } from "react";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";
import { Redirect } from "react-router";
import i18n from "../../../translations/i18n";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createItem, createCachedItem, clearCachedItem } from "../../../actions/paintingOfferActions";
import { animateScroll as scroll } from 'react-scroll'


import FormGroup from "../../../components/ui/form/group";
import InputField from "../../../components/ui/form/input_field";
import SelectInput from "../../../components/ui/form/select_input";
import MaskInputField from "../../../components/ui/form/mask_input_field";
import { PROPERTY_TECHNIQUE_KIND } from "../../../constants/propertyConstants";
import {
  GA_PARTNERS_SOURCE,
  TrackFieldError,
  TrackFieldValid,
  TrackTechniqueClick,
  TrackPaintingOfferClick,
  TrackPaintingOfferSubmitError,
  TrackPaintingOfferSubmitSuccess,
} from "../../../helpers/analytics";
import emailDomainIsInvalid from "../../../helpers/invalidEmailDomains";
import phoneIsLandline from "../../../helpers/landlinePhoneCodes";


class PaintingOfferWrapper extends React.Component {

  SCROLL_OFFSET=200

  DEFAULT_STATE={
    redirectToLogin: false
  }

  constructor(props) {
    super(props);
    this.state = this.DEFAULT_STATE;
    this.paintingOfferRef = createRef();
  }

  handleSubmit = (values) => {
    const { createItem } = this.props;

    TrackPaintingOfferSubmitSuccess();

    createItem(values)
    window.scrollTo(0, this.paintingOfferRef.current.offsetTop - this.SCROLL_OFFSET)
  }

  handleClearCache = () => {
    const { clearCachedItem } = this.props;
    clearCachedItem();
    this.setState(this.DEFAULT_STATE);
  }

  componentDidMount() {
    const { userLoggedIn, paintingOffer, createItem, clearCachedItem } = this.props;

    if (paintingOffer.submitted) {
      clearCachedItem();
      this.setState(this.DEFAULT_STATE)
    }
    else if (paintingOffer.cached && paintingOffer.redirected) {
      if (userLoggedIn) {
        createItem(paintingOffer.cached);
        window.scrollTo(0, this.paintingOfferRef.current.offsetTop - this.SCROLL_OFFSET)
      } else {
        //TODO set formik values if not logged in
        this.setState(paintingOffer.cached);
      }
    }
  }

  techniqueOptions = (properties) => {
    return (properties || []).filter((i) => i.kind == PROPERTY_TECHNIQUE_KIND).map((technique) => ({value: technique.id, label: technique.name}))
  }

  render() {

    if (this.state.redirectToLogin) {
      return (
        <Redirect to="/login"/>
      )
    }

    return <PaintingOfferWithFormik
      {...this.props}
      handleSubmit={this.handleSubmit}
      handleClearCache={this.handleClearCache}
      techniqueOptions={this.techniqueOptions}
      paintingOfferRef={this.paintingOfferRef}
    />
  }
}

class PaintingOffer extends React.Component {

  DEFAULT_STATE={
    technique: undefined,
    files: []
  }

  constructor(props) {
    super(props);
    this.state = this.DEFAULT_STATE;
  }

  handleTechniqueChange = (e) => {
    TrackTechniqueClick();
    this.setState({technique: e.value});
    this.props.setFieldValue("technique", e.value);
  }

  handleSelectFile = (e) => {
    if (this.state.files.filter((i) => i.name == e.currentTarget.files[0].name).length == 0) {
      const newFiles = this.state.files.concat([e.currentTarget.files[0]])
      this.setState({files: newFiles});
      this.props.setFieldValue("files", newFiles);
    }
  }

  handleRemoveFile = (file) => {
    const newFiles = this.state.files.filter((f) => f.name != file.name)
    this.setState({files: newFiles})
    this.props.setFieldValue("files", newFiles);
  }

  selectedFiles = () => {
    return this.state.files.map((file) => {
      return (
        <div className="p-partners__form-image-wrapper" key={file.name}>
          <a className="p-partners__form-image-close tooltip-top tooltip-mobile" onClick={() => this.handleRemoveFile(file)}>
            {i18n.t('pages.partners.form.delete_image')}
          </a>
          <img src={URL.createObjectURL(file)} className="p-partners__form-image"/>
        </div>
      );
    })
  }

  secondPartVisible = () => {
    return this.props.values.author && this.props.values.author.length > 0 && this.props.values.name && this.props.values.name.length > 0
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSubmitting && !this.props.isSubmitting && !this.props.isValid) {
      scroll.scrollTo( document.querySelector(`[name="${Object.keys(this.props.errors)[0]}"]`).offsetParent.offsetTop - 200, {
        duration: 500,
        delay: 100,
        smooth: true
      })
    }
  }

  render() {
    const {
      touched,
      properties,
      paintingOffer,
      handleSubmit,
      handleClearCache,
      techniqueOptions,
      paintingOfferRef,
      userLoggedIn
    } = this.props;

    return (
      <>
        <div id="painting_offer" className="painting-offer-anchor"/>
        <div id="painting_offer_form" ref={paintingOfferRef}></div>

        <div className="p-partners__form">
          <div className="container">
            <div className="p-partners__form-content">
              <div className="p-partners__form-title">
                {i18n.t('pages.partners.form.title')}
              </div>
              { paintingOffer.submitted && (
                <div id="success" className="p-partners__form-completed">
                  <p dangerouslySetInnerHTML={{ __html: i18n.t('pages.partners.form.completed.title')}}/>
                  <a onClick={handleClearCache}>
                    {i18n.t('pages.partners.form.completed.link')}
                  </a>
                </div>
              ) || (
                <div className="p-partners__form-wrapper">
                  <Form>
                    <div className="p-partners__form-section">
                      <div className="p-partners__form-field-wrapper">
                        <FormGroup
                          className="form__group"
                          label={i18n.t('pages.partners.form.author.label')}
                          touched={touched.author}>
                          <Field
                            component={InputField}
                            className="form__field p-partners__form-field"
                            name="author"
                            required
                          />
                        </FormGroup>
                        <FormGroup
                          className="form__group"
                          label={i18n.t('pages.partners.form.name.label')}
                          touched={touched.name}>
                          <Field
                            component={InputField}
                            className="form__field p-partners__form-field"
                            name="name"
                            required
                          />
                        </FormGroup>
                        {this.secondPartVisible() && (
                          <>
                            <div className="form__fieldset form__fieldset-1">
                              <FormGroup
                                className="form__group"
                                label={i18n.t('pages.partners.form.year.label')}
                                touched={touched.year}>
                                <Field
                                  component={InputField}
                                  className="form__field p-partners__form-field"
                                  name="year"
                                  required
                                />
                              </FormGroup>
                              <div className="form__group">
                                <SelectInput
                                  options={techniqueOptions(properties)}
                                  classNamePrefix="react-select"
                                  placeholder={i18n.t('pages.partners.form.technique')}
                                  onChange={this.handleTechniqueChange}
                                />
                              </div>
                            </div>
                            <div className="form__fieldset form__fieldset-2">
                              <FormGroup
                                className="form__group"
                                label={i18n.t('pages.partners.form.length.label')}
                                touched={touched.length}>
                                <Field
                                  component={InputField}
                                  className="form__field p-partners__form-field"
                                  name="length"
                                  required
                                />
                              </FormGroup>
                              <FormGroup
                                className="form__group"
                                label={i18n.t('pages.partners.form.width.label')}
                                touched={touched.width}>
                                <Field
                                  component={InputField}
                                  className="form__field p-partners__form-field"
                                  name="width"
                                  required
                                />
                              </FormGroup>
                              <FormGroup
                                className="form__group"
                                label={i18n.t('pages.partners.form.height.label')}
                                touched={touched.height}>
                                <Field
                                  component={InputField}
                                  className="form__field p-partners__form-field"
                                  name="height"
                                  required
                                />
                              </FormGroup>
                            </div>
                            <div className="section-title p-partners__form-section-title">
                              {i18n.t('pages.partners.form.section_title')}
                            </div>
                            <div className="section-subtitle p-partners__form-section-subtitle">
                              {i18n.t('pages.partners.form.section_subtitle')}
                            </div>
                            <div className="p-partners__form-field-wrapper">
                              <FormGroup
                                className="form__group"
                                label={i18n.t('pages.partners.form.collections.label')}
                                touched={touched.collections || (this.props.values.collections && this.props.values.collections.length > 0)}>
                                <Field
                                  component={InputField}
                                  className="form__field p-partners__form-field"
                                  name="collections"
                                  component="textarea"
                                />
                                <div className="form__group-help">
                                  {i18n.t('pages.partners.form.collections.help')}
                                </div>
                              </FormGroup>
                              <FormGroup
                                className="form__group"
                                label={i18n.t('pages.partners.form.exhibitions.label')}
                                touched={touched.exhibitions || (this.props.values.exhibitions && this.props.values.exhibitions.length > 0)}>
                                <Field
                                  component={InputField}
                                  className="form__field p-partners__form-field"
                                  name="exhibitions"
                                  component="textarea"
                                />
                                <div className="form__group-help">
                                  {i18n.t('pages.partners.form.exhibitions.help')}
                                </div>
                              </FormGroup>
                              <FormGroup
                                className="form__group"
                                label={i18n.t('pages.partners.form.publications.label')}
                                touched={touched.publications || (this.props.values.publications && this.props.values.publications.length > 0)}>
                                <Field
                                  component={InputField}
                                  className="form__field p-partners__form-field"
                                  name="publications"
                                  component="textarea"
                                />
                                <div className="form__group-help">
                                  {i18n.t('pages.partners.form.publications.help')}
                                </div>
                              </FormGroup>
                              <FormGroup
                                className="form__group"
                                label={i18n.t('pages.partners.form.examinations.label')}
                                touched={touched.examinations || (this.props.values.examinations && this.props.values.examinations.length > 0)}>
                                <Field
                                  component={InputField}
                                  className="form__field p-partners__form-field"
                                  name="examinations"
                                  component="textarea"
                                />
                                <div className="form__group-help">
                                  {i18n.t('pages.partners.form.examinations.help')}
                                </div>
                              </FormGroup>
                              <FormGroup
                                className="form__group"
                                label={i18n.t('pages.partners.form.comment.label')}
                                touched={touched.comment || (this.props.values.comment && this.props.values.comment.length > 0)}>
                                <Field
                                  component={InputField}
                                  className="form__field p-partners__form-field"
                                  name="comment"
                                  component="textarea"
                                />
                              </FormGroup>
                            </div>

                            { !userLoggedIn && (
                              <>
                                {/* Данные пользователя */}
                                <div className="p-partners__form-section">
                                  <div className="section-title p-partners__form-section-title-without-subtitle">
                                    {i18n.t('pages.about.order.user.title')}
                                  </div>

                                  <FormGroup
                                    className="form__group"
                                    label={i18n.t('pages.partners.form.username.label')}
                                    touched={touched.username}>
                                    <Field
                                      component={InputField}
                                      className="form__field p-partners__form-field"
                                      name="username"
                                      required
                                    />
                                  </FormGroup>

                                  <FormGroup
                                    className="form__group"
                                    label={i18n.t('pages.partners.form.phone.label')}
                                    touched={touched.phone}>
                                    <Field
                                      component={MaskInputField}
                                      className="form__field p-partners__form-field"
                                      mask="+7 (999) 999-99-99"
                                      name="phone"
                                    />
                                  </FormGroup>

                                  <FormGroup
                                    className="form__group"
                                    label={i18n.t('pages.partners.form.email.label')}
                                    touched={touched.email}>
                                    <Field
                                      component={InputField}
                                      className="form__field p-partners__form-field"
                                      name="email"
                                      required
                                    />
                                  </FormGroup>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {this.secondPartVisible() && (
                      <>
                        <div className="p-partners__form-images">
                          {this.selectedFiles()}
                        </div>
                        <div className="p-partners__form-section">
                          <div className="p-partners__form-button-wrapper p-partners__form-button-file-upload-wrapper">
                            <label htmlFor="file-upload" className="button p-partners__form-button p-partners__form-button-file-upload" type="button">
                              {i18n.t('pages.partners.form.upload_images')}
                            </label>
                            <input style={{display:"none"}} id="file-upload" name="file" type="file" onChange={this.handleSelectFile} />
                          </div>
                          <div className="p-partners__form-notice">
                            <p>{i18n.t('pages.partners.form.notice.title')}</p>
                            <ul>
                              {[1,2,3,4,5].map((item) =>
                                <li key={item}>{i18n.t(`pages.partners.form.notice.list.${item}`)}</li>
                              )}
                            </ul>
                            <p>{i18n.t('pages.partners.form.notice.allowed')}</p>
                          </div>
                          <div className="p-partners__form-button-wrapper">
                            <button
                              className="button p-partners__form-button p-partners__form-button-send"
                              type="submit"
                              onSubmit={handleSubmit}
                            >
                              {i18n.t('shared.button.send')}
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const PaintingOfferWithFormik = withFormik({
  handleSubmit: (values, {props: {handleSubmit}}) => handleSubmit(values),
  validate: (values, props) => {
    let errors = {};

    TrackPaintingOfferClick();

    const requiredFields = ['author', 'name', 'height', 'length', 'width']
    const requiredUserFields = ['username', 'email']

    requiredFields.map((field) => {
      if (isEmpty(values[field])) {
        errors[field] = i18n.t("validation.field.required");
      }
    });

    if (!props.userLoggedIn) {
      requiredUserFields.map((field) => {
        if (isEmpty(values[field])) {
          TrackFieldError(field, 'empty', GA_PARTNERS_SOURCE);
          errors[field] = i18n.t("validation.field.required");
        } else {
          TrackFieldValid(field, GA_PARTNERS_SOURCE);
        }
      });

      if (!isEmpty(values.phone) && (!/^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/i.test(values.phone))) {
        TrackFieldError('phone', 'invalid', GA_PARTNERS_SOURCE);
        errors.phone = i18n.t("validation.phone.invalid");
      } else if (!isEmpty(values.phone) && phoneIsLandline(values.phone)) {
        TrackFieldError('phone', 'invalid', GA_PARTNERS_SOURCE);
        errors.phone = i18n.t("validation.phone.landline");
      } else {
        TrackFieldValid('phone', GA_PARTNERS_SOURCE);
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        TrackFieldError('email', 'invalid', GA_PARTNERS_SOURCE);
        errors.email = i18n.t("validation.email.invalid");
      } else if (emailDomainIsInvalid(values.email)) {
        TrackFieldError('email', 'invalid', GA_PARTNERS_SOURCE);
        errors.email = i18n.t("validation.email.invalid");
      } else {
        TrackFieldValid('email', GA_PARTNERS_SOURCE);
      }
    }

    if (!isEmpty(errors)) {
      TrackPaintingOfferSubmitError();
    }

    return errors;
  }
})(PaintingOffer);

function mapStateToProps({currentUser, paintingOffer}) {
  return {
    userLoggedIn: !!currentUser.token,
    paintingOffer: paintingOffer
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createItem,
    createCachedItem,
    clearCachedItem
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PaintingOfferWrapper)
