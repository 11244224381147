import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../../translations/i18n";
import { PAINTING_SHAPE } from "../../../../../constants/paintingConstants";
import Poster from "../../../../../components/painting/poster";
import Ended from "../../../../../components/painting/ended";
import Dimensions from "../../../../../components/painting/dimensions";
import Title from "../../../../../components/painting/title";
import Appointment from "../../../../../components/layout/appointment";
import Favorite from "../../../../../components/layout/favorite";
import Link from "../../../../../components/ui/link";
import { GA_APPOINTMENT_FAVORITES_SOURCE } from "../../../../../helpers/analytics";


class Item extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item, onAppointment, onFavorite } = this.props;
    const paintingPath = `/paintings/${item.slug}`;

    return (
      <div className="b-paintings__item">
        <div className="b-paintings__card">
          <Link to={paintingPath} className="b-paintings__card-image-link">
            <Poster poster={item.poster} className="b-paintings__card-image" />
          </Link>
          <div className="b-paintings__card-content">
            <Link to={paintingPath} className="b-paintings__card-name">
              {item.new &&
                <span className="badge-new">{i18n.t('pages.paintings.new.title')}</span>
              }
              <Title painting={item} splited />
            </Link>
            <div className="b-paintings__card-date">
              <Ended title={item.ended_at} />
              <Dimensions height={item.height} width={item.width} />
            </div>
            <div className="b-paintings__card-media">
              <div className="b-paintings__card-media-signup-wrapper">
                <Appointment
                  className="b-paintings__card-media-signup"
                  available={item.available}
                  appointment={item.appointment}
                  source={GA_APPOINTMENT_FAVORITES_SOURCE}
                  onAppointment={onAppointment}
                />
              </div>
              <div className="b-paintings__card-media-favorite">
                <Favorite
                  favorite={item.favorite}
                  isShort={true}
                  removeClassName="remove-favorite"
                  removeTitle={i18n.t('helpers.remove_from_fav.title')}
                  onFavorite={onFavorite}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  item: PropTypes.shape(PAINTING_SHAPE).isRequired,
  isLarge: PropTypes.bool,
  onAppointment: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)
