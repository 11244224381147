import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as QueryString from "query-string";

import { fetchItems } from "../../actions/searchActions";
import {
  makeSearchPreviewsSelector,
  makeSearchPreviewsAnyKeySelector,
  makeSearchPreviewsFetchStatusSelector,
} from "../../selectors/searchPreviews";
import { SEARCH_RESULT_SHAPE } from "../../constants/searchConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withSearch(WrappedComponent) {

  const WithSearch = class extends Component {

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    componentDidUpdate(prevProps) {
      const { location } = this.props;

      if (location && location !== prevProps.location) {
        const params = QueryString.parse(location.search);
        this.fetchSearchResults(params);
      }
    }

    fetchSearchResults = (params, currentPage = 1) => {
      const { fetchItems, fetchStatus } = this.props;

      if (!fetchStatus.isFetching) {
        fetchItems(params, currentPage);
      }
    };

    render() {
      const { results, pagination, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    results={results}
                    pagination={pagination}
                    fetchStatus={fetchStatus}
                    fetchSearchResults={this.fetchSearchResults}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithSearch.propTypes = {
    results: PropTypes.arrayOf(PropTypes.shape(SEARCH_RESULT_SHAPE)),
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const searchResultsSelector = makeSearchPreviewsSelector();
  const searchResultsPaginationSelector = makeSearchPreviewsAnyKeySelector('pagination');
  const searchResultsFetchStatusSelector = makeSearchPreviewsFetchStatusSelector();
  const searchResultsFacetsSelector = makeSearchPreviewsAnyKeySelector('facets');

  function mapStateToProps(state) {
    return {
      results: searchResultsSelector(state),
      pagination: searchResultsPaginationSelector(state) || {},
      fetchStatus: searchResultsFetchStatusSelector(state),
      facets: searchResultsFacetsSelector(state) || [],
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItems,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithSearch);
}