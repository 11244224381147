import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../../translations/i18n";
import { ARTIST_SHAPE } from "../../../../constants/aritstConstants";
import Description from "../../../../components/artist/description";


class Artist extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { artist } = this.props;

    return (
      <div className="p-picture__info-description-painter" id="#artist">
        <h3 className="p-picture__info-description-title">
          <span>{i18n.t('pages.painting.aside.artist.title')}</span>
        </h3>
        <Description
          withLink
          artist={artist}
          imgClassName="p-picture__info-description-painter-image"
          moreClassName="p-picture__info-description-read-more"
        />
      </div>
    )
  }
}

Artist.propTypes = {
  artist: PropTypes.shape(ARTIST_SHAPE).isRequired
};

export default Artist