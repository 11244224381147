import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cn from "classnames";

import { NOTICE_SHAPE } from "../../../constants/noticeConstants";
import Icon from "../../ui/icon";


class Notice extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { notice } = this.props;
    const noticeClassName = cn('w-notice', {'w-notice-success': notice.visible})

    return (
      <>
        { notice.visible &&
          <div className={noticeClassName}>
            <div className="w-notice__title">
              <Icon icon="check" />
              <span>{notice.title}</span>
            </div>
            { notice.description &&
              <div className="w-notice__description">
                {notice.description}
              </div>
            }
          </div>
        }
      </>
    )
  }
}

Notice.propTypes = {
  notice: PropTypes.shape(NOTICE_SHAPE).isRequired,
};

function mapStateToProps({layout}) {
  return {
    notice: layout.notice,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Notice)