import { denormalize } from "normalizr";

import { getEntities } from "./entities";
import { expositionSchema } from "../schemas";

export const DEFAULT_MODIFICATION = "form";

export function getExposition(state, id) {
  return denormalize(id, expositionSchema, getEntities(state));
}
