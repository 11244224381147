import React from "react";
import PropTypes from "prop-types";

import { BANNER_SHAPE } from "../../../../../constants/bannerConstants";
import Dimensions from "../../../../../components/painting/dimensions";


class Slide extends React.Component {

  constructor(props) {
    super(props);
  }

  handleClick = (event) => {
    event.preventDefault();
  }

  render () {
    const { item } = this.props;

    const descriptionClass = item.hide_properties_on_mobile ? "b-hero-carousel__item-description hidden-on-mobile" : "b-hero-carousel__item-description"

    return (
      <div
        className="b-hero-carousel__item"
        style={{backgroundImage: `url(${item.image})`}}
      >
        <a
          href={item.link}
          target="_blank"
          className="b-hero-carousel__item-link"
        >
          <div className="b-hero-carousel__item-content">
            { item.caption &&
              <div className="b-hero-carousel__item-name">
                {item.caption}
              </div>
            }
            <div className="b-hero-carousel__item-title" 
              dangerouslySetInnerHTML={{__html: item.name}}
            />
            <div className={descriptionClass}>
              <>
                {item.properties
                  ? <span>
                      {item.properties}
                    </span>
                  : <>
                      {item.made_of &&
                        <>
                          {item.made_of.map((property, index) =>
                            <span key={index}>
                              {property}&nbsp;·&nbsp;
                            </span>
                          )}
                        </>
                      }
                      <Dimensions height={item.height} width={item.width} />
                    </>
                }
              </>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

Slide.propTypes = {
  item: PropTypes.shape(BANNER_SHAPE),
};

export default Slide
