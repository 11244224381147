import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Form, withFormik } from "formik";

import i18n from "../../../../translations/i18n";
import { USER_SHAPE } from "../../../../constants/userConstants";
import { MODIFICATION_SHAPE } from "../../../../selectors/modifications";
import Loader from "../../../../components/ui/loadable/loader";


class AvatarForm extends Component {

  constructor(props) {
    super(props);

    this.ref = createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchStatus, setSubmitting } = this.props;

    if (prevProps.fetchStatus !== fetchStatus && !fetchStatus.isFetching) {
      setSubmitting(false);
    }
  }

  handleChoseFile = (event) => {
    event.preventDefault();

    if ((this.ref) && (this.ref.current)) {
      this.ref.current.click();
    }
  }

  handleUpload = (event) => {
    const { handleSubmit, setFieldValue } = this.props;

    setFieldValue("avatar", event.currentTarget.files);
    handleSubmit(event.currentTarget.files)
  }

  render() {
    const { account, fetchStatus } = this.props;

    return(
      <>
        { account.avatar
          ? <img
              className="p-profile__head-user-avatar-image tooltip-top tooltip-mobile"
              data-tooltip={i18n.t('pages.account.avarar.upload.title')}
              src={account.avatar}
              onClick={this.handleChoseFile}
            />
          : <div
              className="p-profile__head-user-avatar-uploader tooltip-top tooltip-mobile"
              data-tooltip={i18n.t('pages.account.avarar.upload.title')}
              onClick={this.handleChoseFile}
            />
        }
        <Loader isLoading={fetchStatus.isFetching} />

        <Form className="p-profile__head-user-avatar-upload">
          <input
            id="file"
            name="file"
            type="file"
            ref={this.ref}
            onChange={this.handleUpload}
          />
        </Form>
      </>
    )
  }
}

const AvatarFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};

    return errors;
  },
})(AvatarForm);

AvatarFormWithFormik.propTypes = {
  account: PropTypes.shape(USER_SHAPE).isRequired,
  fetchStatus: PropTypes.shape(MODIFICATION_SHAPE).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AvatarFormWithFormik