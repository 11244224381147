import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import {
  defaultArtists,
  defaultProperties,
  defaultNovelties,
} from "../../../actions/paintingActions";
import { withPaintings } from "../../../hocs/entities/withPaintings";
import {
  HOME_PAGE_PAINTINGS_LIMIT,
  HOME_PAGE_PAINTINGS_LARGE_LIMIT,
} from "../../../config";
import { PAINTING_SHAPE } from "../../../constants/paintingConstants";
import List from "./list";
import Link from "../../../components/ui/link";


class Novelties extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchPaintings(defaultArtists, defaultProperties, defaultNovelties, null, 1);
  }

  render () {
    const { paintings, fetchPaintings } = this.props;
    const isLarge = !isEmpty(paintings.slice(0, HOME_PAGE_PAINTINGS_LIMIT).filter((p) => p.large === true))

    return (
      <>
        {!isEmpty(paintings) &&
          <div className="b-paintings">
            <div className="section-title">
              {i18n.t("pages.home.novelties.title")}
            </div>
            <div className="container">
              <div className="b-paintings__content">
                <List
                  large
                  items={paintings.slice(0, (isLarge ? HOME_PAGE_PAINTINGS_LARGE_LIMIT : HOME_PAGE_PAINTINGS_LIMIT))}
                  onAppointment={fetchPaintings}
                  onFavorite={fetchPaintings}
                />
              </div>
              <div className="button__wrapper b-paintings__button-wrapper">
                <Link
                  to="/paintings"
                  className="button button-more"
                  title={i18n.t("pages.home.novelties.more.title")}
                >
                  {i18n.t("pages.home.novelties.more.title")}
                </Link>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

Novelties.propTypes = {
  paintings: PropTypes.arrayOf(PropTypes.shape(PAINTING_SHAPE)),
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withPaintings,
  connect(mapStateToProps, mapDispatchToProps)
)(Novelties)
