import {
  ARTIST_LETTERS_PREVIEWS,
} from "../../../constants/aritstConstants";

const DEFAULT_STATE = {
  letters: [],
};

export default function(state = DEFAULT_STATE, { type, payload, meta }) {
  switch (type) {
    case ARTIST_LETTERS_PREVIEWS.SUCCESS:
      return {
        ...state,
        letters: payload.letters,
      };
    default:
      return state;
  }
}