import {
  USER_SIGN_IN,
  USER_SIGN_UP,
  USER_SUBSCRIBE,
  USER_SIGN_OUT,
  USER_CHECK_TOKEN,
  USER_CHANGE_PASSWORD,
  USER_RESTORE_ACCOUNT,
} from "../constants/userConstants";

const DEFAULT_STATE = {
  id: null,
  email: null,
  token: null,
};

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case USER_SIGN_IN.SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case USER_SIGN_UP.SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case USER_SUBSCRIBE.SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case USER_CHECK_TOKEN.SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case USER_CHECK_TOKEN.FAILED:
      return {
        ...DEFAULT_STATE,
      };
    case USER_CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case USER_RESTORE_ACCOUNT.SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case USER_SIGN_OUT.SUCCESS: {
      return {
        ...DEFAULT_STATE,
      }
    }
    default:
      return state;
  }
}