import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import cn from "classnames";

import i18n from "../../../../translations/i18n";
import {
  restorePassword,
  resetAuthForm as reset
} from "../../../../actions/userActions";
import {
  closeModal
} from "../../../../actions/modalActions";
import {
  disableAppointment
} from "../../../../actions/appointmentActions";
import BaseModal from "../../../ui/modal/base";
import RestorePasswordForm from "./form";
import { RESTORE_PASSWORD_MODAL } from "../../../../constants/modalConstants";


class RestorePasswordModal extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.reset();
  }

  handleClose = (event) => {
    const { closeModal, disableAppointment } = this.props;
    event.preventDefault();

    closeModal();
    disableAppointment();
  }

  handleSubmit = (params) => {
    this.props.restorePassword(params);
  }

  render () {
    const { auth, modal } = this.props;

    return (
      <BaseModal
        className={cn('modal', { 'modal-message': !auth.passwordSended, 'modal-password': auth.passwordSended })}
        isOpen={modal.name === RESTORE_PASSWORD_MODAL}
        onClose={this.handleClose}
      >
        { auth.passwordSended
          ? <>
              <div className="modal__title">
                {i18n.t('pages.restore_password.sended.title')}
              </div>
              <div className="modal__form">
                <div className="modal__form-button-wrapper">
                  <a
                    href=""
                    className="button modal__form-button-ok"
                    onClick={this.handleClose}
                  >
                    {i18n.t('pages.restore_password.sended.button')}
                  </a>
                </div>
              </div>
            </>
          : <>
              <div className="modal__title">
                {i18n.t('pages.restore_password.title')}
              </div>
              <div className="modal__form">
                <RestorePasswordForm
                  initialValues={{email: ''}}
                  auth={auth}
                  onSubmit={this.handleSubmit}
                />
              </div>
            </>
        }
      </BaseModal>
    );
  }
}

RestorePasswordModal.propTypes = {
  restorePassword: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

function mapStateToProps({auth, layout}) {
  return {
    auth,
    modal: layout.modal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    restorePassword,
    reset,
    closeModal,
    disableAppointment,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(RestorePasswordModal)