import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Route, Redirect, Link } from "react-router-dom";

import i18n from "../../../translations/i18n";
import { withHeader } from "../../../hocs/layout/withHeader";
import {
  updatePassword,
  resetAuthForm as reset
} from "../../../actions/userActions";
import ChangePasswordForm from "./form";
import Meta from "../../../components/layout/meta";


class ChangePassword extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.reset();
  }

  handleSubmit = (params) => {
    this.props.updatePassword(params);
  }

  render () {
    const { auth, userLoggedIn } = this.props;

    return (
      <Route render={({location}) => {
        return userLoggedIn
          ? <Redirect to="/" />
          : <main className="layout auth">
              <Meta />
              <div className="modal modal-password">
                <div className="modal__container">
                  <div className="modal__inner">
                    <Link to="/" className="modal__close">
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L15.8492 15.8492" stroke="black" strokeWidth="2"/>
                        <path d="M16 1L1.15076 15.8492" stroke="black" strokeWidth="2"/>
                      </svg>
                    </Link>
                    <div className="modal__content">
                      <div className="modal__title">
                        {i18n.t('pages.change_password.title')}
                      </div>
                      <div className="modal__form">
                        <ChangePasswordForm
                          initialValues={{password: '', reset_password_token: location.search.replace("?reset_password_token=", "")}}
                          auth={auth}
                          location={location}
                          onSubmit={this.handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
      }} />
    );
  }
}

ChangePassword.propTypes = {
  updatePassword: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

function mapStateToProps({auth, currentUser}) {
  return {
    auth,
    userLoggedIn: !!currentUser.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updatePassword,
    reset
  }, dispatch)
}

export default compose(
  withHeader({transparent: true}),
  connect(mapStateToProps, mapDispatchToProps)
)(ChangePassword)
