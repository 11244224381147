import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import { INTERIOR_SHAPE } from "../../../../constants/interiorConstants";
import Slide from "./slide";

const SETTINGS = {
  speed: 500,
  centerMode: true,
  dots: false,
  slidesToShow: 1,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        arrows: false
      }
    },
  ],
}


class Gallery extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { images, onAppointment } = this.props;

    return (
      <div className="b-interior-carousel__content">
        <div className="p-picture__gallery-row">
          <Slider
            className="b-interior-carousel__row"
            ref={slider => (this.slider = slider)}
            {...SETTINGS}
          >
            {images.map((image) =>
              <Slide
                key={image.id}
                item={image}
                onAppointment={onAppointment}
              />
             )}
          </Slider>
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(INTERIOR_SHAPE)),
  onAppointment: PropTypes.func.isRequired,
};

export default Gallery
