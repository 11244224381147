import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";

import { API, INTERIORS_PER_PAGE } from "../config";
import { INTERIOR_PREVIEWS } from "../constants/interiorConstants";
import { interiorPreviewSchema } from "../schemas";
import { DEFAULT_COLLECTION } from "../selectors/interiorPreviews";
import { FUNC_REPLACE } from "../reducers/entities/collectionsReducer";


export function fetchItems(page = 1, perPage = INTERIORS_PER_PAGE, fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE,
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/interiors?page=${page}&per_page=${perPage}`,
        types: INTERIOR_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { interiors, meta } = await res.json();

      return {
        ...normalize(interiors, [interiorPreviewSchema]),
        pagination: meta,
      };
    }
  };
}