import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import { USER_SHAPE } from "../../../../constants/userConstants";
import { MODIFICATION_SHAPE } from "../../../../selectors/modifications";

import FormGroup from "../../../../components/ui/form/group";
import FormActions from "../../../../components/ui/form/actions";
import FormError from "../../../../components/ui/form/error";
import InputField from "../../../../components/ui/form/input_field";
import Button from "../../../../components/ui/form/button";


class DeleteForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchStatus, setSubmitting } = this.props;

    if (prevProps.fetchStatus !== fetchStatus && !fetchStatus.isFetching) {
      setSubmitting(false);
    }
  }

  render() {
    const {
      account,
      isSubmitting,
      handleSubmit,
    } = this.props;

    return(
      <div className="p-settings__form">
        <div className="p-settings__form-title">
          {i18n.t('pages.account.delete.title')}
        </div>

        <div className="p-settings__form-text">
          {i18n.t('pages.account.delete.text')}
        </div>

        <Form>
          <FormGroup touched={true}>
            <Field
              required
              component={InputField}
              name="password"
              type="password"
              placeholder={i18n.t("pages.account.delete.form.password.placeholder")}
            />
          </FormGroup>

          <FormGroup touched={true}>
            <Field
              required
              component={InputField}
              name="delete_reason"
              placeholder={i18n.t("pages.account.delete.form.delete_reason.placeholder")}
            />
          </FormGroup>

          <FormActions className="button-wrapper p-settings__form-button-wrapper">
            <FormError>
              {account.error}
            </FormError>
            <Button
              className="p-settings__form-button"
              type="submit"
              disabled={isSubmitting}
              onSubmit={handleSubmit}
            >
              {i18n.t("pages.account.delete.form.submit.title")}
            </Button>
          </FormActions>
        </Form>

      </div>
    )
  }
}

const DeleteFormFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};

    if (isEmpty(values.password)) {
      errors.password = i18n.t("validation.field.required");
    }
    if (isEmpty(values.delete_reason)) {
      errors.delete_reason = i18n.t("validation.field.required");
    }

    return errors;
  },
})(DeleteForm);

DeleteFormFormWithFormik.propTypes = {
  account: PropTypes.shape(USER_SHAPE).isRequired,
  fetchStatus: PropTypes.shape(MODIFICATION_SHAPE).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DeleteFormFormWithFormik
