import React from "react";
import PropTypes from "prop-types";


export function withErrorMessage(WrappedComponent) {
  const WrappedWithErrorMessage = function ({error, required, wrapperClassName, ...restProps}) {

    return (
      <>
        <WrappedComponent {...restProps} error={error} />
        {error && <span className="form__field-error__message">{error}</span>}
      </>
    )
  };

  WrappedWithErrorMessage.propTypes = {
    required: PropTypes.bool,
    error: PropTypes.string,
  };

  return WrappedWithErrorMessage;
}