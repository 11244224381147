import { GA_TRACKING_ENABLED } from "../../../config";

export function TrackAddPersonalTourLogin() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "make an personal_tour button",
    "'Заказать экскурсию' form",
    "'Заказать экскурсию' login form"
  )
}

export function TrackAddPersonalTourSubmitClick(source, expositionName = "") {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Заказать экскурсию'",
    "click",
    "exposition page " + expositionName
  )
}

export function TrackAddPersonalTourSubmitError() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Заказать'",
    "user_failure",
    "thank you page_make an personal_tour"
  )
}

export function TrackAddPersonalTourSubmitSuccess() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Заказать'",
    "success",
    "thank you page_make an personal_tour"
  )
}
