import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import { POST_SHAPE } from "../../../constants/postConstants";
import Head from "./head";
import Content from "./content";
import Similar from "../similar";
import Subscription from "../../../components/layout/subscription";


class Photoreport extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { post, fetchPost } = this.props;

    return (
      <>
        <div className="p-photos" id="article">
          <Head item={post} fetchPost={fetchPost} />
          <Content item={post} fetchPost={fetchPost} />
        </div>
        <Similar post={post} />
        <Subscription source="posts" />
      </>
    )
  }
}

Photoreport.propTypes = {
  post: PropTypes.shape(POST_SHAPE).isRequired,
  fetchPost: PropTypes.func.isRequired,
};

export default Photoreport
