import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../../translations/i18n";
import { openHeaderSearch, closeHeaderSearch } from "../../../../../actions/layoutActions";
import { getAccount } from "../../../../../selectors/accounts";
import { ACCOUNT_SHAPE } from "../../../../../constants/userConstants";


class SearchHistory extends Component {

  constructor(props) {
    super(props);
  }

  handleClick = (event, query) => {
    const { history, closeHeaderSearch } = this.props;
    event.preventDefault();

    closeHeaderSearch();
    history.push(`/search?q=${query}`)
  }

  render () {
    const { account } = this.props;

    return (
      <>
        { account && !isEmpty(account.searches) &&
          <ul>
            {account.searches.map((search) =>
              <li key={search.id}>
                <a
                  href="#"
                  className="header__search-link"
                  onClick={(event) => this.handleClick(event, search.query)}
                >
                  {search.query}
                </a>
              </li>
            )}
          </ul>
        }
      </>
    );
  }
}

SearchHistory.propTypes = {
  account: PropTypes.shape(ACCOUNT_SHAPE),
};

function mapStateToProps(state) {
  const currentUser = state.currentUser;
  return {
    account: getAccount(state, currentUser.id),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openHeaderSearch,
    closeHeaderSearch,
  }, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SearchHistory)