import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import { withServices } from "../../hocs/entities/withServices"
import { withExperts } from "../../hocs/entities/withExperts";
import Reasons from "../../components/layout/reasons";
import Meta from "../../components/layout/meta";
import ScrollHandler from "../../components/layout/scroll"
import Description from "./description";
import Services from "./services";
import Experts from "./experts";
import Order from "./order";
import { GA_ORDERS_ABOUT_SOURCE } from "../../helpers/analytics";


class About extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { services, experts } = this.props;

    return (
      <main className="layout">
        <Meta />
        <ScrollHandler />
        <div className="p-about">
          <Description />
          <Services services={services} />
          <Order services={services} source={GA_ORDERS_ABOUT_SOURCE} />
          <Reasons items={[1, 2, 3]} />
          <Experts experts={experts} />
        </div>
      </main>
    );
  }
}


About.propTypes = {
  fetchServices: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withServices,
  withExperts,
  withBodyClass('app-about'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(About)
