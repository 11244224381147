import React from "react";
import { getIn } from "formik";
import cn from "classnames";

import Checkbox from "../checkbox";


export default function CheckboxGroupField(
  {
    field,
    form: {touched, errors, submitCount, setFieldValue},
    options,
    initialValue,
    onChange,
    ...restProps
  }
) {
  const error = getIn(errors, field.name);
  const isTouched = getIn(touched, field.name);
  const hasError = (isTouched || submitCount > 0) && !!error;
  const className = cn("checkbox-group", {"is-invalid": hasError});

  const handleChange = (event) => {
    const newValue = event.target.value;
    let values = field.value || [];

    if (values.includes(newValue)) {
      values.splice(values.findIndex((v) => v === newValue), 1);
    } else {
      values.push(newValue);
    }

    setFieldValue(field.name, values);
    if (onChange) {
      onChange(values);
    }
  };

  return (
    <div className="checkbox-group__wrapper">
      <div className={className}>
        {
          options.map(({label, value}) =>
            <Checkbox
              {...field}
              {...restProps}
              key={value}
              value={value}
              name={field.name}
              checked={field.value.includes(value)}
              onChange={handleChange}
            >
              {label}
            </Checkbox>
          )
        }
      </div>
      {error && <span className="form__field-error__message">{error}</span>}
    </div>

  )
}