import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { fetchItem } from "../../actions/expositionActions";
import { getExposition } from "../../selectors/expositions";
import { EXPOSITION_SHAPE } from "../../constants/expositionConstants";
import LoadableEntity from "../../components/ui/loadable/entity";
import NotFound from "../../screens/static/not_found";


export function withExposition(WrappedComponent) {

    const WithExposition = class extends Component {

      componentDidMount() {
        this.fetchExposition();
      }

      fetchExposition = () => {
        const { match } = this.props;
        const expositionId = match.params["id"];

        this.props.fetchItem(expositionId);
      };

      render() {
        const { exposition, fetchStatus, ...restProps } = this.props;

        return (
          <LoadableEntity
            entity={exposition}
            renderError={() => <NotFound/>}
            // renderLoading={() => <LoadingPage/>}
          >
            {
              (exposition) =>
                <WrappedComponent
                  {...restProps}
                  exposition={exposition}
                  fetchExposition={this.fetchExposition}
                />
            }
          </LoadableEntity>
        )
      }
    };

    WithExposition.propTypes = {
      exposition: PropTypes.shape(EXPOSITION_SHAPE),
    };

    function mapStateToProps(state, {match}) {
      const expositionId = parseInt(match.params["id"]);

      return {
        exposition: getExposition(state, expositionId),
      }
    }

    function mapDispatchToProps(dispatch) {
      return bindActionCreators({
        fetchItem,
      }, dispatch)
    }

    return compose(
      withRouter,
      connect(mapStateToProps, mapDispatchToProps)
    )(WithExposition)
  }
