import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  createItem,
  disableAppointment,
} from "../../../../actions/appointmentActions";
import {
  openModal,
  closeModal,
} from "../../../../actions/modalActions";
import { APPOINTMENT_MODAL } from "../../../../constants/modalConstants";
import BaseModal from "../../../../components/ui/modal/base";
import AppointmentForm from "../form";
import { TrackAddAppointmentSubmitSuccess } from "../../../../helpers/analytics";


class AppointmentWarningModal extends Component {

  constructor(props) {
    super(props);
  }

  handleSubmit = (values) => {
    const { modal, location, userLoggedIn, createItem } = this.props;
    event.preventDefault();

    if (userLoggedIn) {
      TrackAddAppointmentSubmitSuccess()

      values.path = location.pathname;
      createItem(modal.paintingId, values);

      modal.onAppointment();
    }
  }

  handleClose = (event) => {
    const { closeModal, disableAppointment } = this.props;
    if (event) {
      event.preventDefault();
    }

    closeModal();
    disableAppointment();
  }

  render() {
    const { appointment, modal } = this.props;

    return (
      <BaseModal
        className="modal modal-approintment fade-in"
        isOpen={modal.name === APPOINTMENT_MODAL}
        onClose={this.handleClose}
      >
        <AppointmentForm
          initialValues={{
            purpose: [],
            source: modal.source,
            paintingName: modal.paintingName,
          }}
          serverError={appointment.error}
          onSubmit={this.handleSubmit}
        />
      </BaseModal>
    )
  }
}

AppointmentWarningModal.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  createItem: PropTypes.func.isRequired,
  disableAppointment: PropTypes.func.isRequired,
};

function mapStateToProps({layout, currentUser}) {
  return {
    appointment: layout.appointment,
    modal: layout.modal,
    userLoggedIn: !!currentUser.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createItem,
    disableAppointment,
    openModal,
    closeModal,
  }, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AppointmentWarningModal)
