import { paintingPreviewSchema } from "../schemas";
import {
  makeAnyKeySelector,
  makeCollectionSelector,
  makeAnyKeyCollectionSelector,
  makeFetchStatusSelector,
} from "./collections";

export const DEFAULT_COLLECTION = "index";

export function makeViewAnyKeySelector(key, collection) {
  return makeAnyKeySelector(key, collection, paintingPreviewSchema);
}

export function makePaintingPreviewsSelector(collection = DEFAULT_COLLECTION) {
  return makeCollectionSelector(collection, paintingPreviewSchema);
}

export function makePaintingPreviewsAnyKeySelector(key, collection = DEFAULT_COLLECTION) {
  return makeAnyKeyCollectionSelector(key, collection, paintingPreviewSchema);
}

export function makePaintingPreviewsFetchStatusSelector(collection = DEFAULT_COLLECTION) {
  return makeFetchStatusSelector(collection, paintingPreviewSchema);
}