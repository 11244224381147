import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { ARTIST_OPTIONS } from "../../../constants/aritstConstants";
import { makeCollectionsReducer } from "../collectionsReducer";
import { artistOptionsSchema } from "../../../schemas";


export default combineReducers({
  byId: makeByIdReducer(artistOptionsSchema.key, [ARTIST_OPTIONS.SUCCESS]),
  collections: makeCollectionsReducer(ARTIST_OPTIONS.typeNames()),
});