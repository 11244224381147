import React from "react";
import PropTypes from "prop-types";

import { EXPOSITION_POSTER_SHAPE } from "../../../constants/expositionConstants";


class Poster extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { poster, className } = this.props;

    return (
      <div className="p-exhibitions__list-item-image">
        { poster &&
          <img
            src={poster.thumb}
            alt={poster.name}
            title={poster.name}
            className={className}
          />
        }
      </div>
    );
  }
}

Poster.propTypes = {
  poster: PropTypes.shape(EXPOSITION_POSTER_SHAPE).isRequired,
  className: PropTypes.string,
};

export default Poster
