import React from "react";
import PropTypes from "prop-types";

import Link from "../../ui/link";


class Empty extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { title, button, to } = this.props;

    return (
      <div className="p-profile__empty">
        <div className="p-profile__empty-description">
          {title}
        </div>
        <div className="button__wrapper">
          <Link to={to} className="button button-more">
            {button}
          </Link>
        </div>
      </div>
    );
  }
}

Empty.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Empty