import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import Link from "../../../components/ui/link";


class Lead extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <div className="b-main-title">
        <div className="container">
          <h1>
            {i18n.t("pages.home.lead.title")}
          </h1>
          <div className="b-main-title__link-wrapper">
            <Link
              to="/about"
              className="b-main-title__link"
              title={i18n.t("pages.home.lead.link.title")}
            >
              {i18n.t("pages.home.lead.link.title")}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Lead.propTypes = {
};

export default Lead