import { RSAA } from "../middlewares/redux-api-middleware";

import { API } from "../config";
import {
  APPOINTMENT_ITEM_CREATE,
  ENABLE_APPOINTMENT,
  DISABLE_APPOINTMENT,
} from "../constants/appointmentConstants";
import { openModal } from "./modalActions";
import { store } from "../store";
import {
  APPOINTMENT_MODAL,
  APPOINTMENT_SUCESS_MODAL,
} from "../constants/modalConstants";
import isNil from "lodash/isNil";


export function createItem(id, values) {
  return function(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/appointments`,
        method: 'POST',
        body: {
          appointment: {
            painting_id: id,
            purpose: values.purpose,
          },
          path: values.path
        },
        types: APPOINTMENT_ITEM_CREATE.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      dispatch(openModal({name: APPOINTMENT_SUCESS_MODAL}));
      return {}
    }
  }
}


export function enableAppointment(payload) {
  return function(dispatch) {
    return dispatch({
      type: ENABLE_APPOINTMENT,
      payload: payload
    });
  }
}


export function disableAppointment() {
  return function(dispatch) {
    return dispatch({
      type: DISABLE_APPOINTMENT,
      payload: {}
    });
  }
}


export function openAppointmentModal() {
  const appointment = store.getState().layout.appointment

  return function openAppointmentModalThunk(dispatch) {
    if (appointment && appointment.enabled && !isNil(appointment.paintingId)) {
      dispatch(openModal({
        name: APPOINTMENT_MODAL,
        paintingId: appointment.paintingId,
        onAppointment: appointment.onAppointment,
      }));
    }
  }
}
