import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { withNotice } from "../../hocs/layout/withNotice";
import { withService } from "../../hocs/entities/withService";
import { withServices } from "../../hocs/entities/withServices";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import Meta from "../../components/layout/meta";
import Head from "./head";
import Content from "./content";


class Service extends Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { service, services } = this.props;

    return (
      <main className="layout">
        <Meta />

        <div className="p-service">
          <Head item={service} />
          <Content item={service} services={services}/>
        </div>
      </main>
    );
  }
}

Service.propTypes = {
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withServices,
  withService,
  withNotice(),
  withBodyClass('app-post'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Service)