import { GA_TRACKING_ENABLED } from "../../../config";

export const GA_REGISTRATION_FORM_SOURCE = "signup form";

export function TrackRegistrationClick() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'вступить в клуб'",
    "click",
    "signup form"
  )
}

export function TrackRegistrationCloseClick() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "X button_decline",
    "click",
    "signup form"
  )
}

export function TrackRegistrationSubmitError() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'вступить в клуб'",
    "user_failure",
    "thank you page_signup form"
  )
}

export function TrackRegistrationSubmitSuccess() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'вступить в клуб'",
    "success",
    "thank you page_signup form"
  )
}
