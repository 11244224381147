import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as QueryString from "query-string";

import { withArtists } from "../../hocs/entities/withArtists";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import List from "../../components/artists/list";
import Pagination from "../../components/layout/pagination";
import Filter from "./filter";
import Meta from "./meta";


class Artists extends React.Component {

  constructor(props) {
    super(props);
  }

  fetchCollection = (page = 1) => {
    const { location, fetchArtists } = this.props;
    const params = QueryString.parse(location.search)

    if (params.q) {
      fetchArtists(params.q, false, page);
    } else {
      fetchArtists(null, false, page);
    }
  }

  handlePageChange = (page) => {
    if (event) {
      event.preventDefault();
    }

    this.fetchCollection(page);
  }

  handleFilter = (event, letter) => {
    const { fetchArtists } = this.props;

    fetchArtists(letter)
  }

  handleFavorite = () => {
    const { pagination } = this.props;

    this.fetchCollection(pagination.current_page);
  }

  render () {
    const { artists, pagination, fetchStatus } = this.props;

    return (
      <main className="layout">
        <Meta
          items={artists}
          pagination={pagination}
        />
        <div className="p-painters">
          <Filter onFilter={this.handleFilter} />
          <div className="b-painters">
            <div className="container">
              <div className="b-painters__content" id="persons">
                <List
                  items={artists}
                  onFavorite={this.handleFavorite}
                />
              </div>
              <Pagination
                totalPages={pagination.total_pages || 1}
                disabled={fetchStatus.isFetching}
                onChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      </main>
    );
  }
}

Artists.propTypes = {
  fetchArtists: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withArtists,
  withBodyClass('app-artists'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Artists)