import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import i18n from "../../translations/i18n";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import { withAccount } from "../../hocs/entities/withAccount";
import { uploadAvatar, signOut } from "../../actions/userActions";
import Meta from "../../components/layout/meta";
import AvatarForm from "./avatar/form";
import ExpositionOrders from "./expositions/orders";
import FavoritePaintings from "./favorite/paintings";
import FavoriteArtists from "./favorite/artists";
import FavoritePosts from "./favorite/posts";
import Link from "../../components/ui/link";


class Account extends React.Component {

  constructor(props) {
    super(props);
  }

  handleSignOut = (event) => {
    event.preventDefault();
    this.props.signOut();
  }

  handleUpload = (values) => {
    const { fetchStatus, uploadAvatar } = this.props;

    if (!fetchStatus.isFetching) {
      uploadAvatar(values);
    }
  }

  render () {
    const { account, fetchStatus } = this.props;

    return (
      <main className="layout">
        <Meta />
        <div className="p-profile">
          <div className="container">
            <div className="p-profile__head">
              <div className="p-profile__head-links">
                <div className="p-profile__head-logout">
                  <a
                    href="#"
                    onClick={this.handleSignOut}
                  >
                    {i18n.t('header.sign_out.title')}
                  </a>
                </div>
                <div className="p-profile__head-settings">
                  <Link to="/account/settings">{i18n.t('header.settings.title')}</Link>
                </div>
              </div>

              <div className="p-profile__head-user">
                <div className="p-profile__head-user-avatar">
                  <AvatarForm
                    initialValues={{avatar: ''}}
                    account={account}
                    fetchStatus={fetchStatus}
                    onSubmit={this.handleUpload}
                  />
                </div>
                <div className="p-profile__head-user-name">
                  {account.name}
                </div>
                <div className="p-profile__head-user-position">
                  {account.role_human_name}
                </div>
              </div>
            </div>

            <ExpositionOrders />
            <FavoritePaintings />
            <FavoriteArtists />
            <FavoritePosts />

          </div>
        </div>
      </main>
    );
  }
}

Account.propTypes = {
  signOut: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    uploadAvatar,
    signOut,
  }, dispatch)
}

export default compose(
  withAccount,
  withBodyClass('app-account'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Account)
