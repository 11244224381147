import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cn from "classnames";

import i18n from "../../../../translations/i18n";
import { closeModal } from "../../../../actions/modalActions";
import BaseModal from "../../../../components/ui/modal";
import { PAINTING_CONTENT_IMAGES_MODAL } from "../../../../constants/modalConstants";


class Modal extends Component {

  constructor(props) {
    super(props);
  }

  handleCloseModal = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.closeModal();
  }

  renderImages = () => {
    const { modal } = this.props;

    return (
      <>
        {(modal.images || []).map((image, index) =>
          <div
            key={index}
            className="p-picture__gallery-modal-item"
          >
            <img
              className="p-picture__gallery-modal-image"
              src={image.url}
            />
          </div>
        )}
      </>
    )
  }

  render () {
    const { modal } = this.props;

    return (
      <BaseModal
        isOpen={modal.name === PAINTING_CONTENT_IMAGES_MODAL}
        className={cn('p-picture__gallery-modal', {'state-active': modal.visible})}
      >
        <div className="p-picture__gallery-modal-logo">
          <a
            href="#"
            dangerouslySetInnerHTML={{__html: i18n.t('header.logo.title')}}
            onClick={this.handleCloseModal}
          />
        </div>
        <a
          href="#"
          className="p-picture__gallery-modal-close"
          title={i18n.t('modal.close.title')}
          onClick={this.handleCloseModal}
        >
          {i18n.t('modal.close.title')}
        </a>
        <div className="container">
          <div className="p-picture__gallery-modal-content" id="p-picture__gallery-modal-content">
            {this.renderImages()}
          </div>
        </div>
      </BaseModal>
    )
  }
}

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {
  return {
    modal: layout.modal
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)