import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";
import { purgeStoredState } from "redux-persist";

import { API } from "../config";
import {
  USER_SIGN_IN,
  USER_SIGN_UP,
  USER_CONFIRM_PHONE,
  USER_RESET_PHONE_CHANGE,
  USER_RESEND_CODE,
  USER_SUBSCRIBE,
  USER_SIGN_OUT,
  USER_RESTORE_PASSWORD,
  USER_CHANGE_PASSWORD,
  USER_RESET_AUTH_FORM,
  USER_RESTORE_ACCOUNT,
  USER_CHECK_TOKEN,
  USER_ITEM,
  USER_ITEM_UPDATE,
  USER_ITEM_DELETE,
} from "../constants/userConstants";
import { DEFAULT_MODIFICATION } from "../selectors/accounts";
import i18n from "../translations/i18n";
import { accountSchema } from "../schemas";
import { toggleNotice } from "./noticeActions";
import { persistConfig } from "../store";
import { openAppointmentModal } from "./appointmentActions";
import { openPersonalTourModal } from "./personalTourActions";
import { openModal, closeModal } from "./modalActions";
import {
  PHONE_MODAL,
  UNCONFIRMED_MODAL,
} from "../constants/modalConstants";


export function signIn({email, password}) {
  return function signInThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/sessions`,
        method: 'POST',
        body: { email, password },
        types: USER_SIGN_IN.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      const user = await res.json();
      const account = user.user;
      const { id, email, token } = account;

      if (account && account.phone && account.phone_confirmed) {
        dispatch(openAppointmentModal());
        dispatch(openPersonalTourModal());
      } else {
        dispatch(openModal({name: PHONE_MODAL}));
      }

      return {
        id, email, token
      }
    }
  }
}


export function signInBy({confirmationToken}) {
  return function signInThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/sessions/confirm`,
        method: 'POST',
        body: { confirmation_token: confirmationToken },
        types: USER_SIGN_IN.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      const user = await res.json();
      const account = user.user;
      const { id, email, token } = account;

      return {
        id, email, token
      }
    }
  }
}


export function signUp({email, phone, password, name, role, subscribe_to_news = false}) {
  return function signUpThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/registrations`,
        method: 'POST',
        body: { email, unconfirmed_phone: phone, password, name, role, subscribe_to_news},
        types: USER_SIGN_UP.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      const user = await res.json();
      const { id, email, token } = user.user;

      return {
        id, email, token
      }
    }
  }
}


export function confirmPhone({code}, fetchOptions = {}) {
  const meta = {
    modification: fetchOptions.modification || DEFAULT_MODIFICATION,
  };

  return function confirmPhoneThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/confirm_phone`,
        method: 'POST',
        body: { code },
        types: USER_CONFIRM_PHONE.types({
          all: { meta },
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      const user = await res.json();
      const { id, email, token } = user.user;

      dispatch(toggleNotice({
        title: i18n.t('notice.confirm_phone.success.message'),
      }));

      dispatch(closeModal());
      dispatch(openAppointmentModal());
      dispatch(openPersonalTourModal());

      return {
        id, email, token,
        ...normalize(user.user, accountSchema),
      }
    }
  }
}


export function resendCode() {
  return function(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/confirm_phone/resend_code`,
        method: 'POST',
        body: {},
        types: USER_RESEND_CODE.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      dispatch(toggleNotice({
        title: i18n.t('notice.resend_code.error.message'),
      }));

      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      const user = await res.json();
      const { id, email, token } = user.user;

      dispatch(toggleNotice({
        title: i18n.t('notice.resend_code.success.message'),
      }));

      return {
        id, email, token
      }
    }
  }
}


export function resetPhoneChange(fetchOptions = {}) {
  const meta = {
    modification: fetchOptions.modification || DEFAULT_MODIFICATION,
  };

  return function(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/confirm_phone/reset`,
        method: 'POST',
        body: {},
        types: USER_RESET_PHONE_CHANGE.types({
          all: { meta },
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      const user = await res.json();

      return {
        ...normalize(user.user, accountSchema),
      }
    }
  }
}


export function subscribe({email, name, source}) {
  return function(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/subscriptions`,
        method: 'POST',
        body: {
          subscription: {
            email, name
          },
          path: `/${source}` // source = 'home' or 'posts'
        },
        types: USER_SUBSCRIBE.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      const user = await res.json();
      const { id, email, token } = user.user;

      dispatch(toggleNotice({
        title: i18n.t('notice.subscribe.success.message'),
      }));

      return {
        id, email, token
      }
    }
  }
}


export function restorePassword({email}) {
  return {
    [RSAA]: {
      endpoint: `${API}/restore_password`,
      method: 'POST',
      body: { email },
      types: USER_RESTORE_PASSWORD.types({
        success: {
          payload: parseResponse
        },
        failed: {
          payload: errorHandler
        }
      }),
    },
  };

  async function errorHandler(action, state, res) {
    return { error: (await res.json()).error };
  }

  async function parseResponse(action, state, res) {
    return {}
  }
}


export function updatePassword({password, reset_password_token}) {
  return {
    [RSAA]: {
      endpoint: `${API}/passwords`,
      method: 'PUT',
      body: {
        user: { password, password_confirmation: password, reset_password_token }
      },
      types: USER_CHANGE_PASSWORD.types({
        success: {
          payload: parseResponse
        },
        failed: {
          payload: errorHandler
        }
      }),
    },
  };

  async function errorHandler(action, state, res) {
    return { error: (await res.json()).error };
  }

  async function parseResponse(action, state, res) {
    const user = await res.json();
    const { id, email, token } = user.user;

    return {
      id, email, token
    }
  }
}


export function checkToken({token}) {
  return function(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/sessions/check`,
        method: 'POST',
        body: { token },
        types: USER_CHECK_TOKEN.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      dispatch(signOut());

      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      const user = await res.json();
      const { id, email, token } = user.user;

      return {
        id, email, token
      }
    }
  }
}


export function fetchItem(fetchOptions = {}) {
  const meta = {
  };
  const include = fetchOptions.include || {};

  return function fetchItemThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account`,
        types: USER_ITEM.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      dispatch(signOut());

      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      const user = await res.json();
      const account = user.user;

      if (account && !account.confirmed) {
        dispatch(openModal({name: UNCONFIRMED_MODAL}));
      }

      return {
        ...normalize(account, accountSchema),
      }
    }
  }
}


export function updateItem({
    email,
    phone,
    name,
    role,
    password,
    password_confirmation,
    subscribe_to_news,
    subscribe_to_world_art_news,
    subscribe_to_genres,
    subscribe_to_techniques,
    subscribe_to_years,
    path
  }, fetchOptions = {}) {

  const meta = {
    modification: fetchOptions.modification || DEFAULT_MODIFICATION,
  };

  return function updateItemThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account`,
        method: "PATCH",
        body: {
          account: {
            email,
            unconfirmed_phone: phone,
            name,
            role,
            password,
            password_confirmation,
            subscribe_to_news,
            subscribe_to_world_art_news,
            subscribe_to_genres,
            subscribe_to_techniques,
            subscribe_to_years,
          },
          path: path
        },
        types: USER_ITEM_UPDATE.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      let title = null;

      if (password && password_confirmation && password != password_confirmation) {
        title = i18n.t('notice.account.change_password.error')
      } else {
        title = i18n.t('notice.account.update.error');
      }

      if (title) {
        dispatch(toggleNotice({title}));
      }

      return {};
    }

    async function parseResponse(action, state, res) {
      const user = await res.json();

      let title = null;

      if (email) {
        title = i18n.t('notice.account.change_email.message')
      } else if (phone) {
        title = i18n.t('notice.account.change_phone.message')
      } else if (password && password_confirmation) {
        title = i18n.t('notice.account.change_password.message')
      } else if (subscribe_to_news || subscribe_to_world_art_news) {
        title = i18n.t('notice.account.subscription.message')
      } else {
        title = i18n.t('notice.account.update.message');
      }

      dispatch(toggleNotice({title}));

      return {
        ...normalize(user.user, accountSchema),
      };
    }
  };
}


export function deleteItem({password, delete_reason}, fetchOptions = {}) {
  return async function removeItemThunk(dispatch) {
    const meta = {
      modification: fetchOptions.modification || DEFAULT_MODIFICATION,
    };

    return dispatch({
      [RSAA]: {
        method: "DELETE",
        body: {
          account: { password, delete_reason }
        },
        endpoint: `${API}/account`,
        types: USER_ITEM_DELETE.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      dispatch(toggleNotice({
        title: i18n.t('notice.account.delete.message.title'),
        description: i18n.t('notice.account.delete.message.description')
      }));
      dispatch(signOut());

      return {};
    }
  }
}


export function restoreAccount({restore_token}) {
  return {
    [RSAA]: {
      endpoint: `${API}/restore_account`,
      method: 'POST',
      body: {
        user: { restore_token: restore_token }
      },
      types: USER_RESTORE_ACCOUNT.types({
        success: {
          payload: parseResponse
        },
        failed: {
          payload: errorHandler
        }
      }),
    },
  };

  async function errorHandler(action, state, res) {
    return { error: (await res.json()).error };
  }

  async function parseResponse(action, state, res) {
    const user = await res.json();
    const { id, email, token } = user.user;

    return {
      id, email, token
    }
  }
}


export function uploadAvatar({avatar}, fetchOptions = {}) {
  const meta = {
    modification: fetchOptions.modification || DEFAULT_MODIFICATION,
  };

  return function updateItemThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/avatar`,
        multipartFormData: true,
        method: "POST",
        body: { 'files[]': avatar[0] },
        types: USER_ITEM_UPDATE.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const user = await res.json();

      return {
        ...normalize(user.user, accountSchema),
      };
    }
  };
}


export function signOut(fetchOptions = {}) {
  const meta = {
    modification: fetchOptions.modification || DEFAULT_MODIFICATION,
  };

  return function signOutThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/sessions`,
        method: "DELETE",
        types: USER_SIGN_OUT.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      dispatch(flushCache());

      return {};
    }
  }
}

export function resetAuthForm() {
  return function(dispatch) {
    return dispatch({
      type: USER_RESET_AUTH_FORM,
      payload: {}
    });
  }
}

export function flushCache() {
  return function(dispatch) {
    purgeStoredState(persistConfig);
  }
}
