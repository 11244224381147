import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cn from "classnames";

import i18n from "../../../translations/i18n";
import { PAINTING_SHAPE } from "../../../constants/paintingConstants";
import Poster from "../../../components/painting/poster";
import Ended from "../../../components/painting/ended";
import Dimensions from "../../../components/painting/dimensions";
import Title from "../../painting/title";
import Favorite from "../../../components/layout/favorite";
import Appointment from "../../../components/layout/appointment";
import Link from "../../../components/ui/link";


class Item extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { item, large, novelty, onAppointment, onFavorite, source } = this.props;
    const paintingPath = `/paintings/${item.slug}`;

    return (
      <div className={cn('b-paintings__item', {'__large': item.large && large})}>
        <div className="b-paintings__card">
          <Link
            to={paintingPath}
            title={item.name}
            className="b-paintings__card-image-link"
          >
            <Poster poster={item.poster} className="b-paintings__card-image" />
          </Link>
          <div className="b-paintings__card-content">
            <Link to={paintingPath} className="b-paintings__card-name">
              {novelty && item.new &&
                <span className="badge-new">{i18n.t('pages.paintings.new.title')}</span>
              }
              <Title painting={item} splited />
            </Link>
            <div className="b-paintings__card-date">
              <Ended title={item.ended_at} />
              <Dimensions height={item.height} width={item.width} />
            </div>
            <div className="b-paintings__card-media">
              <div className="b-paintings__card-media-signup-wrapper">
                <Appointment
                  className="b-paintings__card-media-signup"
                  available={item.available}
                  appointment={item.appointment}
                  source={source}
                  onAppointment={onAppointment}
                />
              </div>
              <div className="b-paintings__card-media-favorite">
                <Favorite
                  favorite={item.favorite}
                  isShort={true}
                  onFavorite={onFavorite}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  item: PropTypes.shape(PAINTING_SHAPE).isRequired,
  large: PropTypes.bool,
  onAppointment: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired,
};

Item.defaultProps = {
  large: false,
  novelty: false,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)
