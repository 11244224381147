import {
  OPEN_HEADER_SEARCH,
  CLOSE_HEADER_SEARCH,
} from "../../constants/layoutConstants";

const DEFAULT_STATE = {
  visible: false,
};

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case OPEN_HEADER_SEARCH:
      return {
        ...state,
        ...payload,
        visible: true,
      };
    case CLOSE_HEADER_SEARCH:
      return {
        ...state,
        ...DEFAULT_STATE,
      }
    default:
      return state;
  }
}