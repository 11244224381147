import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Route, Redirect, withRouter } from "react-router-dom";

import { fetchItem, signInBy } from "../../actions/userActions";
import { getAccount, makeAccountModificationStatusSelector } from "../../selectors/accounts";
import { USER_SHAPE, ACCOUNT_SHAPE } from "../../constants/userConstants";
import { MODIFICATION_SHAPE } from "../../selectors/modifications";
import LoadableEntity from "../../components/ui/loadable/entity";


export function withAccount(WrappedComponent) {

  const WithAccount = class extends Component {

    componentDidMount() {
      this.fetchAccount();
    }

    componentDidUpdate(prevProps, nextProps) {
      const { fetchStatus } = this.props;

      if (prevProps.fetchStatus !== fetchStatus && fetchStatus.isSuccess) {
        this.fetchAccount();
      }
    }

    fetchAccount = () => {
      const { history, location, userLoggedIn, fetchItem, signInBy } = this.props;
      const afterConfirmation = location.search && location.search.includes("confirmation_token")

      if (afterConfirmation) {
        let params = new URLSearchParams(location.search);
        let confirmationToken = params.get("confirmation_token");

        signInBy({confirmationToken});
        history.push('/account/change_password?after_confirmation');
      }

      if (userLoggedIn) {
        fetchItem();
      }
    };

    render() {
      const { currentUser, account, fetchStatus, userLoggedIn, ...restProps } = this.props;

      return (
        <Route render={({location}) => {
          return userLoggedIn
            ? <LoadableEntity entity={account}>
                {
                  () => <WrappedComponent
                            {...restProps}
                            account={account}
                            fetchStatus={fetchStatus}
                            fetchAccount={this.fetchAccount}
                        />
                }
              </LoadableEntity>
            : <Redirect to="/login" />
          }}
        />
      )
    }
  };

  WithAccount.propTypes = {
    currentUser: PropTypes.shape(USER_SHAPE).isRequired,
    account: PropTypes.shape(ACCOUNT_SHAPE),
    fetchStatus: PropTypes.shape(MODIFICATION_SHAPE).isRequired,
    userLoggedIn: PropTypes.bool.isRequired,
  };

  const fetchStatus = makeAccountModificationStatusSelector();

  function mapStateToProps(state) {
    const currentUser = state.currentUser;
    return {
      currentUser: currentUser,
      account: getAccount(state, currentUser.id),
      fetchStatus: fetchStatus(state),
      userLoggedIn: !!currentUser.token,
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItem,
      signInBy,
    }, dispatch)
  }

  return compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(WithAccount);
}