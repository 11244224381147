import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";

import { API, SEARCH_RESULTS_PER_PAGE } from "../config";
import { SEARCH_PREVIEWS } from "../constants/searchConstants";
import { searchPreviewSchema } from "../schemas";
import { DEFAULT_COLLECTION } from "../selectors/searchPreviews";
import { FUNC_REPLACE } from "../reducers/entities/collectionsReducer";


export function fetchItems(params = {}, page = 1, perPage = SEARCH_RESULTS_PER_PAGE, fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE,
  };

  let endpoint = `${API}/search?page=${page}&per_page=${perPage}`
  if (params.q) {
    endpoint = `${endpoint}&q=${encodeURIComponent(params.q)}`
  }
  if (params.type) {
    endpoint = `${endpoint}&type=${params.type}`
  }

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: endpoint,
        types: SEARCH_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { results, meta } = await res.json();

      return {
        ...normalize(results, [searchPreviewSchema]),
        pagination: meta,
        facets: meta.facets,
      };
    }
  };
}