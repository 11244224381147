import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";

import i18n from "../../../../translations/i18n";
import { POST_SHAPE } from "../../../../constants/postConstants";
import Favorite from "../../../../components/layout/favorite";
import Share from "../../../../components/post/share";
import Author from "../../../../components/post/author";


class Content extends Component {

  constructor(props) {
    super(props);
  }

  handleFavorite = () => {
    this.props.fetchPost();
  }

  renderNode(node, index) {
    if (node.type === 'tag' && node.name === 'figure') {
      const attachment = JSON.parse(node.attribs['data-trix-attachment']);
      const attributes = JSON.parse(node.attribs['data-trix-attributes']);

      return (
        <figure key={index} className={node.attribs.class}>
          {attachment && attachment.url &&
            <img src={attachment.url} />
          }
          {attributes && attributes.caption &&
            <figcaption className="attachment__caption">
              {attributes.caption}
            </figcaption>
          }
        </figure>
      )
    }
  }

  render () {
    const { item } = this.props;

    return (
      <div className="p-post__content">
        <div className="container">
          <div className="p-post__description">
            {item.lead &&
              <p
                className="p-post__description-paragraph-top"
                dangerouslySetInnerHTML={{__html: item.lead}}
              />
            }
            {item.text &&
              <div className="p-post__description-container">
                {ReactHtmlParser(item.text, {
                    transform: (node, index) => this.renderNode(node, index)
                  }
                )}
              </div>
            }
          </div>
          <div className="p-post__media">
            <div className="p-post__media-favorite">
              <Favorite
                favorite={item.favorite}
                onFavorite={this.handleFavorite}
              />
            </div>
            <div className="p-post__media-share">
              <div className="p-post__media-share-name">
                {i18n.t('helpers.share.title')}
              </div>
              <Share slug={item.slug} title={item.name} />
            </div>
          </div>
          <Author author={item.author} />
        </div>
      </div>
    )
  }
}

Content.propTypes = {
  item: PropTypes.shape(POST_SHAPE).isRequired,
  fetchPost: PropTypes.func.isRequired,
};

export default Content