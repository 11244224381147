import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import {
  PAINTING_SHAPE,
  PAINTING_PROVENANCE_KIND,
  PAINTING_EXHIBITION_KIND,
  PAINTING_PUBLICATION_KIND,
  PAINTING_CONCLUSION_KIND,
  PAINTING_SELECT_DEFAULT_OPTION,
} from "../../../constants/paintingConstants";
import ScrollHandler from "../../../components/layout/scroll";
import Select from "../../../components/ui/form/select";
import Dimensions from "../../../components/painting/dimensions";
import Appointment from "../../../components/layout/appointment";
import Favorite from "../../../components/layout/favorite";
import Description from "./description";
import List from "./list";
import Modal from "./modal";
import Conclusion from "./conclusion";
import Artist from "./artist";
import { GA_APPOINTMENT_PAINTING_SOURCE } from "../../../helpers/analytics";


class Content extends Component {

  constructor(props) {
    super(props);

    this.state = {
      provenance: this.infoByKind(PAINTING_PROVENANCE_KIND),
      exhibition: this.infoByKind(PAINTING_EXHIBITION_KIND),
      publication: this.infoByKind(PAINTING_PUBLICATION_KIND),
      conclusion: this.infoByKind(PAINTING_CONCLUSION_KIND),
      sections: [],
      options: []
    }
  }

  componentDidMount() {
    this.buildSections();
    this.buildSelectOptions();
  }

  isArtist = () => {
    const { painting } = this.props;
    return painting.artist && painting.artist.published && !isEmpty(painting.artist.biography);
  }

  buildSections = () => {
    const { provenance, exhibition, publication, conclusion } = this.state;

    const sections = [
      'about',
    ]

    if (!isEmpty(provenance)) { sections.push(PAINTING_PROVENANCE_KIND) }
    if (!isEmpty(exhibition)) { sections.push(PAINTING_EXHIBITION_KIND) }
    if (!isEmpty(publication)) { sections.push(PAINTING_PUBLICATION_KIND) }
    if (!isEmpty(conclusion)) { sections.push(PAINTING_CONCLUSION_KIND) }
    if (this.isArtist()) { sections.push('artist'); }

    this.setState({sections});
  }

  buildSelectOptions = () => {
    const { provenance, exhibition, publication, conclusion } = this.state;

    const options = [
      PAINTING_SELECT_DEFAULT_OPTION,
    ]

    if (!isEmpty(provenance)) {
      options.push({ value: PAINTING_PROVENANCE_KIND, label: i18n.t(`pages.painting.aside.${PAINTING_PROVENANCE_KIND}.title`) });
    }
    if (!isEmpty(exhibition)) {
      options.push({ value: PAINTING_EXHIBITION_KIND, label: i18n.t(`pages.painting.aside.${PAINTING_EXHIBITION_KIND}.title`) });
    }
    if (!isEmpty(publication)) {
      options.push({ value: PAINTING_PUBLICATION_KIND, label: i18n.t(`pages.painting.aside.${PAINTING_PUBLICATION_KIND}.title`) });
    }
    if (!isEmpty(conclusion)) {
      options.push({ value: PAINTING_CONCLUSION_KIND, label: i18n.t(`pages.painting.aside.${PAINTING_CONCLUSION_KIND}.title`) });
    }
    if (this.isArtist()) {
      options.push({ value: 'artist', label: i18n.t('pages.painting.aside.artist.title') });
    }

    this.setState({options});
  }

  infoByKind = (kind) => {
    return this.props.painting.informations.filter((item) => item.kind === kind);
  }

  handleChange = (option) => {
    const { painting, history } = this.props;

    history.push(`${painting.slug}#${option.value}`);
  }

  render () {
    const { painting, location, fetchPainting } = this.props;
    const { provenance, exhibition, publication, conclusion, sections, options } = this.state;

    return (
      <div className="p-picture__info" id="painting">
        <ScrollHandler />
        <div className="container">
          <div className="p-picture__info-content">
            <div className="p-picture__info-row row">

              <div className="p-picture__info-sidebar">
                <div className="p-picture__info-sidebar-content">
                  <div className="p-picture__info-sidebar-name">
                    <div className="p-picture__info-sidebar-name-item">
                      {painting.artist.name}:
                    </div>
                    <div className="p-picture__info-sidebar-name-item">
                      {painting.name}
                    </div>
                  </div>

                  <div className="p-picture__info-sidebar-media">
                    <ul>
                      {painting.ended_at &&
                        <li className="p-picture__info-sidebar-media-item">
                          {painting.ended_at}
                        </li>
                      }
                      {painting.made_of &&
                        <>
                          {(painting.made_of).map((property, index) =>
                            <li key={index} className="p-picture__info-sidebar-media-item">
                              {property}
                            </li>
                          )}
                        </>
                      }
                      <li className="p-picture__info-sidebar-media-item">
                        <Dimensions height={painting.height} width={painting.width} />
                      </li>
                    </ul>
                  </div>

                  <div className="p-picture__info-sidebar-button-wrapper">
                    <Appointment
                      className="button p-picture__info-sidebar-button"
                      available={painting.available}
                      appointment={painting.appointment}
                      source={GA_APPOINTMENT_PAINTING_SOURCE}
                      onAppointment={fetchPainting}
                    />
                  </div>

                  <div className="p-picture__info-sidebar-favorite">
                    <Favorite
                      favorite={painting.favorite}
                      onFavorite={fetchPainting}
                    />
                  </div>

                  <div className="p-picture__info-sidebar-menu">
                    <ul className="p-picture__info-sidebar-menu-list">
                      {sections.map((item, index) =>
                        <li key={index} className="p-picture__info-sidebar-menu-item">
                          <a
                            href={`#${item}`}
                            title={i18n.t(`pages.painting.aside.${item}.title`)}
                            className={cn('p-picture__info-sidebar-menu-link', { 'state-selected': location.hash === `#${item}`})}
                          >
                            {i18n.t(`pages.painting.aside.${item}.title`)}
                          </a>
                        </li>
                      )}
                    </ul>
                    <div className="select">
                      <Select
                        name="navigation"
                        defaultValue={PAINTING_SELECT_DEFAULT_OPTION}
                        options={options}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-picture__info-description">
                <div className="p-picture__info-description-content">
                  <Modal />
                  <Description description={painting.description} />
                  <List
                    title={i18n.t('pages.painting.aside.provenance.title')}
                    className="p-picture__info-description-provenanse"
                    id={PAINTING_PROVENANCE_KIND}
                    items={provenance}
                  />
                  <List
                    title={i18n.t('pages.painting.aside.exhibition.title')}
                    className="p-picture__info-description-show"
                    id={PAINTING_EXHIBITION_KIND}
                    items={exhibition}
                  />
                  <List
                    title={i18n.t('pages.painting.aside.publication.title')}
                    className="p-picture__info-description-publications"
                    id={PAINTING_PUBLICATION_KIND}
                    items={publication}
                  />
                  {this.isArtist() &&
                    <Artist artist={painting.artist} />
                  }
                  <Conclusion
                    title={i18n.t('pages.painting.aside.conclusion.title')}
                    className="p-picture__info-description-conclusion"
                    id={PAINTING_CONCLUSION_KIND}
                    items={conclusion}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Content.propTypes = {
  painting: PropTypes.shape(PAINTING_SHAPE).isRequired,
};

export default compose(withRouter)(Content)
