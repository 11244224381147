import React, { Component } from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";


class Reasons extends Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { items } = this.props;

    return (
      <div className="b-reasons">
        <div className="section-title">
          {i18n.t("reasons.title", {count: items.length})}
        </div>
        <div className="container">
          <div className="b-reasons__content">
            <div className="b-reasons__row row">
              {items.map((item) =>
                <div
                  key={item}
                  className="b-reasons__item"
                >
                  <div className="b-reasons__item-figure">
                    {item}
                  </div>
                  <div className="b-reasons__item-title">
                    {i18n.t(`reasons.items.${item}.title`)}
                  </div>
                  <div className="b-reasons__item-description">
                    {i18n.t(`reasons.items.${item}.text`)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Reasons.propTypes = {
  items: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Reasons.defaultProps = {
  items: [1, 2, 3, 4],
};

export default Reasons