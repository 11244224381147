import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";
import { PROPERTY_SHAPE } from "./propertyConstants";

export const BANNER_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  image: PropTypes.string,
}

export const BANNER_PREVIEWS = createRequestActionsFor("BANNER_PREVIEWS");
