import React, { Component } from "react";
import i18n from "../../../translations/i18n";
import PartnersHead from "../../../../../images/partners/head.jpg";

const DEFAULT_STATE = {
  expandedQuestions: [],
  expandedDescriptions: [],
  hideExpandButton: [],
  hideAllExpandButtons: false
}

class Head extends Component {

  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  componentDidMount() {
    const hideExpandButton = Array.prototype.slice.call(document.getElementsByClassName('js-partners__faq-question-container')).map((v) => v.clientHeight > 150 );
    const hideAllExpandButtons = hideExpandButton.some((v)=>v)
    this.setState({ hideExpandButton: hideExpandButton, hideAllExpandButtons: hideAllExpandButtons });
  }

  expandAnswer = (i) => {
    this.setState(prevState => ({
      expandedQuestions: [...prevState.expandedQuestions, i]
    }));
  }

  hideAnswer = (i) => {
    this.setState(prevState => ({
      expandedQuestions: prevState.expandedQuestions.filter((index) => index != i)
    }));
  }

  toggleAnswer = (i) => {
    if (this.state.expandedQuestions.includes(i))
      this.hideAnswer(i)
    else
      this.expandAnswer(i)
  }

  expandDescription = (i) => {
    this.setState(prevState => ({
      expandedDescriptions: [...prevState.expandedDescriptions, i]
    }));
  }

  hideDescription = (i) => {
    this.setState(prevState => ({
      expandedDescriptions: prevState.expandedDescriptions.filter((index) => index != i)
    }));
  }

  toggleDescription = (i) => {
    if (this.state.expandedDescriptions.includes(i))
      this.hideDescription(i)
    else
      this.expandDescription(i)
  }

  render() {
    var hiddenParagraphClassLeft = this.state.expandedDescriptions.includes(0) ? null: "hidden";
    var hiddenParagraphClassRight = this.state.expandedDescriptions.includes(1) ? null: "hidden";

    return (
      <>
        <div className="p-partners__head">
          <div className="container">
            <h1>
              {i18n.t('pages.partners.head.title')}
            </h1>
            <div className="p-partners__head-image-wrapper">
              <img alt="" src={PartnersHead} />
            </div>
            <h2 className="p-partners__head-subtitle-desktop"
              dangerouslySetInnerHTML={{ __html: i18n.t('pages.partners.head.subtitle_desktop', {returnObjects: true}).join("<br/>")}}
            />
            <h2 className="p-partners__head-subtitle-mobile">
              {i18n.t('pages.partners.head.subtitle_mobile')}
            </h2>
          </div>
        </div>
        <div className="p-partners__description">
          <div className="container">
            <div className="p-partners__description-row">
              <div className="p-partners__description-col" onClick={(e) => this.expandDescription(0)}>
                <div>
                  <p className={hiddenParagraphClassLeft}>
                    {i18n.t('pages.partners.head.description.left.text')}
                  </p>
                  <div className="p-partners__description-open">
                    {!this.state.expandedDescriptions.includes(0) && (
                      <>
                        <span
                          className="p-partners__description-read-more"
                          title={i18n.t('pages.about.read_all')}
                          onClick={(e) => this.expandDescription(0)}
                        >
                          {i18n.t('pages.about.read_all')}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-partners__description-col" onClick={(e) => this.expandDescription(1)}>
                <div>
                  <p className={hiddenParagraphClassRight}>
                    {i18n.t('pages.partners.head.description.right.text')}
                  </p>
                  <div className="p-partners__description-open">
                    {!this.state.expandedDescriptions.includes(1) && (
                      <>
                        <span
                          className="p-partners__description-read-more"
                          title={i18n.t('pages.about.read_all')}
                          onClick={(e) => this.expandDescription(1)}
                        >
                          {i18n.t('pages.about.read_all')}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-partners__faq">
          <div className="container">
            <h2>
              {i18n.t('pages.partners.head.faq_title')}
            </h2>
            {i18n.t('pages.partners.head.faq', {returnObjects: true}).map((o, i) => {
              var fadeoutClass = ""
              var answerClass = "p-partners__faq-answer"
              if (!this.state.expandedQuestions.includes(i)) {
                fadeoutClass = "fade-out-text"
                answerClass += " partially-hidden"
              }

              var toggleButtonClass = !this.state.expandedQuestions.includes(i) ? "expand-description-button" : "hide-description-button"

              return <div key={i} className="js-partners__faq-question-container">
                <p onClick={(e) => this.toggleAnswer(i)}>
                  <span className="p-partners__faq-question"
                    dangerouslySetInnerHTML={{ __html: i18n.t('pages.partners.head.faq.' + i + '.q') }}
                  />
                  {!this.state.hideAllExpandButtons && (
                    <span className={toggleButtonClass} />
                  )}
                </p>

                <p className={answerClass}
                  onClick={(e) => this.toggleAnswer(i)}
                  dangerouslySetInnerHTML={{ __html: i18n.t('pages.partners.head.faq.' + i + '.a', {returnObjects: true}).join("<br/><br/>") }}
                />
                {!this.state.expandedQuestions.includes(i) && (
                  <div className={fadeoutClass} onClick={(e) => this.expandAnswer(i)}/>
                )}
              </div>
            })}
          </div>
        </div>
      </>
    );
  }
}

export default Head;
