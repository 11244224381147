import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import { getAccount } from "../../../selectors/accounts";
import { fetchItem, checkToken } from "../../../actions/userActions";
import {
  USER_SHAPE,
  USER_REGISTRATION_SOURCE,
  ACCOUNT_SHAPE
} from "../../../constants/userConstants";


class Check extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { history, account, currentUser, userLoggedIn, checkToken } = this.props;

    this.fetchAccount();

    if (userLoggedIn) {
      checkToken({token: currentUser.token});
    }

    if (userLoggedIn &&
        account &&
        !account.confirmed &&
        !account.phone_confirmed &&
        account.registration_source === USER_REGISTRATION_SOURCE) {
      history.push('/signup')
    }
  }

  fetchAccount = () => {
    const { userLoggedIn, fetchItem } = this.props;

    if (userLoggedIn) {
      fetchItem();
    }
  };

  render () {
    return (
      <></>
    );
  }
}

Check.propTypes = {
  currentUser: PropTypes.shape(USER_SHAPE).isRequired,
  account: PropTypes.shape(ACCOUNT_SHAPE),
  userLoggedIn: PropTypes.bool.isRequired,
  checkToken: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const currentUser = state.currentUser;
  const layout = state.layout;
  return {
    currentUser,
    account: getAccount(state, currentUser.id),
    userLoggedIn: !!currentUser.token,
    modal: layout.modal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchItem,
    checkToken,
  }, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Check)