import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import cn from "classnames";

import i18n from "../../../../translations/i18n";
import {
  closeModal
} from "../../../../actions/modalActions";
import {
  disableAppointment
} from "../../../../actions/appointmentActions";
import { RESTORE_PASSWORD_MODAL } from "../../../../constants/modalConstants";
import BaseModal from "../../../ui/modal/base";
import RestorePassword from "../../auth/restore_password";


class RestorePasswordModal extends React.Component {

  constructor(props) {
    super(props);
  }

  handleClose = (event) => {
    const { closeModal, disableAppointment } = this.props;
    event.preventDefault();

    closeModal();
    disableAppointment();
  }

  render () {
    const { auth, modal } = this.props;

    return (
      <BaseModal
        className={cn('modal', { 'modal-message': !auth.passwordSended, 'modal-password': auth.passwordSended })}
        isOpen={modal.name === RESTORE_PASSWORD_MODAL}
        onClose={this.handleClose}
      >
        <RestorePassword />
      </BaseModal>
    );
  }
}

RestorePasswordModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

function mapStateToProps({auth, layout}) {
  return {
    auth,
    modal: layout.modal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    disableAppointment,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(RestorePasswordModal)