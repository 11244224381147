import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { withArtist } from "../../hocs/entities/withArtist";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import Favorite from "../../components/layout/favorite";
import Meta from "./meta";
import Description from "../../components/artist/description";
import Statistics from "./statistics";
import Timelines from "./timelines";
import Paintings from "./paintings";


class Artist extends React.Component {

  constructor(props) {
    super(props);
  }

  handleAppointment = () => {
    this.props.fetchArtist();
  }

  handleFavorite = () => {
    this.props.fetchArtist();
  }

  render () {
    const { artist } = this.props;

    return (
      <main className="layout layout__painter">
        <Meta />
        <div className="p-painter">
          { artist.poster &&
            <div className="p-painter__picture" style={{backgroundImage: `url(${artist.poster.main})`}} />
          }
          <div className="p-painter__info" id="person">
            <div className="container">
              <div className="p-painter__info-name">
                {artist.name}
              </div>
              <div className="p-painter__info-favorite-wrapper">
                <Favorite
                  favorite={artist.favorite}
                  onFavorite={this.handleFavorite}
                />
              </div>
              <Statistics artist={artist} />
              <div className="p-painter__info-about-painter">
                <Description
                  artist={artist}
                  imgClassName="p-painter__info-about-painter-image"
                  moreClassName="p-painter__info-read-more"
                />
              </div>
              { artist.lead &&
                <h1 className="p-painter__info-title">
                  {artist.lead}
                </h1>
              }
              <Timelines timelines={artist.timelines} />
            </div>
          </div>
          <Paintings
            paintings={artist.paintings}
            onAppointment={this.handleAppointment}
            onFavorite={this.handleFavorite}
          />
        </div>
      </main>
    );
  }
}

Artist.propTypes = {
  fetchArtist: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withArtist,
  withBodyClass('app-artist'),
  withHeader({transparent: true}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Artist)
