import {
  OPEN_MODAL,
  CLOSE_MODAL,
} from "../constants/modalConstants";


export function openModal(payload = {}) {
  return function(dispatch) {
    return dispatch({
      type: OPEN_MODAL,
      payload: payload
    });
  }
}


export function closeModal() {
  return function(dispatch) {
    return dispatch({
      type: CLOSE_MODAL,
      payload: {}
    });
  }
}