export const ERROR_KEY = "fetchError";

export const BLANK_ID = "blank-id";
export const DEFAULT_STATE = {
  [BLANK_ID]: {},
};

export function makeByIdReducer(entityKey, successActionTypes, failedActionTypes = []) {
  return function(state = DEFAULT_STATE, { type, payload, meta }) {
    if (successActionTypes.includes(type)){
      return {
        ...state,
        ...(payload.entities || {})[entityKey],
      }
    } else if(failedActionTypes.includes(type)) {
      return {
        ...state,
        [parseInt(meta.key)]: {
          [ERROR_KEY]: true,
        },
      }
    } else {
      return state;
    }
  }
}