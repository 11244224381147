import { propertyOptionsSchema } from "../schemas";
import {
  makeAnyKeySelector,
  makeCollectionSelector,
  makeAnyKeyCollectionSelector,
  makeFetchStatusSelector,
} from "./collections";

export const DEFAULT_COLLECTION = "index";

export function makeViewAnyKeySelector(key, collection) {
  return makeAnyKeySelector(key, collection, propertyOptionsSchema);
}

export function makePropertyOptionsSelector(collection = DEFAULT_COLLECTION) {
  return makeCollectionSelector(collection, propertyOptionsSchema);
}

export function makePropertyOptionsAnyKeySelector(key, collection = DEFAULT_COLLECTION) {
  return makeAnyKeyCollectionSelector(key, collection, propertyOptionsSchema);
}

export function makePropertyOptionsFetchStatusSelector(collection = DEFAULT_COLLECTION) {
  return makeFetchStatusSelector(collection, propertyOptionsSchema);
}