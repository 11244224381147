import { denormalize } from "normalizr";

import { getEntities } from "./entities";
import { accountSchema } from "../schemas";
import { makeModificationStatusSelector } from "./modifications";


export const DEFAULT_MODIFICATION = "form";

export function getAccount(state, id) {
  return denormalize(id, accountSchema, getEntities(state));
}

export function makeAccountModificationStatusSelector(modification = DEFAULT_MODIFICATION) {
  return makeModificationStatusSelector(modification, accountSchema);
}