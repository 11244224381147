import React, { Component } from "react";


export function withBodyClass(classToAdd) {
  return function(WrappedComponent) {

    const WithBodyClass = class extends Component {

      componentDidMount() {
        classToAdd instanceof Array
          ? classToAdd.map((klass) => this.addBodyClass(klass))
          : this.addBodyClass(classToAdd);
      }

      componentWillUnmount() {
        classToAdd instanceof Array
          ? classToAdd.map((klass) => this.removeBodyClass(klass))
          : this.removeBodyClass(classToAdd);
      }

      addBodyClass = (className) => {
        document.body.classList.add(className);
      }

      removeBodyClass = (className) => {
        document.body.classList.remove(className);
      }

      render() {
        const { ...restProps } = this.props;

        return (
          <WrappedComponent {...restProps} />
        )
      }
    };

    return WithBodyClass;
  }
}