import uniq from "lodash/uniq";

export const FUNC_REPLACE = "replace";
export const FUNC_APPEND = "append";

const DEFAULT_STATE = {};

export function makeCollectionsReducer([requestAction, successAction, failedAction]) {
  return function(state = DEFAULT_STATE, { type, payload, meta }) {

    const collectionName = (meta || {}).collection;
    if (!collectionName) {
      return state;
    }

    const collection = state[collectionName] || {};
    switch (type) {
      case requestAction:
        return {
          ...state,
          [collectionName]: {
            ...collection,
            ids: collection.ids || [],
            isFetching: true,
          }
        };
      case successAction:
        const { result, entities, ...options } = payload;
        const idsFunc = meta.func || FUNC_REPLACE;
        let ids = result;
        if (idsFunc === FUNC_APPEND) {
          ids = uniq([
            ...(collection.ids || []),
            ...result,
          ])
        }
        return {
          ...state,
          [collectionName]: {
            ...collection,
            ...options,
            ids: ids,
            isFetching: false,
          }
        };
      case failedAction:
        return {
          ...state,
          [collectionName]: {
            ...collection,
            isFetching: false,
            error: payload,
          }
        };
      default:
        return state;
    }
  }
}