import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";

export const PERSONAL_TOUR_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exposition_id: PropTypes.number,
  comment: PropTypes.string
}

export const ENABLE_PERSONAL_TOUR = "ENABLE_PERSONAL_TOUR";
export const DISABLE_PERSONAL_TOUR = "DISABLE_PERSONAL_TOUR";

export const PERSONAL_TOUR_ITEM_CREATE = createRequestActionsFor("PERSONAL_TOUR_ITEM_CREATE");
