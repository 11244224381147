import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import { EXPERT_SHAPE } from "../../../constants/expertConstants";


class Experts extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { experts } = this.props;

    return (
      <div className="p-about__experts">
        <div className="section-title p-about__experts-title">
          {i18n.t('pages.about.experts.title')}
        </div>
        <div className="container">
          <div className="p-about__experts-list">
            <ul>
              {experts.map((item, index) =>
                <li key={index}>
                  <a
                    href={item.url}
                    target="_blank"
                    title={item.name}
                    className="p-about__experts-item"
                  >
                    {item.image &&
                      <img
                        src={item.image.thumb}
                        className="about__experts-image"
                        alt={item.name}
                        title={item.name}
                      />
                    }
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

Experts.propTypes = {
  experts: PropTypes.arrayOf(PropTypes.shape(EXPERT_SHAPE)).isRequired,
};

export default Experts