import { GA_TRACKING_ENABLED } from "../../../config";

export function TrackFooterClick(contactsType) {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    contactsType + ' button',
    'click',
    'footer'
  )
}
