import React from "react";
import classNames from "classnames";


export default function FormActions({children, className}) {
  const resultClass = classNames(className, "form__actions")
  return (
    <div className={resultClass}>
      {children}
    </div>
  )
}