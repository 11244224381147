import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";


class Dimensions extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { height, width } = this.props;

    return (
      <>
        { height && width &&
          <>
            {i18n.t('pages.painting.dimensions.title', {height, width})}
          </>
        }
      </>
    );
  }
}

Dimensions.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Dimensions