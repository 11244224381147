import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchItems } from "../../actions/expositionOrderActions";
import {
  makeExpositionOrderPreviewsSelector,
  makeExpositionOrderPreviewsAnyKeySelector,
  makeExpositionOrderPreviewsFetchStatusSelector,
} from "../../selectors/expositionOrderPreviews";
import { EXPOSITION_ORDER_SHAPE } from "../../constants/expositionOrderConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withExpositionOrders(WrappedComponent) {

  const WithExpositionOrders = class extends Component {

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    fetchExpositionOrders = (currentPage = 1, perPage) => {
      const { fetchItems, fetchStatus } = this.props;

      if (!fetchStatus.isFetching) {
        fetchItems(currentPage, perPage);
      }
    };

    render() {
      const { expositionOrders, pagination, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    expositionOrders={expositionOrders}
                    pagination={pagination}
                    fetchStatus={fetchStatus}
                    fetchExpositionOrders={this.fetchExpositionOrders}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithExpositionOrders.propTypes = {
    expositionOrders: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_ORDER_SHAPE)),
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const expositionOrdersSelector = makeExpositionOrderPreviewsSelector();
  const expositionOrdersPaginationSelector = makeExpositionOrderPreviewsAnyKeySelector('pagination');
  const expositionOrdersFetchStatusSelector = makeExpositionOrderPreviewsFetchStatusSelector();

  function mapStateToProps(state) {
    return {
      expositionOrders: expositionOrdersSelector(state),
      pagination: expositionOrdersPaginationSelector(state) || {},
      fetchStatus: expositionOrdersFetchStatusSelector(state),
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItems,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithExpositionOrders);
}
