export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const LOGIN_MODAL = "LOGIN_MODAL";
export const REGISTRATION_MODAL = "REGISTRATION_MODAL";
export const RESTORE_PASSWORD_MODAL = "RESTORE_PASSWORD_MODAL";

export const PHONE_MODAL = "PHONE_MODAL";

export const APPOINTMENT_MODAL = "APPOINTMENT_MODAL";
export const APPOINTMENT_SUCESS_MODAL = "APPOINTMENT_SUCESS_MODAL";
export const FAVORITE_MODAL = "FAVORITE_MODAL";
export const PAINTING_IMAGES_MODAL = "PAINTING_IMAGES_MODAL";
export const PAINTING_CONTENT_IMAGES_MODAL = "PAINTING_CONTENT_IMAGES_MODAL";

export const PAINTINGS_FILTER_MODAL = "PAINTINGS_FILTER_MODAL";
export const PAINTINGS_FILTER_ARTISTS_MODAL = "PAINTINGS_FILTER_ARTISTS_MODAL";
export const PAINTINGS_FILTER_OPTIONS_MODAL = "PAINTINGS_FILTER_OPTIONS_MODAL";

export const PERSONAL_TOUR_MODAL = "PERSONAL_TOUR_MODAL";
export const PERSONAL_TOUR_SUCESS_MODAL = "PERSONAL_TOUR_SUCESS_MODAL";

export const EXPOSITION_ORDER_SUCESS_MODAL = "EXPOSITION_ORDER_SUCESS_MODAL";
export const EXPOSITION_ORDER_TICKET_MODAL = "EXPOSITION_ORDER_TICKET_MODAL";

export const EXPOSITION_ORDER_ITEM_REFUND_MODAL = "EXPOSITION_ORDER_ITEM_REFUND_MODAL";
export const EXPOSITION_ORDER_ITEM_SUCESS_MODAL = "EXPOSITION_ORDER_ITEM_SUCESS_MODAL";

export const EXPOSITION_CONTENT_IMAGES_MODAL = "EXPOSITION_CONTENT_IMAGES_MODAL";

export const UNCONFIRMED_MODAL = "UNCONFIRMED_MODAL";
