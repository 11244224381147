import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import * as QueryString from "query-string";
import cn from "classnames";

import i18n from "../../../translations/i18n";
import { withArtistsLetters } from "../../../hocs/entities/withArtistsLetters";

const LETTERS = [
  'а', 'б', 'в', 'г', 'д', 'е', 'ё', 'ж', 'з', 'и',
  'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т',
  'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ъ', 'ы', 'ь',
  'э', 'ю', 'я'
]

const HEADER_SCROLL_OFFSET = 64;


function Filter({location, letters, onFilter}) {
  const [sticky, setSticky] = useState(false);
  const ref = useRef(null);

  const handleScroll = () => {
    if (ref.current && isMobile) {
      setSticky(window.scrollY > HEADER_SCROLL_OFFSET)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const letterClassName = (letter) => {
    const params = QueryString.parse(location.search);

    if (params && params.q) {
      return cn({"state-selected": letter === params.q})
    }
  }

  return (
    <div
      className={cn('p-painters__filter', {'p-painters__filter-sticky': sticky})}
      ref={ref}
    >
      <div className="container">
        <div className="p-painters__filter-content">
          <div className="p-painters__filter-head">
            <div className="p-painters__filter-name">
              {i18n.t('pages.artists.title')}
            </div>
            <div className="p-painters__filter-reset">
              <Link to="/artists" onClick={(event) => onFilter(event)}>
                {i18n.t('helpers.filter.reset.title')}
              </Link>
            </div>
          </div>
          <div className="p-painters__filter-alphabet">
            <ul>
              {LETTERS.map((letter, index) =>
                <li key={index}>
                  {letters.includes(letter)
                    ? <Link
                        to={`/artists?q=${letter}`}
                        className={letterClassName(letter)}
                        onClick={(event) => onFilter(event, letter)}
                      >
                        {letter}
                      </Link>
                    : <span>{letter}</span>
                  }
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

Filter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withArtistsLetters,
)(Filter)