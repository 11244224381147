import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as QueryString from "query-string";

import { fetchItems } from "../../actions/artistActions";
import {
  makeArtistPreviewsSelector,
  makeArtistPreviewsAnyKeySelector,
  makeArtistPreviewsFetchStatusSelector,
} from "../../selectors/artistPreviews";
import { ARTISTS_PER_PAGE } from "../../config";
import { ARTIST_SHAPE } from "../../constants/aritstConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withArtists(WrappedComponent) {

  const WithArtists = class extends Component {

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    // TODO: it breaks pagination
    // componentDidUpdate(prevProps) {
    //   const { location } = this.props;

    //   if (location && location !== prevProps.location) {
    //     const params = QueryString.parse(location.search);
    //     this.fetchArtists(params.q);
    //   }
    // }

    fetchArtists = (q = null, topArtists = false, currentPage = 1, perPage = ARTISTS_PER_PAGE) => {
      const { fetchItems, fetchStatus } = this.props;

      if (!fetchStatus.isFetching) {
        fetchItems(q, topArtists, currentPage, perPage);
      }
    };

    render() {
      const { artists, pagination, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    artists={artists}
                    pagination={pagination}
                    fetchStatus={fetchStatus}
                    fetchArtists={this.fetchArtists}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithArtists.propTypes = {
    artists: PropTypes.arrayOf(PropTypes.shape(ARTIST_SHAPE)),
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const artistsSelector = makeArtistPreviewsSelector();
  const artistsPaginationSelector = makeArtistPreviewsAnyKeySelector('pagination');
  const artistsFetchStatusSelector = makeArtistPreviewsFetchStatusSelector();

  function mapStateToProps(state) {
    return {
      artists: artistsSelector(state),
      pagination: artistsPaginationSelector(state) || {},
      fetchStatus: artistsFetchStatusSelector(state),
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItems,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithArtists);
}