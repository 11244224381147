import {
  HIDE_COOKIES,
} from "../constants/cookiesConstants";

const DEFAULT_STATE = {
  visible: true,
};

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case HIDE_COOKIES:
      return {
        ...state,
        visible: false,
      }
    default:
      return state;
  }
}