import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import {
  APPOINTMENT_PURPOSE_SHAPE,
  APPOINTMENT_PURPOSE_OPTIONS,
} from "../../../../constants/appointmentConstants";

import FormActions from "../../../../components/ui/form/actions";
import FormError from "../../../../components/ui/form/error";
import CheckboxGroupField from "../../../../components/ui/form/checkbox_group";
import Button from "../../../../components/ui/form/button";
import {
  TrackFieldError,
  TrackFieldValid,
  TrackAddAppointmentSubmitClick,
  TrackAddAppointmentSubmitError,
} from "../../../../helpers/analytics";


class AppointmentForm extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { serverError, errors, isSubmitting, handleSubmit } = this.props;

    return (
      <Form>
        <div className="modal__title">
          {i18n.t('modal.appointment.form.purpose.title')}
        </div>
        <p>
          {i18n.t('modal.appointment.form.purpose.message')}
        </p>
        <p>
          {i18n.t("modal.appointment.form.purpose.label")}
        </p>
        <Field
          name="purpose"
          component={CheckboxGroupField}
          options={APPOINTMENT_PURPOSE_OPTIONS}
          disabled={isSubmitting}
        />
        <p className="modal__small-text">
          {i18n.t("modal.appointment.form.notice.text")}
        </p>
        <p className="modal__small-text">
          {i18n.t("modal.appointment.form.contacts.text")}
        </p>
        <FormActions className="modal__form-button-wrapper">
          <FormError>
            {serverError}
          </FormError>
          <FormError>
            {errors.purpose}
          </FormError>
          <Button
            className="modal__form-button"
            type="submit"
            disabled={isSubmitting}
            onSubmit={handleSubmit}
          >
            {i18n.t("modal.appointment.form.submit.title")}
          </Button>
        </FormActions>
      </Form>
    )
  }
}

const AppointmentFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};

    TrackAddAppointmentSubmitClick(values.source, values.paintingName)

    if (isEmpty(values.purpose)) {
      TrackFieldError('purpose', 'empty', values.source);
      errors.purpose = i18n.t("modal.appointment.form.validation.purpose.required");
    } else {
      TrackFieldValid('purpose', values.source)
    }

    if (!isEmpty(errors)) {
      TrackAddAppointmentSubmitError();
    }

    return errors;
  },
})(AppointmentForm);

AppointmentFormWithFormik.propTypes = {
  initialValues: PropTypes.shape(APPOINTMENT_PURPOSE_SHAPE).isRequired,
  serverError: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default AppointmentFormWithFormik
