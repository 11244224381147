import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import {
  USER_AUTH_SHAPE,
  USER_PHONE_CONFIRMATION_SHAPE
} from "../../../../constants/userConstants";

import FormGroup from "../../../../components/ui/form/group";
import FormActions from "../../../../components/ui/form/actions";
import FormError from "../../../../components/ui/form/error";
import InputField from "../../../../components/ui/form/input_field";
import Button from "../../../../components/ui/form/button";


class PhoneConfirmationForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth, setSubmitting } = this.props;

    if (prevProps.auth !== auth && auth.error) {
      setSubmitting(false);
    }
  }

  handleResend = (event) => {
    event.preventDefault();
    this.props.onResend()
  }

  render() {
    const {
      auth,
      touched,
      isSubmitting,
      handleSubmit,
    } = this.props;

    return (
      <Form className="modal__form-confirmation fade-in">
        <div className="modal__form-fields-group">
          <FormGroup label={i18n.t("pages.phone_confirmation.form.code.label")} touched={touched.code}>
            <Field
              component={InputField}
              name="code"
              required
            />
            <a
              href="#"
              title={i18n.t("pages.phone_confirmation.form.resend.title")}
              className="modal__form-resend-link"
              onClick={this.handleResend}
            >
              {i18n.t("pages.phone_confirmation.form.resend.title")}
            </a>
          </FormGroup>
        </div>

        <FormActions className="modal__form-button-wrapper">
          <FormError>
            {auth.error}
          </FormError>
          <Button
            className="modal__form-button"
            type="submit"
            disabled={isSubmitting}
            onSubmit={handleSubmit}
          >
            {i18n.t("pages.phone_confirmation.form.submit.title")}
          </Button>
        </FormActions>
      </Form>
    )
  }
}

const PhoneConfirmationFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};

    if (isEmpty(values.code)) {
      errors.name = i18n.t("validation.field.required");
    }

    return errors;
  },
})(PhoneConfirmationForm);

PhoneConfirmationFormWithFormik.propTypes = {
  initialValues: PropTypes.shape(USER_PHONE_CONFIRMATION_SHAPE).isRequired,
  auth: PropTypes.shape(USER_AUTH_SHAPE).isRequired,
  onResend: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PhoneConfirmationFormWithFormik
