import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import { openModal } from "../../../../actions/modalActions";
import { PAINTING_INFORMATION_SHAPE } from "../../../../constants/paintingConstants";
import { PAINTING_CONTENT_IMAGES_MODAL } from "../../../../constants/modalConstants";
import Figure from "../../../../components/ui/figure";


class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [],
    }
  }

  handleClick = (images) => {
    const { openModal } = this.props;

    openModal({
      name: PAINTING_CONTENT_IMAGES_MODAL,
      images: images,
    });
  }

  renderNode(node, index) {
    const { images } = this.state;

    if (node.type === 'tag' && node.name === 'figure') {
      const attachment = JSON.parse(node.attribs['data-trix-attachment']);
      const attributes = JSON.parse(node.attribs['data-trix-attributes']);

      if (images.filter((image) => image.url === attachment.url).length === 0) {
        images.push(attachment);
      }

      return (
        <Figure
          key={index}
          className={node.attribs.class}
          attachment={attachment}
          attributes={attributes}
          images={images}
          modalName={PAINTING_CONTENT_IMAGES_MODAL}
        />
      )
    }
  }

  render () {
    const { title, items, className, id } = this.props;

    return (
      <>
        { !isEmpty(items) &&
          <div className={className} id={`#${id}`}>
            <h3 className="p-picture__info-description-title">
              <span>{title}</span>
            </h3>
            {items.map((item) =>
              <Fragment key={item.id}>
                {ReactHtmlParser(item.description, {
                    transform: (node, index) => this.renderNode(node, index)
                  }
                )}
              </Fragment>
            )}
          </div>
        }
      </>
    )
  }
}

List.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(PAINTING_INFORMATION_SHAPE)),
  class: PropTypes.string,
  id: PropTypes.string,
  openModal: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(List)