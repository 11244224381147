import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";

export const SEARCH_RESULT_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  searchable: PropTypes.shape(SEARCHABLE_SHAPE).isRequired,
}

const SEARCH_RESULT_IMAGE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thumb: PropTypes.string.isRequired,
}

export const SEARCHABLE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  image: PropTypes.shape(SEARCH_RESULT_IMAGE_SHAPE),
}

export const SEARCHABLE_ARTIST_SHAPE = {
  ...SEARCHABLE_SHAPE,
  year_of_birth: PropTypes.number,
  year_of_death: PropTypes.number,
}

export const SEARCHABLE_PAINTING_SHAPE = {
  ...SEARCHABLE_SHAPE,
  ended_at: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

export const SEARCHABLE_POST_SHAPE = {
  ...SEARCHABLE_SHAPE,
  date: PropTypes.string,
  annotation: PropTypes.string,
}

export const SEARCH_FACET_SHAPE = {
  type: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
}

export const SEARCH_PARAMS_SHAPE = {
  q: PropTypes.string,
}

export const SEARCH_RESULT_ARTIST_KIND = 'artist';
export const SEARCH_RESULT_PAINTING_KIND = 'painting';
export const SEARCH_RESULT_POST_KIND = 'post';

export const SEARCH_PREVIEWS = createRequestActionsFor("SEARCH_PREVIEWS");
