import { searchPreviewSchema } from "../schemas";
import {
  makeAnyKeySelector,
  makeCollectionSelector,
  makeAnyKeyCollectionSelector,
  makeFetchStatusSelector,
} from "./collections";

export const DEFAULT_COLLECTION = "index";

export function makeViewAnyKeySelector(key, collection) {
  return makeAnyKeySelector(key, collection, searchPreviewSchema);
}

export function makeSearchPreviewsSelector(collection = DEFAULT_COLLECTION) {
  return makeCollectionSelector(collection, searchPreviewSchema);
}

export function makeSearchPreviewsAnyKeySelector(key, collection = DEFAULT_COLLECTION) {
  return makeAnyKeyCollectionSelector(key, collection, searchPreviewSchema);
}

export function makeSearchPreviewsFetchStatusSelector(collection = DEFAULT_COLLECTION) {
  return makeFetchStatusSelector(collection, searchPreviewSchema);
}