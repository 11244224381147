import PropTypes from "prop-types";

import i18n from "../translations/i18n";
import { createRequestActionsFor } from "./apiFetch";
import { ARTIST_SHORT_SHAPE } from "./aritstConstants";

export const PAINTING_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  slug: PropTypes.string,
  new: PropTypes.bool,
  ended_at: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  available: PropTypes.bool,
  artist: PropTypes.shape(ARTIST_SHORT_SHAPE),
  poster: PropTypes.shape(PAINTING_POSTER_SHAPE),
  informations: PropTypes.arrayOf(PropTypes.shape(PAINTING_INFORMATION_SHAPE)),
  slider: PropTypes.arrayOf(PropTypes.shape(PAINTING_SLIDE_SHAPE)),
}

export const PAINTING_POSTER_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  medium: PropTypes.string,
  thumb: PropTypes.string,
}

export const PAINTING_SLIDE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  medium: PropTypes.string.isRequired,
  main: PropTypes.string.isRequired,
}

export const PAINTING_SLIDE_SCROLL = 'scroll-to-slide';

export const PAINTING_INFORMATION_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  kind: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export const PAINTING_PROVENANCE_KIND = 'provenance';
export const PAINTING_EXHIBITION_KIND = 'exhibition';
export const PAINTING_PUBLICATION_KIND = 'publication';
export const PAINTING_CONCLUSION_KIND = 'conclusion';

export const PAINTING_SELECT_DEFAULT_OPTION = {
  value: 'about',
  label: i18n.t('pages.painting.aside.about.title')
}

export const PAINTING_DETAILS_KIND = 'details';

export const PAINTING_PREVIEWS = createRequestActionsFor("PAINTING_PREVIEWS");
export const PAINTING_ITEM = createRequestActionsFor("PAINTING_ITEM");

export const PAINTINGS_BY_ARTISTS_FILTER = "PAINTINGS_BY_ARTISTS_FILTER";
export const PAINTINGS_BY_PROPERTIES_FILTER = "PAINTINGS_BY_PROPERTIES_FILTER";
export const PAINTINGS_BY_NOVELTY_FILTER = "PAINTINGS_BY_NOVELTY_FILTER";
export const PAINTINGS_FILTER_RESET = "PAINTINGS_FILTER_RESET";
export const PAINTINGS_FILTER_UPDATE_HISTORY = "PAINTINGS_FILTER_UPDATE_HISTORY";
