import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import InputField from "../../../components/ui/form/input_field";
import { SEARCH_PARAMS_SHAPE } from "../../../constants/searchConstants";


class SearchForm extends Component {

  constructor(props) {
    super(props);
  }

  debouncedSubmit = debounce(this.props.handleSubmit, 100);

  render() {
    const { handleSubmit } = this.props;

    return(
      <Form onSubmit={handleSubmit}>
        <div className="p-search__field-wrapper">
          <Field
            name="q"
            type="text"
            autoFocus={true}
            component={InputField}
            className="form__field p-search__field"
            placeholder={i18n.t('pages.search.form.input.placeholder')}
            onChange={this.debouncedSubmit}
          />
        </div>
      </Form>
    )
  }
}

const SearchFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};

    if (isEmpty(values.q)) {
      errors.email = i18n.t("validation.field.required");
    }

    return errors;
  },
})(SearchForm);

SearchFormWithFormik.propTypes = {
  initialValues: PropTypes.shape(SEARCH_PARAMS_SHAPE).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SearchFormWithFormik