import React, { Component } from "react";
import i18n from "../../../translations/i18n";
import AboutHead from "../../../../../images/about/head.jpg";
import PartnerImage1 from "../../../../../images/about/description/partner1.png";
import PartnerImage2 from "../../../../../images/about/description/partner2.png";


class Description extends Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    }
  }

  handleClick = (event) => {
    event.preventDefault();
    this.setState({visible: !this.state.visible});
  }

  render () {
    const { visible } = this.state;
    const hiddenStyle = { display: 'none' };

    return (
      <div className="p-about__description">
        <div className="container">
          <div className="p-about__description-head">
            <h1>
              {i18n.t('pages.about.title')}
            </h1>
            <div className="p-about__description-head-image-wrapper">
              <img alt="" src={AboutHead} />
            </div>
          </div>
          <div className="p-about__description-title">
            {i18n.t('pages.about.lead.title')}
          </div>
          <div className="p-about__description-paragraph">
            <div className="p-about__description-paragraph-text">
              <p>
                {i18n.t('pages.about.description.items.1')}
                &nbsp;
                <a
                  href="#"
                  className="p-about__description-read-more"
                  title={i18n.t('pages.about.read_all')}
                  style={visible ? hiddenStyle : null}
                  onClick={this.handleClick}
                >
                  {i18n.t('pages.about.read_all')}
                </a>
              </p>
              {[2,3,4].map(item =>
                <p key={item} style={visible ? null : hiddenStyle}>{i18n.t(`pages.about.description.items.${item}`)}</p>
              )}
            </div>
            <div className="p-about__description-paragraph-partners">
              <div className="p-about__description-paragraph-partners-title">
                {i18n.t('pages.about.description.partner.title')}
              </div>
              <div className="p-about__description-paragraph-partners-items">
                <div className="p-about__description-paragraph-partners-item">
                  <span className="p-about__description-paragraph-partners-img">
                    <img alt="" src={PartnerImage1} />
                  </span>
                  <p>
                    {i18n.t('pages.about.description.partner.text1')}
                  </p>
                </div>
                <div className="p-about__description-paragraph-partners-item">
                  <span className="p-about__description-paragraph-partners-img">
                    <img alt="" src={PartnerImage2} />
                  </span>
                  <p>
                    {i18n.t('pages.about.description.partner.text2')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Description;