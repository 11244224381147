import React, { Component } from "react";
import PropTypes from "prop-types";
import { createUltimatePagination, ITEM_TYPES } from "react-ultimate-pagination";
import cn from "classnames";


function itemClassName(props) {
  return cn("pagination__item", { "state-selected": props.isActive });
}

function Page(props) {
  return (
    <li>
      <a className={itemClassName(props)} disabled={props.isDisabled} onClick={props.onClick}>
        {props.value}
      </a>
    </li>
  )
}

function Ellipsis(props) {
  return (
    <li>
      <a href="#" className="pagination__item">...</a>
    </li>
  )
}

// function FirstPageLink(props) {
//   return (
//     <li>
//       <a className={itemClassName(props)} disabled={props.isDisabled} onClick={props.onClick}>First</a>
//     </li>
//   )
// }

// function PreviousPageLink(props) {
//   return (
//     <li>
//       <a className={itemClassName(props)} disabled={props.isDisabled} onClick={props.onClick}>&lt;</a>
//     </li>
//   )
// }

function NextPageLink(props) {
  return (
    <li>
      <a className="w-pagination__next" disabled={props.isDisabled} onClick={props.onClick}></a>
    </li>
  )
}

// function LastPageLink(props) {
//   return <a className={itemClassName(props)} disabled={props.isDisabled} onClick={props.onClick}>Last</a>
// }

function Stub(props) {
  return <></>
}

function Wrapper({ children, wrapperClassName }) {
  const wrapperClass = cn("w-pagination", wrapperClassName);

  return (
    <div className={wrapperClass}>
      <ul>
        {children}
      </ul>
    </div>
  )
}

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
  [ITEM_TYPES.ELLIPSIS]: Ellipsis,
  [ITEM_TYPES.FIRST_PAGE_LINK]: Stub,
  [ITEM_TYPES.PREVIOUS_PAGE_LINK]: Stub,
  [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
  [ITEM_TYPES.LAST_PAGE_LINK]: Stub
};

const Paginator = createUltimatePagination({
  itemTypeToComponent: itemTypeToComponent,
  WrapperComponent: Wrapper
});

Paginator.defaultProps = {
  hideFirstAndLastPageLinks: true,
};

export default Paginator