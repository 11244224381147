import React from "react";
import PropTypes from "prop-types";


class Info extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { date, place } = this.props;

    return (
      <>
        { date && place &&
          <>
            {date}
            ,&nbsp;
            {place}
          </>
        }
      </>
    );
  }
}

Info.propTypes = {
};

export default Info
