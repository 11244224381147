import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { withNotice } from "../../hocs/layout/withNotice";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import Posts from "../../components/posts/list";
import Subscription from "../../components/layout/subscription";
import Meta from "./meta";
import Actual from "./actual";
import Future from "./future";
import Past from "./past";
import { EXPOSITION_PAGE_POSTS_LIMIT } from "../../config";


class Expositions extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <main className="layout">
        <Meta />
        <div className="p-exhibitions">
          <div className="container">
            <div className="p-exhibitions__inner">
              <Actual />
              <Future />
              <Past />
            </div>
          </div>
        </div>
        <Posts withLink={true} postsLimit={EXPOSITION_PAGE_POSTS_LIMIT} />
        <Subscription source="expositions" />
      </main>
    );
  }
}

Expositions.propTypes = {
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withNotice(),
  withBodyClass('app-expositions'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Expositions)
