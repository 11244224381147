import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchItems } from "../../actions/cartActions";
import {
  makeCartPreviewsSelector,
  makeCartPreviewsAnyKeySelector,
  makeCartPreviewsFetchStatusSelector,
} from "../../selectors/cartPreviews";
import { CART_ITEM_SHAPE } from "../../constants/cartConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withCart(WrappedComponent) {

  const WithCart = class extends Component {

    componentDidMount() {
      this.fetchCart();
    }

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    fetchCart = () => {
      const { fetchItems, fetchStatus } = this.props;

      if (!fetchStatus.isFetching) {
        fetchItems();
      }
    };

    render() {
      const { cartItems, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    cartItems={cartItems}
                    fetchStatus={fetchStatus}
                    fetchCart={this.fetchCart}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithCart.propTypes = {
    cartItems: PropTypes.arrayOf(PropTypes.shape(CART_ITEM_SHAPE)),
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const cartSelector = makeCartPreviewsSelector();
  const cartPaginationSelector = makeCartPreviewsAnyKeySelector('pagination');
  const cartFetchStatusSelector = makeCartPreviewsFetchStatusSelector();

  function mapStateToProps(state) {
    return {
      cartItems: cartSelector(state),
      pagination: cartPaginationSelector(state) || {},
      fetchStatus: cartFetchStatusSelector(state),
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItems,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithCart);
}
