import React, { Component } from "react";
import { Redirect } from "react-router";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  createItem,
  createCachedItem,
  clearCachedItem
} from "../../../actions/orderActions";
import OrderForm from "./form";
import {
  TrackServiceClick,
  TrackOrderSubmitSubmitSuccess
} from "../../../helpers/analytics";


class Order extends Component {

  DEFAULT_STATE={
    selectedServices: [],
    redirectToLogin: false
  }

  constructor(props) {
    super(props);
    this.state = this.DEFAULT_STATE;
  }

  componentDidMount() {
    const { userLoggedIn, order, createItem, clearCachedItem } = this.props;

    if (order.submitted) {
      clearCachedItem();
      this.setState(this.DEFAULT_STATE)
    }
    else if (order.cached && order.redirected) {
      if (userLoggedIn) {
        createItem({comment: order.cached.comment, selectedServices: order.cached.selectedServices});
        window.scrollTo(0, this.orderRef.current.offsetTop - SCROLL_OFFSET)
      } else {
        this.setState({selectedServices: order.cached.selectedServices, comment: order.cached.comment});
      }
    }
  }

  handleClearCache = () => {
    const { clearCachedItem } = this.props;
    clearCachedItem();
    this.setState(this.DEFAULT_STATE);
  }

  handleSubmit = (values) => {
    const { location, createItem } = this.props;

    if (values.source) {
      TrackOrderSubmitSubmitSuccess(values.source);
    }

    values.path = location.pathname;
    createItem({...values, selectedServices: this.state.selectedServices});
  }

  handleClearOrder = () => {
    const { clearCachedItem } = this.props;
    clearCachedItem();
    this.setState(DEFAULT_STATE);
  }

  serviceOptions = (services) => {
    return services.map((service) => ({value: service.id, label: service.name}))
  }

  notSelectedOptions = (services) => {
    return this.serviceOptions(services.filter((service) => !this.state.selectedServices.includes(service.id)))
  }

  handleSelect = (e, oldServiceIndex) => {
    TrackServiceClick(e.label)

    let selectedServices = [...this.state.selectedServices];
    selectedServices[oldServiceIndex] = e.value;
    this.setState({selectedServices: selectedServices});
  }

  handleAdditionalSelect = (v) => {
    TrackServiceClick(v.label)

    let selectedServices = this.state.selectedServices.concat([v.value])
    this.setState({selectedServices: selectedServices})
  }

  handleClearOrder = () => {
    const { clearCachedItem } = this.props;
    clearCachedItem();
    this.setState(this.DEFAULT_STATE);
  }

  render() {
    if (this.state.redirectToLogin) {
      return (
        <Redirect to="/login"/>
      )
    }

    return <OrderForm
      {...this.props}
      handleSubmit={this.handleSubmit}
      handleClearCache={this.handleClearCache}
      handleClearOrder={this.handleClearOrder}
      serviceOptions={this.serviceOptions}
      notSelectedOptions={this.notSelectedOptions}
      handleSelect={this.handleSelect}
      handleAdditionalSelect={this.handleAdditionalSelect}
      selectedServices={this.state.selectedServices}
    />
  }
}

function mapStateToProps({currentUser, entities}) {
  // TODO: use selectors
  const order = entities.order;

  return {
    userLoggedIn: !!currentUser.token,
    order: order
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createItem,
    createCachedItem,
    clearCachedItem
  }, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Order)
