import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { SEARCH_PREVIEWS } from "../../../constants/searchConstants";
import { makeCollectionsReducer } from "../collectionsReducer";
import { searchPreviewSchema } from "../../../schemas";


export default combineReducers({
  byId: makeByIdReducer(searchPreviewSchema.key, [SEARCH_PREVIEWS.SUCCESS]),
  collections: makeCollectionsReducer(SEARCH_PREVIEWS.typeNames()),
});