import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { withSimilarPaintings } from "../../../hocs/entities/withSimilarPaintings";
import { PAINTING_SHAPE } from "../../../constants/paintingConstants";
import Gallery from "./gallery";


class Similar extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchPaintings();
  }

  handleReload = () => {
    this.props.fetchPaintings();
  }

  render () {
    const { paintings } = this.props;
    const isSimilar = !isEmpty(paintings) && paintings.length >= 3

    return (
      <>
        { isSimilar &&
          <div className="b-paintings-carousel">
            <div className="section-title">
              {i18n.t('pages.painting.similar.title')}
            </div>
            <Gallery
              paintings={paintings}
              onAppointment={this.handleReload}
              onFavorite={this.handleReload}
            />
          </div>
        }
      </>
    )
  }
}

Similar.propTypes = {
  painting: PropTypes.shape(PAINTING_SHAPE).isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withSimilarPaintings,
  connect(mapStateToProps, mapDispatchToProps)
)(Similar)