import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { ARTIST_TIMELINE_SHAPE } from "../../../constants/aritstConstants";

const VISIBLE_TIMELINES_COUNT = 4;


class Timelines extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    }
  }

  handleClick = (event) => {
    event.preventDefault();
    this.setState({expanded: !this.state.expanded});
  }

  render () {
    const { timelines } = this.props;
    const { expanded } = this.state;

    return (
      <>
        { !isEmpty(timelines) &&
          <div className="p-painter__info-chronology">
            <div className="section-title p-painter__info-chronology-title">
              {i18n.t('pages.artist.timelines.title')}
            </div>
            <div className="p-painter__info-chronology-list">
              {(expanded ? timelines : timelines.slice(0, VISIBLE_TIMELINES_COUNT)).map((timeline) =>
                <div key={timeline.id} className="p-painter__info-chronology-row">
                  <div className="p-painter__info-chronology-year">
                    <div className="p-painter__info-chronology-year-figures">
                      <span>{timeline.year_starts_at}</span>
                      {timeline.year_ends_at &&
                        <span> — {timeline.year_ends_at}</span>
                      }
                    </div>
                  </div>
                  <div className="p-painter__info-chronology-data">
                    {timeline.description}
                  </div>
                </div>
              )}
            </div>
            {!expanded &&
              <a
                href="#"
                className="p-painter__info-chronology-link-more"
                onClick={this.handleClick}
              >
                {i18n.t('pages.artist.timelines.more.title')}
              </a>
            }
          </div>
        }
      </>
    )
  }
}

Timelines.propTypes = {
  timelines: PropTypes.arrayOf(PropTypes.shape(ARTIST_TIMELINE_SHAPE))
};

export default Timelines