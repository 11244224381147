import { denormalize } from "normalizr";

import { getEntities } from "./entities";
import { paintingSchema } from "../schemas";

export const DEFAULT_MODIFICATION = "form";

export function getPainting(state, id) {
  return denormalize(id, paintingSchema, getEntities(state));
}
