import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import i18n from "../../translations/i18n";
import { withAccount } from "../../hocs/entities/withAccount";
import { withCart } from "../../hocs/entities/withCart";
import { withNotice } from "../../hocs/layout/withNotice";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import {
  updateItem,
  deleteItem
} from "../../actions/cartActions";
import { createItem as createOrder } from "../../actions/expositionOrderActions";
import { openModal } from "../../actions/modalActions";
import { getExpositionOrder } from "../../selectors/expositionOrders";
import Meta from "../../components/layout/meta";
import List from "../../components/cart/list";
import { EXPOSITION_ORDER_SUCESS_MODAL } from "../../constants/modalConstants";


class Cart extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, latestExpositionOrder, expositionOrder, openModal } = this.props;

    // order was created successfully
    if (prevProps.latestExpositionOrder !== latestExpositionOrder && latestExpositionOrder.created) {
      // free tickets
      if (expositionOrder && expositionOrder.free) {
        history.push('/expositions');
        openModal({name: EXPOSITION_ORDER_SUCESS_MODAL, expositionOrderId: expositionOrder.id});
      // payment
      } else {
        // TODO: change payment page
        // const alfaBankPaymentPage = 'https://ya.ru';
        // window.location = alfaBankPaymentPage;
      }
    }
  }

  handleUpdate = (id, count) => {
    this.props.updateItem(id, count)
  }

  handleSubmit = (items) => {
    const values = items.map(({date, count, price_id}) => {
      return { date, count, price_id }
    })

    this.props.createOrder(values)
  }

  handleDelete = (id) => {
    this.props.deleteItem(id)
  }

  render() {
    const { cartItems } = this.props;

    return (
      <main className="layout">
        <Meta />
        <div className="p-exhibitions">
          <div className="container">
            <div className="p-exhibitions__inner">
              <div className="p-exhibitions__title">
                {i18n.t('pages.cart.title')}
              </div>
              <div className="p-exhibitions__cart-inner">
                <div className="p-exhibitions__cart-head">
                  <ul>
                    <li className="p-exhibitions__cart-item-type">
                      {i18n.t('pages.cart.head.type.title')}
                    </li>
                    <li className="p-exhibitions__cart-item-quantity">
                      {i18n.t('pages.cart.head.quantity.title')}
                    </li>
                    <li className="p-exhibitions__cart-item-cost">
                      {i18n.t('pages.cart.head.cost.title')}
                    </li>
                  </ul>
                </div>
                <List
                  items={cartItems}
                  onUpdate={this.handleUpdate}
                  onSubmit={this.handleSubmit}
                  onDelete={this.handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

Cart.propTypes = {
  updateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const { layout } = state;
  const expositionOrderId = layout.expositionOrder.id

  return {
    latestExpositionOrder: layout.expositionOrder,
    expositionOrder: getExpositionOrder(state, expositionOrderId),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateItem,
    deleteItem,
    createOrder,
    openModal,
  }, dispatch)
}

export default compose(
  withRouter,
  withAccount,
  withCart,
  withNotice(),
  withBodyClass('app-expositions'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Cart)
