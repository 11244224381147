import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { makeModificationsReducer, requestActionsToTypes } from "../modificationsReducer";
import { expositionOrderSchema } from "../../../schemas";
import { EXPOSITION_ORDER_CREATE } from "../../../constants/expositionOrderConstants";


export default combineReducers({
  byId: makeByIdReducer(
    expositionOrderSchema.key,
    [EXPOSITION_ORDER_CREATE.SUCCESS],
    [EXPOSITION_ORDER_CREATE.FAILED]
  ),
  modifications: makeModificationsReducer(requestActionsToTypes([
    EXPOSITION_ORDER_CREATE,
  ])),
})
