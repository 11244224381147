import { RSAA } from "../middlewares/redux-api-middleware";

import { API } from "../config";
import {
  PERSONAL_TOUR_ITEM_CREATE,
  ENABLE_PERSONAL_TOUR,
  DISABLE_PERSONAL_TOUR,
} from "../constants/personalTourConstants";
import { openModal } from "./modalActions";
import { store } from "../store";
import {
  PERSONAL_TOUR_MODAL,
  PERSONAL_TOUR_SUCESS_MODAL,
} from "../constants/modalConstants";
import isNil from "lodash/isNil";


export function createItem(id, values) {
  return function(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/personal_tours`,
        method: 'POST',
        body: {
          personal_tour: {
            exposition_id: id,
            comment: values.comment,
          },
          path: values.path
        },
        types: PERSONAL_TOUR_ITEM_CREATE.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      dispatch(openModal({name: PERSONAL_TOUR_SUCESS_MODAL}));
      return {}
    }
  }
}

export function enablePersonalTour(payload) {
  return function(dispatch) {
    return dispatch({
      type: ENABLE_PERSONAL_TOUR,
      payload: payload
    });
  }
}


export function disablePersonalTour() {
  return function(dispatch) {
    return dispatch({
      type: DISABLE_PERSONAL_TOUR,
      payload: {}
    });
  }
}


export function openPersonalTourModal() {
  const personalTour = store.getState().layout.personalTour

  return function openPersonalTourModalThunk(dispatch) {
    if (personalTour && personalTour.enabled && !isNil(personalTour.expositionId)) {
      dispatch(openModal({
        name: PERSONAL_TOUR_MODAL,
        expositionId: personalTour.expositionId,
        onPersonalTour: personalTour.onPersonalTour,
      }));
    }
  }
}
