import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";

export const SEO_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  logo: PropTypes.string,
  email: PropTypes.string,
  telephone: PropTypes.string,
  robots_blocked: PropTypes.bool,
}

export const SEO_ARTIST_KIND = "artist";
export const SEO_PAINTING_KIND = "painting";
export const SEO_POST_KIND = "post";

export const SEO_ITEM = createRequestActionsFor("SEO_ITEM");
