import React from "react";

import Meta from "../../../components/layout/meta";


class Terms extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <main className="layout">
        <Meta />
        <div className="p-static">
          <div className="container">

            <h1>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>
            <p>Настоящий документ, представленный на сайте www.collectart.ru под наименованием «Пользовательское соглашение» (далее – <strong>Соглашение</strong>), является официальной публичной офертой в соответствии со статьями 428, 432, 435, 437, 438 и статьей 445 Гражданского Кодекса Российской Федерации (ГК РФ) индивидуального предпринимателя Язычьян Рубена Севоновича (ОГРНИП 306770000181679, далее также – <strong>Исполнитель</strong>)  и определяет условия использования посетителями и пользователями сайта www.collectart.ru (далее – <strong>Сайт</strong>), материалов  размещенных на Сайте, содержит все существенные условия предоставления услуг, предлагаемых на Сайте.</p>
            <p>Совершение Пользователем действий по посещению и использованию Сайта, в том числе, регистрация личного кабинета, заказ услуги, направление сообщений через форму связи и прочие действия по использованию функциональности Сайта, является полным и безоговорочным согласием Пользователя с офертой и с этого момента Пользователь считается присоединившимся к настоящему Соглашению. В случае несогласия с любым из условий Соглашения Пользователь обязан немедленно прекратить использование Сайта и покинуть его.</p>
            <p>Принимая условия Соглашения Пользователь подтверждает свою правоспособность и дееспособность, достижение возраста 18 лет, а также законное право вступать в договорные отношения с Исполнителем.</p>
            <br/>
            <h2>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
            <p><strong>1.1. Исполнитель</strong> – Индивидуальный предприниматель Язычьян Рубен Севонович, осуществляющий свою деятельность посредством Интернет-ресурса www.collectart.ru и сопутствующих ему услуг и сервисов. Исполнитель предлагает свои Услуги и сервисы Сайта на условиях, изложенных в настоящем Соглашении.</p>
            <p><strong>1.2. Сайт</strong> – ресурс, расположенный в сети Интернет по адресу www.collectart.ru.</p>
            <p><strong>1.3. Пользователь</strong> – любое физическое или юридическое лицо (представитель юридического лица), имеющее доступ к сайту www.collectart.ru посредством сети Интернет и использующее информацию, материалы и услуги и сервисы сайта www.collectart.ru.</p>
            <p><strong>1.4. Авторизованный пользователь</strong> - пользователь Сайта www.collectart.ru, прошедший процедуру регистрации на Сайте www.collectart.ru и получивший доступ к личному кабинету (аккаунту) на Сайте www.collectart.ru.</p>
            <p><strong>1.5. Личный кабинет</strong> – учетная запись (аккаунт) Пользователя на Интернет-сайте www.collectart.ru, создаваемая в момент регистрации Пользователя и содержащая его персональные данные, указанные при регистрации или последующем изменении сведений, а также данные о Заказах.</p>
            <p><strong>1.6. Регистрация</strong> — процедура внесения Персональных данных Пользователя в специальную форму на Сайте www.collectart.ru, необходимая для создания учетной записи (личного кабинета) Пользователя.</p>
            <p><strong>1.7. Персональные данные</strong> — личная информация (в том числе фамилия, имя, отчество, контактный телефон, адрес электронной почты), добровольно и осознанно предоставляемая Пользователем при Регистрации на Сайте www.collectart.ru и необходимая для выполнения для доступа Пользователя к услугам и сервисам Сайта www.collectart.ru, а также для выполнения Заказа.</p>
            <p><strong>1.8. Заказ</strong> – заявка Пользователя на оказание Услуг, оказываемых Исполнителем, корректно оформленная через Личный кабинет Пользователя на сайте www.collectart.ru, путем направления сообщения на электронную почту info@collectart.ru или по телефону +7 495 648 62 42. Действия по размещению Заказа являются полным и безоговорочным акцептом данного Соглашения как оферты на оказание Услуг. </p>
            <p><strong>1.9. Заказчик</strong> – Пользователь, оформивший Заказ на Сайте www.collectart.ru. </p>
            <p><strong>1.10. Услуги</strong> — комплекс услуг, оказываемых Исполнителем через Сайт www.collectart.ru, в том числе услуги арт-консалтинга, провенанса, атрибуции, каталогизации, реставрации и оформления произведений, а также иные связанные с введением в оборот предметов изобразительного искусства услуги, которые могут быть согласованы в индивидуальном порядке Исполнителем и Пользователем.</p>
            <p><strong>1.11. Соглашение</strong> — настоящее Пользовательское Соглашение, регулирующее отношения Исполнителя и Пользователя на протяжении всего периода предоставления Услуг и доступа Пользователя к сервисам Сайта www.collectart.ru. </p>
            <p><strong>1.12. Произведение</strong> - произведение живописи и графики и иные предметы изобразительного искусства и/или предметы коллекционирования.</p>
            <p><strong>1.13. Стороны</strong> – Заказчик и Исполнитель применительно к договору оказания Услуг.</p>
            <br/>
            <h2>2. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
            <p><strong>2.1.</strong> Сайт создан с целью предоставления возможности профессионального общения, поиска подлинных, редких и качественных произведений живописи и графики XIX – XX веков, оказания содействия по вопросам организации выставок и рекламирования предметов изобразительного искусства и предметов коллекционирования, а также оказания Исполнителем Пользователям Услуг, как они определены в п. 1.10 настоящего Соглашения. </p>
            <p><strong>2.2.</strong> Исполнитель гарантирует, что у него имеются все разрешения, необходимые в соответствии с законодательством РФ для оказания Услуг.</p>
            <p><strong>2.3.</strong> Пользователь обязуется не предпринимать действий и не оставлять комментарии и записи, которые могут рассматриваться как нарушающие российское законодательство или нормы международного права, в том числе в сфере интеллектуальной собственности, авторских и/или смежных прав, общепринятые нормы морали и нравственности, а также любых действий, которые приводят или могут привести к нарушению нормальной работы Сайта и сервисов Сайта.</p>
            <p><strong>2.4.</strong> Использование материалов Сайта без согласия Исполнителя не допускается. При цитировании материалов Сайта, включая охраняемые объект интеллектуальной собственности, ссылка на Сайт обязательна.</p>
            <p><strong>2.5.</strong>  Исполнитель не несет ответственности за посещение и использование Пользователем внешних ресурсов, ссылки на которые могут содержаться на Сайте.</p>
            <p><strong>2.6.</strong> Содержание Сайта не предназначено для несовершеннолетних (18+). Такой контент, может включать изображение наготы или другой графический контент, который некоторые люди могут счесть неприемлемым или оскорбительным. Если Пользователь позволяет несовершеннолетнему лицу использовать свой компьютер или иное устройство и/или предоставляет ему доступ к Личному кабинету на Сайте, Пользователь самостоятельно принимает на себя ответственность решать, является ли контент Сайта и любые изображения, размещенные на Сайте, подходящими для его несовершеннолетнего ребенка. Если содержимое Сайта кажется вам оскорбительным, вам необходимо прекратить использование Сайта.</p>
            <p><strong>2.7.</strong> Материал, опубликованный на Сайте, может содержать неточности или опечатки. Некоторые материалы на Сайте предоставлены третьими лицами, в том числе авторизованными Пользователями. Исполнитель не несет ответственность за точность и достоверность любых материалов, предоставленных третьими лицами.</p>
            <p><strong>2.8.</strong> Пользователи обязуются не использовать Сайт с целью нарушения прав третьих лиц и нарушения действующего законодательства РФ, в том числе обязуются не загружать противоправный контент и контент, который может нарушать права третьих лиц на результаты интеллектуальной деятельности, принадлежащие или правомерно используемые третьими лицами.</p>
            <p><strong>2.9.</strong> Если вы не удовлетворены работой любого раздела Сайта, его контентом или любым из положений настоящего Соглашения, ваше единственное и исключительное право - это прекратить использование Сайта. </p>
            <br/>
            <h2>3. регистрация на сайте</h2>
            <p><strong>3.1.</strong> Для получения доступа к Услугам Пользователь осуществляет регистрацию на Сайте, то есть создает учетную запись – Личный кабинет. </p>
            <p><strong>3.2.</strong> При создании Личного кабинета Пользователь, являющийся физически лицом, указывает свои имя и фамилию, номер телефона и адрес электронной почты. </p>
            <p><strong>3.3.</strong>  При регистрации Личного кабинета от имени и в интересах юридического лица регистрацию осуществляет физическое лицо, уполномоченное действовать от имени юридического лица. В этом случае такое физическое лицо указывает свое имя и фамилию, а также иные данные, предусмотренные п. 3.2. настоящего Соглашения. Наличие у физического лица необходимых полномочий действовать от имени юридического лица предполагается, при этом юридическое лицо самостоятельно несет ответственность за действия уполномоченного физического лица при регистрации Личного кабинета и дальнейшие его действия. Тем не менее, Исполнитель сохраняет за собой право затребовать у физического лица документы, подтверждающие его полномочия действовать от имени юридического лица. </p>
            <p><strong>3.4.</strong> Регистрируясь на Сайте, Пользователь:</p>
            <ul className="static-list">
              <li> подтверждает, что указанные им данные являются достоверными и актуальными; </li>
              <li> подтверждает свою дееспособность в соответствии с законодательством РФ; </li>
              <li> подтверждает полноту и достоверность предоставляемой им информации о Произведениях, которую он размещает в Личном кабинете, а также принимает на себя все коммерческие и иные риски, связанные с предоставление неполных или недостоверных данных о Произведениях. </li>
            </ul>
            <p><strong>3.5.</strong> Пользователь, прошедший Регистрацию на Сайте, обязуется не передавать уникальный логин и пароль от Личного кабинета третьим лицам. Пользователь самостоятельно несет ответственность и риски в связи с передачей логина и пароля от Личного кабинета третьим лицам, в том числе несет обязательства по сделкам, заключённым от его имени через Личный кабинет на Сайте. </p>
            <p><strong>3.6.</strong> Исполнитель оставляет за собой право отказать в Регистрации Личного кабинета или заблокировать ранее созданный Личный Кабинет и отказать Пользователю в доступе к Услугам и Сайту, если у Исполнителя есть основания полагать, что Пользователем представлены неполные и/или недостоверные данные или действия Пользователя нарушают права третьих лиц и нормы действующего законодательства. Пользователь, доступ которого к Сайту и Личному кабинету был прекращен или данные которого признаны недостоверными, не имеет права создавать новую учетную запись повторно (в том числе с использованием ранее указанного Пользователем адреса электронной почты) без особого разрешения Исполнителя.</p>
            <br/>
            <h2>4. УСЛОВИЯ И ПОРЯДОК ПРЕДОСТАВЛЕНИЯ УСЛУГ</h2>
            <p><strong>4.1.</strong> Исполнитель оказывает Услуги, наименование которых указано в соответствующем разделе Сайта, в том числе услуги арт-консалтинга, провенанса, атрибуции, каталогизации, реставрации и оформления произведений, а также иные связанные с введением в оборот предметов изобразительного искусства услуги, которые могут быть согласованы в индивидуальном порядке Исполнителем и Пользователем. Исполнитель также оформляет или оказывает содействие в оформлении необходимой и сопутствующей документации, имеющей отношение к предмету Услуг.  </p>
            <p><strong>4.2.</strong> Исполнитель оказывает Услуги на возмездной основе и на условиях, предусмотренных настоящим Соглашением. Размещая Заказ на Услуги, Пользователь выражает полное и безоговорочное согласие с порядком и условиями оказания Услуг, предусмотренными настоящим Соглашением.</p>
            <p><strong>4.3.</strong> Исполнитель оставляет за собой право отклонить Заказ Пользователя по собственному усмотрению и не обязуется обосновывать свое решение.</p>
            <p><strong>4.4.</strong> Выбрав вид Услуги, Пользователь направляет в адрес Исполнителя Заказ на оказание Услуг (что является акцептом оферты), после чего договор на оказание соответствующих Услуг между Пользователем (Заказчиком) и Исполнителем считается заключённым.</p>
            <p><strong>4.5.</strong> Исполнитель обязуется:</p>
            <ul className="static-list no-dots">
              <li><p><strong>4.5.1.</strong> Своевременно и в полном объеме оказать Заказчику Услуги, наименование, объем, цена и прочие условия, которых указаны в счёте на оплату Услуги (п. 4.7. настоящего Соглашения). Исполнитель вправе без согласования и уведомления Заказчика привлечь третьих лиц к процессу оказания Услуг;</p></li>
              <li><p><strong>4.5.2.</strong> Своевременно извещать Заказчика обо всех условиях оказания Услуг, требующих дополнительного согласования. </p></li>
            </ul>
            <p><strong>4.6.</strong> Заказчик обязуется:</p>
            <ul className="static-list no-dots">
              <li><p><strong>4.6.1.</strong> Оплатить Услуги Исполнителя;</p></li>
              <li><p><strong>4.6.2.</strong> Предоставить Исполнителю необходимые для оказания Услуг информацию, материалы, документы, в том числе предусмотренные 5.4. настоящего Соглашения. </p></li>
            </ul>
            <p><strong>4.7.</strong> Оказание всех Услуг осуществляется Исполнителем на основании 100% предоплаты. Оплата производится на основании выставленного Исполнителем соответствующего счёта в срок, указанный в счёте. Счет на оплату выставляемся после размещения Заказчиком Заказа выбранной Услуги.</p>
            <p><strong>4.8.</strong> Оплата предоставляемых Исполнителем услуг производится в рублях банковским переводом или посредством соответствующей транзакции через кассовый терминал наличным или безналичным способом в офисе Исполнителя.</p>
            <p><strong>4.9.</strong> Обязательства Заказчика по оплате Услуг считаются исполненными им надлежащим образом в день зачисления денежных средств в полном объёме на расчётный счёт или в кассу Исполнителя.</p>
            <p><strong>4.10.</strong> Услуги считаются оказанными надлежащим образом и в полном объёме, если Заказчиком не выставлена мотивированная претензия в момент приемки исполнения по договору. В случае отсутствия мотивированной претензии, акт оказанных услуг считается подписанным, а Услуги оказанными надлежащим образом. </p>
            <p><strong>4.11.</strong> По факту оказания услуг Исполнитель предоставляет универсальный передаточный документ в качестве первичного документа и счета-фактуры.</p>
            <p><strong>4.12.</strong> Если заказанные Услуги подразумевают передачу Произведения Исполнителю, такая передача осуществляется в офисе Исполнителя, если Исполнитель и Заказчик не согласовали иное условие о месте передачи Произведения.</p>
            <p><strong>4.13.</strong> В момент передачи Произведения составляется и подписывается Акт приёма-передачи, а после оказания услуги - Акт оказания услуги.</p>
            <p><strong>4.14.</strong> Заказчик несет ответственность за достоверность предоставленной им информации и все риски, связанные с подлинностью Произведений, передаваемых им Исполнителю, в том числе принимает на себя обязанность возместить убытки Исполнителя, понесенные им в связи с приемкой такого Произведения.</p>
            <p><strong>4.15.</strong> Исполнитель несёт ответственность за нарушение обязательств по настоящему договору только при наличии в этом его прямой и непосредственной вины. В случае невозможности оказания услуг по вине Исполнителя, Исполнитель обязуется произвести возврат денежных средств, оплаченных Заказчиком, за вычетом расходов, фактически понесённых к этому моменту в ходе исполнения договора оказания Услуг.</p>
            <p><strong>4.16.</strong> Если в ходе исполнения договора оказания Услуг обнаруживаются препятствия к его надлежащему исполнению, каждая из сторон (Заказчик и Исполнитель) обязана принять все зависящие от неё разумные меры по устранению таких препятствий.</p>
            <p><strong>4.17.</strong> В случае отказа Заказчика от договора оказания Услуг, оплата возвращается Заказчику полностью, либо за исключением уже понесённых Исполнителем расходов по исполнению договора оказание Услуг пропорционально части выполненной работы (оказанной услуги). Отказом от услуги считается получение Исполнителем извещения о расторжении договора оказание Услуг от Заказчика. Заказчик должен направить заявку на возврат денежных средств через Личный кабине или путем направления письма на адрес электронной почты info@collectart.ru.</p>
            <p><strong>4.18.</strong> Стороны освобождаются от ответственности за полное или частичное неисполнение обязательств по договору оказания Услуг, если докажут, что надлежащее исполнение обязательств оказалось невозможным, а неисполнение обязательств явилось следствием обстоятельств, вызванных прямо или косвенно действием непреодолимой силы, возникших после заключения договора оказания Услуг и не могущих быть ни предвиденными, ни предотвратимыми разумными мерами при данных условиях (форс-мажор).</p>
            <p><strong>4.19.</strong> Под обстоятельствами непреодолимой силы понимаются чрезвычайные и непреодолимые обстоятельства, препятствующие исполнению своих обязательств по настоящему договору Заказчиком и Исполнителем. К таким обстоятельствам относятся стихийные явления (землетрясения, наводнения и т.п.), обстоятельства общественной жизни (военные действия, чрезвычайные положения, крупнейшие забастовки, эпидемии и т.п.), запретительные меры государственных органов (запрещение перевозок, валютные ограничения, международные санкции запрета на торговлю и т.п.). В течение этого времени Заказчик и Исполнитель не могут иметь взаимных претензий и принимают на себя свой риск последствия форс-мажорных обстоятельств.</p>
            <p><strong>4.20.</strong> К обстоятельствам чрезвычайного характера не относятся, в частности, нарушение обязательств со стороны контрагентов Заказчика, действия иных третьих лиц, отсутствие у банковского учреждения или на счёте Заказчика необходимых денежных средств, ненадлежащие действия сотрудников Заказчика.</p>
            <p><strong>4.21.</strong> Стороны обязаны в течение трех (трех) дней письменно известить друг друга о начале и окончании обстоятельств форс-мажора, препятствующих исполнению договора оказания Услуг, в том числе о конкретном препятствии и его влиянии на исполнение обязательств по договору.</p>
            <p><strong>4.22.</strong> Сроки выполнения обязательств по договору оказания Услуг соразмерно отодвигаются на время действия форс-мажорных обстоятельств, если они значительно влияют на выполнение в срок всего договора или той его части, которая подлежит выполнению после наступления обстоятельств форс-мажора.</p>
            <br/>
            <h2>5. ИНФОРМАЦИЯ О ПРОИЗВЕДЕНИЯХ НА САЙТЕ</h2>

            <p><strong>5.1.</strong> Все Произведения, информация о которых опубликована на Сайте в разделе «Каталог», подробно описываются, в частности указывается следующая основная информация о Произведении: размер, датировка, материалы, атрибуции. </p>
            <p><strong>5.2.</strong> На Сайте размещаются фотографии Произведения в высоком качестве в различных ракурсах, позволяющих получить представление о внешнем виде Произведения, а также фотографии с отображением марок, клейм, подписей, возможных дефектов и недостатков Произведения.</p>
            <p><strong>5.3.</strong> Пользователь, намеренный стать партнером Исполнителя и выставить для всеобщего ознакомления Произведение на Сайте, сообщает об этом Исполнителю путем размещения соответствующего Заказа в Личном кабинете или путем направления заявления по адресу электронной почты <a href="mailto:info@collectart.ru">info@collectart.ru</a> или телефонам, указанным на Сайте. </p>
            <p><strong>5.4.</strong> Владелец Произведения, выразивший желание стать партнером Исполнителя и разместить информацию о Произведении на сайте, должен предоставить:</p>
            <ul className="static-list no-dots">
              <li><p><strong>5.4.1.</strong> Фотографии лицевой и оборотной стороны, отдельно — подписи, надписей и пр., хорошего качества (высокой резкости, освещенности и цветопередачи, с размерами файлов не менее 300–400 Кбайт);</p></li>
              <li><p><strong>5.4.2.</strong> Описание произведения (информацию, которой располагает владелец):</p></li>
              <ul className="static-list">
                <li>фамилия, имя, отчество автора, год его рождения / годы жизни;</li>
                <li>название работы;</li>
                <li>год создания работы;</li>
                <li>материал;</li>
                <li>техника;</li>
                <li>размер работы;</li>
                <li>тираж и номер экземпляра (для тиражной графики);</li>
                <li>провенанс — сведения об истории бытования произведения: принадлежность к частным собраниям, участие в выставках, аукционах, упоминания в публикациях;</li>
                <li>экспертиза и другие письменные подтверждения подлинности от профильных экспертов. Возможны письменные подтверждения подлинности от самих авторов или наследников художников;</li>
                <li>наличие или отсутствие оформления (рама, паспарту и пр.).</li>
              </ul>
            </ul>
            <p><strong>5.5.</strong> Решение Исполнителя касательно выставления Произведения на Сайте сообщается владельцу Произведения  в течение 7 рабочих дней после получения от него всей необходимой информации. Отрицательные решения не комментируются. В случае положительного решения Исполнитель связывается с владельцем Произведения искусства для уточнения деталей и назначает личную встречу для детального осмотра Произведения.</p>
            <p><strong>5.6.</strong> Исполнитель гарантирует конфиденциальность представленной Заказчиком / Пользователем информации, включая конфиденциальность текста переписки с Исполнителем, материалов о Произведении (архивные документы, фотоснимки и т.д.), стоимости Услуг. Иная информация, если она не была оговорена в Заказе или в процессе переписки между Исполнителем и Заказчиком/Пользователем, конфиденциальной не является.</p>
            <p><strong>5.7.</strong> Заинтересованные Пользователи Сайта могут связаться с Исполнителем, нажав кнопку «Записаться на просмотр» на Сайте на странице интересующего Произведения, с целью самолично ознакомиться с произведением искусства.</p>
            <br/>
            <h2>6. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
            <p><strong>6.1.</strong> Пользовательское соглашение может быть изменено Исполнителем в любое время без какого-либо специального уведомления об этом Пользователя. Исполнитель вправе в одностороннем порядке изменять условия Соглашения путем опубликования его новой редакции на Сайте. При этом к отношениям между Исполнителем и Пользователем/Заказчиком подлежит применению редакция Соглашения, действующая на момент на момент акцепта. Регулярное ознакомление с действующей редакцией Пользовательского соглашения является обязанностью Пользователя. Посещение Сайта и использование его ресурсов после вступления в силу новой редакции Соглашение означает согласие Пользователя с новой редакцией. Действующая редакция настоящего Соглашения доступна по адресу https://collectart.ru/terms.</p>
            <p><strong>6.2.</strong> Признание отдельных частей настоящего Пользовательского соглашения недействительными не отменяет действие других положений настоящего Пользовательского соглашения.</p>
            <p><strong>6.3.</strong> Правом, применимым к настоящему Соглашению, является право Российской Федерации. Все вопросы, не урегулированные Соглашением, регламентируются законодательством РФ.</p>
            <p><strong>6.4.</strong> Все споры и разногласия между Пользователем и Исполнителем решаются путём переговоров. В случае если споры и разногласия не могут быть урегулированы путём переговоров, они передаются на рассмотрение суда в соответствии с установленной законодательством РФ подсудностью.</p>
            <br/>
            <p className="center">
              <strong>ИП ЯЗЫЧЬЯН РУБЕН СЕВОНОВИЧ</strong><br/>
              <strong>ИНН 773765030022 ОГРНИП 306770000181679</strong><br/>
              <strong>119034 МОСКВА УЛ.ПРЕЧИСТЕНКА 30/2</strong>
            </p>
          </div>
        </div>
      </main>
    );
  }
}

Terms.propTypes = {
};

export default Terms