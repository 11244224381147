import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";

import { API, POSTS_PER_PAGE } from "../config";
import {
  POST_PREVIEWS,
  POST_ITEM,
} from "../constants/postConstants";
import {
  postPreviewSchema,
  postSchema
} from "../schemas";
import { DEFAULT_COLLECTION } from "../selectors/postPreviews";
import { FUNC_APPEND, FUNC_REPLACE } from "../reducers/entities/collectionsReducer";


export function fetchItems(page = 1, perPage = POSTS_PER_PAGE, fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE, // page === 1 ? FUNC_REPLACE : FUNC_APPEND,
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/posts?page=${page}&per_page=${perPage}`,
        types: POST_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { posts, meta } = await res.json();

      return {
        ...normalize(posts, [postPreviewSchema]),
        pagination: meta,
      };
    }
  };
}


export function fetchItem(id, fetchOptions = {}) {
  const meta = {
    key: id,
  };
  const include = fetchOptions.include || {};

  return function fetchItemThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/posts/${id}`,
        types: POST_ITEM.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { post } = await res.json();

      return {
        ...normalize(post, postSchema),
      }
    }
  }
}


export function fetchSimilar(id, page = 1, perPage = POSTS_PER_PAGE, fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE,
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/posts/${id}/similar?page=${page}&per_page=${perPage}`,
        types: POST_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { posts, meta } = await res.json();

      return {
        ...normalize(posts, [postPreviewSchema]),
        pagination: meta,
      };
    }
  }
}
