import { GA_TRACKING_ENABLED } from "../../../config";

export const GA_ORDERS_ABOUT_SOURCE = 'about';
export const GA_ORDERS_CONSULTING_SOURCE = 'consulting';

export function TrackChooseServiceClick(source) {
  switch (source) {
    case GA_ORDERS_ABOUT_SOURCE:
      if (GA_TRACKING_ENABLED) window.ga(
        'send', 'event',
        "'Выберите' field",
        "click",
        "service order form_about page"
      )
      break;
    case GA_ORDERS_CONSULTING_SOURCE:
      if (GA_TRACKING_ENABLED) window.ga(
        'send', 'event',
        "'Выберите' field",
        "click",
        "service order form_artconsulting page"
      )
      break;
    default:
      return;
  }
}

export function TrackServiceClick(serviceName) {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    serviceName,
    "click",
    "'Выберите' field"
  )
}

export function TrackOrderSubmitClick(source) {
  switch (source) {
    case GA_ORDERS_ABOUT_SOURCE:
      if (GA_TRACKING_ENABLED) window.ga(
        'send', 'event',
        "button 'Отправить'",
        "click",
        "service order form_about page"
      )
      break;
    case GA_ORDERS_CONSULTING_SOURCE:
      if (GA_TRACKING_ENABLED) window.ga(
        'send', 'event',
        "button 'Отправить'",
        "click",
        "service order form_artconsulting page"
      )
      break;
    default:
      return;
  }
}

export function TrackOrderSubmitSubmitError(source) {
  switch (source) {
    case GA_ORDERS_ABOUT_SOURCE:
      if (GA_TRACKING_ENABLED) window.ga(
        'send', 'event',
        "button 'Отправить'",
        "user_failure",
        "thank you page_service order form_about page"
      )
      break;
    case GA_ORDERS_CONSULTING_SOURCE:
      if (GA_TRACKING_ENABLED) window.ga(
        'send', 'event',
        "button 'Отправить'",
        "user_failure",
        "thank you page_service order form_artconsulting page"
      )
      break;
    default:
      return;
  }
}

export function TrackOrderSubmitSubmitSuccess(source) {
  switch (source) {
    case GA_ORDERS_ABOUT_SOURCE:
      if (GA_TRACKING_ENABLED) window.ga(
        'send', 'event',
        "button 'Отправить'",
        "success",
        "thank you page_service order form_about page"
      )
      break;
    case GA_ORDERS_CONSULTING_SOURCE:
      if (GA_TRACKING_ENABLED) window.ga(
        'send', 'event',
        "button 'Отправить'",
        "success",
        "thank you page_service order form_artconsulting page"
      )
      break;
    default:
      return;
  }
}
