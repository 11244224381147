import React, { Component } from "react";
import PropTypes from "prop-types";


import i18n from "../../../translations/i18n";

import LoginModal from "../../layout/modal/login";
import RegistrationModal from "../../layout/modal/registration";
import RestorePasswordModal from "../../layout/modal/restore_password";
import PhoneModal from "../../layout/modal/phone";

import AppointmentWarningModal from "../appointment/modal";
import AppointmenSuccessModal from "../appointment/success/";
import FavoriteWarningModal from "../favorite/modal";
import PersonalTourWarningModal from "../personal_tour/modal";
import PersonalTourSuccessModal from "../personal_tour/success";
import ExpositionOrderTicketModal from "../exposition_order/ticket";
import ExpositionOrderSuccessModal from "../exposition_order/success";
import ExpositionOrderItemRefundModal from "../exposition_order_item/modal";
import ExpositionOrderItemSuccessModal from "../exposition_order_item/success";
import UnconfirmedWarningModal from "../unconfirmed/modal"

import Cookies from "../cookies";
import Link from "../../ui/link";
import Social from "../social";
import { TrackFooterClick } from "../../../helpers/analytics";

// Use:
// <Footer />

class Footer extends Component {

  gaTrackClick = (contactsType) => {
    TrackFooterClick(contactsType);
  }

  render() {

    return(
      <footer className="footer">
        <div className="container">
          <div className="footer__wrapper">
            <div className="footer__logo">
              <Link
                to="/"
                title={i18n.t('footer.title')}
                className="footer__logo-link"
              />
            </div>

            <div className="footer__content">
              <div className="footer__content-top">
                <div className="footer__contacts">
                  <div className="footer__contacts-row">
                    <div className="footer__contacts-item">
                      <a href={`mailto:${i18n.t('footer.contacts.email.title')}`}
                        target="_blank"
                        title={i18n.t('footer.contacts.email.title')}
                        onClick={() => this.gaTrackClick("email")}
                      >
                        {i18n.t('footer.contacts.email.title')}
                      </a>
                    </div>
                    <div className="footer__contacts-item">
                      <a href={`tel:${i18n.t('footer.contacts.phone.title')}`}
                        target="_blank"
                        title={i18n.t('footer.contacts.phone.title')}
                        onClick={() => this.gaTrackClick("phone")}
                      >
                        {i18n.t('footer.contacts.phone.title')}
                      </a>
                    </div>
                  </div>

                  <div className="footer__contacts-row">
                    <div className="footer__contacts-item">
                      {i18n.t('footer.contacts.address.title')}
                    </div>
                    <div className="footer__contacts-item">
                      {i18n.t('footer.contacts.time.title')}
                    </div>
                  </div>
                </div>

                <Social
                  items={['vk', 'telegram', 'youtube', 'zen']}
                  onClick={(contactsType) => this.gaTrackClick(contactsType)}
                />
              </div>

              <div className="footer__description">
                <div className="footer__copyright">
                  {i18n.t('footer.copyright.text', {year: new Date().getFullYear()})}
                </div>

                <div className="footer__links">
                  <Link to="/terms" target="_blank" title={i18n.t('footer.links.terms.title')}>
                    {i18n.t('footer.links.terms.title')}
                  </Link>
                  <Link to="/privacy" target="_blank" title={i18n.t('footer.links.privacy.title')}>
                    {i18n.t('footer.links.privacy.title')}
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>

        <AppointmentWarningModal />
        <AppointmenSuccessModal />
        <FavoriteWarningModal />
        <PersonalTourWarningModal />
        <PersonalTourSuccessModal />
        <ExpositionOrderTicketModal />
        <ExpositionOrderSuccessModal />
        <ExpositionOrderItemRefundModal />
        <ExpositionOrderItemSuccessModal />
        <UnconfirmedWarningModal />

        <LoginModal />
        <RegistrationModal />
        <RestorePasswordModal />
        <PhoneModal />

        <Cookies />
      </footer>
    )
  }
}

Footer.propTypes = {
};

export default Footer
