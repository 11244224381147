import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";


class BaseModal extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { className, children, onOpen, onClose, ...restProps } = this.props;

    return (
      <ReactModal
        onAfterOpen={onOpen}
        onRequestClose={onClose}
        className={className}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        {...restProps}
      >
        {children}
      </ReactModal>
    )
  }
}

BaseModal.propTypes = {
  className: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default BaseModal