import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {

  componentDidUpdate(prevProps) {
    const { pathname, search } = this.props.location;

    if ((pathname !== prevProps.location.pathname) || search !== prevProps.location.search) {
      setTimeout(() => {
        window.scrollTo({
          behavior: "smooth",
          top: 0
        });
      }, 100);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);