import { GA_TRACKING_ENABLED } from "../../../config";

export const GA_FAVORITE_PAINTING_TYPE = 'Painting';
export const GA_FAVORITE_ARTIST_TYPE = 'Artist';
export const GA_FAVORITE_POST_TYPE = 'Post';

export function TrackAddToFavorite(type, title) {
  let label = null;

  switch (type) {
    case GA_FAVORITE_PAINTING_TYPE:
      label = `painting_${title}`;
      break;
    case GA_FAVORITE_ARTIST_TYPE:
      label = `painter_${title}`;
      break;
    case GA_FAVORITE_POST_TYPE:
      label = `post_${title}`;
      break;
    default:
      return;
  }

  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "add to favorites_button",
    "click",
    label,
  )
}
