import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cn from "classnames";

import i18n from "../../../../../translations/i18n";
import {
  openModal
} from "../../../../../actions/modalActions";
import {
  EXPOSITION_ORDER_ITEM_EXPOSITION_TYPE,
  EXPOSITION_ORDER_ITEM_TOUR_TYPE,
  EXPOSITION_ORDER_SHAPE,
  EXPOSITION_ORDER_ITEM_PAID_TYPE,
  EXPOSITION_ORDER_ITEM_REJECTED_TYPE,
  EXPOSITION_ORDER_ITEM_REFUNDED_TYPE
} from "../../../../../constants/expositionOrderConstants";
import {
  EXPOSITION_ORDER_ITEM_REFUND_MODAL
} from "../../../../../constants/modalConstants";
import Info from "../../../../../components/exposition/info";


class Item extends React.Component {

  constructor(props) {
    super(props);
  }

  renderPrice() {
    const { item } = this.props;
    const isMultiple = item.count > 1;

    return(
      <>
        {isMultiple &&
          <>
            {item.count}&nbsp;X&nbsp;
          </>
        }
        <>
          {i18n.t('pages.exposition_order.item.price.amount', {amount: item.price.amount})}
        </>
      </>
    )
  }

  renderFree() {
    const { item } = this.props;
    const isMultiple = item.count > 1;

    return(
      <>
        {isMultiple &&
          <>
            {item.count}&nbsp;X&nbsp;
          </>
        }
        <span>
          {i18n.t('pages.exposition_order.item.price.free')}
        </span>
      </>
    )
  }

  handleRefund = (event) => {
    const { item, openModal } = this.props;
    event.preventDefault();

    const isDisabled = item.state !== EXPOSITION_ORDER_ITEM_PAID_TYPE;

    if (!isDisabled) {
      openModal({name: EXPOSITION_ORDER_ITEM_REFUND_MODAL, orderItem: item });
    }
  }

  render() {
    const { item } = this.props;
    const isFree = item.price.amount === 0;
    const isExposition = item.type === EXPOSITION_ORDER_ITEM_EXPOSITION_TYPE;
    const isTour = item.type === EXPOSITION_ORDER_ITEM_TOUR_TYPE;
    const isDisabled = item.state !== EXPOSITION_ORDER_ITEM_PAID_TYPE;

    return (
      <li
        key={item.id}
        className="p-profile__exhibition-ticket"
      >
        <div className="p-profile__exhibition-ticket-type">
          <div className="p-profile__exhibition-ticket-title">
            {isExposition &&
              <>
                <span>
                  {i18n.t('pages.exposition_order.item.type.exposition', { title: item.price.title })}
                </span>
                {item.name}
              </>
            }
            {isTour &&
              <>
                <span>
                  {i18n.t('pages.exposition_order.item.type.tour', { title: item.price.title })}
                </span>
                {item.name}
              </>
            }
          </div>
          <div className="p-profile__exhibition-ticket-info">
            {item.info &&
              <div className="p-exhibitions__cart-item-info">
                {isTour &&
                  <>
                    <span>{item.guide.occupation}:&nbsp;</span>
                    {item.guide.name}
                    ,&nbsp;
                  </>
                }
                <Info
                  date={item.info.date}
                  place={item.info.place}
                />
              </div>
            }
          </div>
          <div className="p-profile__exhibition-ticket-cost-mobile">
            {isFree ? this.renderFree() : this.renderPrice()}
          </div>
          <div className="p-profile__exhibition-ticket-button-wrap">
            <a
              href="#"
              className={cn('p-profile__exhibition-button button', { 'disabled': isDisabled })}
              disabled={isDisabled}
              onClick={this.handleRefund}
            >
              {isFree ? i18n.t('pages.exposition_order.item.cancel.title') : i18n.t('pages.exposition_order.item.refund.title')}
            </a>
          </div>
        </div>
        <div className="p-profile__exhibition-ticket-cost">
          {isFree
            ? <div className="p-profile__exhibition-ticket-free">
                {this.renderFree()}
              </div>
            : <>
                <div className="p-profile__exhibition-ticket-cost-name">
                  {i18n.t('pages.exposition_order.item.price.title')}
                </div>
                <div>
                  {this.renderPrice()}
                </div>
              </>
          }
        </div>
      </li>
    );
  }
}

Item.propTypes = {
  item: PropTypes.shape(EXPOSITION_ORDER_SHAPE).isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)
