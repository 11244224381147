import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { makeModificationsReducer, requestActionsToTypes } from "../modificationsReducer";
import { makeCollectionsReducer } from "../collectionsReducer";
import {
  CART_ITEM_PREVIEWS,
  CART_ITEM_CREATE,
  CART_ITEM_UPDATE,
  CART_ITEM_DELETE,
} from "../../../constants/cartConstants";
import { cartPreviewSchema } from "../../../schemas";


export default combineReducers({
  byId: makeByIdReducer(cartPreviewSchema.key, [CART_ITEM_PREVIEWS.SUCCESS]),
  collections: makeCollectionsReducer(CART_ITEM_PREVIEWS.typeNames()),
  modifications: makeModificationsReducer(requestActionsToTypes([
    CART_ITEM_CREATE,
    CART_ITEM_UPDATE,
    CART_ITEM_DELETE
  ])),
});
