import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";


class Input extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { error, ...restProps } = this.props;

    return(
      <input
        type="text"
        className={cn('form__field', {'is-invalid': error})}
        {...restProps}
      />
    )
  }
}

Input.propTypes = {
  error: PropTypes.string,
};

export default Input