import React, { Component } from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import {
  EXPOSITION_SHAPE,
} from "../../../constants/expositionConstants";
import Calendar from "./calendar";


class Tours extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { item, fetchExposition } = this.props;

    return (
      <div className="p-exhibition__body-section p-exhibition__body-excursions" id="#tickets">
        <h3 className="p-exhibition__body-title">
          <span>{i18n.t('pages.exposition.aside.tickets-and-tours.title')}</span>
        </h3>
        <Calendar
          exposition={item}
          place={item.place}
          items={item.calendar}
          fetchExposition={fetchExposition}
          onSelect={this.handleSelect}
        />
      </div>
    )
  }
}

Tours.propTypes = {
  item: PropTypes.shape(EXPOSITION_SHAPE).isRequired,
  fetchExposition: PropTypes.func.isRequired,
};

export default Tours
