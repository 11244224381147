export const API = process.env.API_URL;
export const APP_URL = process.env.APP_URL;

export const PASSWORD_MIN_LENGTH = 6;

// pagination
const DEFAULT_PER_PAGE = 24;
export const ARTISTS_PER_PAGE = DEFAULT_PER_PAGE;
export const TOP_ARTISTS_PER_PAGE = 8;
export const PAINTINGS_PER_PAGE = DEFAULT_PER_PAGE;
export const POSTS_PER_PAGE = DEFAULT_PER_PAGE;
export const BANNERS_PER_PAGE = 10;
export const INTERIORS_PER_PAGE = 10;
export const FAVORITE_PAINTINGS_PER_PAGE = 6;
export const FAVORITE_ARTISTS_PER_PAGE = 8;
export const FAVORITE_POSTS_PER_PAGE = 3;
export const SEARCH_RESULTS_PER_PAGE = DEFAULT_PER_PAGE;
export const EXPOSITIONS_PER_PAGE = 5;
export const EXPOSITION_ORDERS_PER_PAGE = 10;
export const EXPOSITION_PAGE_POSTS_LIMIT = 3;

// screens
export const HOME_PAGE_PAINTINGS_LARGE_LIMIT = 5;
export const HOME_PAGE_PAINTINGS_LIMIT = 6;
export const HOME_PAGE_ARTISTS_LIMIT= 8;
export const HOME_PAGE_POSTS_LIMIT= 10;

// limits
export const CART_MAX_COUNT = 4;

// google analytics
export const GA_TRACKING_ID = "UA-57351564-1";
export const GA_TRACKING_ENABLED = process.env.GA_TRACKING_ENABLED == "true";
