import React from "react";
import PropTypes from "prop-types";
import isNil from "lodash/isNil";


class Badge extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { adultsOnly, ageRestriction } = this.props;

    return (
      <>
        { adultsOnly
          ? <span>18+</span>
          : <>
              {!isNil(ageRestriction) &&
                <span>{ageRestriction}+</span>
              }
            </>
        }
      </>
    );
  }
}

Badge.propTypes = {
  adultsOnly: PropTypes.bool,
  ageRestriction: PropTypes.number,
};

export default Badge
