import React from "react";
import PropTypes from "prop-types";

import { ARTIST_AVATAR_SHAPE } from "../../../constants/aritstConstants";


class Avatar extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { avatar, className } = this.props;

    return (
      <>
        { avatar &&
          <img
            src={avatar.medium}
            alt={avatar.name}
            title={avatar.name}
            className={className}
          />
        }
      </>
    );
  }
}

Avatar.propTypes = {
  avatar: PropTypes.shape(ARTIST_AVATAR_SHAPE).isRequired,
  className: PropTypes.string,
};

export default Avatar
