import {
  SHOW_NOTICE,
  HIDE_NOTICE,
  NOTICE_DURATION,
} from "../constants/noticeConstants";


export function toggleNotice({title, description = null}) {
  return function(dispatch) {
    dispatch(
      showNotice({title, description})
    );
    setTimeout(() => dispatch(hideNotice()), NOTICE_DURATION);
  }
}


export function showNotice({title, description = null}) {
  return function(dispatch) {
    return dispatch({
      type: SHOW_NOTICE,
      payload: { title, description }
    });
  }
}


export function hideNotice() {
  return function(dispatch) {
    return dispatch({
      type: HIDE_NOTICE,
      payload: {}
    });
  }
}