import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import i18n from "../../../../translations/i18n";
import Slide from "./slide";
import { PAINTING_SHAPE } from "../../../../constants/paintingConstants";

const SETTINGS = {
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  pauseOnHover: true,
  easing: "linear",
  responsive: [
    {
      breakpoint: 1106,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: "unslick"
    }
  ]
}


class Gallery extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { paintings, onAppointment, onFavorite } = this.props;

    return (
      <div className="container">
        <div className="b-paintings-carousel__content">
          <Slider
            className="b-paintings-carousel__row"
            ref={slider => (this.slider = slider)}
            {...SETTINGS}
          >
            {paintings.map((painting) =>
              <Slide
                key={painting.id}
                item={painting}
                onAppointment={onAppointment}
                onFavorite={onFavorite}
              />
            )}
          </Slider>
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {
  paintings: PropTypes.arrayOf(PropTypes.shape(PAINTING_SHAPE)),
  onAppointment: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired,
};

export default Gallery