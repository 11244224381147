import { servicePreviewSchema } from "../schemas";
import {
  makeAnyKeySelector,
  makeCollectionSelector,
  makeAnyKeyCollectionSelector,
  makeFetchStatusSelector,
} from "./collections";

export const DEFAULT_COLLECTION = "index";

export function makeViewAnyKeySelector(key, collection) {
  return makeAnyKeySelector(key, collection, servicePreviewSchema);
}

export function makeServicePreviewsSelector(collection = DEFAULT_COLLECTION) {
  return makeCollectionSelector(collection, servicePreviewSchema);
}

export function makeServicePreviewsAnyKeySelector(key, collection = DEFAULT_COLLECTION) {
  return makeAnyKeyCollectionSelector(key, collection, servicePreviewSchema);
}

export function makeServicePreviewsFetchStatusSelector(collection = DEFAULT_COLLECTION) {
  return makeFetchStatusSelector(collection, servicePreviewSchema);
}