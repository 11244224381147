import { createSelector } from "reselect";
import { denormalize } from "normalizr";
import PropTypes from "prop-types";

import { getEntities, getEntitiesState } from "./entities";


export function makeAnyKeySelector(key, collection, schema) {
  return createSelector(
    getEntitiesState,
    (entitiesState) => {
      return (entitiesState[schema.key] || {})[key];
    }
  );
}

export function makeCollectionIdsSelector(collection, schema) {
  return createSelector(
    getEntitiesState,
    (entitiesState) => (entitiesState[schema.key].collections[collection] || {}).ids || []
  );
}

export function makeCollectionSelector(collection, schema) {
  const getIds = makeCollectionIdsSelector(collection, schema);
  return createSelector(
    [getIds, getEntities],
    (ids, entities) => denormalize(ids, [schema], entities)
  );
}

export function makeIsFetchingCollectionSelector(collection, schema) {
  return makeAnyKeyCollectionSelector('isFetching', collection, schema);
}

export function makeAnyKeyCollectionSelector(key, collection, schema) {
  return createSelector(
    getEntitiesState,
    (entitiesState) => {
      return (entitiesState[schema.key].collections[collection] || {})[key];
    }
  );
}

export function makeFetchStatusSelector(collection, schema) {
  return createSelector(
    makeIsFetchingCollectionSelector(collection, schema),
    makeAnyKeyCollectionSelector("error", collection, schema),
    (isFetching, error) => ({
      isFetching: isFetching || false,
      isSuccess: !isFetching && !error,
      isFailed: !isFetching && !!error,
    })
  )
}

export const COLLECTION_FETCH_STATUS_SHAPE = {
  isFetching: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
};
