import {
  PAINTINGS_BY_ARTISTS_FILTER,
  PAINTINGS_BY_PROPERTIES_FILTER,
  PAINTINGS_BY_NOVELTY_FILTER,
  PAINTINGS_FILTER_RESET,
} from "../../../constants/paintingConstants";

const DEFAULT_STATE = {
  artists: [],
  properties: [],
  novelties: false,
};

export default function(state = DEFAULT_STATE, { type, payload, meta }) {
  switch (type) {
    case PAINTINGS_BY_ARTISTS_FILTER:
      return {
        ...state,
        artists: payload
      };
    case PAINTINGS_BY_PROPERTIES_FILTER:
      return {
        ...state,
        properties: payload
      };
    case PAINTINGS_BY_NOVELTY_FILTER:
      return {
        ...state,
        novelties: payload
      };
    case PAINTINGS_FILTER_RESET:
      return {
        ...state,
        artists: [],
        properties: [],
        novelties: false,
      }
    default:
      return state;
  }
}