import {
  OPEN_HEADER_SEARCH,
  CLOSE_HEADER_SEARCH,
} from "../constants/layoutConstants";


export function openHeaderSearch(payload = {}) {
  return function(dispatch) {
    return dispatch({
      type: OPEN_HEADER_SEARCH,
      payload: payload
    });
  }
}


export function closeHeaderSearch() {
  return function(dispatch) {
    return dispatch({
      type: CLOSE_HEADER_SEARCH,
      payload: {}
    });
  }
}