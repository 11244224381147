import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import i18n from "../../../translations/i18n";
import { withHeader } from "../../../hocs/layout/withHeader";
import { withFooter } from "../../../hocs/layout/withFooter";
import { withBodyClass } from "../../../hocs/layout/withBodyClass";
import { withAccount } from "../../../hocs/entities/withAccount";
import { withNotice } from "../../../hocs/layout/withNotice";
import { updateItem } from "../../../actions/userActions";
import Meta from "../../../components/layout/meta";
import Menu from "../../../components/account/menu";
import Form from "./form";
import { TrackSubmitSettingsSuccess } from "../../../helpers/analytics";


class Settings extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, location, fetchStatus } = this.props;
    const afterConfirmation = location.search && location.search.includes("after_confirmation")

    if (prevProps.fetchStatus !== fetchStatus && fetchStatus.isSuccess) {
      if (afterConfirmation) {
        history.push('/');
      }
    }
  }

  handleSubmit = (values) => {
    const { location, fetchStatus, updateItem } = this.props;
    const isTrackable = location.search && location.search.includes("after_confirmation");

    if (!fetchStatus.isFetching) {
      if (isTrackable) TrackSubmitSettingsSuccess();

      values.path = location.pathname;
      updateItem(values);
    }
  }

  render () {
    const { account, fetchStatus, location } = this.props;
    const isTrackable = location.search && location.search.includes("after_confirmation");

    return (
      <main className="layout">
        <Meta />
        <div className="p-settings">
          <div className="container">
            <div className="p-settings__title">
              <span>{i18n.t('pages.account.settings.title')}</span>
            </div>
            <div className="p-settings__content">
              <Menu />
              <div className="p-settings__section p-settings__profile">
                <Form
                  initialValues={{
                    name: account.name,
                    role: account.role,
                    subscribe_to_genres: account.subscribe_to_genres,
                    subscribe_to_techniques: account.subscribe_to_techniques,
                    subscribe_to_years: account.subscribe_to_years,
                    isTrackable: isTrackable
                  }}
                  account={account}
                  fetchStatus={fetchStatus}
                  onSubmit={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

Settings.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateItem
  }, dispatch)
}

export default compose(
  withRouter,
  withAccount,
  withNotice(),
  withBodyClass('app-account'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Settings)
