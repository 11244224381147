import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../translations/i18n";
import { hide } from "../../../actions/cookiesActions";
import { COOKIES_SHAPE } from "../../../constants/cookiesConstants";


class Cookies extends Component {

  constructor(props) {
    super(props);
  }

  handleClick = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.props.hide();
  }

  render() {
    const { cookies } = this.props;

    return (
      <>
        { cookies.visible &&
          <div className="w-cookies">
            <div className="w-cookies__content">
              <div className="w-cookies__text">
                {i18n.t('cookies.text')}
              </div>
              <button className="w-cookies__button" onClick={this.handleClick}>
                {i18n.t('cookies.button.title')}
              </button>
            </div>
          </div>
        }
      </>
    )
  }
}

Cookies.propTypes = {
  cookies: PropTypes.shape(COOKIES_SHAPE).isRequired,
  hide: PropTypes.func.isRequired,
};

function mapStateToProps({cookies}) {
  return {
    cookies,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    hide,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Cookies)