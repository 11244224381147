import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { uniqueId } from "../../../../helpers/uniqueId";


class RadioButton extends Component {

  constructor(props) {
    super(props);
    this.radioId = uniqueId("radio_");
  }

  render() {
    const { className, inline, id, children, ...restProps } = this.props;
    const radioClass = cn("radio__input visually-hidden", className);
    const wrapperClass = cn("radio", { "radio-inline": inline });
    const inputId = id || this.radioId;

    return (
      <div className={wrapperClass}>
        <input
          {...restProps}
          className={radioClass}
          type="radio"
          id={inputId}
        />
        <label
          className="radio__label"
          htmlFor={inputId}
        >
          {children}
        </label>
      </div>
    )
  }

}

RadioButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  inline: PropTypes.bool,
};

export default RadioButton;