import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { EXPOSITION_PREVIEWS } from "../../../constants/expositionConstants";
import { makeCollectionsReducer } from "../collectionsReducer";
import { expositionPreviewSchema } from "../../../schemas";


export default combineReducers({
  byId: makeByIdReducer(expositionPreviewSchema.key, [EXPOSITION_PREVIEWS.SUCCESS]),
  collections: makeCollectionsReducer(EXPOSITION_PREVIEWS.typeNames()),
});
