import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";
import { PAINTING_SHAPE } from "./paintingConstants";

export const ARTIST_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  patronymic: PropTypes.string,
  slug: PropTypes.string,
  year_of_birth: PropTypes.number,
  year_of_death: PropTypes.number,
  biography: PropTypes.string,
  lead: PropTypes.string,
  rating: PropTypes.number,
  investment: PropTypes.number,
  avatar: PropTypes.shape(ARTIST_AVATAR_SHAPE),
  timelines: PropTypes.arrayOf(PropTypes.shape(ARTIST_TIMELINE_SHAPE)),
  paintings: PropTypes.arrayOf(PropTypes.shape(PAINTING_SHAPE)),
}

export const ARTIST_SHORT_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  patronymic: PropTypes.string,
  slug: PropTypes.string.isRequired,
  year_of_birth: PropTypes.number,
  year_of_death: PropTypes.number,
}

export const ARTIST_AVATAR_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  medium: PropTypes.string,
}

export const ARTIST_TIMELINE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  year_starts_at: PropTypes.string.isRequired,
  year_ends_at: PropTypes.string,
  description: PropTypes.string.isRequired,
}

export const ARTIST_OPTION_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
}

export const ARTIST_PREVIEWS = createRequestActionsFor("ARTIST_PREVIEWS");
export const ARTIST_ITEM = createRequestActionsFor("ARTIST_ITEM");

export const ARTIST_LETTERS_PREVIEWS = createRequestActionsFor("ARTIST_LETTERS_PREVIEWS");

export const ARTIST_OPTIONS = createRequestActionsFor("ARTIST_OPTIONS");

