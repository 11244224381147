import React from "react";
import PropTypes from "prop-types";

import { POST_AUTHOR_SHAPE } from "../../../constants/postConstants";


class Author extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { author } = this.props;

    return (
      <>
        {author &&
          <div className="p-post__author">
            {author.image &&
              <div className="p-post__author-avatar">
                <img
                  src={author.image}
                  className="p-post__author-avatar-image"
                />
              </div>
            }
            <div className="p-post__author-info">
              {author.occupation
                ? <div className="p-post__author-info-name">
                    {author.name}
                    {author.occupation &&
                      <>
                        ,
                        &nbsp;
                        <span>
                          {author.occupation}
                        </span>
                      </>
                    }
                  </div>
                : <div className="p-post__author-info-name">
                    {author.name}
                  </div>
              }
              {author.company &&
                <div className="p-post__author-info-place">
                  {author.company}
                </div>
              }
            </div>
          </div>
        }
      </>
    )
  }
}

Author.propTypes = {
  author: PropTypes.shape(POST_AUTHOR_SHAPE),
};

export default Author