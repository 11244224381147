import {
  HIDE_COOKIES,
} from "../constants/cookiesConstants";


export function hide() {
  return function(dispatch) {
    return dispatch({
      type: HIDE_COOKIES,
      payload: {}
    });
  }
}