import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";


import i18n from "../../../translations/i18n";
import {
  subscribe,
  resetAuthForm as reset
} from "../../../actions/userActions";
import { USER_SHAPE } from "../../../constants/userConstants";
import SubscribeForm from "./form";
import Social from "../social";
import {
  TrackSubscribeClick,
  TrackSubscribeSubmitSuccess
} from "../../../helpers/analytics";
import Icon from "../../ui/icon";
import cn from "classnames";


class Subscription extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isStepVisible: false
    }
  }

  componentDidMount() {
    this.props.reset();
  }

  handleFill = () => {
    const { isStepVisible } = this.state;

    if (!isStepVisible) {
      this.setState({isStepVisible: true});
    }
  }

  handleSubmit = (values) => {
    const { subscribe } = this.props;

    if (values.source) {
      TrackSubscribeSubmitSuccess(values.source);
    }

    subscribe(values);
  }

  gaTrackClick = (contactsType, source) => {
    TrackSubscribeClick(source);
  }

  render() {
    const { currentUser, userLoggedIn, auth, source } = this.props;
    const { isStepVisible } = this.state;

    return(
      <>
        {/*{ !userLoggedIn &&*/}
          <div className="b-subscription">
            <div className="container">
              <div className="b-subscription__content">
                <div className="b-subscription__mailing">
                  <div
                    className="b-subscription__title b-subscription__mailing-title"
                    dangerouslySetInnerHTML={{__html: i18n.t('pages.home.subscription.form.title')}}
                  />
                  <div className="b-subscription__mailing-description">
                    {i18n.t('pages.home.subscription.form.description')}
                  </div>

                  <a
                    href={i18n.t(`social.telegram.link`)}
                    target="_blank"
                    title={i18n.t(`social.telegram.title`)}
                    className="button b-subscription__goto-telegram-link"
                    onClick={() => onClick(i18n.t(`social.telegram.title`))}
                    rel="noopener"
                  >
                    <Icon
                      icon="telegram"
                      className={cn('social-group__icon', `social-group__icon-telegram`)}
                    />
                    {i18n.t('pages.home.subscription.form.goto_telegram')}
                  </a>

                  {/*{ currentUser.subscribe_to_news || auth.subscribedSuccessfully*/}
                  {/*  ? <div className="b-subscription__mailing-hint">*/}
                  {/*      {i18n.t('pages.home.subscription.message.title')}*/}
                  {/*    </div>*/}
                  {/*  : <div className="b-subscription__form">*/}
                  {/*      <SubscribeForm*/}
                  {/*        initialValues={{*/}
                  {/*          email: '',*/}
                  {/*          name: '',*/}
                  {/*          source: source,*/}
                  {/*        }}*/}
                  {/*        isStepVisible={isStepVisible}*/}
                  {/*        auth={auth}*/}
                  {/*        onFill={this.handleFill}*/}
                  {/*        onSubmit={this.handleSubmit}*/}
                  {/*      />*/}
                  {/*    </div>*/}
                  {/*}*/}
                </div>
                <div className="b-subscription__social">
                  <div className="b-subscription__title b-subscription__social-title">
                    {i18n.t('pages.home.subscription.social.title')}
                  </div>
                  <Social
                    // items={['vk', 'telegram', 'youtube', 'zen']}
                    items={['telegram', 'youtube']}
                    wrapperClassName="b-subscription__social-group"
                    onClick={(contactsType) => this.gaTrackClick(contactsType, source)}
                  />
                </div>
              </div>
            </div>
          </div>
        {/*}*/}
      </>
    )
  }
}

Subscription.propTypes = {
  currentUser: PropTypes.shape(USER_SHAPE),
  subscribe: PropTypes.func.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
};

function mapStateToProps({auth, currentUser}) {
  return {
    auth,
    currentUser,
    userLoggedIn: !!currentUser.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    subscribe,
    reset,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Subscription)
