import React, { Component } from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import { ARTIST_SHAPE } from "../../../constants/aritstConstants";


class Link extends Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { artist } = this.props;
    const artistPaintingsPath = `/paintings?artists[]=${artist.id}`
    const isLink = artist.count > 1;

    return (
      <>
        {isLink &&
          <div className="p-picture__info-description-painter-link">
            <a
              href={artistPaintingsPath}
              title={artist.name}
            >
              {i18n.t('pages.artist.link.count.key', {count: artist.count || 0})}
            </a>
          </div>
        }
      </>
    );
  }
}

Link.propTypes = {
  artist: PropTypes.shape(ARTIST_SHAPE).isRequired,
};

export default Link
