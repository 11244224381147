import { combineReducers } from "redux";

import headerSearch from "./headerSearch";
import modal from "./modal";
import appointment from "./appointment";
import expositionOrder from "./expositionOrder";
import personalTour from "./personalTour";
import notice from "./notice";

export default combineReducers({
  headerSearch,
  modal,
  appointment,
  expositionOrder,
  personalTour,
  notice,
})
