import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import {
  USER_AUTH_SHAPE,
  USER_SUBSCRIPTION_SHAPE
} from "../../../../constants/userConstants";

import FormGroup from "../../../../components/ui/form/group";
import FormActions from "../../../../components/ui/form/actions";
import FormError from "../../../../components/ui/form/error";
import InputField from "../../../../components/ui/form/input_field";
import Button from "../../../../components/ui/form/button";
import {
  TrackFieldError,
  TrackFieldValid,
  TrackSubscribeClick,
  TrackSubscribeSubmitError,
} from "../../../../helpers/analytics";
import emailDomainIsInvalid from "../../../../helpers/invalidEmailDomains";


class SubscribeForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth, values, errors, setSubmitting } = this.props;

    if (!isEmpty(values.email) && isEmpty(errors.email)) {
      this.props.onFill();
    }

    if (prevProps.auth !== auth && auth.error) {
      setSubmitting(false);
    }
  }

  render() {
    const {
      auth,
      touched,
      isSubmitting,
      isStepVisible,
      handleSubmit
    } = this.props;

    const isDisabled = isSubmitting || !isStepVisible;

    return (
      <Form>
        <div className="b-subscription__field-wrapper">
          <FormGroup
            className="form__group-email"
            label={i18n.t("pages.home.subscription.form.email.label")}
            touched={touched.email}>
            <Field
              component={InputField}
              className="form__field b-subscription__field b-subscription__field-email"
              name="email"
              required
            />
          </FormGroup>
          { isStepVisible &&
            <FormGroup
              className="form__group-name fade-in"
              label={i18n.t("pages.home.subscription.form.name.label")}
              touched={touched.name}>
              <Field
                component={InputField}
                className="form__field b-subscription__field"
                name="name"
                required
              />
            </FormGroup>
          }
        </div>

        <FormActions className="b-subscription__button-wrapper">
          <FormError>
            {auth.error}
          </FormError>
          <Button
            className="b-subscription__button"
            type="submit"
            disabled={isDisabled}
            onSubmit={handleSubmit}
          >
            {i18n.t("pages.home.subscription.form.submit.title")}
          </Button>
        </FormActions>
      </Form>
    )
  }
}

const SubscribeFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};

    TrackSubscribeClick(values.source);

    if (isEmpty(values.email)) {
      TrackFieldError('email', 'empty', values.source);

      errors.email = i18n.t("validation.field.required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
      errors.email = i18n.t("validation.email.invalid");
    } else if (emailDomainIsInvalid(values.email)) {
      errors.email = i18n.t("validation.email.invalid");
    } else {
      TrackFieldValid('email', values.source);
    }

    if (isEmpty(values.name)) {
      TrackFieldError('name', 'empty', values.source);

      errors.name = i18n.t("validation.field.required");
    } else {
      TrackFieldValid('name', values.source);
    }

    if (!isEmpty(errors)) {
      TrackSubscribeSubmitError(values.source);
    }

    return errors;
  },
})(SubscribeForm);

SubscribeFormWithFormik.propTypes = {
  initialValues: PropTypes.shape(USER_SUBSCRIPTION_SHAPE).isRequired,
  isStepVisible: PropTypes.bool.isRequired,
  auth: PropTypes.shape(USER_AUTH_SHAPE).isRequired,
  onFill: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SubscribeFormWithFormik
