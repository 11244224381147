import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";

import { API } from "../config";
import {
  EXPERT_PREVIEWS,
} from "../constants/expertConstants";
import {
  expertPreviewSchema
} from "../schemas";
import { DEFAULT_COLLECTION } from "../selectors/expertPreviews";
import { FUNC_REPLACE } from "../reducers/entities/collectionsReducer";


export function fetchItems(fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/experts`,
        types: EXPERT_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const r = await res.json()
      const { experts } = r;

      return {
        ...normalize(experts, [expertPreviewSchema])
      };
    }
  };
}

