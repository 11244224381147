import React, {Component} from "react";
import PropTypes from "prop-types";
import i18n from "../../../translations/i18n";
import isEmpty from "lodash/isEmpty";

class BuyTicketButtons extends Component {
  render() {
    const {
      exposition: {
        past,
        booking_available,
        external_tour_tickets_url,
        external_exposition_tickets_url,
      }
    } = this.props;
    if (past) return null;

    if (booking_available) {
      return (
        <div className="p-exhibition__info-buttons">
          <a href="#tickets" className="button p-exhibition__info-button">
            {i18n.t('pages.exposition.tickets.buy_tour.title')}
          </a>
          <a href="#tickets" className="button p-exhibition__info-button">
            {i18n.t('pages.exposition.tickets.buy_exposition.title')}
          </a>
        </div>
      )
    } else {
      return (
        <div className="p-exhibition__info-buttons">
          {
            !isEmpty(external_tour_tickets_url) &&
            <a href={external_tour_tickets_url} className="button p-exhibition__info-button" target="_blank">
              {i18n.t('pages.exposition.tickets.buy_tour.title')}
            </a>
          }
          {
            !isEmpty(external_exposition_tickets_url) &&
            <a href={external_exposition_tickets_url} className="button p-exhibition__info-button" target="_blank">
              {i18n.t('pages.exposition.tickets.buy_exposition.title')}
            </a>
          }
        </div>
      )
    }
  }
}

BuyTicketButtons.propTypes = {
  exposition: PropTypes.shape({
    past: PropTypes.bool,
    booking_available: PropTypes.bool,
    external_tour_tickets_url: PropTypes.string,
    external_exposition_tickets_url: PropTypes.string,
  }).isRequired,
};

export default BuyTicketButtons;
