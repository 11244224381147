import React from "react";


export default function FormError({children}) {
  if (children) {
    return (
      <div className="form__error">
        {children}
      </div>
    )
  } else {
    return null;
  }
}