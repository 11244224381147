import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { makeCartPreviewsFetchStatusSelector } from "../../../selectors/cartPreviews";
import { CART_ITEM_SHAPE } from "../../../constants/cartConstants";
import Item from "../item";
import Form from "../form";
import Empty from "../../../components/ui/empty";


class List extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { items, fetchStatus, onUpdate, onSubmit, onDelete } = this.props;

    return (
      <>
        { isEmpty(items)
          ? <>
              {fetchStatus && fetchStatus.isFetching
                ? <Empty message={i18n.t("helpers.loading.title")} />
                : <Empty message={i18n.t("pages.cart.empty.title")} />
              }
            </>
          : <div className="p-exhibitions__cart-items">
              {(items || []).map((item) =>
                <Item
                  key={item.id}
                  item={item}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                />
              )}
              <Form
                initialValues={{
                  accept_terms: false,
                  accept_rules: false,
                  count: items.reduce((count, item) => count + item.count, 0)
                }}
                items={items}
                onSubmit={onSubmit}
              />
            </div>
        }
      </>
    )
  }
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(CART_ITEM_SHAPE)),
  onUpdate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const cartFetchStatusSelector = makeCartPreviewsFetchStatusSelector();

function mapStateToProps(state) {

  return {
    fetchStatus: cartFetchStatusSelector(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
