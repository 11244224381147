import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { EXPOSITION_CALENDAR_CELL_SHAPE } from "../../../../../constants/expositionConstants";


class Item extends Component {

  constructor(props) {
    super(props);
  }

  handleDayClick(event) {
    const { item, onDaySelect } = this.props;
    event.preventDefault();

    onDaySelect(item)
  }

  render() {
    const { item, isActive } = this.props;
    const isDisabled = item && item.cell.past;

    return(
      <div
        className={cn('p-exhibition__calendar-date', { 'state-active': isActive, 'state-past': isDisabled })}
        onClick={(event) => this.handleDayClick(event)}
      >
        <div className="p-exhibition__calendar-day">
          {item.cell.day}
        </div>
        <div className="p-exhibition__calendar-number">
          {item.cell.num}
        </div>
        <div className="p-exhibition__calendar-month">
          {item.cell.month}
        </div>
      </div>
    )
  }
}

Item.propTypes = {
  item: PropTypes.shape(EXPOSITION_CALENDAR_CELL_SHAPE).isRequired,
  isActive: PropTypes.bool,
  onDaySelect: PropTypes.func.isRequired,
};

export default Item
