import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import i18n from "../../../../translations/i18n";
import { getAccount } from "../../../../selectors/accounts";
import { USER_SHAPE } from "../../../../constants/userConstants";
import Link from "../../../../components/ui/link";


class Cart extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { account } = this.props;

    return (
      <>
        { account &&
          <Link
            to={'/cart'}
          >
            {account.cart_items_count}
          </Link>
        }
      </>
    );
  }
}

Cart.propTypes = {
  account: PropTypes.shape(USER_SHAPE),
};

function mapStateToProps(state) {
  const currentUser = state.currentUser;

  return {
    currentUser: currentUser,
    account: getAccount(state, currentUser.id),
    userLoggedIn: !!currentUser.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Cart)
