import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../../translations/i18n";
import { POST_SHAPE } from "../../../../../constants/postConstants";
import Favorite from "../../../../../components/layout/favorite";


class Item extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item, onFavorite } = this.props;
    const postPath = `/posts/${item.slug}`

    return (
      <div className="b-journal-carousel__item">
        <a
          href={postPath}
          className="b-journal-carousel__card"
        >
          <div className="b-journal-carousel__card-link">
            <img
              src={item.poster.medium}
              className="b-journal-carousel__card-image"
            />
          </div>
          <div className="b-journal-carousel__card-content">
            <div className="b-journal-carousel__card-date">
              {item.date}
            </div>
            <h2 className="b-journal-carousel__card-title">
              {item.name_without_word_breaks}
            </h2>
            <p
              className="b-journal-carousel__card-description"
              dangerouslySetInnerHTML={{__html: item.annotation}}
            />
          </div>
        </a>
        <div className="b-journal-carousel__item-media">
          <Favorite
            favorite={item.favorite}
            isShort={true}
            removeClassName="remove-favorite"
            removeTitle={i18n.t('helpers.remove_from_fav.title')}
            onFavorite={onFavorite}
          />
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  item: PropTypes.shape(POST_SHAPE),
  onFavorite: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)