import React, { Component } from "react";
import PropTypes from "prop-types";

import { SEARCHABLE_SHAPE } from "../../../../constants/searchConstants";


class Image extends Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item } = this.props;

    return (
      <div className="p-search__results-list-item-image-wrapper">
        {item.image &&
          <img
            src={item.image.thumb}
            className="p-search__results-list-item-image"
            alt={item.name}
            title={item.name}
          />
        }
      </div>
    );
  }
}

Image.propTypes = {
  item: PropTypes.shape(SEARCHABLE_SHAPE).isRequired,
};

export default Image;