const landlinePhoneCodes = [
  "+7 (495)",
  "+7 (496)",
  "+7 (498)",
  "+7 (499)",
  "+7 (812)",
  "+7 (813)"
]

const phoneIsLandline = (phone) => {
  let value = false

  value = landlinePhoneCodes.some(code => {
    return phone.startsWith(code)
  });

  return value
}

export default phoneIsLandline
