import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../../translations/i18n";
import { ARTIST_SHAPE } from "../../../../../constants/aritstConstants";
import Avatar from "../../../../../components/artist/avatar";
import Favorite from "../../../../../components/layout/favorite";
import Link from "../../../../../components/ui/link";


class Item extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item, onFavorite } = this.props;
    const artistPath = `/artists/${item.slug}`

    return (
      <div className="b-painters__card">
        <div className="b-painters__card-image-wrapper">
          <Link to={artistPath} className="b-painters__card-image-link">
            <Avatar avatar={item.avatar} className="b-painters__card-image" />
          </Link>
        </div>
        <div className="b-painters__card-content">
          <Link to={artistPath} className="b-painters__card-name">
            {item.name}
          </Link>
          <div className="b-painters__card-media">
            { item.year_of_birth && item.year_of_death &&
              <div className="b-painters__card-media-date-wrapper">
                <div className="b-painters__card-media-date">
                  {i18n.t('pages.artists.years', {birth: item.year_of_birth, death: item.year_of_death})}
                </div>
              </div>
            }
            <div className="b-painters__card-media-favorite-wrapper">
              <div className="b-painters__card-media-favorite">
                <Favorite
                  favorite={item.favorite}
                  isShort={true}
                  removeClassName="remove-favorite"
                  removeTitle={i18n.t('helpers.remove_from_fav.title')}
                  onFavorite={onFavorite}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  item: PropTypes.shape(ARTIST_SHAPE).isRequired,
  onFavorite: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)