import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { makeModificationsReducer, requestActionsToTypes } from "../modificationsReducer";
import { accountSchema } from "../../../schemas";
import {
  USER_ITEM,
  USER_ITEM_UPDATE,
  USER_CONFIRM_PHONE,
  USER_RESET_PHONE_CHANGE,
  USER_ITEM_DELETE,
} from "../../../constants/userConstants";

export default combineReducers({
  byId: makeByIdReducer(
    accountSchema.key,
    [USER_ITEM.SUCCESS],
    [USER_CONFIRM_PHONE.SUCCESS],
    [USER_RESET_PHONE_CHANGE.SUCCESS],
    [USER_ITEM.FAILED]
  ),
  modifications: makeModificationsReducer(requestActionsToTypes([
    USER_ITEM_UPDATE,
    USER_CONFIRM_PHONE,
    USER_RESET_PHONE_CHANGE,
    USER_ITEM_DELETE
  ])),
})