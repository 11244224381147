import { denormalize } from "normalizr";

import { getEntities } from "./entities";
import { expositionOrderSchema } from "../schemas";
import { makeModificationStatusSelector } from "./modifications";


export const DEFAULT_MODIFICATION = "form";

export function getExpositionOrder(state, id) {
  return denormalize(id, expositionOrderSchema, getEntities(state));
}

export function makeAccountModificationStatusSelector(modification = DEFAULT_MODIFICATION) {
  return makeModificationStatusSelector(modification, expositionOrderSchema);
}
