import React from "react";
import PropTypes from "prop-types";

import { PAINTING_POSTER_SHAPE } from "../../../constants/paintingConstants";


class Poster extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { poster, className } = this.props;

    return (
      <>
        { poster &&
          <img
            src={poster.medium}
            alt={poster.name}
            title={poster.name}
            className={className}
          />
        }
      </>
    );
  }
}

Poster.propTypes = {
  poster: PropTypes.shape(PAINTING_POSTER_SHAPE).isRequired,
  className: PropTypes.string,
};

export default Poster
