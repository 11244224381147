import { GA_TRACKING_ENABLED } from "../../../config";

export function TrackExpositionShare(tag, title) {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    'share ' + tag + ' button',
    'click',
    'exposition "' + title + '"'
  )
}
