import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../translations/i18n";
import {
  openModal,
  closeModal
} from "../../../../actions/modalActions";
import {
  disableAppointment
} from "../../../../actions/appointmentActions";
import {
} from "../../../../actions/userActions";
import Phone from "../../../../components/account/phone";
import BaseModal from "../../../ui/modal/base";
import { PHONE_MODAL } from "../../../../constants/modalConstants";


class PhoneModal extends React.Component {

  constructor(props) {
    super(props);
  }

  handleClose = (event) => {
    const { closeModal, disableAppointment } = this.props;
    event.preventDefault();

    disableAppointment();
    closeModal();
  }

  render () {
    const { modal } = this.props;

    return (
      <BaseModal
        className="modal modal-phone"
        isOpen={modal.name === PHONE_MODAL}
        onClose={this.handleClose}
      >
        <div className="modal__title">
          {i18n.t('modal.phone_confirmation.title')}
        </div>
        <div className="modal__form">
          <Phone
            hintTitle={i18n.t("modal.phone_confirmation.form.phone.hint")}
            submitTitle={i18n.t("modal.phone_confirmation.form.submit.title")}
          />
        </div>
      </BaseModal>
    );
  }
}

PhoneModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {
  return {
    modal: layout.modal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    closeModal,
    disableAppointment,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PhoneModal)