import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Route, Redirect, Link, withRouter } from "react-router-dom";
import isNil from "lodash/isNil";

import i18n from "../../../translations/i18n";
import { withHeader } from "../../../hocs/layout/withHeader";
import { restoreAccount } from "../../../actions/userActions";


class Restore extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { location, restoreAccount } = this.props;
        restoreAccount({ restore_token: location.search.replace("?restore_token=", "") })
    }

    render() {
        const { auth, userLoggedIn } = this.props;

        return (
            <Route render={({ location }) => {
                return userLoggedIn
                    ? <Redirect to="/account/settings" />
                    : <main className="layout auth">
                        {!isNil(auth.error) &&
                            <div className="modal modal-restore-account">
                                <div className="modal__container">
                                    <div className="modal__inner">
                                        <Link to="/" className="modal__close">
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L15.8492 15.8492" stroke="black" strokeWidth="2"/>
                                            <path d="M16 1L1.15076 15.8492" stroke="black" strokeWidth="2"/>
                                        </svg>
                                        </Link>
                                            <div className="modal__content">
                                                <div className="modal__title">
                                                    {i18n.t('pages.restore_account.title')}
                                                </div>
                                                <div className="modal__form">
                                                    <p>{auth.error}</p>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </main>
            }} />
        );
    }
}

Restore.propTypes = {
    restoreAccount: PropTypes.func.isRequired,
};

function mapStateToProps({ auth, currentUser }) {
    return {
        auth,
        userLoggedIn: !!currentUser.token,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        restoreAccount,
    }, dispatch)
}

export default compose(
    withHeader({ transparent: true }),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Restore)
