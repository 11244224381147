import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../translations/i18n";
import {
  signIn,
  resetAuthForm as reset
} from "../../../../actions/userActions";
import {
  openModal,
} from "../../../../actions/modalActions";
import {
  RESTORE_PASSWORD_MODAL,
  REGISTRATION_MODAL,
} from "../../../../constants/modalConstants";
import LoginForm from "./form";


class Login extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.reset();
  }

  handleOpenRestorePassword = (event) => {
    event.preventDefault();
    this.props.openModal({name: RESTORE_PASSWORD_MODAL});
  }

  handleOpenRegistration = (event, source) => {
    event.preventDefault();
    this.props.openModal({name: REGISTRATION_MODAL, source: source});
  }

  handleSubmit = (params) => {
    this.props.signIn(params);
  }

  render () {
    const { auth, source } = this.props;

    return (
      <>
        <div className="modal__title">
          {i18n.t('pages.login.title')}
        </div>
        <div className="modal__form">
          <LoginForm
            initialValues={{email: '', password: ''}}
            auth={auth}
            onRestorePassword={this.handleOpenRestorePassword}
            onSubmit={this.handleSubmit}
          />
          {/* TODO: add omniauth
            <div className="modal__separator">
              {i18n.t('pages.login.form.social.title')}
            </div>
            <div className="modal__social-auth">
              <Button className="modal__social-auth-button modal__social-auth-facebook">
                {i18n.t('pages.login.form.social.facebook.title')}
              </Button>
              <Button className="modal__social-auth-button modal__social-auth-vkontakte">
                {i18n.t('pages.login.form.social.vk.title')}
              </Button>
            </div>
          */}
          <div className="modal__reg-tip">
            {i18n.t('pages.login.form.signup.title')}
            <a
              href=""
              className="modal__reg-tip-link"
              onClick={(e) => this.handleOpenRegistration(e, source)}
            >
              {i18n.t('pages.login.form.signup.link')}
            </a>
          </div>
        </div>
      </>
    );
  }
}

Login.propTypes = {
  signIn: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

function mapStateToProps({auth, currentUser, layout}) {
  return {
    auth,
    appointment: layout.appointment,
    userLoggedIn: !!currentUser.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    signIn,
    reset,
    openModal,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Login)