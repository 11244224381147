import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { POST_SHAPE } from "../../../../../constants/postConstants";
import Item from "../item";

class List extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { items, onFavorite } = this.props;

    return (
      <div className="b-journal-carousel__row row">
        {(items || []).map((item) =>
          <Item
            item={item}
            key={item.id}
            isLarge={false}
            onFavorite={onFavorite}
          />
        )}
      </div>
    )
  }
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(POST_SHAPE)),
  onFavorite: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(List)