import {
  ORDER_SEND,
  ORDER_SEND_CACHED,
  ORDER_SEND_CLEAR
} from "../../../constants/orderConstants";

const DEFAULT_STATE = {
  error: null,
  submitted: false,
  redirected: false,
  cached: null
};

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case ORDER_SEND.REQUEST:
      return {
        ...state,
        error: null
      };
    case ORDER_SEND.SUCCESS:
      return {
        ...state,
        error: null,
        submitted: true,
        cached: null,
        redirected: false
      };
    case ORDER_SEND.FAILED:
      return {
        ...state,
        error: payload.error
      };
    case ORDER_SEND_CACHED:
      return {
        ...state,
        error: null,
        redirected: true,
        cached: payload
      };
    case ORDER_SEND_CLEAR:
      return {
        ...state,
        ...DEFAULT_STATE
      };
    default:
      return state;
  }
}