import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import { USER_SHAPE } from "../../../../constants/userConstants";
import { MODIFICATION_SHAPE } from "../../../../selectors/modifications";
import { PASSWORD_MIN_LENGTH } from "../../../../config";

import FormGroup from "../../../../components/ui/form/group";
import FormActions from "../../../../components/ui/form/actions";
import FormError from "../../../../components/ui/form/error";
import InputField from "../../../../components/ui/form/input_field";
import Button from "../../../../components/ui/form/button";
import {
  GA_PROFILE_SOURCE,
  TrackFieldError,
  TrackFieldValid,
  TrackChangePasswordClick,
  TrackSubmitPasswordError,
  TrackSubmitPasswordSuccess,
} from "../../../../helpers/analytics";


class ChangePasswordForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchStatus, setSubmitting } = this.props;

    if (prevProps.fetchStatus !== fetchStatus && !fetchStatus.isFetching) {
      setSubmitting(false);
    }
  }

  render() {
    const {
      account,
      touched,
      isSubmitting,
      handleSubmit
    } = this.props;

    return(
      <div className="p-settings__form">
        <Form>
          <FormGroup label={i18n.t("pages.account.form.password.label")} touched={touched.password}>
            <Field
              component={InputField}
              name="password"
              type="password"
              required
            />
          </FormGroup>

          <FormGroup label={i18n.t("pages.account.form.password_confirmation.label")} touched={touched.password_confirmation}>
            <Field
              component={InputField}
              name="password_confirmation"
              type="password"
              required
            />
          </FormGroup>

          <FormActions className="button-wrapper p-settings__form-button-wrapper">
            <FormError>
              {account.error}
            </FormError>
            <Button
              className="p-settings__form-button"
              type="submit"
              disabled={isSubmitting}
              onSubmit={handleSubmit}
            >
              {i18n.t("pages.account.change_password.form.submit.title")}
            </Button>
          </FormActions>
        </Form>

      </div>
    )
  }
}

const ChangePasswordFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};
    let isTrackable = values.isTrackable;

    if (isTrackable) TrackChangePasswordClick();

    if (isEmpty(values.password)) {
      if (isTrackable) TrackFieldError('password', 'empty', GA_PROFILE_SOURCE);

      errors.password = i18n.t("validation.field.required");
    } else if (values.password.length < PASSWORD_MIN_LENGTH) {
      if (isTrackable) TrackFieldError('password', 'invalid', GA_PROFILE_SOURCE);

      errors.password = i18n.t("validation.password.toShort", {length: PASSWORD_MIN_LENGTH});
    } else {
      if (isTrackable) TrackFieldValid('password', GA_PROFILE_SOURCE);
    }

    if (isEmpty(values.password_confirmation)) {
      if (isTrackable) TrackFieldError('password_confirmation', 'empty', GA_PROFILE_SOURCE);

      errors.password_confirmation = i18n.t("validation.field.required");
    } else if (values.password_confirmation.length < PASSWORD_MIN_LENGTH) {
      if (isTrackable) TrackFieldError('password_confirmation', 'invalid', GA_PROFILE_SOURCE);

      errors.password_confirmation = i18n.t("validation.password.toShort", {length: PASSWORD_MIN_LENGTH});
    } else {
      if (isTrackable) TrackFieldValid('password_confirmation', GA_PROFILE_SOURCE);
    }

    if (!isEmpty(errors)) {
      if (isTrackable) TrackSubmitPasswordError();
    }

    return errors;
  },
})(ChangePasswordForm);

ChangePasswordFormWithFormik.propTypes = {
  account: PropTypes.shape(USER_SHAPE).isRequired,
  fetchStatus: PropTypes.shape(MODIFICATION_SHAPE).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangePasswordFormWithFormik
