import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { fetchItem } from "../../actions/artistActions";
import { getArtist } from "../../selectors/artists";
import { ARTIST_SHAPE } from "../../constants/aritstConstants";
import LoadableEntity from "../../components/ui/loadable/entity";
import NotFound from "../../screens/static/not_found";


export function withArtist(WrappedComponent) {

    const WithArtist = class extends Component {

      componentDidMount() {
        this.fetchArtist();
      }

      fetchArtist = () => {
        const { match } = this.props;
        const artistId = match.params["id"];

        this.props.fetchItem(artistId);
      };

      render() {
        const { artist, fetchStatus, ...restProps } = this.props;

        return (
          <LoadableEntity
            entity={artist}
            renderError={() => <NotFound/>}
            // renderLoading={() => <LoadingPage/>}
          >
            {
              (artist) =>
                <WrappedComponent
                  {...restProps}
                  artist={artist}
                  fetchArtist={this.fetchArtist}
                />
            }
          </LoadableEntity>
        )
      }
    };

    WithArtist.propTypes = {
      artist: PropTypes.shape(ARTIST_SHAPE),
    };

    function mapStateToProps(state, {match}) {
      const artistId = parseInt(match.params["id"]);

      return {
        artist: getArtist(state, artistId),
      }
    }

    function mapDispatchToProps(dispatch) {
      return bindActionCreators({
        fetchItem,
      }, dispatch)
    }

    return compose(
      withRouter,
      connect(mapStateToProps, mapDispatchToProps)
    )(WithArtist)
  }
