import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";


class Truncated extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    }
  }

  handleClick = (event) => {
    event.preventDefault();
    this.setState({expanded: !this.state.expanded});
  }

  renderImage = () => {
    const { link, image, imgClassName } = this.props;

    return (
      <>
        { link && image &&
          <a href={link}>
            <img
              src={image.thumb}
              alt={image.name}
              title={image.name}
              className={imgClassName}
            />
          </a>
        }
      </>
    )
  }

  render () {
    const { annotation, text, moreClassName } = this.props;
    const { expanded } = this.state;

    return (
      <>
        {this.renderImage()}
        <p dangerouslySetInnerHTML={{__html: expanded ? text : (annotation ? annotation : text)}} />
        {annotation && !expanded &&
          <a
            href='#'
            onClick={this.handleClick}
            className={moreClassName}
          >
            {i18n.t('helpers.more.title')}
          </a>
        }
      </>
    );
  }
}

Truncated.propTypes = {
  annotation: PropTypes.string,
  text: PropTypes.string.isRequired,
  image: PropTypes.object,
  link: PropTypes.string,
  imgClassName: PropTypes.string,
  moreClassName: PropTypes.string,
};

export default Truncated