import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  createItem,
  disablePersonalTour,
} from "../../../../actions/personalTourActions";
import {
  openModal,
  closeModal,
} from "../../../../actions/modalActions";
import { PERSONAL_TOUR_MODAL } from "../../../../constants/modalConstants";
import BaseModal from "../../../../components/ui/modal/base";
import PersonalTourForm from "../form";
import { TrackAddPersonalTourSubmitSuccess } from "../../../../helpers/analytics";


class PersonalTourWarningModal extends Component {

  constructor(props) {
    super(props);
  }

  handleSubmit = (values) => {
    const { modal, location, userLoggedIn, createItem } = this.props;
    event.preventDefault();

    if (userLoggedIn) {
      TrackAddPersonalTourSubmitSuccess()

      values.path = location.pathname;
      createItem(modal.expositionId, values);

      modal.onPersonalTour();
    }
  }

  handleClose = (event) => {
    const { closeModal, disablePersonalTour } = this.props;
    if (event) {
      event.preventDefault();
    }

    closeModal();
    disablePersonalTour();
  }

  render() {
    const { personalTour, modal } = this.props;

    return (
      <BaseModal
        className="modal modal-personal-tour fade-in"
        isOpen={modal.name === PERSONAL_TOUR_MODAL}
        onClose={this.handleClose}
      >
        <PersonalTourForm
          initialValues={{
            comment: '',
            source: modal.source,
            expositionName: modal.expositionName,
          }}
          serverError={personalTour.error}
          onSubmit={this.handleSubmit}
        />
      </BaseModal>
    )
  }
}

PersonalTourWarningModal.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  createItem: PropTypes.func.isRequired,
  disablePersonalTour: PropTypes.func.isRequired,
};

function mapStateToProps({layout, currentUser}) {
  return {
    personalTour: layout.personalTour,
    modal: layout.modal,
    userLoggedIn: !!currentUser.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createItem,
    disablePersonalTour,
    openModal,
    closeModal,
  }, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PersonalTourWarningModal)
