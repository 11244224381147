import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { uniqueId } from "../../../../helpers/uniqueId";


class Checkbox extends Component {

  constructor(props) {
    super(props);
    this.checkboxId = uniqueId("checkbox_");
  }

  render() {
    const { wrapperClassName, className, id, children, title, disabled, ...restProps } = this.props;
    const checkboxClass = cn("checkbox__input visually-hidden", className);
    const wrapperClass = cn("checkbox", wrapperClassName, {"checkbox--disabled": disabled});
    const inputId = id || this.checkboxId;

    return (
      <div className={wrapperClass} title={title}>
        <input
          {...restProps}
          className={checkboxClass}
          type="checkbox"
          id={inputId}
          disabled={disabled}
        />
        <label
          className="checkbox__label"
          htmlFor={inputId}
        >
          {children}
        </label>
      </div>
    )
  }

}

Checkbox.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Checkbox;