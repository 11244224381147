import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";

export const SERVICE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape(SERVICE_IMAGE_SHAPE).isRequired
}

export const SERVICE_IMAGE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  full: PropTypes.string,
}

export const SERVICE_PREVIEWS = createRequestActionsFor("SERVICE_PREVIEWS")
export const SERVICE_ITEM = createRequestActionsFor("SERVICE_ITEM");
