import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import i18n from "../../../translations/i18n";
import {
  createItem,
  deleteItem,
} from "../../../actions/favoriteActions";
import {
  openModal,
  closeModal,
} from "../../../actions/modalActions";
import { FAVORITE_SHAPE } from "../../../constants/favoriteConstants";
import { FAVORITE_MODAL } from "../../../constants/modalConstants";
import { TrackAddToFavorite } from "../../../helpers/analytics";


class Favorite extends Component {

  constructor(props) {
    super(props);
  }

  handleAddToFavorite = (event) => {
    const { userLoggedIn, favorite, location, createItem, onFavorite, openModal } = this.props;
    event.preventDefault();

    if (userLoggedIn) {
      createItem(favorite.favorable_id, favorite.favorable_type, location.pathname);
      TrackAddToFavorite(favorite.favorable_type, favorite.name);
      onFavorite();
    } else {
      openModal({name: FAVORITE_MODAL});
    }
  }

  handleDeleteFavorite = (event) => {
    const { favorite, deleteItem, onFavorite } = this.props;
    event.preventDefault();

    deleteItem(favorite.id);
    onFavorite();
  }

  handleCloseModal = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.closeModal();
  }

  render() {
    const { favorite, isShort, removeClassName, removeTitle } = this.props;

    return (
      <>
        { favorite && favorite.id
          ? <a
              href=""
              title={removeTitle}
              className={`w-favorite ${removeClassName}`}
              onClick={this.handleDeleteFavorite}
            >
              {removeTitle}
            </a>
          : <a
              href=""
              title={isShort ? i18n.t('helpers.add_to_fav.short.title') : i18n.t('helpers.add_to_fav.full.title')}
              className="w-favorite add-favorite"
              onClick={this.handleAddToFavorite}
            >
              {isShort ? i18n.t('helpers.add_to_fav.short.title') : i18n.t('helpers.add_to_fav.full.title')}
            </a>
        }
      </>
    )
  }
}

Favorite.propTypes = {
  favorite: PropTypes.shape(FAVORITE_SHAPE),
  isShort: PropTypes.bool,
  removeClassName: PropTypes.string,
  removeTitle: PropTypes.string,
  onFavorite: PropTypes.func.isRequired,
  createItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

Favorite.defaultProps = {
  removeClassName: "in-favorite",
  removeTitle: i18n.t('helpers.added_to_fav.title'),
};

function mapStateToProps({currentUser}) {
  return {
    userLoggedIn: !!currentUser.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createItem,
    deleteItem,
    openModal,
    closeModal,
  }, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Favorite)
