import {
  USER_SIGN_IN,
  USER_SIGN_UP,
  USER_CONFIRM_PHONE,
  USER_RESEND_CODE,
  USER_SUBSCRIBE,
  USER_RESTORE_PASSWORD,
  USER_CHANGE_PASSWORD,
  USER_RESTORE_ACCOUNT,
  USER_RESET_AUTH_FORM,
} from "../constants/userConstants";

const DEFAULT_STATE = {
  error: null,
  subscribedSuccessfully: false,
  passwordSended: false,
};

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case USER_SIGN_IN.REQUEST:
      return {
        ...state,
        error: null,
      };
    case USER_SIGN_IN.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case USER_SIGN_IN.FAILED:
      return {
        ...state,
        error: payload.error,
      };
    case USER_SIGN_UP.REQUEST:
      return {
        ...state,
        error: null,
      };
    case USER_SIGN_UP.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case USER_SIGN_UP.FAILED:
      return {
        ...state,
        error: payload.error,
      };
    case USER_CONFIRM_PHONE.REQUEST:
      return {
        ...state,
        error: null,
      };
    case USER_CONFIRM_PHONE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case USER_CONFIRM_PHONE.FAILED:
      return {
        ...state,
        error: payload.error,
      };
    case USER_RESEND_CODE.REQUEST:
      return {
        ...state,
        error: null,
      };
    case USER_RESEND_CODE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case USER_RESEND_CODE.FAILED:
      return {
        ...state,
        error: payload.error,
      };
    case USER_SUBSCRIBE.REQUEST:
      return {
        ...state,
        error: null,
      };
    case USER_SUBSCRIBE.SUCCESS:
      return {
        ...state,
        subscribedSuccessfully: true,
        error: null,
      };
    case USER_SUBSCRIBE.FAILED:
      return {
        ...state,
        error: payload.error,
      };
    case USER_RESTORE_PASSWORD.REQUEST:
      return {
        ...state,
        error: null,
        passwordSended: false,
      };
    case USER_RESTORE_PASSWORD.SUCCESS:
      return {
        ...state,
        error: null,
        passwordSended: true,
      };
    case USER_RESTORE_PASSWORD.FAILED:
      return {
        ...state,
        error: payload.error,
        passwordSended: false,
      };
    case USER_CHANGE_PASSWORD.REQUEST:
      return {
        ...state,
        error: null,
      };
    case USER_CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case USER_CHANGE_PASSWORD.FAILED:
      return {
        ...state,
        error: payload.error,
      };
    case USER_RESTORE_ACCOUNT.REQUEST:
      return {
        ...state,
        error: null,
      };
    case USER_RESTORE_ACCOUNT.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case USER_RESTORE_ACCOUNT.FAILED:
      return {
        ...state,
        error: payload.error,
      };
    case USER_RESET_AUTH_FORM:
      return {
        ...state,
        ...DEFAULT_STATE,
      }
    default:
      return state;
  }
}