import { GA_TRACKING_ENABLED } from "../../../config";

export function TrackFieldError(field, errorType, source) {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    'field ' + field,
    errorType == 'empty' ? 'not filled' : 'failure',
    source
  )
}

export function TrackFieldValid(field, source) {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    'field ' + field,
    'success',
    source
  )
}
