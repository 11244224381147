import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cn from "classnames";

import i18n from "../../../../../translations/i18n";
import { openModal, closeModal } from "../../../../../actions/modalActions";
import BaseModal from "../../../../../components/ui/modal";
import {
  PAINTINGS_FILTER_MODAL, PAINTINGS_FILTER_ARTISTS_MODAL,
} from "../../../../../constants/modalConstants";


class Artists extends Component {

  constructor(props) {
    super(props);
  }

  handleToggleModal = (event) => {
    const { openModal, closeModal } = this.props;

    if (event) {
      event.preventDefault();
    }

    closeModal();
    openModal({name: PAINTINGS_FILTER_MODAL});
  }

  itemClassName = (option) => {
    const { selectedArtists } = this.props;
    const selected = selectedArtists.filter((artist) => artist.id === option.id);

    if (selected.filter((s) => s.id === option.id).length > 0) {
      return "state-selected";
    } else {
      "stub";
    }
  }

  render () {
    const { modal, artistOptions, onToggle } = this.props;

    return (
      <BaseModal
        isOpen={modal.name === PAINTINGS_FILTER_ARTISTS_MODAL}
        className={cn('p-paintings__filter-modal', {'state-active': modal.visible})}
      >
        <a
          href="#"
          className="p-paintings__filter-modal-close"
          title={i18n.t('modal.close.title')}
          onClick={this.handleToggleModal}
        >
          {i18n.t('modal.close.title')}
        </a>

        <div className="p-paintings__filter-modal-content">
          <div className="p-paintings__filter-modal-name">
            <span className="p-paintings__filter-modal-name-text">
              {modal.title}
            </span>
          </div>
          <div className="p-paintings__filter-modal-list-minor">
            <ul>
              {artistOptions.map((option) =>
                <li
                  key={option.id}
                  className={this.itemClassName(option)}
                >
                  <a
                    href="#"
                    title={option.name}
                    onClick={(event) => onToggle(event, option)}
                  >
                    {option.name}
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="p-paintings__filter-modal-button-wrapper">
            <a
              href="#"
              className="p-paintings__filter-modal-button button"
              onClick={this.handleToggleModal}
            >
              {i18n.t('pages.paintings.filter.modal.choose.title')}
            </a>
          </div>
        </div>
      </BaseModal>
    );
  }
}

Artists.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {
  return {
    modal: layout.modal
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    closeModal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Artists)