import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { makeModificationsReducer, requestActionsToTypes } from "../modificationsReducer";
import { makeCollectionsReducer } from "../collectionsReducer";
import {
  FAVORITE_PREVIEWS,
  FAVORITE_ITEM_CREATE,
  FAVORITE_ITEM_DELETE,
} from "../../../constants/favoriteConstants";
import { favoritePreviewSchema } from "../../../schemas";


export default combineReducers({
  byId: makeByIdReducer(favoritePreviewSchema.key, [FAVORITE_PREVIEWS.SUCCESS]),
  collections: makeCollectionsReducer(FAVORITE_PREVIEWS.typeNames()),
  modifications: makeModificationsReducer(requestActionsToTypes([
    FAVORITE_ITEM_CREATE,
    FAVORITE_ITEM_DELETE,
  ])),
});