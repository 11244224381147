import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";

import { API } from "../config";
import {
  PROPERTY_OPTIONS,
} from "../constants/propertyConstants";
import {
  propertyOptionsSchema,
} from "../schemas";
import { DEFAULT_COLLECTION } from "../selectors/paintingPreviews";
import { FUNC_REPLACE } from "../reducers/entities/collectionsReducer";


export function fetchItems(fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE,
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/options/properties`,
        types: PROPERTY_OPTIONS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { properties } = await res.json();

      return {
        ...normalize(properties, [propertyOptionsSchema]),
      };
    }
  };
}