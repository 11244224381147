import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { INTERIOR_PREVIEWS } from "../../../constants/interiorConstants";
import { makeCollectionsReducer } from "../collectionsReducer";
import { interiorPreviewSchema } from "../../../schemas";


export default combineReducers({
  byId: makeByIdReducer(interiorPreviewSchema.key, [INTERIOR_PREVIEWS.SUCCESS]),
  collections: makeCollectionsReducer(INTERIOR_PREVIEWS.typeNames()),
});