import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cn from "classnames";

import i18n from "../../../../translations/i18n";
import { openModal, closeModal } from "../../../../actions/modalActions";
import {
  PAINTINGS_FILTER_MODAL,
  PAINTINGS_FILTER_ARTISTS_MODAL,
  PAINTINGS_FILTER_OPTIONS_MODAL,
} from "../../../../constants/modalConstants";
import BaseModal from "../../../../components/ui/modal";


class Modal extends Component {

  constructor(props) {
    super(props);
  }

  // second level options modal
  handleOpenModal = (event, filter, type) => {
    const { openModal } = this.props;
    if (event) {
      event.preventDefault();
    }

    openModal({
      name: type,
      filter: filter,
      title: i18n.t(`pages.paintings.filter.${filter}.title`),
    });
  }

  handleCloseModal = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.closeModal();
  }

  renderSelectedArtistsCounter = () => {
    const { selectedArtists } = this.props;

    if (selectedArtists.length > 0) {
      return (
        <span className="p-paintings__filter-figure">
          {selectedArtists.length}
        </span>
      )
    }
  }

  renderArtistsReset = () => {
    const { selectedArtists, onResetArtist } = this.props;

    if (selectedArtists.length > 0) {
      return (
        <span
          className="p-paintings__filter-modal-list-reset"
          onClick={(event) => onResetArtist()}
        >
          {i18n.t('pages.paintings.filter.modal.reset.title')}
        </span>
      )
    }
  }

  renderSelectedOptionsCounter = (item) => {
    const { selectedProperties } = this.props;
    const selected = selectedProperties.filter((property) => property.kind === item)

    if (selected.length > 0) {
      return (
        <span className="p-paintings__filter-figure">
          {selected.length}
        </span>
      )
    }
  }

  renderOptionsReset = (item) => {
    const { selectedProperties, onResetProperty } = this.props;
    const selected = selectedProperties.filter((property) => property.kind === item)

    if (selected.length > 0) {
      return (
        <span
          className="p-paintings__filter-modal-list-reset"
          onClick={(event) => onResetProperty(item)}
        >
          {i18n.t('pages.paintings.filter.modal.reset.title')}
        </span>
      )
    }
  }

  render () {
    const { modal, total, onReset } = this.props;

    return (
      <BaseModal
        isOpen={modal.name === PAINTINGS_FILTER_MODAL}
        className={cn('p-paintings__filter-modal', {'state-active': modal.visible})}
      >
        <a
          href="#"
          className="p-paintings__filter-modal-close"
          title={i18n.t('modal.close.title')}
          onClick={this.handleCloseModal}
        >
          {i18n.t('modal.close.title')}
        </a>

        <div className="p-paintings__filter-modal-content">
          <div className="p-paintings__filter-modal-name">
            <span className="p-paintings__filter-modal-name-text">
              {i18n.t("pages.paintings.filters.title")}
            </span>
            <span
              className="p-paintings__filter-modal-name-reset"
              onClick={onReset}
            >
              {i18n.t('helpers.filter.reset.title')}
            </span>
          </div>
          <div className="p-paintings__filter-modal-list-major">
            <ul>
              <li>
                <a
                  href="#"
                  onClick={(event) => this.handleOpenModal(event, 'genre', PAINTINGS_FILTER_OPTIONS_MODAL)}
                >
                  {i18n.t('pages.paintings.filter.genre.title')}
                  {this.renderSelectedOptionsCounter('genre')}
                </a>
                {this.renderOptionsReset('genre')}
              </li>
              <li>
                <a
                  href="#"
                  onClick={(event) => this.handleOpenModal(event, 'artists', PAINTINGS_FILTER_ARTISTS_MODAL)}
                >
                  {i18n.t('pages.paintings.filter.artists.title')}
                  {this.renderSelectedArtistsCounter()}
                </a>
                {this.renderArtistsReset()}
              </li>
              {['technique', 'material', 'orientation', 'size'].map((item, index) =>
                <li key={index}>
                  <a
                    href="#"
                    onClick={(event) => this.handleOpenModal(event, item, PAINTINGS_FILTER_OPTIONS_MODAL)}
                  >
                    {i18n.t(`pages.paintings.filter.${item}.title`)}
                    {this.renderSelectedOptionsCounter(item)}
                  </a>
                  {this.renderOptionsReset(item)}
                </li>
              )}
            </ul>
          </div>
          <div className="p-paintings__filter-modal-button-wrapper">
            <a
              href="#"
              className="p-paintings__filter-modal-button button"
              onClick={this.handleCloseModal}
            >
              {i18n.t('pages.paintings.filter.modal.button.count.key', {count: total || 0})}
            </a>
          </div>
        </div>
      </BaseModal>
    );
  }
}

Modal.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onResetProperty: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {
  return {
    modal: layout.modal
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    closeModal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)