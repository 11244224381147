import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import { SERVICE_SHAPE } from "../../../constants/serviceConstants";
import Share from "../../../components/post/share";


class Head extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item } = this.props;

    return (
      <div className="p-service__head">
        <div className="p-service__head-content">
          <div className="container">
            <h1 className="p-service__head-title">
              {item.name}
            </h1>
            <div className="p-service__head-media">
              <div className="p-service__head-media-share">
                <div className="p-service__head-media-share-name">
                  {i18n.t('helpers.share.title')}
                </div>
                <Share collection="about" slug={item.slug} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Head.propTypes = {
  item: PropTypes.shape(SERVICE_SHAPE).isRequired,
};

export default Head