import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import { PAINTING_SHAPE } from "../../../constants/paintingConstants";


class Title extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { painting, splited } = this.props;

    return (
      <>
        {painting.artist
          ? <>
              {splited
                ? <>
                    <span>{painting.artist.name}:&nbsp;</span>
                    <span>{painting.name}</span>
                  </>
                : <>{i18n.t('pages.painting.title', {artist: painting.artist.name, name: painting.name})}</>
              }
            </>
          : <>
              {painting.name}
            </>
        }
      </>
    );
  }
}

Title.propTypes = {
  painting: PropTypes.shape(PAINTING_SHAPE),
  splited: PropTypes.bool,
};

Title.defaultProps = {
  splited: false,
};

export default Title