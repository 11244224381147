export const STATUS_REQUEST = "request";
export const STATUS_SUCCESS = "success";
export const STATUS_FAILED = "failed";

export function makeModificationsReducer({requestTypes = [], successTypes = [], failedTypes = []}) {
  return function(state = {}, { type, payload, meta }) {
    const modificationName = (meta || {}).modification;
    if (!modificationName) {
      return state;
    }

    const modification = state[modificationName];
    if (requestTypes.includes(type)) {
      return {
        ...state,
        [modificationName]: {
          ...modification,
          status: STATUS_REQUEST,
          payload: null,
        }
      };
    } else if (successTypes.includes(type)) {
      return {
        ...state,
        [modificationName]: {
          ...modification,
          status: STATUS_SUCCESS,
          payload,
        }
      };
    } else if (failedTypes.includes(type)) {
      return {
        ...state,
        [modificationName]: {
          ...modification,
          status: STATUS_FAILED,
          payload,
        }
      };
    } else {
      return state;
    }
  }
}

export function requestActionsToTypes(requestActions) {
  return requestActions.reduce((all, action) => {
    return {
      requestTypes: [
        ...all.requestTypes,
        action.REQUEST,
      ],
      successTypes: [
        ...all.successTypes,
        action.SUCCESS,
      ],
      failedTypes: [
        ...all.failedTypes,
        action.FAILED,
      ],
    }
  }, {requestTypes: [], successTypes: [], failedTypes: []});
}