import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import {
  PERSONAL_TOUR_SHAPE,
} from "../../../../constants/personalTourConstants";

import FormGroup from "../../../../components/ui/form/group";
import FormActions from "../../../../components/ui/form/actions";
import FormError from "../../../../components/ui/form/error";
import InputField from "../../../../components/ui/form/input_field";
import Button from "../../../../components/ui/form/button";
import {
  TrackFieldError,
  TrackFieldValid,
  TrackAddPersonalTourSubmitClick,
  TrackAddPersonalTourSubmitError,
} from "../../../../helpers/analytics";


class PersonalTourForm extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { serverError, errors, touched, isSubmitting, handleSubmit } = this.props;

    return (
      <Form>
        <div className="modal__title">
          {i18n.t('modal.personal_tour.form.title')}
        </div>
        <FormGroup
          className="form__group-comment"
          label={i18n.t("modal.personal_tour.form.comment.label")}
          touched={touched.comment}>
          <Field
            className="form__field b-personal_tour__field b-personal_tour__field-comment"
            component={InputField}
            disabled={isSubmitting}
            name="comment"
            required
          />
        </FormGroup>
        <FormActions className="modal__form-button-wrapper">
          <FormError>
            {serverError}
          </FormError>
          <FormError>
            {errors.purpose}
          </FormError>
          <Button
            className="modal__form-button"
            type="submit"
            disabled={isSubmitting}
            onSubmit={handleSubmit}
          >
            {i18n.t("modal.personal_tour.form.submit.title")}
          </Button>
        </FormActions>
      </Form>
    )
  }
}

const PersonalTourFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};

    if (isEmpty(values.comment)) {
      TrackFieldError('purpose', 'empty', values.source);
      errors.comment = i18n.t("modal.personal_tour.form.validation.comment.required");
    } else {
      TrackFieldValid('comment', values.source)
    }

    if (isEmpty(errors)) {
      TrackAddPersonalTourSubmitClick(values.source, values.expositionName)
    } else {
      TrackAddPersonalTourSubmitError();
    }

    return errors;
  },
})(PersonalTourForm);

PersonalTourFormWithFormik.propTypes = {
  initialValues: PropTypes.shape(PERSONAL_TOUR_SHAPE).isRequired,
  serverError: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default PersonalTourFormWithFormik
