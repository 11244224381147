import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { EXPOSITION_PRICE_SHAPE } from "../../../constants/expositionConstants";


class Prices extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { items } = this.props;

    return (
      <div className="p-exhibition__info-contacts-row">
        {i18n.t('pages.exposition.prices.title')}
        { items.map((item) => i18n.t('pages.exposition.prices.price', {kind: item.title, price: item.amount})).join(', ') }
      </div>
    );
  }
}

Prices.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_PRICE_SHAPE)),
};

export default Prices
