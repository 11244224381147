import { GA_TRACKING_ENABLED } from "../../../config";

export const GA_PARTNERS_SOURCE = "suggest a painting form_partners page";

export function TrackPaintingOfferClick() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Отправить'",
    "click",
    "suggest a painting form_partners page"
  )
}

export function TrackTechniqueClick() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "technics field",
    "success",
    "suggest a painting form_partners page"
  )
}

export function TrackPaintingOfferSubmitError() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Отправить'",
    "user_failure",
    "thank you page_suggest a painting form_partners page"
  )
}

export function TrackPaintingOfferSubmitSuccess() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Отправить'",
    "success",
    "thank you page_suggest a painting form_partners page"
  )
}
