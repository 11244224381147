import { GA_TRACKING_ENABLED } from "../../../config";

export const GA_APPOINTMENT_PAINTINGS_SOURCE = 'paintings';
export const GA_APPOINTMENT_PAINTING_SOURCE = 'painting';
export const GA_APPOINTMENT_ARTIST_SOURCE = 'artist';
export const GA_APPOINTMENT_NOVELTIES_SOURCE = 'novelties';
export const GA_APPOINTMENT_INTERIOR_SOURCE = 'interior';
export const GA_APPOINTMENT_FAVORITES_SOURCE = 'favorites';


export function TrackAddAppointmentLogin() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "make an appointment button",
    "'Записаться на просмотр' form",
    "'Записаться на просмотр' login form"
  )
}

export function TrackAddAppointmentSubmitClick(source, paintingName = "") {
  let label = null;

  switch (source) {
    case GA_APPOINTMENT_PAINTINGS_SOURCE:
      label = "collection page";
      break;
    case GA_APPOINTMENT_PAINTING_SOURCE:
      label = "painting page_" + paintingName;
      break;
    case GA_APPOINTMENT_ARTIST_SOURCE:
      label = "artist page";
      break;
    case GA_APPOINTMENT_NOVELTIES_SOURCE:
      label = "home page_new";
      break;
    case GA_APPOINTMENT_INTERIOR_SOURCE:
      label = "home page_interior";
      break;
    case GA_APPOINTMENT_FAVORITES_SOURCE:
      label = "account page_favorite";
      break;
    default:
      return;
  }

  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Записаться на просмотр'",
    "click",
    label,
  )
}

export function TrackAddAppointmentSubmitError() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Записаться на просмотр'",
    "user_failure",
    "thank you page_make an appointment"
  )
}

export function TrackAddAppointmentSubmitSuccess() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Записаться на просмотр'",
    "success",
    "thank you page_make an appointment"
  )
}
