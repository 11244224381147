import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import InputMask from "react-input-mask";


class MaskInput extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { mask, error, ...restProps } = this.props;

    return(
      <InputMask
        type="tel"
        className={cn('form__field', {'is-invalid': error})}
        mask={mask}
        {...restProps}
      />
    )
  }
}

MaskInput.propTypes = {
  error: PropTypes.string,
};

MaskInput.defaultProps = {
  mask: "+7 (999) 999-99-99",
}

export default MaskInput