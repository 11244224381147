import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as QueryString from "query-string";
import isEmpty from "lodash/isEmpty";
import cn from "classnames";

import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import { withSearch } from "../../hocs/entities/withSearch";
import SearchForm from "./form";
import Aside from "../../components/search/aside";
import List from "../../components/search/list";
import Pagination from "../../components/layout/pagination";
import Meta from "../../components/layout/meta";


class Search extends React.Component {

  constructor(props) {
    super(props);
  }

  fetchCollection = (page = 1) => {
    const { location, fetchSearchResults } = this.props;
    const params = QueryString.parse(location.search);

    fetchSearchResults(params, page);
  }

  handlePageChange = (page = 1) => {
    if (event) {
      event.preventDefault();
    }

    this.fetchCollection(page);
  }

  handleSubmit = (values) => {
    const { history } = this.props;
    const { pathname, search } = history.location;

    let searchParams = new URLSearchParams(search);
    searchParams.set("page", 1);

    if (values && values.q) {
      searchParams.set("q", values.q);
    }
    if (values && values.type) {
      searchParams.set("type", values.type);
    } else if (values && values.type === null) {
      searchParams.delete("type");
    }
    history.push(`${pathname}?${searchParams.toString()}`);

    this.fetchCollection();
  }

  render () {
    const { results, facets, pagination, location, fetchStatus } = this.props;
    const params = QueryString.parse(location.search);
    const notEmpty = !isEmpty(facets);

    return (
      <main className="layout">
        <Meta />
        <div className="p-search">
          <div className="container">
            <SearchForm
              initialValues={{q: params.q}}
              onSubmit={this.handleSubmit}
            />
            <div className="p-search__results">
              {notEmpty &&
                <Aside
                  items={facets}
                  currentQuery={params.q}
                  currentType={params.type}
                  onFilter={this.handleSubmit}
                />
              }
              <div className={cn('p-search__results-content', {'not-empty': notEmpty})}>
                <List items={results} q={params.q} />
              </div>
            </div>
          </div>
          <Pagination
            totalPages={pagination.total_pages || 1}
            disabled={fetchStatus.isFetching}
            onChange={this.handlePageChange}
          />
        </div>
      </main>
    );
  }
}

Search.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps({ router: { location: { search } } }) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withSearch,
  withRouter,
  withBodyClass('app-search'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Search)