import { createMigrate } from "redux-persist";

const migrations = {
  0: (state) => {
    return {
      ...state,
    }
  },
  30112021: (state) => {
    // store cleanup migration
    return {
      ...state,
      entities: {},
      layout: {},
      paintingOffer: {}
    }
  }
}

export const VERSION = '30112021'

export default createMigrate(migrations, { debug: true })
