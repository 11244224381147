import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { PROPERTY_OPTIONS } from "../../../constants/propertyConstants";
import { makeCollectionsReducer } from "../collectionsReducer";
import { propertyOptionsSchema } from "../../../schemas";


export default combineReducers({
  byId: makeByIdReducer(propertyOptionsSchema.key, [PROPERTY_OPTIONS.SUCCESS]),
  collections: makeCollectionsReducer(PROPERTY_OPTIONS.typeNames()),
});