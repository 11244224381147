import React, { createRef } from "react";
import i18n from "../../../translations/i18n";
import List from "../../../components/artists/list";

class Painters extends React.Component {

  constructor(props) {
    super(props);
  }

  handleFavorites() {
    const { onFavorite } = this.props;
    onFavorite()
  }

  render () {
    const { artists }  = this.props;
    return (
      <>
        {artists && artists.length > 0 &&
          <div className="b-painters">
            <div className="section-title">
              {i18n.t('pages.partners.painters.title')}
            </div>
            <div className="container">
              <div className="b-painters__content">
                <List
                  items={artists}
                  onFavorite={() => this.handleFavorites()}
                />
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default Painters;