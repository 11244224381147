import PropTypes from "prop-types";

export const SHOW_NOTICE = "SHOW_NOTICE";
export const HIDE_NOTICE = "HIDE_NOTICE";

export const NOTICE_DURATION = 3500;

export const NOTICE_SHAPE = {
  title: PropTypes.string,
  description: PropTypes.string,
}