import React, { Component } from "react";
import PropTypes from "prop-types";

import i18n from "../../../../translations/i18n";
import { SEARCHABLE_POST_SHAPE } from "../../../../constants/searchConstants";
import Image from "../image";
import Link from "../../../../components/ui/link";


class Post extends Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item } = this.props;

    return (
      <Link
        key={item.id}
        to={item.path}
        className="p-search__results-list-item"
      >
        <div className="p-search__results-list-item-info">
          <div className="p-search__results-list-item-info-description">
            <ul>
              <li>
                <strong>
                  {i18n.t(`pages.search.results.${item.kind}.title`)}
                </strong>
              </li>
              <li>
                {item.date}
              </li>
            </ul>
          </div>
          <div className="p-search__results-list-item-info-name">
            {item.name}
          </div>
          <div
            className="p-search__results-list-item-info-paragraph"
            dangerouslySetInnerHTML={{__html: item.annotation}}
          />
        </div>
        <Image item={item} />
      </Link>
    );
  }
}

Post.propTypes = {
  item: PropTypes.shape(SEARCHABLE_POST_SHAPE).isRequired,
};

export default Post;