import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../../translations/i18n";
import Loading from "../loading";

import { ERROR_KEY } from "../../../../reducers/entities/byIdReducer";


function isFetched(entity) {
  // неденормализованное значение будет null, undefined или строка (id-шник),
  // соответственно денормализованное (а значит загруженное) будет объектом
  return !!entity && typeof entity === "object"
}

function LoadableEntity({entity, children, renderLoading, renderError}) {
  const fetched = isFetched(entity);
  if (!fetched) {
    if (renderLoading) {
      return renderLoading();
    } else {
      return <Loading isLoading={true} />;
    }
  }

  const hasError = entity[ERROR_KEY];
  if (hasError) {
    if (renderError) {
      return renderError();
    } else {
      return <>{i18n.t("helpers.loading.error")}</>;
    }
  }

  if (children) {
    return (
      <>
        {children(entity)}
        {
          /*
           This is filler,
           because entity can be object without props,
           children(entity) returns "undefined",
           react raise error...
          */
        }
      </>
    );
  } else {
    return null;
  }
}

LoadableEntity.propTypes = {
  children: PropTypes.func,
  renderLoading: PropTypes.func,
  renderError: PropTypes.func,
  entity: PropTypes.any,
};

export default LoadableEntity