import { createSelector } from "reselect";
import PropTypes from "prop-types";

import { getEntitiesState } from "./entities";
import {
  STATUS_FAILED,
  STATUS_REQUEST,
  STATUS_SUCCESS
} from "../reducers/entities/modificationsReducer";


export function makeModificationSelector(modification, schema) {
  return createSelector(
    getEntitiesState,
    (entitiesState) => (entitiesState[schema.key].modifications[modification] || {})
  )
}

export function makeModificationFetchingSelector(modification, schema) {
  return createSelector(
    makeModificationSelector(modification, schema),
    (modificationData) => modificationData.status === STATUS_REQUEST
  );
}

export function makeModificationSuccessSelector(modification, schema) {
  return createSelector(
    makeModificationSelector(modification, schema),
    (modificationData) => modificationData.status === STATUS_SUCCESS
  );
}

export function makeModificationFailedSelector(modification, schema) {
  return createSelector(
    makeModificationSelector(modification, schema),
    (modificationData) => modificationData.status === STATUS_FAILED
  );
}

export const MODIFICATION_SHAPE = {
  isFetching: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
};

export function makeModificationStatusSelector(modification, schema) {
  return createSelector(
    makeModificationFetchingSelector(modification, schema),
    makeModificationSuccessSelector(modification, schema),
    makeModificationFailedSelector(modification, schema),
    (isFetching, isSuccess, isFailed) => ({isFetching, isSuccess, isFailed})
  );
}

export function makeModificationPayloadSelector(modification, schema) {
  return createSelector(
    makeModificationSelector(modification, schema),
    (modificationData) => modificationData.payload
  );
}