import PropTypes from "prop-types";

import i18n from "../translations/i18n";
import { createRequestActionsFor } from "./apiFetch";

export const EXPOSITION_POSTER_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  main: PropTypes.string,
}

export const EXPOSITION_PLACE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  address: PropTypes.string,
  description: PropTypes.string,
}

export const EXPOSITION_GUIDE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  company: PropTypes.string,
  occupation: PropTypes.string,
}

export const EXPOSITION_SCHEDULE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  weekday: PropTypes.string,
  start_at: PropTypes.string,
  end_at: PropTypes.string,
}

export const EXPOSITION_CALENDAR_CELL_SHAPE = {
  past: PropTypes.bool,
  num: PropTypes.string,
  month: PropTypes.string,
  day: PropTypes.string,
  date: PropTypes.string,
}

export const EXPOSITION_CALENDAR_SHAPE = {
  cell: PropTypes.shape(EXPOSITION_CALENDAR_CELL_SHAPE),
  tours: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_TOUR_SHAPE))
}

export const EXPOSITION_PRICE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  description: PropTypes.string,
  amount: PropTypes.number,
}

export const EXPOSITION_TOUR_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  start_at: PropTypes.string,
  price_min: PropTypes.number,
  price_max: PropTypes.number,
  free: PropTypes.bool,
  tickets_count: PropTypes.number,
  tickets_available: PropTypes.number,
  adults_only: PropTypes.bool,
  guide: PropTypes.shape(EXPOSITION_GUIDE_SHAPE),
  prices: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_PRICE_SHAPE)),
}

export const EXPOSITION_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  slug: PropTypes.string,
  annotation: PropTypes.string,
  adults_only: PropTypes.bool,
  poster: PropTypes.shape(EXPOSITION_POSTER_SHAPE),
  schedule: PropTypes.shape(EXPOSITION_SCHEDULE_SHAPE),
  calendar: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_CALENDAR_SHAPE)),
  place: PropTypes.shape(EXPOSITION_PLACE_SHAPE),
  prices: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_PRICE_SHAPE)),
}

export const EXPOSITION_KIND_ACTUAL = 'actual';
export const EXPOSITION_KIND_FUTURE = 'future';
export const EXPOSITION_KIND_PAST = 'past';

export const EXPOSITION_TOUR_KIND = 'tours';
export const EXPOSITION_TICKETS_KIND = 'tickets';
export const EXPOSITION_IMAGES_KIND = 'images';
export const EXPOSITION_PUBLICATIONS_KIND = 'publications';

export const EXPOSITION_SELECT_DEFAULT_OPTION = {
  value: 'about',
  label: i18n.t('pages.exposition.aside.about.title')
}

export const EXPOSITION_PREVIEWS = createRequestActionsFor("EXPOSITION_PREVIEWS")
export const EXPOSITION_ITEM = createRequestActionsFor("EXPOSITION_ITEM");
