import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { paintingSchema } from "../../../schemas";
import { PAINTING_ITEM } from "../../../constants/paintingConstants";

export default combineReducers({
  byId: makeByIdReducer(
    paintingSchema.key,
    [PAINTING_ITEM.SUCCESS],
    [PAINTING_ITEM.FAILED]
  ),
})