import {
  ENABLE_APPOINTMENT,
  DISABLE_APPOINTMENT,
  APPOINTMENT_ITEM_CREATE
} from "../../constants/appointmentConstants";

const DEFAULT_STATE = {
  error: null,
  enabled: false,
  paintingId: null,
  onAppointment: null,
};

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case ENABLE_APPOINTMENT:
      return {
        ...state,
        ...payload,
        enabled: true,
      };
    case DISABLE_APPOINTMENT:
      return {
        ...state,
        ...DEFAULT_STATE,
      }
    case APPOINTMENT_ITEM_CREATE.FAILED:
      return {
        ...state,
        ...payload,
        enabled: true,
      };
    default:
      return state;
  }
}
