import {
  PAINTING_OFFER,
  PAINTING_OFFER_CACHED,
  PAINTING_OFFER_CLEAR
} from "../constants/paintingOfferConstants";

const DEFAULT_STATE = {
  error: null,
  submitted: false,
  redirected: false,
  cached: null
};

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case PAINTING_OFFER.REQUEST:
      return {
        ...state,
        error: null
      };
    case PAINTING_OFFER.SUCCESS:
      return {
        ...state,
        error: null,
        submitted: true,
        cached: null,
        redirected: false
      };
    case PAINTING_OFFER.FAILED:
      return {
        ...state,
        error: payload.error
      };
    case PAINTING_OFFER_CACHED:
      return {
        ...state,
        error: null,
        redirected: true,
        cached: payload
      };
    case PAINTING_OFFER_CLEAR:
      return {
        ...state,
        ...DEFAULT_STATE
      };
    default:
      return state;
  }
}