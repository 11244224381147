import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import Icon from "../../ui/icon";
import Account from "./account";
import Cart from "./cart";
import SearchModal from "./search/modal";
import SearchLink from "./search/link";
import { USER_SHAPE } from "../../../constants/userConstants";
import { openHeaderSearch, closeHeaderSearch } from "../../../actions/layoutActions";
import Link from "../../ui/link";

const HEADER_SCROLL_OFFSET = 100;

const LEFT_NAVIGATION = [
  {
    id: 'paintings',
    title: i18n.t('header.paintings.title'),
    submenu: [
      { id: 'all-paintings', slug: '/paintings', title: i18n.t('header.paintings.all_paintings') },
      { id: 'century_19', slug: '/paintings/19_century', title: i18n.t('header.paintings.century_19') },
      { id: 'century_20', slug: '/paintings/20_century', title: i18n.t('header.paintings.century_20') },
    ]
  },
  { id: 'artists', slug: '/artists', title: i18n.t('header.artists.title') },
  { id: 'posts', slug: '/posts', title: i18n.t('header.posts.title') },
]

const RIGHT_NAVIGATION = [
  { id: 'expositions', slug: '/expositions', title: i18n.t('header.expositions.title') },
  {
    id: 'about',
    title: i18n.t('header.about.title'),
    submenu: [
      { id: 'about_us', slug: '/about', title: i18n.t('header.about_us.title') },
      { id: 'services', slug: '/about#services', title: i18n.t('header.services.title') },
      { id: 'contacts', slug: '/contacts', title: i18n.t('header.contacts.title') },
    ]
  },
  { id: 'partners', slug: '/partners', title: i18n.t('header.partners.title') }
]

// Use:
// <Header />
// <Header transparent />

function Header({userLoggedIn, headerSearch, transparent, location}) {
  const [sticky, setSticky] = useState(false);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handleScroll = () => {
    if (ref.current) {
      setSticky(window.scrollY > HEADER_SCROLL_OFFSET)
    }
  }

  const handleMenuClick = () => {
    if (!open) {
      document.body.classList.add('hidden');
    } else {
      document.body.classList.remove('hidden');
    }

    setOpen(!open);
  }

  const handleDropdownClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const headerClassName = cn('header', {
    'header--transparent': transparent && !sticky,
    'header-sticky': sticky,
    'header--open': open
  })

  const renderList = (items) => {
    return (
      <>
        {items.map((item) =>
          <li
            key={item.id}
            className={cn('header__menu-list-item', {'is-dropdown': !isEmpty(item.submenu)})}
          >
            {renderLink(item)}
          </li>
        )}
      </>
    )
  }

  const renderLink = (item) => {
    const subPages = (item.submenu || []).map((item) => item.slug)
    const isDropdownLink = subPages.includes(location.pathname);

    return (
      <>
        {isEmpty(item.submenu)
          ? <Link
              to={item.slug}
              className="header__menu-list-item-link"
              activeClassName="state-selected"
            >
              {item.title}
            </Link>
          :
            <>
              <Link
                to=""
                onClick={handleDropdownClick}
                className={cn('header__menu-list-item-link', { 'state-selected': isDropdownLink })}
              >
                {item.title}
              </Link>
              <div className="header__menu-dropdown">
                <ul className="header__menu-dropdown-list">
                  {renderList(item.submenu)}
                </ul>
              </div>
            </>
        }
      </>
    )
  }

  return (
    <header
      className={headerClassName}
      ref={ref}
    >
      <SearchModal />
      <div className="header__logo">
        <a
          href="/"
          dangerouslySetInnerHTML={{__html: i18n.t('header.logo.title')}}
        />
      </div>

      <input
        className="header__menu-button"
        id="header__menu-button"
        type="checkbox"
      />
      <label
        className="header__menu-icon"
        htmlFor="header__menu-button"
        onClick={handleMenuClick}
      >
        <span className="navicon" />
      </label>

      <div className="header__collapse">
        <div className="header__collapse-content">
          <div className="header__menu header__menu-left">
            <ul>
              <li className="header__menu-search">
                <SearchLink />
              </li>
              {renderList(LEFT_NAVIGATION)}
            </ul>
          </div>
          <div className="header__menu header__menu-right">
            <ul>
              {renderList(RIGHT_NAVIGATION)}
              <li className={cn('header__menu-account', {'logged-in': userLoggedIn})}>
                { userLoggedIn
                  ? <Link
                      to="/account"
                      className="header__menu-account-link"
                    >
                      <Icon icon="account" />
                    </Link>
                  : <Link
                      to="/login"
                      activeClassName="state-selected"
                    >
                      {i18n.t('header.sign_in.title')}
                    </Link>
                }
              </li>
              {/*<li className="header__menu-cart">*/}
              {/*  <Cart />*/}
              {/*</li>*/}
            </ul>
          </div>
          <Account />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  currentUser: PropTypes.shape(USER_SHAPE),
  userLoggedIn: PropTypes.bool,
  transparent: PropTypes.bool,
};

function mapStateToProps({currentUser, layout}) {
  return {
    currentUser,
    userLoggedIn: !!currentUser.token,
    headerSearch: layout.headerSearch,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openHeaderSearch,
    closeHeaderSearch,
  }, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header)
