import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import cn from "classnames";

import i18n from "../../../translations/i18n";
import {
  filterBy,
  filterByArtists,
  filterByProperties,
  fiterByNovelty,
  resetFilter,
} from "../../../actions/paintingActions";
import { openModal } from "../../../actions/modalActions";
import {
  PROPERTY_GENRE_KIND,
  PROPERTY_TECHNIQUE_KIND,
  PROPERTY_MATERIAL_KIND,
  PROPERTY_ORIENTATION_KIND,
  PROPERTY_SIZE_KIND
} from "../../../constants/propertyConstants";
import Options from "./options";
import Modal from "./modal";
import ArtistsModal from "./modal/artists";
import OptionsModal from "./modal/options";
import { PAINTINGS_FILTER_MODAL } from "../../../constants/modalConstants";

const HEADER_SCROLL_OFFSET = 64;


function Filter({
    artistOptions,
    selectedArtists,
    propertyOptions,
    selectedProperties,
    novelties,
    total,
    filterBy,
    fiterByNovelty,
    openModal,
    resetFilter,
    onFilter,
  }) {

  const [sticky, setSticky] = useState(false);
  const ref = useRef(null);

  const handleScroll = () => {
    if (ref.current && isMobile) {
      setSticky(window.scrollY > HEADER_SCROLL_OFFSET)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const handleToggleArtists = (event, option) => {
    if (event) {
      event.preventDefault();
    }

    if (selectedArtists.filter((s) => s.id === option.id).length > 0) {
      selectedArtists.splice(selectedArtists.findIndex((s) => s.id === option.id), 1);
    } else {
      selectedArtists.push(option);
    }

    filterBy(selectedArtists, selectedProperties);
    onFilter();
  }

  const handleToggleProperties = (event, option) => {
    if (event) {
      event.preventDefault();
    }

    if (selectedProperties.filter((s) => s.id === option.id).length > 0) {
      selectedProperties.splice(selectedProperties.findIndex((s) => s.id === option.id), 1);
    } else {
      selectedProperties.push(option);
    }

    filterBy(selectedArtists, selectedProperties);
    onFilter();
  }

  const handleResetArtist = () => {
    if (selectedArtists.length > 0) {
      // TODO: wtf?
      setTimeout(() => {
        filterBy([], selectedProperties)
      }, 0)
      setTimeout(() => {
        onFilter();
      }, 0)
    }
  }

  const handleResetProperty = (item) => {
    const selected = selectedProperties.filter((s) => s.kind === item)

    if (selected.length > 0) {
      selected.map((item) => selectedProperties.splice(selectedProperties.findIndex((s) => s.id === item.id), 1));
    }

    filterBy(selectedArtists, selectedProperties);
    onFilter();
  }

  const handleToggleNovelties = (event) => {
    event.preventDefault();

    let novelty = !novelties;

    // TODO: wtf?
    setTimeout(() => {
      fiterByNovelty(novelty);
    }, 0)
    setTimeout(() => {
      onFilter();
    }, 0)
  }

  const handleResetFilter = (event) => {
    event.preventDefault();

    resetFilter();
  }

  const handleOpenModal = (event) => {
    event.preventDefault();

    openModal({name: PAINTINGS_FILTER_MODAL});
  }

  return (
    <div
      className={cn('p-paintings__filter', {'p-paintings__filter-sticky': sticky})}
      ref={ref}
    >
      <Modal
        total={total}
        artistOptions={artistOptions}
        selectedArtists={selectedArtists}
        propertyOptions={propertyOptions}
        selectedProperties={selectedProperties}
        onResetArtist={handleResetArtist}
        onResetProperty={handleResetProperty}
        onReset={handleResetFilter}
      />
      <ArtistsModal
        artistOptions={artistOptions}
        selectedArtists={selectedArtists}
        onToggle={handleToggleArtists}
      />
      <OptionsModal
        propertyOptions={propertyOptions}
        selectedProperties={selectedProperties}
        onToggle={handleToggleProperties}
      />
      <div className="container">
        <div className="p-paintings__filter-content">
          <div className="p-paintings__filter-name">
            {i18n.t("pages.paintings.title")}
          </div>
          <div className="p-paintings__filter-new">
            <a
              href="#"
              className={cn({'state-seleted': novelties})}
              title={i18n.t("pages.paintings.filter.new.title")}
              onClick={handleToggleNovelties}
            >
              {i18n.t("pages.paintings.filter.new.title")}
            </a>
          </div>
          <div className="p-paintings__filter-plus">
            <a
              href="#"
              title={i18n.t("pages.paintings.filters.title")}
              onClick={handleOpenModal}
            >
              {i18n.t("pages.paintings.filters.title")}
            </a>
          </div>
          <ul className="p-paintings__filter-list">
            <Options
              title={i18n.t("pages.paintings.filter.genre.title")}
              options={propertyOptions.filter((property) => property.kind === PROPERTY_GENRE_KIND)}
              selected={selectedProperties.filter((property) => property.kind === PROPERTY_GENRE_KIND)}
              onToggle={handleToggleProperties}
            />
            <Options
              title={i18n.t("pages.paintings.filter.artists.title")}
              options={artistOptions}
              selected={selectedArtists}
              onToggle={handleToggleArtists}
            />
            <Options
              title={i18n.t("pages.paintings.filter.technique.title")}
              options={propertyOptions.filter((property) => property.kind === PROPERTY_TECHNIQUE_KIND)}
              selected={selectedProperties.filter((property) => property.kind === PROPERTY_TECHNIQUE_KIND)}
              onToggle={handleToggleProperties}
            />
            <Options
              title={i18n.t("pages.paintings.filter.material.title")}
              options={propertyOptions.filter((property) => property.kind === PROPERTY_MATERIAL_KIND)}
              selected={selectedProperties.filter((property) => property.kind === PROPERTY_MATERIAL_KIND)}
              onToggle={handleToggleProperties}
            />
            <Options
              title={i18n.t("pages.paintings.filter.orientation.title")}
              options={propertyOptions.filter((property) => property.kind === PROPERTY_ORIENTATION_KIND)}
              selected={selectedProperties.filter((property) => property.kind === PROPERTY_ORIENTATION_KIND)}
              onToggle={handleToggleProperties}
            />
            <Options
              title={i18n.t("pages.paintings.filter.size.title")}
              options={propertyOptions.filter((property) => property.kind === PROPERTY_SIZE_KIND)}
              selected={selectedProperties.filter((property) => property.kind === PROPERTY_SIZE_KIND)}
              onToggle={handleToggleProperties}
            />
          </ul>
          <div className="p-paintings__filter-reset">
            <a href="#" onClick={handleResetFilter}>
              {i18n.t('helpers.filter.reset.title')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

Filter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    filterBy,
    filterByArtists,
    filterByProperties,
    fiterByNovelty,
    resetFilter,
    openModal,
  }, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Filter)