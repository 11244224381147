import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchItems } from "../../actions/serviceActions";
import {
  makeServicePreviewsSelector,
  makeServicePreviewsAnyKeySelector,
  makeServicePreviewsFetchStatusSelector,
} from "../../selectors/servicePreviews";
import { SERVICE_SHAPE } from "../../constants/serviceConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withServices(WrappedComponent) {

  const WithServices = class extends Component {

    componentDidMount() {
      this.fetchServices();
    }

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    fetchServices = () => {
      const { fetchItems, fetchStatus } = this.props;

      if (!fetchStatus.isFetching) {
        fetchItems();
      }
    };

    render() {
      const { services, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    services={services}
                    fetchServices={this.fetchServices}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithServices.propTypes = {
    services: PropTypes.arrayOf(PropTypes.shape(SERVICE_SHAPE)),
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const servicesSelector = makeServicePreviewsSelector();
  const servicesFetchStatusSelector = makeServicePreviewsFetchStatusSelector();

  function mapStateToProps(state) {
    return {
      services: servicesSelector(state),
      fetchStatus: servicesFetchStatusSelector(state),
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItems,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithServices);
}