import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { withNotice } from "../../hocs/layout/withNotice";
import { withPost } from "../../hocs/entities/withPost";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import {
  POST_ARTICLE_KIND,
  POST_PHOTOREPORT_KIND,
  POST_YOUTUBE_KIND
} from "../../constants/postConstants";
import Article from "./article";
import Photoreport from "./photoreport";
import Youtube from "./youtube";
import Meta from "./meta";


class Post extends React.Component {

  constructor(props) {
    super(props);
  }

  handleFavorite = () => {
  }

  render () {
    const { post, fetchPost } = this.props;
    const isArticle = post.kind === POST_ARTICLE_KIND;
    const isPhotoreport = post.kind === POST_PHOTOREPORT_KIND;
    const isYoutube = post.kind === POST_YOUTUBE_KIND;

    return (
      <main className="layout">
        <Meta />
        {isArticle && <Article post={post} fetchPost={fetchPost} />}
        {isPhotoreport && <Photoreport post={post} fetchPost={fetchPost} />}
        {isYoutube && <Youtube post={post} fetchPost={fetchPost} />}
      </main>
    );
  }
}

Post.propTypes = {
  fetchPost: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withPost,
  withNotice(),
  withBodyClass('app-post'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Post)
