import { RSAA } from "../middlewares/redux-api-middleware";
import { serialize } from "object-to-formdata";

const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "charset": "utf-8",
  "X-Requested-With": "XMLHttpRequest",
  "credentials": "same-origin"
};

const DEFAULT_METHOD = 'GET';

export const apiFetchMiddleware = store => next => action => {
  if (!action[RSAA]) {
    return next(action);
  }

  const { multipartFormData, ...actionConfig } = action[RSAA];
  const body = multipartFormData ? serialize(actionConfig.body) : JSON.stringify(actionConfig.body);
  const token = store.getState().currentUser.token

  let headers = {
    ...actionConfig.headers,
    Authorization: `Bearer ${token}`,
  }

  if (!multipartFormData) {
    headers = {
      ...DEFAULT_HEADERS,
      ...headers,
    }
  }

  const request = {
    ...actionConfig,
    method: actionConfig.method || DEFAULT_METHOD,
    body,
    headers,
  };

  return next({
    [RSAA]: request,
  });
};
