import React from "react";
import PropTypes from "prop-types";

import { INTERIOR_SHAPE } from "../../../../../constants/interiorConstants";
import Title from "../../../../../components/painting/title";
import Ended from "../../../../../components/painting/ended";
import Dimensions from "../../../../../components/painting/dimensions";
import Appointment from "../../../../../components/layout/appointment";
import { GA_APPOINTMENT_INTERIOR_SOURCE } from "../../../../../helpers/analytics";


class Slide extends React.Component {

  constructor(props) {
    super(props);
  }

  handleClick = (event) => {
    event.preventDefault();
  }

  render () {
    const { item, onAppointment } = this.props;

    return (
      <div className="b-interior-carousel__item">
        <div className="b-interior-carousel__card">
          <div className="b-interior-carousel__card-image-wrap">
            <img
              src={item.medium}
              alt={item.painting.name}
              title={item.painting.name}
              className="b-interior-carousel__card-image"
            />
          </div>
          <div className="b-interior-carousel__card-info">
            <div className="b-interior-carousel__card-painter">
              <div className="b-interior-carousel__card-painter-name">
                <Title painting={item.painting} />
              </div>
              <div className="b-interior-carousel__card-painter-date">
                <Ended title={item.painting.ended_at} />
                <Dimensions height={item.painting.height} width={item.painting.width} />
              </div>
            </div>
            <div className="b-interior-carousel__card-button-wrapper">
              <Appointment
                className="button b-interior-carousel__card-button"
                available={item.painting.available}
                appointment={item.painting.appointment}
                source={GA_APPOINTMENT_INTERIOR_SOURCE}
                onAppointment={onAppointment}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Slide.propTypes = {
  item: PropTypes.shape(INTERIOR_SHAPE),
  onAppointment: PropTypes.func.isRequired,
};

export default Slide
