import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Masonry from "react-masonry-component";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { makePaintingPreviewsFetchStatusSelector } from "../../../selectors/paintingPreviews";
import { PAINTING_SHAPE } from "../../../constants/paintingConstants";
import Item from "../item";
import Empty from "../../ui/empty";

const masonryOptions = {
  transitionDuration: 0
};

const masonryImagesOptions = {}

class List extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { items, large, novelty, fetchStatus, source, onAppointment, onFavorite } = this.props;

    return (
      <>
        { isEmpty(items)
          ? <>
              {fetchStatus && fetchStatus.isFetching
                ? <Empty message={i18n.t("helpers.loading.title")} />
                : <Empty message={i18n.t("pages.paintings.empty.title")} />
              }
            </>
          : <Masonry
              className="b-paintings__row row"
              elementType={"div"}
              options={masonryOptions}
              imagesLoadedOptions={masonryImagesOptions}
              disableImagesLoaded={false}
              updateOnEachImageLoad={false}
            >
              {(items || []).map((item) =>
                <Item
                  key={item.id}
                  item={item}
                  large={large}
                  novelty={novelty}
                  source={source}
                  onAppointment={onAppointment}
                  onFavorite={onFavorite}
                />
              )}
            </Masonry>
        }
      </>
    )
  }
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(PAINTING_SHAPE)),
  onAppointment: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired,
};

const paintingsFetchStatusSelector = makePaintingPreviewsFetchStatusSelector();

function mapStateToProps(state) {
  return {
    fetchStatus: paintingsFetchStatusSelector(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
