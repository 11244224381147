import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { expositionSchema } from "../../../schemas";
import { EXPOSITION_ITEM } from "../../../constants/expositionConstants";

export default combineReducers({
  byId: makeByIdReducer(
    expositionSchema.key,
    [EXPOSITION_ITEM.SUCCESS],
    [EXPOSITION_ITEM.FAILED]
  ),
})
