import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../../translations/i18n";
import {
  USER_SESSIONS_SHAPE,
  USER_AUTH_SHAPE,
  USER_ROLE_OPTIONS
} from "../../../../../constants/userConstants";
import { PASSWORD_MIN_LENGTH } from "../../../../../config";

import FormGroup from "../../../../../components/ui/form/group";
import FormActions from "../../../../../components/ui/form/actions";
import FormError from "../../../../../components/ui/form/error";
import InputField from "../../../../../components/ui/form/input_field";
import RadioGroupField from "../../../../../components/ui/form/radio_group";
import CheckboxField from "../../../../../components/ui/form/checkbox_field";
import MaskInputField from "../../../../../components/ui/form/mask_input_field";
import Select from "../../../../../components/ui/form/select";
import Button from "../../../../../components/ui/form/button";
import {
  GA_REGISTRATION_FORM_SOURCE,
  TrackFieldError,
  TrackFieldValid,
  TrackRegistrationSubmitError,
} from "../../../../../helpers/analytics";
import emailDomainIsInvalid from "../../../../../helpers/invalidEmailDomains";
import phoneIsLandline from "../../../../../helpers/landlinePhoneCodes";

class RegistrationForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth, setSubmitting } = this.props;

    if (prevProps.auth !== auth && auth.error) {
      setSubmitting(false);
    }
  }

  handleChangeRole = (option) => {
    const { setFieldValue } = this.props;
    setFieldValue('role', option.value);
  }

  render() {
    const {
      auth,
      values,
      errors,
      userLoggedIn,
      touched,
      isSubmitting,
      handleSubmit,
    } = this.props;

    const isStepVisible = !isEmpty(values) &&
      !isEmpty(values.email) &&
      !isEmpty(values.phone) &&
      !isEmpty(values.password) &&
      !isEmpty(values.name) &&
      isEmpty(errors)

    return (
      <Form>
        <div className="modal__form-fields-group">
          <FormGroup
            label={i18n.t("pages.registration.form.email.label")}
            touched={touched.email || !isEmpty(values.email)}
          >
            <Field
              component={InputField}
              name="email"
              required
            />
          </FormGroup>
          <FormGroup
            label={i18n.t("pages.registration.form.phone.label")}
            touched={touched.phone || !isEmpty(values.phone)}
          >
            <Field
              component={MaskInputField}
              mask="+7 (999) 999-99-99"
              name="phone"
              required
            />
          </FormGroup>
          <FormGroup
            label={i18n.t("pages.registration.form.password.label")}
            touched={touched.password || !isEmpty(values.password)}
          >
            <Field
              component={InputField}
              name="password"
              type="password"
              required
            />
          </FormGroup>
          <FormGroup
            label={i18n.t("pages.registration.form.name.label")}
            touched={touched.name || !isEmpty(values.name)}
          >
            <Field
              component={InputField}
              name="name"
              required
            />
          </FormGroup>
        </div>
        { isStepVisible &&
          <div className="modal__form-fields-group fade-in">
            <div className="modal__title">
              {i18n.t('pages.registration.form.role.title')}
            </div>
            <Field
              name="role"
              component={RadioGroupField}
              options={USER_ROLE_OPTIONS}
              disabled={isSubmitting}
            />
            <div className="modal__select">
              <Select
                name="role"
                defaultValue={USER_ROLE_OPTIONS[0]}
                options={USER_ROLE_OPTIONS}
                onChange={this.handleChangeRole}
              />
            </div>
            <div className="modal__subscription-checkbox">
              <Field name="subscribe_to_news" component={CheckboxField}>
                {i18n.t('pages.registration.form.subscribe.label')}
              </Field>
            </div>
          </div>
        }
        {!userLoggedIn &&
          <FormActions className="modal__form-button-wrapper">
            <FormError>
              {auth.error}
            </FormError>
            <Button
              className="modal__form-button"
              type="submit"
              disabled={isSubmitting}
              onSubmit={handleSubmit}
            >
              {i18n.t("pages.registration.form.submit.title")}
            </Button>
          </FormActions>
        }
      </Form>
    )
  }
}

const RegistrationFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};

    if (isEmpty(values.email)) {
      TrackFieldError('email', 'empty', GA_REGISTRATION_FORM_SOURCE);

      errors.email = i18n.t("validation.field.required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
      TrackFieldError('email', 'invalid', GA_REGISTRATION_FORM_SOURCE);

      errors.email = i18n.t("validation.email.invalid");
    } else if (emailDomainIsInvalid(values.email)) {
      TrackFieldError('email', 'invalid', GA_REGISTRATION_FORM_SOURCE);

      errors.email = i18n.t("validation.email.invalid");
    } else {
      TrackFieldValid('email', GA_REGISTRATION_FORM_SOURCE);
    }

    if (isEmpty(values.phone)) {
      TrackFieldError('phone', 'empty', GA_REGISTRATION_FORM_SOURCE);

      errors.phone = i18n.t("validation.field.required");
    } else if (!/^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/i.test(values.phone)) {
      TrackFieldError('phone', 'invalid', GA_REGISTRATION_FORM_SOURCE);

      errors.phone = i18n.t("validation.phone.invalid");
    } else if (phoneIsLandline(values.phone)) {
      TrackFieldError('phone', 'invalid', GA_REGISTRATION_FORM_SOURCE);

      errors.phone = i18n.t("validation.phone.landline");
    } else {
      TrackFieldValid('phone', GA_REGISTRATION_FORM_SOURCE);
    }

    if (isEmpty(values.password)) {
      TrackFieldError('password', 'empty', GA_REGISTRATION_FORM_SOURCE);

      errors.password = i18n.t("validation.field.required");
    } else if (values.password.length < PASSWORD_MIN_LENGTH) {
      TrackFieldError('password', 'invalid', GA_REGISTRATION_FORM_SOURCE);

      errors.password = i18n.t("validation.password.toShort", {length: PASSWORD_MIN_LENGTH});
    } else {
      TrackFieldValid('password', GA_REGISTRATION_FORM_SOURCE);
    }

    if (isEmpty(values.name)) {
      TrackFieldError('name', 'empty', GA_REGISTRATION_FORM_SOURCE);

      errors.name = i18n.t("validation.field.required");
    } else {
      TrackFieldValid('password', GA_REGISTRATION_FORM_SOURCE);
    }

    if (!isEmpty(errors)) {
      TrackRegistrationSubmitError();
    }

    return errors;
  },
})(RegistrationForm);

RegistrationFormWithFormik.propTypes = {
  initialValues: PropTypes.shape(USER_SESSIONS_SHAPE).isRequired,
  auth: PropTypes.shape(USER_AUTH_SHAPE).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RegistrationFormWithFormik
