import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { fetchItem } from "../../actions/serviceActions";
import { getService } from "../../selectors/services";
import { SERVICE_SHAPE } from "../../constants/serviceConstants";
import LoadableEntity from "../../components/ui/loadable/entity";
import NotFound from "../../screens/static/not_found";


export function withService(WrappedComponent) {

    const WithService = class extends Component {

      componentDidMount() {
        this.fetchService();
      }

      fetchService = () => {
        const { match } = this.props;
        const serviceId = match.params["id"];

        this.props.fetchItem(serviceId);
      };

      render() {
        const { service, fetchStatus, ...restProps } = this.props;

        return (
          <LoadableEntity
            entity={service}
            renderError={() => <NotFound/>}
            // renderLoading={() => <LoadingPage/>}
          >
            {
              (service) =>
                <WrappedComponent
                  {...restProps}
                  service={service}
                  fetchService={this.fetchService}
                />
            }
          </LoadableEntity>
        )
      }
    };

    WithService.propTypes = {
      service: PropTypes.shape(SERVICE_SHAPE),
    };

    function mapStateToProps(state, {match}) {
      const serviceId = parseInt(match.params["id"]);

      return {
        service: getService(state, serviceId),
      }
    }

    function mapDispatchToProps(dispatch) {
      return bindActionCreators({
        fetchItem,
      }, dispatch)
    }

    return compose(
      withRouter,
      connect(mapStateToProps, mapDispatchToProps)
    )(WithService)
  }
