import React from "react";
import PropTypes from "prop-types";


class Item extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { value, max, title, tooltip } = this.props;

    return (
      <>
        { value &&
          <div className="p-painter__info-statistics-item">
            <div className="p-painter__info-statistics-figures">
              <span>{value}</span>
              <span>/{max}</span>
            </div>
            <div className="p-painter__info-statistics-title">
              <div
                className="p-painter__info-statistics-title-text"
                dangerouslySetInnerHTML={{__html: title}}
              />
              <div
                className="p-painter__info-statistics-tooltip tooltip-top tooltip-mobile tooltip-mobile-left"
                data-tooltip={tooltip}
              />
            </div>
          </div>
        }
      </>
    )
  }
}

Item.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default Item