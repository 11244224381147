import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import { SERVICE_SHAPE } from "../../../constants/serviceConstants";
import { GA_ORDERS_CONSULTING_SOURCE } from "../../../helpers/analytics";

import Order from "../../about/order";


class Content extends Component {

  constructor(props) {
    super(props);
  }

  renderNode(node, index) {
    if (node.type === 'tag' && node.name === 'figure') {
      const attachment = JSON.parse(node.attribs['data-trix-attachment']);
      const attributes = JSON.parse(node.attribs['data-trix-attributes']);

      return (
        <figure key={index} className={node.attribs.class}>
          {attachment && attachment.url &&
            <img src={attachment.url} />
          }
          {attributes && attributes.caption &&
            <figcaption className="attachment__caption">
              {attributes.caption}
            </figcaption>
          }
        </figure>
      )
    }
  }

  render () {
    const { item, services } = this.props;
    const formInsertIndex = item.main ? item.main.indexOf("{form}") : -1;
    const formInsertIndexEnd = formInsertIndex + "{form}".length;

    return (
      <div className="p-youtube__list">
        <div className="container">
          {item.lead &&
            <p
              className="p-youtube__list-description"
              dangerouslySetInnerHTML={{__html: item.lead}}
            />
          }
          { (formInsertIndex < 0) && (
            item.main &&
              <>
                <div className="p-youtube__list-item">
                  {ReactHtmlParser(item.main, {
                      transform: (node, index) => this.renderNode(node, index)
                    }
                  )}
                </div>
                <Order services={services} source={GA_ORDERS_CONSULTING_SOURCE} serviceName={item.slug}/>
              </>
          ) || (
            <>
              <div className="p-youtube__list-item">
                {ReactHtmlParser(item.main.substr(0, formInsertIndex), {
                    transform: (node, index) => this.renderNode(node, index)
                  }
                )}
              </div>
              <div className="p-service__form-insert">
                <Order services={services} source={GA_ORDERS_CONSULTING_SOURCE} serviceName={item.slug}/>
              </div>
              <div className="p-youtube__list-item">
                {ReactHtmlParser(item.main.substr(formInsertIndexEnd), {
                    transform: (node, index) => this.renderNode(node, index)
                  }
                )}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

Content.propTypes = {
  item: PropTypes.shape(SERVICE_SHAPE).isRequired
};

export default Content
