import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import {
  closeModal
} from "../../../../actions/modalActions";
import {
  disableAppointment
} from "../../../../actions/appointmentActions";
import { REGISTRATION_MODAL } from "../../../../constants/modalConstants";
import BaseModal from "../../../ui/modal/base";
import Registration from "../../auth/registration";
import { TrackRegistrationCloseClick } from "../../../../helpers/analytics";


class RegistrationModal extends React.Component {

  constructor(props) {
    super(props);
  }

  handleClose = (event) => {
    const { closeModal, disableAppointment } = this.props;
    event.preventDefault();

    TrackRegistrationCloseClick()

    closeModal();
    disableAppointment();
  }

  render () {
    const { modal } = this.props;

    return (
      <BaseModal
        className="modal modal-registration"
        isOpen={modal.name === REGISTRATION_MODAL}
        onClose={this.handleClose}
      >
        <Registration source={modal.source}/>
      </BaseModal>
    );
  }
}

RegistrationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {

  return {
    modal: layout.modal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    disableAppointment,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(RegistrationModal)
