import React from "react";
import { getIn } from "formik";
import cn from "classnames";

import RadioButton from "../radio";


export default function RadioGroupField(
  {
    field,
    form: {touched, errors, submitCount, setFieldValue},
    options,
    initialValue,
    onChange,
    ...restProps
  }
) {
  const error = getIn(errors, field.name);
  const isTouched = getIn(touched, field.name);
  const hasError = (isTouched || submitCount > 0) && !!error;
  const className = cn("radio-group", {"is-invalid": hasError});

  const handleChange = (event) => {
    const newValue = event.target.value;
    setFieldValue(field.name, newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className="radio-group__wrapper">
      <div className={className}>
        {
          options.map(({label, value}) =>
            <RadioButton
              {...field}
              {...restProps}
              key={value}
              value={value}
              name={field.name}
              checked={value === field.value}
              onChange={handleChange}
            >
              {label}
            </RadioButton>
          )
        }
      </div>
      {error && <span className="form__field-error__message">{error}</span>}
    </div>

  )
}