import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import { PAINTING_SHAPE } from "../../../../constants/paintingConstants";
import Item from "../../../../components/paintings/item";
import Empty from "../../../../components/ui/empty";
import { GA_APPOINTMENT_NOVELTIES_SOURCE } from "../../../../helpers/analytics";


class List extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { items, large, novelty, onAppointment, onFavorite } = this.props;

    return (
      <>
        { isEmpty(items)
          ? <Empty message={i18n.t("pages.paintings.empty.title")} />
          : <div className="b-paintings__row row">
              {(items || []).map((item) =>
                <Item
                  key={item.id}
                  item={item}
                  large={large}
                  novelty={novelty}
                  source={GA_APPOINTMENT_NOVELTIES_SOURCE}
                  onAppointment={onAppointment}
                  onFavorite={onFavorite}
                />
              )}
            </div>
        }
      </>
    )
  }
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(PAINTING_SHAPE)),
  onAppointment: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired,
};

export default List
