import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


function FormGroup({ children, label, name, hint, className, touched }) {
  const childName = (React.Children.toArray(children).find((c) => !!c.props.name) || {props: {}}).props.name;
  const labelFor = name || childName;
  const groupClassName = classNames(className, "form__group", {'focused': touched})

  return (
    <div className={groupClassName}>
      <label className="form__label" htmlFor={labelFor}>
        { typeof label === "function" ? label() : label }
      </label>
      {
        children ? <>{ children }</> : null
      }
      { hint &&
        <div className="form__group-help">{hint}</div>
      }
    </div>
  )
}

FormGroup.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  name: PropTypes.string,
  hint: PropTypes.string,
  touched: PropTypes.bool,
};

export default FormGroup;