const invalidEmailDomains = [
  "@yndex.ru",
  "@yanbex.ru",
  "@uandex.ru",
  "@yandex.ru.ru",
  "@yadex.ru",
  "@eandex.ru",
  "@iandex.ru",
  "@jandex.ru",
  "@yandekx.ru",
  "@yahdex.ru",
  "@yqndex.ru",
  "@maii.ru",
  "@mael.ru",
  "@mail.ru.ru",
  "@meil.ru",
  "@maile.ru",
  "@maiil.ru",
  "@mial.ru",
  "@vail.ru",
  "@indox.ru",
  "@invox.ru",
  "@ibnox.ru",
  "@gmail.ru",
  "@gvail.com",
  "@gail.com",
  "@gmal.com",
  "@qmail.com",
  "@bmail.com",
  "@hmail.com",
  "@gmeil.com",
  "@gmal.ru",
  "@gmal.com",
  "@jmail.com",
  "@ramler.ru",
  "@bambler.ru",
  "@ramrler.ru",
  "@rambker.ru",
  "@ramdler.ru",
  "@gambler.ru",
  "@rfmbler.ru",
  ".ry",
  ".ri",
  ".con",
  ".co"
]

const emailDomainIsInvalid = (email) => {
  let value = false

  value = invalidEmailDomains.some(domain => {
    return email.endsWith(domain)
  });

  return value
}

export default emailDomainIsInvalid
