import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import i18n from "../../../translations/i18n";
import { withHeader } from "../../../hocs/layout/withHeader";
import { withFooter } from "../../../hocs/layout/withFooter";
import { withBodyClass } from "../../../hocs/layout/withBodyClass";
import { withAccount } from "../../../hocs/entities/withAccount";
import { withNotice } from "../../../hocs/layout/withNotice";
import { updateItem } from "../../../actions/userActions";
import Meta from "../../../components/layout/meta";
import Menu from "../../../components/account/menu";
import Form from "./form";


class ChangeEmail extends React.Component {

  constructor(props) {
    super(props);
  }

  handleSubmit = (values) => {
    const { fetchStatus, updateItem } = this.props;

    if (!fetchStatus.isFetching) {
      updateItem(values);
    }
  }

  render () {
    const { account, fetchStatus } = this.props;

    return (
      <main className="layout">
        <Meta />
        <div className="p-settings">
          <div className="container">
            <div className="p-settings__title">
              <span>{i18n.t('pages.account.settings.title')}</span>
            </div>
            <div className="p-settings__content">
              <Menu />
              <div className="p-settings__section p-settings__email">
                <Form
                  initialValues={{email: account.unconfirmed_email || account.email}}
                  account={account}
                  fetchStatus={fetchStatus}
                  onSubmit={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

ChangeEmail.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateItem
  }, dispatch)
}

export default compose(
  withAccount,
  withNotice(),
  withBodyClass('app-account'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(ChangeEmail)
