import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { fetchItem } from "../../actions/postActions";
import { getPost } from "../../selectors/posts";
import { POST_SHAPE } from "../../constants/postConstants";
import LoadableEntity from "../../components/ui/loadable/entity";
import NotFound from "../../screens/static/not_found";


export function withPost(WrappedComponent) {

    const WithPost = class extends Component {

      componentDidMount() {
        this.fetchPost();
      }

      fetchPost = () => {
        const { match } = this.props;
        const postId = match.params["id"];

        this.props.fetchItem(postId);
      };

      render() {
        const { post, fetchStatus, ...restProps } = this.props;

        return (
          <LoadableEntity
            entity={post}
            renderError={() => <NotFound/>}
            // renderLoading={() => <LoadingPage/>}
          >
            {
              (post) =>
                <WrappedComponent
                  {...restProps}
                  post={post}
                  fetchPost={this.fetchPost}
                />
            }
          </LoadableEntity>
        )
      }
    };

    WithPost.propTypes = {
      post: PropTypes.shape(POST_SHAPE),
    };

    function mapStateToProps(state, {match}) {
      const postId = parseInt(match.params["id"]);

      return {
        post: getPost(state, postId),
      }
    }

    function mapDispatchToProps(dispatch) {
      return bindActionCreators({
        fetchItem,
      }, dispatch)
    }

    return compose(
      withRouter,
      connect(mapStateToProps, mapDispatchToProps)
    )(WithPost)
  }
