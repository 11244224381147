import React from "react";
import { Route } from "react-router";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import i18n from "../../../translations/i18n";
import { withHeader } from "../../../hocs/layout/withHeader";
import { withFooter } from "../../../hocs/layout/withFooter";
import ErrorImageArtists from "../../../../../images/error/artists.jpg"
import ErrorImagePictures from "../../../../../images/error/paintings.jpg"
import ErrorImagePosts from "../../../../../images/error/posts.jpg"
import Meta from "../../../components/layout/meta";
import Link from "../../../components/ui/link";



function Status({ code, children }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
}

class NotFound extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { location } = this.props;
    const isNotSearch = location.pathname !== "/search";

    return (
      <Status code={404}>
        {isNotSearch &&
          <main className="layout">
            <Meta />
            <div className="p-error">
              <div className="p-error__content">
                <div className="container">
                  <div className="p-error__number">{i18n.t('pages.not_found.header.code')}</div>
                  <div className="p-error__title">{i18n.t('pages.not_found.header.title')}</div>
                  <div className="p-error__description">{i18n.t('pages.not_found.header.description')}</div>
                  <div className="button__wrapper p-error__button-wrapper">
                    <Link to="/" className="button p-error__button">
                      {i18n.t('pages.not_found.button.title')}
                    </Link>
                  </div>
                  <div className="p-error__pages">
                    <div className="p-error__pages-item">
                      <Link to="/artists" className="p-error__pages-link">
                        <img alt="" className="p-error__pages-image" src={ErrorImageArtists} />
                        <div className="p-error__pages-title" dangerouslySetInnerHTML={{__html: i18n.t('pages.not_found.artists.title')}} />
                      </Link>
                    </div>
                    <div className="p-error__pages-item">
                      <Link to="/paintings" className="p-error__pages-link">
                        <img alt="" className="p-error__pages-image" src={ErrorImagePictures} />
                        <div className="p-error__pages-title" dangerouslySetInnerHTML={{__html: i18n.t('pages.not_found.paintings.title')}} />
                      </Link>
                    </div>
                    <div className="p-error__pages-item">
                      <Link to="/posts" className="p-error__pages-link">
                        <img alt="" className="p-error__pages-image" src={ErrorImagePosts} />
                        <div className="p-error__pages-title" dangerouslySetInnerHTML={{__html: i18n.t('pages.not_found.posts.title')}} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        }
      </Status>
    );
  }
}

NotFound.propTypes = {
};

export default compose(
  withRouter,
  withHeader({transparent: false}),
  withFooter(),
)(NotFound)
