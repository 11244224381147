import { GA_TRACKING_ENABLED } from "../../../config";

const GA_SUBSCRIBE_HOME_SOURCE = "subscription form_home page";
const GA_SUBSCRIBE_POSTS_SOURCE = "subscription form_posts page";

export function TrackSubscribeClick(source) {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "subscribe button",
    "click",
    "translation missing: ru.analytics.subscriptions.user.click.label",
    source == 'posts' ? GA_SUBSCRIBE_POSTS_SOURCE : GA_SUBSCRIBE_HOME_SOURCE
  )
}

export function TrackSubscribeSubmitError(source) {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "subscribe button",
    "user_failure",
    source == 'posts' ? GA_SUBSCRIBE_POSTS_SOURCE : GA_SUBSCRIBE_HOME_SOURCE
  )
}

export function TrackSubscribeSubmitSuccess(source) {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "subscribe button",
    "success",
    source == 'posts' ? GA_SUBSCRIBE_POSTS_SOURCE : GA_SUBSCRIBE_HOME_SOURCE
  )
}
