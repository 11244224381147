import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../../../translations/i18n";
import { PAINTING_SHAPE } from "../../../../../constants/paintingConstants";
import Poster from "../../../../../components/painting/poster";
import Title from "../../../../../components/painting/title";
import Ended from "../../../../../components/painting/ended";
import Dimensions from "../../../../../components/painting/dimensions";
import Favorite from "../../../../../components/layout/favorite";
import Appointment from "../../../../../components/layout/appointment";


class Slide extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item, onFavorite, onAppointment } = this.props;
    const paintingPath = `/paintings/${item.slug}`

    return (
      <div className="b-paintings__item">
        <div className="b-paintings__card">
          <a
            href={paintingPath}
            className="b-paintings__card-image-link"
          >
            <Poster poster={item.poster} className="b-paintings__card-image" />
          </a>
          <div className="b-paintings__card-content">
            <a
              href={paintingPath}
              className="b-paintings__card-name"
            >
              <Title painting={item} splited />
            </a>
            <div className="b-paintings__card-date">
              <Ended title={item.ended_at} />
              <Dimensions height={item.height} width={item.width} />
            </div>
            <div className="b-paintings__card-media">
              <div className="b-paintings__card-media-signup-wrapper">
                <Appointment
                  className="b-paintings__card-media-signup"
                  available={item.available}
                  appointment={item.appointment}
                  onAppointment={onAppointment}
                />
              </div>
              <div className="b-paintings__card-media-favorite">
                <Favorite
                  favorite={item.favorite}
                  isShort={true}
                  onFavorite={onFavorite}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Slide.propTypes = {
  item: PropTypes.shape(PAINTING_SHAPE),
  onFavorite: PropTypes.func.isRequired,
};

export default Slide
