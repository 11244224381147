import React, { Component } from "react";
import Header from "../../components/layout/header";


export function withHeader({transparent}) {
  return function(WrappedComponent) {

    const WithHeader = class extends Component {

      render() {
        const { ...restProps } = this.props;

        return (
          <>
            <Header transparent={transparent} />
            <WrappedComponent
              {...restProps}
            />
          </>
        )
      }
    };

    return WithHeader;
  }
}