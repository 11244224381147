import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as QueryString from "query-string";
import PropTypes from "prop-types";

import { withPaintings } from "../../hocs/entities/withPaintings";
import { withArtistOptions } from "../../hocs/entities/withArtistOptions";
import { withPropertyOptions } from "../../hocs/entities/withPropertyOptions";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import { filterBy } from "../../actions/paintingActions";
import { PAINTING_SHAPE } from "../../constants/paintingConstants";
import List from "../../components/paintings/list";
import Pagination from "../../components/layout/pagination";
import Filter from "./filter";
import Meta from "./meta";
import { GA_APPOINTMENT_PAINTINGS_SOURCE } from "../../helpers/analytics";


class Paintings extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { artistOptions, selectedArtists, propertyOptions, selectedProperties, location, filterBy } = this.props;

    // load from params /paintings?artists[]=3&artists[]=4&artists[]=5
    const params = QueryString.parse(location.search, {arrayFormat: 'bracket'});
    if (params) {
      let artistsFromParams = artistOptions.filter((option) => (params.artists || []).includes(option.id.toString()));
      let propertiesFromParams = propertyOptions.filter((option) => (params.properties || []).includes(option.id.toString()));

      filterBy(artistsFromParams, propertiesFromParams);
    } else if (selectedArtists && selectedProperties) {
      // load from store entities.paintingPreviews.filter.artists
      filterBy(selectedArtists, selectedProperties);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedArtists, pagination } = this.props;

    if (prevProps.selectedArtists != selectedArtists && selectedArtists) {
      this.fetchCollection(pagination.current_page);
    }
  }

  fetchCollection = (page = 1) => {
    const { selectedArtists, selectedProperties, novelties, fetchPaintings, century } = this.props;

    const artists = QueryString.stringify({
      artists: selectedArtists.map((s) => s.id)
    }, {arrayFormat: 'bracket'});

    const properties = QueryString.stringify({
      properties: selectedProperties.map((s) => s.id)
    }, {arrayFormat: 'bracket'});

    const novelty = QueryString.stringify({
      novelties: novelties
    });

    fetchPaintings(artists, properties, novelty, century, page);
  }

  handlePageChange = (page = 1) => {
    if (event) {
      event.preventDefault();
    }

    const { location } = this.props;
    const params = QueryString.parse(location.search, {arrayFormat: 'bracket'});

    if (params && params.page && params.page != page) {
      this.fetchCollection(params.page);
    } else {
      this.fetchCollection(page);
    }
  }

  handleAppointment = () => {
    const { pagination } = this.props;
    this.fetchCollection(pagination.current_page);
  }

  handleFavorite = () => {
    const { pagination } = this.props;
    this.fetchCollection(pagination.current_page);
  }

  render() {
    const {
      paintings,
      pagination,
      artistOptions,
      selectedArtists,
      propertyOptions,
      selectedProperties,
      novelties,
      fetchStatus
    } = this.props;

    return (
      <main className="layout">
        <Meta
          items={paintings}
        />
        <div className="p-paintings">
          <Filter
            artistOptions={artistOptions}
            selectedArtists={selectedArtists}
            propertyOptions={propertyOptions}
            selectedProperties={selectedProperties}
            novelties={novelties}
            total={pagination.total_count}
            onFilter={this.handlePageChange}
          />
          <div className="b-paintings">
            <div className="container">
              <div className="b-paintings__content" id="paintings">
                <List
                  novelty
                  items={paintings}
                  source={GA_APPOINTMENT_PAINTINGS_SOURCE}
                  onAppointment={this.handleAppointment}
                  onFavorite={this.handleFavorite}
                />
              </div>
              <Pagination
                totalPages={pagination.total_pages || 1}
                disabled={fetchStatus.isFetching}
                onChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      </main>
    );
  }
}

Paintings.propTypes = {
  paintings: PropTypes.arrayOf(PropTypes.shape(PAINTING_SHAPE)),
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    filterBy,
  }, dispatch)
}

export default compose(
  withRouter,
  withArtistOptions,
  withPropertyOptions,
  withPaintings, // should be the last in list
  withBodyClass('app-paintings'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Paintings)
