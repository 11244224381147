import React from "react";
import PropTypes from "prop-types";

import { POST_SHAPE } from "../../../../constants/postConstants";


class Slide extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item } = this.props;
    const postPath = `/posts/${item.slug}`

    return (
      <div className="b-journal-carousel__item">
        <a
          href={postPath}
          title={item.poster.name}
          className="b-journal-carousel__card"
        >
          <div className="b-journal-carousel__card-link">
            <img
              src={item.poster.medium}
              alt={item.poster.name}
              title={item.poster.name}
              className="b-journal-carousel__card-image"
            />
          </div>
          <div className="b-journal-carousel__card-content">
            <div className="b-journal-carousel__card-date">
              {item.date}
            </div>
            <h2 className="b-journal-carousel__card-title">
              {item.name_without_word_breaks}
            </h2>
            <p
              className="b-journal-carousel__card-description"
              dangerouslySetInnerHTML={{__html: item.annotation}}
            />
          </div>
        </a>
      </div>
    );
  }
}

Slide.propTypes = {
  item: PropTypes.shape(POST_SHAPE),
};

export default Slide
