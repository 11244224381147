import React, { Component } from "react";
import PropTypes from "prop-types";

import i18n from "../../../../translations/i18n";
import { SEARCHABLE_PAINTING_SHAPE } from "../../../../constants/searchConstants";
import Ended from "../../../painting/ended";
import Dimensions from "../../../painting/dimensions";
import Image from "../image";
import Link from "../../../../components/ui/link";


class Painting extends Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item } = this.props;

    return (
      <Link
        key={item.id}
        to={item.path}
        className="p-search__results-list-item"
      >
        <div className="p-search__results-list-item-info">
          <div className="p-search__results-list-item-info-description">
            <ul>
              <li>
                <strong>
                  {i18n.t(`pages.search.results.${item.kind}.title`)}
                </strong>
              </li>
              <li>
                <Ended title={item.ended_at} />
              </li>
              <li>
                <Dimensions height={item.height} width={item.width} />
              </li>
            </ul>
          </div>
          <div className="p-search__results-list-item-info-name">
            {item.name}
          </div>
        </div>
        <Image item={item} />
      </Link>
    );
  }
}

Painting.propTypes = {
  item: PropTypes.shape(SEARCHABLE_PAINTING_SHAPE).isRequired,
};

export default Painting;