import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";


class Link extends React.Component {

  constructor(props) {
    super(props);
  }

  className = () => {
    const { to, match, className, activeClassName } = this.props;

    if (to === match.path) {
      return `${className} ${activeClassName}`;
    } else {
      return `${className}`;
    }
  }

  render () {
    const { to, title, onClick, children } = this.props;

    return (
      <a
        href={to}
        title={title}
        className={this.className()}
        onClick={onClick}
      >
        {children}
      </a>
    )
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func
};

export default compose(withRouter)(Link)
