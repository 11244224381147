import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import cn from "classnames";

import i18n from "../../../translations/i18n";
import { withHeader } from "../../../hocs/layout/withHeader";
import {
    restorePassword,
    resetAuthForm as reset
} from "../../../actions/userActions";
import RestorePasswordForm from "./form";
import Meta from "../../../components/layout/meta";
import Link from "../../../components/ui/link";


class RestorePassword extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.reset();
    }

    handleSubmit = (params) => {
        this.props.restorePassword(params);
    }

    render() {
        const { auth } = this.props;

        return (
            <main className="layout auth">
                <Meta />
                <div className={cn('modal', { 'modal-message': !auth.passwordSended, 'modal-password': auth.passwordSended })}>
                    <div className="modal__container">
                        <div className="modal__inner">
                            <Link to="/" className="modal__close">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L15.8492 15.8492" stroke="black" strokeWidth="2"/>
                                    <path d="M16 1L1.15076 15.8492" stroke="black" strokeWidth="2"/>
                                </svg>
                            </Link>
                            <div className="modal__content">
                                {auth.passwordSended
                                    ? <>
                                        <div className="modal__title">
                                            {i18n.t('pages.restore_password.sended.title')}
                                        </div>
                                        <div className="modal__form">
                                            <div className="modal__form-button-wrapper">
                                                <Link to="/" className="button modal__form-button-ok">
                                                    {i18n.t('pages.restore_password.sended.button')}
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                    : <>
                                        <div className="modal__title">
                                            {i18n.t('pages.restore_password.title')}
                                        </div>
                                        <div className="modal__form">
                                            <RestorePasswordForm
                                                initialValues={{ email: '' }}
                                                auth={auth}
                                                onSubmit={this.handleSubmit}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

RestorePassword.propTypes = {
    restorePassword: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
};

function mapStateToProps({ auth }) {
    return {
        auth,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        restorePassword,
        reset
    }, dispatch)
}

export default compose(
    withHeader({ transparent: true }),
    connect(mapStateToProps, mapDispatchToProps)
)(RestorePassword)
