import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";
import isNil from "lodash/isNil";

import { API, EXPOSITION_ORDERS_PER_PAGE } from "../config";
import {
  EXPOSITION_ORDER_PREVIEWS,
  EXPOSITION_ORDER_CREATE,
  EXPOSITION_ORDER_ITEM_REFUND,
} from "../constants/expositionOrderConstants";
import { EXPOSITION_ORDER_ITEM_SUCESS_MODAL } from "../constants/modalConstants";
import { openModal } from "./modalActions";
import { store } from "../store";
import { expositionOrderPreviewSchema, expositionOrderSchema } from "../schemas";
import { DEFAULT_COLLECTION } from "../selectors/expositionOrderPreviews";
import { FUNC_REPLACE, FUNC_APPEND } from "../reducers/entities/collectionsReducer";


export function fetchItems(page = 1, perPage = EXPOSITION_ORDERS_PER_PAGE, fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: page === 1 ? FUNC_REPLACE : FUNC_APPEND,
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/exposition_orders?page=${page}&per_page=${perPage}`,
        types: EXPOSITION_ORDER_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { exposition_orders, meta } = await res.json();

      return {
        ...normalize(exposition_orders, [expositionOrderPreviewSchema]),
        pagination: meta,
      };
    }
  };
}

export function createItem(expositionOrderItems = []) {
  return function(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/exposition_orders`,
        method: 'POST',
        body: {
          exposition_order: {
            exposition_order_items_attributes: expositionOrderItems
          }
        },
        types: EXPOSITION_ORDER_CREATE.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      const { exposition_order } = await res.json();

      return {
        ...normalize(exposition_order, expositionOrderSchema),
        layout: {
          created: true,
          id: exposition_order.id,
          total: exposition_order.total
        },
      }
    }
  }
}


export function refundItem(id, fetchOptions = {}) {
  const meta = {
    key: id,
  };
  const include = fetchOptions.include || {};

  return function fetchItemThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/exposition_order_items/${id}/refund`,
        method: 'PATCH',
        types: EXPOSITION_ORDER_ITEM_REFUND.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      dispatch(openExpositionOrderItemSuccessModal());
      dispatch(fetchItems());

      return {};
    }
  }
}


export function openExpositionOrderItemSuccessModal() {
  const modal = store.getState().layout.modal

  return function openExpositionOrderItemSuccessModalThunk(dispatch) {
    if (modal && !isNil(modal.orderItem)) {
      dispatch(openModal({
        name: EXPOSITION_ORDER_ITEM_SUCESS_MODAL,
        orderItem: modal.orderItem
      }));
    }
  }
}
