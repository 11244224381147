import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { withArtists } from "../../../hocs/entities/withArtists";
import { HOME_PAGE_ARTISTS_LIMIT } from "../../../config";
import { ARTIST_SHAPE } from "../../../constants/aritstConstants";
import List from "../../../components/artists/list";
import Link from "../../../components/ui/link";


class Artists extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchArtists();
  }

  handleFavorite = () => {
    this.props.fetchArtists();
  }

  render () {
    const { artists } = this.props;

    return (
      <>
        {!isEmpty(artists) &&
          <div className="b-painters">
            <div className="section-title">
              {i18n.t("pages.home.artists.title")}
            </div>
            <div className="container">
              <div className="b-painters__content">
                <List
                  items={artists.slice(0, HOME_PAGE_ARTISTS_LIMIT)}
                  onFavorite={this.handleFavorite}
                />
              </div>
              <div className="button__wrapper b-painters__button-wrapper">
                <Link
                  to="/artists"
                  className="button button-more"
                  title={i18n.t("pages.home.artists.more.title")}
                >
                  {i18n.t("pages.home.artists.more.title")}
                </Link>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

Artists.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.shape(ARTIST_SHAPE)),
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withArtists,
  connect(mapStateToProps, mapDispatchToProps)
)(Artists)