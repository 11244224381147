import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";

import i18n from "../../translations/i18n";
import { withExposition } from "../../hocs/entities/withExposition";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import ScrollHandler from "../../components/layout/scroll"
import Share from "../../components/exposition/share";
import Schedule from "../../components/exposition/schedule";
import Prices from "../../components/exposition/prices";
import Meta from "./meta";
import Content from "./content";
import AgeRestriction from "../../components/expositions/age_restriction";
import Posts from "../../components/posts/list";
import Subscription from "../../components/layout/subscription";
import { EXPOSITION_PAGE_POSTS_LIMIT } from "../../config";
import BuyTicketButtons from "./description/buyTicketButtons";


class Exposition extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { exposition, fetchExposition } = this.props;

    return (
      <main className="layout">
        <Meta />
        <ScrollHandler />
        <div className={cn('p-exhibition', { '__inactive': exposition.past })}>
          <div className="container">
            <div className="p-exhibitions__inner">
              {exposition.poster &&
                <div className="p-exhibition__picture" style={{ backgroundImage: `url(${exposition.poster.main})` }} />
              }
              <div className="p-exhibition__info">
                <div className="p-exhibition__info-head">
                  <div className="p-exhibition__info-date">
                    {exposition.period}
                  </div>
                  <div className="p-exhibition__info-share p-exhibition__info-share-1">
                    <div className="p-exhibition__info-share-name">
                      {i18n.t('helpers.share.title')}
                    </div>
                    <Share
                      title={exposition.name}
                      slug={exposition.slug}
                    />
                  </div>
                </div>
                <div className="p-exhibition__info-option">
                  {i18n.t('pages.exposition.kind.title')}
                </div>
                <div className="p-exhibition__info-title">
                  {exposition.name}
                </div>
                <div className="p-exhibition__info-contacts">
                  {!exposition.past &&
                    <>
                      <div className="p-exhibitions__list-item-contacts">
                        <div className="p-exhibition__info-contacts-age-wrapper">
                          <AgeRestriction exposition={exposition} classname="p-exhibition__info-contacts-age" />
                        </div>
                        <Schedule schedule={exposition.schedule} />
                        {exposition.place &&
                          <>
                            {exposition.place.address}, &nbsp;{exposition.place.name}
                          </>
                        }
                        <AgeRestriction
                          exposition={exposition}
                          classname="p-exhibition__info-contacts-age desktop-hidden tablet-hidden"
                        />
                      </div>
                      <Prices
                        exposition={exposition}
                        items={exposition.prices}
                      />
                    </>
                  }
                  {exposition.past &&
                    <div className="p-exhibition__end">
                      {i18n.t('pages.exposition.past.title')}
                    </div>
                  }
                </div>
                <BuyTicketButtons exposition={exposition} />
              </div>
              <Content
                item={exposition}
                fetchExposition={fetchExposition}
              />
            </div>
          </div>
        </div>
        <Posts withLink={true} postsLimit={EXPOSITION_PAGE_POSTS_LIMIT} />
        <Subscription source="expositions" />
      </main>
    );
  }
}

Exposition.propTypes = {
  fetchExposition: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withExposition,
  withBodyClass('app-exposition'),
  withHeader({ transparent: false }),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Exposition)
