import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import i18n from "../../../../translations/i18n";
import Slide from "./slide";
import { BANNER_SHAPE } from "../../../../constants/bannerConstants";

import TempBannerMobile from "../../../../../../../../javascript/public/images/slider/banner_mobile.png"
import TempBannerDesktop from "../../../../../../../../javascript/public/images/slider/banner_desktop.png"

const SETTINGS = {
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 400,
  cssEase: 'linear',
  pauseOnHover: true
}


class Gallery extends React.Component {

  constructor(props) {
    super(props);
  }

  renderEventBanner() {
    return(
      <a
        href="https://collectart.ru/expositions/6-vladimir-makovskiy-dialogi"
        target="_blank"
        className="b-hero-carousel__item b-hero-carousel__widget"
      >
        <div className="container" >
          <div className="b-hero-carousel__widget-content">
            <img alt="" className="b-hero-carousel__widget-image desktop" src={TempBannerDesktop} />
            <img alt="" className="b-hero-carousel__widget-image mobile" src={TempBannerMobile} />
          </div>
        </div>
      </a>
    )
  }

  render () {
    const { images } = this.props;

    return (
      <div className="b-hero-carousel__content">
        <div
          className="b-hero-carousel__title"
          dangerouslySetInnerHTML={{__html: i18n.t('header.logo.title')}}
        />
        <div className="p-picture__gallery-row">
          <Slider
            className="b-hero-carousel__row"
            ref={slider => (this.slider = slider)}
            {...SETTINGS}
          >
            {this.renderEventBanner()}
            {images.map((image) => <Slide key={image.id} item={image} />)}
          </Slider>
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(BANNER_SHAPE)),
};

export default Gallery
