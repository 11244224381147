import React, { Component } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import Icon from "../../../../components/ui/icon";
import Prices from "../prices";
import Tours from "../tours";
import Item from "./item";
import { EXPOSITION_SHAPE } from "../../../../constants/expositionConstants";

const SETTINGS = {
  autoplay: false,
  centerMode: false,
  infinite: false,
  focusOnSelect: false,
  autoplay: false,
  dots: false,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 7,
  pauseOnHover: true,
  easing: "linear"
}


class Calendar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentSlideIndex: 0,
      currentDay: head(props.exposition.calendar) || {}
    }
  }

  handlePrev(event) {
    event.preventDefault()
    this.slider.slickPrev()
  }

  handleNext(event) {
    event.preventDefault()
    this.slider.slickNext()
  }

  handleDaySelect(currentDay) {
    this.setState({ currentDay })
  }

  handleReload = () => {
    this.props.fetchExposition()
  }

  render() {
    const { exposition, place, items } = this.props;
    const { currentDay } = this.state;
    const expositionPath = `/expositions/${exposition.slug}`;

    const settings = {
      ...SETTINGS,
      afterChange: (currentSlide) => this.setState({ currentSlide })
    }

    return(
      <div className="p-exhibition__body-excursion">
        <div className="p-exhibition__calendar">
          <div className="p-exhibition__calendar-head">
            <div className="p-exhibition__calendar-address">
              {place.address}
            </div>
            <div className="p-exhibition__calendar-arrows">
              <a
                href="#"
                className="p-exhibition__calendar-arrow __left"
                onClick={(event) => this.handlePrev(event)}
              >
                <Icon icon="prev" />
              </a>
              <a
                href="#"
                className="p-exhibition__calendar-arrow __right"
                onClick={(event) => this.handleNext(event)}
              >
                <Icon icon="next" />
              </a>
            </div>
          </div>
          <div className="p-exhibition__calendar-dates">
            <Slider
              ref={slider => (this.slider = slider)}
              {...settings}
            >
              {items.map((item, index) =>
                <Item
                  key={index}
                  item={item}
                  isActive={currentDay === item}
                  onDaySelect={(currentDay) => this.handleDaySelect(currentDay)}
                />
              )}
            </Slider>
          </div>
          <Prices
            currentDay={currentDay}
            exposition={exposition}
            items={exposition.prices}
          />
        </div>
        <Tours
          currentDay={currentDay}
          exposition={exposition}
          items={currentDay.tours}
          source={expositionPath}
          onPersonalTour={this.handleReload}
        />
      </div>
    )
  }
}

Calendar.propTypes = {
  exposition: PropTypes.shape(EXPOSITION_SHAPE).isRequired,
  fetchExposition: PropTypes.func.isRequired,
};

export default Calendar
