import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import i18n from "../../../translations/i18n";
import {
  SEARCH_RESULT_SHAPE,
  SEARCH_RESULT_ARTIST_KIND,
  SEARCH_RESULT_POST_KIND,
  SEARCH_RESULT_PAINTING_KIND
} from "../../../constants/searchConstants";
import Artist from "../item/artist";
import Painting from "../item/painting";
import Post from "../item/post";
import Empty from "../../../components/ui/empty";


class List extends Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { items, q } = this.props;

    return (
      <>
        { isEmpty(items)
          ? <div className="p-search__results-fail">
              <span>
                <Empty message={isNil(q) ? i18n.t("pages.search.empty.title") : i18n.t("pages.search.not_found.title")} />
              </span>
            </div>
          : <div className="p-search__results-list">
              {(items || []).map((item) =>
                <Fragment key={item.id}>
                  {item.searchable.kind === SEARCH_RESULT_ARTIST_KIND &&
                    <Artist item={item.searchable} />
                  }
                  {item.searchable.kind === SEARCH_RESULT_PAINTING_KIND &&
                    <Painting item={item.searchable} />
                  }
                  {item.searchable.kind === SEARCH_RESULT_POST_KIND &&
                    <Post item={item.searchable} />
                  }
                </Fragment>
              )}
            </div>
        }
      </>
    )
  }
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(SEARCH_RESULT_SHAPE)),
  q: PropTypes.string,
};

export default List;