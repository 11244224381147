import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { openModal } from "../../../actions/modalActions";


class Figure extends Component {

  constructor(props) {
    super(props);
  }

  handleClick = (event) => {
    const { modalName, images, openModal } = this.props;

    event.preventDefault();

    openModal({
      name: modalName,
      images,
    });
  }

  render () {
    const { attachment, attributes, className } = this.props;

    return (
      <figure className={className}>
        {attachment && attachment.url &&
          <div
            className="p-picture__info-description-image-wrap attachment__wrap"
            onClick={this.handleClick}>
            <img src={attachment.url} />
            <div className="button-plus" />
          </div>
        }
        {attributes && attributes.caption &&
          <figcaption className="attachment__caption">
            {attributes.caption}
          </figcaption>
        }
      </figure>
    )
  }
}

Figure.propTypes = {
  openModal: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Figure)
