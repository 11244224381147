import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";

import { API } from "../config";
import {
  CART_ITEM_PREVIEWS,
  CART_ITEM_CREATE,
  CART_ITEM_UPDATE,
  CART_ITEM_DELETE,
} from "../constants/cartConstants";
import { cartPreviewSchema } from "../schemas";
import { DEFAULT_COLLECTION } from "../selectors/cartPreviews";
import { DEFAULT_MODIFICATION } from "../selectors/cart";
import { FUNC_REPLACE } from "../reducers/entities/collectionsReducer";
import { fetchItem as fetchAccount } from "./userActions";


export function fetchItems(fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE,
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/cart_items`,
        types: CART_ITEM_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { cart_items, meta } = await res.json();

      return {
        ...normalize(cart_items, [cartPreviewSchema]),
        pagination: meta,
      };
    }
  };
}

export function createItem(cartItems = []) {
  return function(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/cart_items`,
        method: 'POST',
        body: {
          cart_items: cartItems
        },
        types: CART_ITEM_CREATE.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      dispatch(fetchAccount());
      return {}
    }
  }
}


export function updateItem(id, count, fetchOptions = {}) {
  const meta = {
    modification: fetchOptions.modification || DEFAULT_MODIFICATION,
  };

  return function updateItemThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/cart_items/${id}`,
        method: "PATCH",
        body: {
          cart_item: {
            count,
          },
        },
        types: CART_ITEM_UPDATE.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      dispatch(fetchItems());
      dispatch(fetchAccount());
      return {};
    }
  };
}


export function deleteItem(id, fetchOptions = {}) {
  return async function removeItemThunk(dispatch) {
    const meta = {
      modification: fetchOptions.modification || DEFAULT_MODIFICATION,
    };

    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/cart_items/${id}`,
        method: "DELETE",
        types: CART_ITEM_DELETE.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      dispatch(fetchItems());
      dispatch(fetchAccount());
      return {};
    }
  }
}
