import React from "react";
import PropTypes from "prop-types";


class Empty extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { message } = this.props;

    return (
      <>
        {message}
      </>
    )
  }
}

Empty.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Empty