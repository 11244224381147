import React from "react";
import i18n from "../../../translations/i18n";


class Reasons extends React.Component {

  render () {

    return (
      <>
        <div className="b-reasons">
          <div className="section-title">
            {i18n.t('pages.partners.reasons.title')}
          </div>
          <div className="container">
            <div className="b-reasons__content">
              <div className="b-reasons__row row">
                <div className="b-reasons__item">
                  <div className="b-reasons__item-figure">
                    1
                  </div>
                  <div className="b-reasons__item-title">
                    {i18n.t('pages.partners.reasons.item.one.title')}
                  </div>
                  <div className="b-reasons__item-description">
                    {i18n.t('pages.partners.reasons.item.one.description')}
                  </div>
                </div>
                <div className="b-reasons__item">
                  <div className="b-reasons__item-figure">
                    2
                  </div>
                  <div className="b-reasons__item-title">
                    {i18n.t('pages.partners.reasons.item.two.title')}
                  </div>
                  <div className="b-reasons__item-description">
                    {i18n.t('pages.partners.reasons.item.two.description')}
                  </div>
                </div>
                <div className="b-reasons__item">
                  <div className="b-reasons__item-figure">
                    3
                  </div>
                  <div className="b-reasons__item-title">
                    {i18n.t('pages.partners.reasons.item.three.title')}
                  </div>
                  <div
                    className="b-reasons__item-description"
                    dangerouslySetInnerHTML={{__html: i18n.t('pages.partners.reasons.item.three.description')}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Reasons;
