import {
  SHOW_NOTICE,
  HIDE_NOTICE,
} from "../../constants/noticeConstants";

const DEFAULT_STATE = {
  title: null,
  description: null,
  visible: false,
};

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case SHOW_NOTICE:
      return {
        ...state,
        ...payload,
        visible: true,
      };
    case HIDE_NOTICE:
      return {
        ...state,
        ...DEFAULT_STATE,
      }
    default:
      return state;
  }
}