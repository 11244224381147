import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { loadingBarReducer } from "react-redux-loading-bar";

import auth from "./auth";
import currentUser from "./currentUser";
import entities from "./entities";
import layout from "./layout";
import cookies from "./cookies";
import paintingOffer from "./paintingOffer";

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  loadingBar: loadingBarReducer,
  auth,
  currentUser,
  entities,
  layout,
  cookies,
  paintingOffer
});

export default createRootReducer