import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Slider from "react-slick";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";

import i18n from "../../../translations/i18n";
import { openModal } from "../../../actions/modalActions";
import { PAINTING_IMAGES_MODAL } from "../../../constants/modalConstants";
import {
  PAINTING_SHAPE,
  PAINTING_DETAILS_KIND,
  PAINTING_SLIDE_SCROLL,
} from "../../../constants/paintingConstants";
import Nav from "./nav";
import Slide from "./slide";
import Modal from "./modal";


class Gallery extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
    }
  }

  handleNav = (index) => {
    this.slider.slickGoTo(index)
  }

  handleOpenModal = (index) => {
    this.props.openModal({name: PAINTING_IMAGES_MODAL, scroll: `${PAINTING_SLIDE_SCROLL}__${index}`});
  }

  render () {
    const { painting } = this.props;
    const { currentSlide } = this.state;

    const settings = {
      speed: 500,
      centerMode: true,
      dots: false,
      slidesToShow: 1,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            arrows: false,
          }
        },
      ],
      afterChange: (currentSlide) => this.setState({ currentSlide })
    }

    return (
      <>
        {!isEmpty(painting.slider) &&
          <div className="p-picture__gallery">
            <div className="p-picture__gallery-menu">
              {uniqBy(painting.slider, 'kind').map((item) =>
                <Nav
                  key={item.id}
                  title={i18n.t(`pages.painting.gallery.${item.kind}.title`)}
                  kind={item.kind}
                  index={item.index}
                  detailIdx={painting.slider.filter((s) => s.kind === PAINTING_DETAILS_KIND).map((s) => s.index)}
                  currentSlide={currentSlide}
                  onNav={this.handleNav}
                />
              )}
            </div>
            <div className="p-picture__gallery-content">
              <Modal images={painting.slider} />
              <Slider
                className="p-picture__gallery-row"
                ref={slider => (this.slider = slider)}
                {...settings}
              >
                {painting.slider.map((item) =>
                  <Slide
                    key={item.id}
                    item={item}
                    index={item.index}
                    currentSlide={currentSlide}
                    onClick={() => this.handleOpenModal(item.index)}
                  />
                )}
              </Slider>
            </div>
          </div>
        }
      </>
    )
  }
}

Gallery.propTypes = {
  painting: PropTypes.shape(PAINTING_SHAPE).isRequired,
};

function mapStateToProps({layout}) {
  return {
    modal: layout.modal
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)