import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";

export const POST_AUTHOR_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  company: PropTypes.string,
  occupation: PropTypes.string,
}

const POST_POSTER_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  main: PropTypes.string,
}

export const POST_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  slug: PropTypes.string,
  kind: PropTypes.string,
  annotation: PropTypes.string,
  lead: PropTypes.string,
  text: PropTypes.string,
  author: PropTypes.shape(POST_AUTHOR_SHAPE),
  poster: PropTypes.shape(POST_POSTER_SHAPE),
}

export const POST_ARTICLE_KIND = 'post';
export const POST_PHOTOREPORT_KIND = 'photoreport';
export const POST_YOUTUBE_KIND = 'youtube';

export const POST_PREVIEWS = createRequestActionsFor("POST_PREVIEWS")
export const POST_ITEM = createRequestActionsFor("POST_ITEM");
