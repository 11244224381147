import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { expositionOrderSchema } from "../../../schemas";
import {
  EXPOSITION_ORDER_ITEM,
  EXPOSITION_ORDER_ITEM_REFUND,
} from "../../../constants/expositionOrderConstants";

export default combineReducers({
  byId: makeByIdReducer(
    expositionOrderSchema.key,
    [
      EXPOSITION_ORDER_ITEM.SUCCESS,
      EXPOSITION_ORDER_ITEM_REFUND.SUCCESS
    ],
    [
      EXPOSITION_ORDER_ITEM.FAILED,
      EXPOSITION_ORDER_ITEM_REFUND.FAILED
    ]
  ),
})
