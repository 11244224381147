import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import { ARTIST_SHAPE } from "../../../constants/aritstConstants";
import Item from "./item";


class Statistics extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { artist } = this.props;

    return (
      <div className="p-painter__info-statistics">
        { artist.rating &&
          <Item
            value={artist.rating}
            max={10}
            title={i18n.t('pages.artist.rating.html')}
            tooltip={i18n.t('pages.artist.rating.tooltip')}
          />
        }
        { artist.investment &&
          <Item
            value={artist.investment}
            max={100}
            title={i18n.t('pages.artist.investment.html')}
            tooltip={i18n.t('pages.artist.investment.tooltip')}
          />
        }
      </div>
    )
  }
}

Statistics.propTypes = {
  artist: PropTypes.shape(ARTIST_SHAPE),
};

export default Statistics