import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { seoSchema } from "../../../schemas";
import { SEO_ITEM } from "../../../constants/seoConstants";

export default combineReducers({
  byId: makeByIdReducer(
    seoSchema.key,
    [SEO_ITEM.SUCCESS],
    [SEO_ITEM.FAILED]
  ),
})