import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import head from "lodash/head";

import i18n from "../../../translations/i18n";
import Link from "../../../components/ui/link";
import Select from "../../../components/ui/form/select";
import { USER_MENU_SELECT_OPTIONS } from "../../../constants/userConstants";


class Menu extends React.Component {

  constructor(props) {
    super(props);
  }

  handleChange = (option) => {
    const { history } = this.props;
    history.push(option.value);
  }

  render () {
    const { location } = this.props;
    const currentOption = head(USER_MENU_SELECT_OPTIONS.filter((option) => option.value === location.pathname))

    return (
      <div className="p-settings__menu">
        <ul className="p-settings__menu-list">
          {USER_MENU_SELECT_OPTIONS.map((option, index) =>
            <li
              key={index}
              className="p-settings__menu-item"
            >
              <Link
                to={option.value}
                className="p-settings__menu-link"
                activeClassName="state-selected"
              >
                {option.label}
              </Link>
            </li>
          )}
        </ul>
        <div className="select">
          <Select
            name="navigation"
            defaultValue={currentOption}
            options={USER_MENU_SELECT_OPTIONS}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
};

export default compose(withRouter)(Menu)