import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";
import { EXPOSITION_PRICE_SHAPE } from "./expositionConstants";

export const EXPOSITION_ORDER_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_ORDER_ITEM_SHAPE)),
  state: PropTypes.string,
}

const EXPOSITION_ORDER_ITEM_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  state: PropTypes.oneOf([
    EXPOSITION_ORDER_ITEM_PAID_TYPE,
    EXPOSITION_ORDER_ITEM_REJECTED_TYPE,
    EXPOSITION_ORDER_ITEM_REFUNDED_TYPE
  ]).isRequired,
  date: PropTypes.string,
  count: PropTypes.number,
  price: PropTypes.shape(EXPOSITION_PRICE_SHAPE)
}

export const EXPOSITION_ORDER_ITEM_EXPOSITION_TYPE = 'Exposition';
export const EXPOSITION_ORDER_ITEM_TOUR_TYPE = 'Tour'

export const EXPOSITION_ORDER_ITEM_PAID_TYPE = 'paid';
export const EXPOSITION_ORDER_ITEM_REJECTED_TYPE = 'rejected';
export const EXPOSITION_ORDER_ITEM_REFUNDED_TYPE = 'refunded';

export const EXPOSITION_ORDER_PREVIEWS = createRequestActionsFor("EXPOSITION_ORDER_PREVIEWS");
export const EXPOSITION_ORDER_ITEM = createRequestActionsFor("EXPOSITION_ORDER_ITEM");
export const EXPOSITION_ORDER_CREATE = createRequestActionsFor("EXPOSITION_ORDER_CREATE");
export const EXPOSITION_ORDER_ITEM_REFUND = createRequestActionsFor("EXPOSITION_ORDER_ITEM_REFUND");
