import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../translations/i18n";
import {
  closeModal,
} from "../../../../actions/modalActions";
import {
  refundItem
} from "../../../../actions/expositionOrderActions";
import { EXPOSITION_ORDER_ITEM_REFUND_MODAL } from "../../../../constants/modalConstants";
import BaseModal from "../../../ui/modal/base";


class ExpositionOrderItemRefundModal extends Component {

  constructor(props) {
    super(props);
  }

  handleRefund = (event) => {
    const { modal, refundItem } = this.props;
    const { orderItem } = modal;

    if (event) {
      event.preventDefault();
    }

    refundItem(orderItem.id);
  }

  handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.closeModal();
  }

  render() {
    const { modal } = this.props;

    return (
      <BaseModal
        className="modal modal-ecursion-cansel"
        isOpen={modal.name === EXPOSITION_ORDER_ITEM_REFUND_MODAL}
        onClose={this.handleClose}
      >
        <div className="modal-ecursion__title4">
          {i18n.t('modal.exposition_order_item.refund.title')}
        </div>
        <div className="modal-ecursion__cansel-links">
          <div>
            <a
              href="#"
              className="modal-ecursion__cansel-yes"
              onClick={this.handleRefund}
            >
              {i18n.t('modal.exposition_order_item.refund.link.title')}
            </a>
          </div>
          <div>
            <a
              href="#"
              className="button"
              onClick={this.handleClose}
            >
              {i18n.t('modal.exposition_order_item.refund.button.title')}
            </a>
          </div>
        </div>
      </BaseModal>
    )
  }
}

ExpositionOrderItemRefundModal.propTypes = {
  modal: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  refundItem: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {
  return {
    modal: layout.modal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    refundItem,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpositionOrderItemRefundModal)
