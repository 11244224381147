import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import { EXPOSITION_SCHEDULE_SHAPE } from "../../../constants/expositionConstants";


class Schedule extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { schedule } = this.props;

    return (
      <>
        { schedule &&
          <>
            {i18n.t('pages.exposition.schedule.title', {start_at: schedule.start_at, end_at: schedule.end_at})}
          </>
        }
      </>
    );
  }
}

Schedule.propTypes = {
  schedule: PropTypes.shape(EXPOSITION_SCHEDULE_SHAPE),
}

export default Schedule
