import {
  OPEN_MODAL,
  CLOSE_MODAL,
} from "../../constants/modalConstants";

const DEFAULT_STATE = {
  visible: false,
  name: null,
};

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case OPEN_MODAL:
      return {
        ...state,
        ...payload,
        visible: true,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        ...DEFAULT_STATE,
      }
    default:
      return state;
  }
}