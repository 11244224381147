import React from "react";
import { compose } from "redux";
import { YMaps, Map, Placemark } from "react-yandex-maps";

import i18n from "../../translations/i18n";
import ContactsHead from "../../../../images/contacts/head.jpg";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import Meta from "../../components/layout/meta";

const MAP_COORDS = [
  55.741420,
  37.591860
]


class Contacts extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <main className="layout">
        <Meta />
        <div className="p-contacts">
          <div className="container">
            <div className="p-contacts__content">
              <div className="p-contacts__office">
                <div className="p-contacts__office-title">
                  {i18n.t('pages.contacts.title')}
                </div>
                <div className="p-contacts__office-image-wrapper">
                  <img alt="" src={ContactsHead} />
                </div>
              </div>
              <div className="p-contacts__info">
                <div className="p-contacts__row row">
                  <div className="p-contacts__col p-contacts__map">
                  <YMaps>
                    <Map defaultState={{ center: MAP_COORDS, zoom: 17 }}>
                      <Placemark geometry={MAP_COORDS} />
                    </Map>
                  </YMaps>
                  </div>
                  <div className="p-contacts__col p-contacts__address">
                    <div className="p-contacts__col-title">
                      {i18n.t('pages.contacts.address.title')}
                    </div>
                    <div className="p-contacts__col-text">
                      <p>
                        {i18n.t('pages.contacts.address.text')}
                        <br/>
                        {i18n.t('pages.contacts.address.metro')}
                      </p>
                      <p>{i18n.t('pages.contacts.address.description')}</p>
                    </div>
                  </div>
                  <div className="p-contacts__col p-contacts__phone">
                    <div className="p-contacts__col-title">
                      {i18n.t('pages.contacts.info.title')}
                    </div>
                    <div className="p-contacts__col-text">
                      {['email', 'phone'].map((item, index) =>
                        <div key={index}>
                          <a
                            href={i18n.t(`pages.contacts.info.${item}.value`)}
                          >
                            {i18n.t(`pages.contacts.info.${item}.title`)}
                          </a>
                        </div>
                      )}
                      <div>{i18n.t('pages.contacts.info.work.title')}</div>
                      <div>{i18n.t('pages.contacts.info.holiday.title')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

Contacts.propTypes = {
};

export default compose(
  withBodyClass('app-contacts'),
  withHeader({transparent: false}),
  withFooter(),
)(Contacts)