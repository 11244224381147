import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { makeArtistPreviewsFetchStatusSelector } from "../../../selectors/artistPreviews";
import { ARTIST_SHAPE } from "../../../constants/aritstConstants";
import Item from "../item";
import Empty from "../../../components/ui/empty";


class List extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { items, fetchStatus, onFavorite } = this.props;

    return (
      <>
        { isEmpty(items)
          ? <>
              {fetchStatus && fetchStatus.isFetching
                ? <Empty message={i18n.t("helpers.loading.title")} />
                : <Empty message={i18n.t("pages.artists.empty.title")} />
              }
            </>
          : <div className="b-painters__row row">
              {(items || []).map((item) =>
                <Item
                  item={item}
                  key={item.id}
                  onFavorite={onFavorite}
                />
              )}
            </div>
        }
      </>
    )
  }
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ARTIST_SHAPE)),
  onFavorite: PropTypes.func.isRequired,
};

const artistsFetchStatusSelector = makeArtistPreviewsFetchStatusSelector();

function mapStateToProps(state) {
  return {
    fetchStatus: artistsFetchStatusSelector(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
