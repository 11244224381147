import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import i18n from "../../../../../translations/i18n";
import Icon from "../../../../../components/ui/icon";
import { EXPOSITION_PRICE_SHAPE } from "../../../../../constants/expositionConstants";


class Item extends Component {

  constructor(props) {
    super(props);

    this.state = {
      count: 0,
    }
  }

  handleMinus = (event) => {
    event.preventDefault();
    const { item, isDisabled, onChange } = this.props;
    const { count } = this.state;

    if (!isDisabled) {
      let newValue = count - 1;
      if (newValue >= 0) {
        this.setState({count: newValue})
        onChange(item, newValue)
      }
    }
  }

  handlePlus = (event) => {
    event.preventDefault();
    const { item, isDisabled, onChange } = this.props;
    const { count } = this.state;

    if (!isDisabled) {
      let newValue = count + 1;
      if (newValue <= 4) {
        this.setState({count: newValue})
        onChange(item, newValue)
      }
    }
  }

  render() {
    const { item, isDisabled } = this.props;
    const { count } = this.state;
    const isFree = item.amount === 0;
    const minusDisabled = count === 0 || isDisabled;
    const plusDisable = count >= 4 || isDisabled;

    return(
      <tr>
        <td>
          <div className="p-exhibition__ticket-selector-left">
            <div>
              {item.title}
            </div>
            <div className="p-exhibition__ticket-selector-cost">
              {isFree
              ? <span className="__free">
                  {i18n.t("pages.exposition.prices.table.amount.free")}
                </span>
              : <>
                  {i18n.t("pages.exposition.prices.table.amount.value", {price: item.amount})}
                </>
              }
            </div>
          </div>
        </td>
        <td>
          <div className="p-exhibition__ticket-quantity">
            <a
              href="#"
              className={cn('p-exhibition__ticket-quantity-button p-exhibition__ticket-quantity-minus', { 'state-past': minusDisabled })}
              disabled={isDisabled}
              onClick={this.handleMinus}
            >
              <Icon icon="arrow-minus" />
            </a>
            <div className="p-exhibition__ticket-quantity-num">
              {count}
            </div>
            <a
              href="#"
              className={cn('p-exhibition__ticket-quantity-button p-exhibition__ticket-quantity-plus', { 'state-past': plusDisable })}
              disabled={isDisabled}
              onClick={this.handlePlus}
            >
              <Icon icon="arrow-plus" />
            </a>
          </div>
        </td>
      </tr>
    )
  }
}

Item.propTypes = {
  item: PropTypes.shape(EXPOSITION_PRICE_SHAPE).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Item
