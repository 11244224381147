import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";

export const INTERIOR_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  medium: PropTypes.string,
  thumb: PropTypes.string,
}

export const INTERIOR_PREVIEWS = createRequestActionsFor("INTERIOR_PREVIEWS");
