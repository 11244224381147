import React from "react";
import PropTypes from "prop-types";
import isNil from "lodash/isNil";

function AgeRestriction({ exposition, classname }) {
  let restriction;
  if (exposition.adults_only) {
    restriction = "18+";
  } else {
    if (!isNil(exposition.age_restriction)) {
      restriction = `${exposition.age_restriction}+`;
    }
  }

  if (!restriction) {
    return null;
  }

  return (
    <div className={classname}>
      {restriction}
    </div>
  )
}

AgeRestriction.propTypes = {
  exposition: PropTypes.shape({
    adults_only: PropTypes.bool,
    age_restriction: PropTypes.number,
  }).isRequired,
  classname: PropTypes.string,
};

export default AgeRestriction;
