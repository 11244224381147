import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchSimilar } from "../../actions/paintingActions";
import {
  makePaintingPreviewsSelector,
  makePaintingPreviewsAnyKeySelector,
  makePaintingPreviewsFetchStatusSelector,
} from "../../selectors/paintingPreviews";
import { PAINTING_SHAPE } from "../../constants/paintingConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withSimilarPaintings(WrappedComponent) {

  const WithSimilarPaintings = class extends Component {

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    fetchPaintings = () => {
      const { painting, fetchSimilar, fetchStatus } = this.props;

      if (!fetchStatus.isFetching) {
        fetchSimilar(painting.id);
      }
    };

    render() {
      const { paintings, pagination, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    paintings={paintings}
                    pagination={pagination}
                    fetchStatus={fetchStatus}
                    fetchPaintings={this.fetchPaintings}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithSimilarPaintings.propTypes = {
    paintings: PropTypes.arrayOf(PropTypes.shape(PAINTING_SHAPE)),
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const paintingsSelector = makePaintingPreviewsSelector();
  const paintingsPaginationSelector = makePaintingPreviewsAnyKeySelector('pagination');
  const paintingsFetchStatusSelector = makePaintingPreviewsFetchStatusSelector();

  function mapStateToProps(state) {
    return {
      paintings: paintingsSelector(state),
      pagination: paintingsPaginationSelector(state) || {},
      fetchStatus: paintingsFetchStatusSelector(state),
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchSimilar,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithSimilarPaintings);
}