import {
  EXPOSITION_ORDER_CREATE
} from "../../constants/expositionOrderConstants";

const DEFAULT_STATE = {
  created: false,
  id: null,
  total: null,
};

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case EXPOSITION_ORDER_CREATE.SUCCESS:
      return {
        ...state,
        ...payload.layout,
      }
    case EXPOSITION_ORDER_CREATE.FAILED:
      return {
        ...state,
        ...payload.layout,
        ...DEFAULT_STATE
      }
    default:
      return state;
  }
}
