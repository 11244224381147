import { denormalize } from "normalizr";

import { getEntities } from "./entities";
import { favoriteSchema } from "../schemas";
import { makeModificationStatusSelector } from "./modifications";


export const DEFAULT_MODIFICATION = "form";

export function getFavorite(state, id) {
  return denormalize(id, favoriteSchema, getEntities(state));
}

export function makeAccountModificationStatusSelector(modification = DEFAULT_MODIFICATION) {
  return makeModificationStatusSelector(modification, favoriteSchema);
}