import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../../translations/i18n";
import { EXPOSITION_ORDER_SHAPE } from "../../../../../constants/expositionOrderConstants";
import Item from "../item";

class List extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { items } = this.props;

    return (
      <div>
        {(items || []).map((item) =>
          <div
            key={item.id}
            className="p-profile__exhibition-tickets"
           >
            <div className="p-profile__exhibition-tickets-number">
              {i18n.t('pages.exposition_order.title', {id: item.id})}
            </div>
            <ul>
              {(item.items || []).map((i) =>
                <Item
                  key={i.id}
                  item={i}
                />
              )}
            </ul>
            <div className="p-profile__exhibition-tickets-total">
              {i18n.t('pages.exposition_order.item.total.title', {
                  total: item.items.reduce((count, item) => count + item.count * item.price.amount, 0)
                }
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_ORDER_SHAPE)),
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
