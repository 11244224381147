import {
  ENABLE_PERSONAL_TOUR,
  DISABLE_PERSONAL_TOUR,
  PERSONAL_TOUR_ITEM_CREATE,
} from "../../constants/personalTourConstants";

const DEFAULT_STATE = {
  error: null,
  enabled: false,
  expositionId: null,
  onPersonalTour: null,
};

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case ENABLE_PERSONAL_TOUR:
      return {
        ...state,
        ...payload,
        enabled: true,
      };
    case DISABLE_PERSONAL_TOUR:
      return {
        ...state,
        ...DEFAULT_STATE,
      }
    case PERSONAL_TOUR_ITEM_CREATE.FAILED:
      return {
        ...state,
        ...payload,
        enabled: true,
      };
    default:
      return state;
  }
}
