import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { withPainting } from "../../hocs/entities/withPainting";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import Meta from "./meta";
import Gallery from "./gallery";
import Content from "./content";
import Similar from "./similar";


class Painting extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { painting, fetchPainting } = this.props;

    return (
      <main className="layout">
        <Meta />
        <div className="p-picture">
          <Gallery painting={painting} />
          <Content painting={painting} fetchPainting={fetchPainting} />
          <Similar painting={painting} />
        </div>
      </main>
    );
  }
}

Painting.propTypes = {
  fetchPainting: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withPainting,
  withBodyClass('app-painting'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Painting)