import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { withNotice } from "../../hocs/layout/withNotice";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import Posts from "../../components/posts/list";
import Subscription from "../../components/layout/subscription";
import Reasons from "../../components/layout/reasons";
import Meta from "./meta";
import Banners from "./banners";
import Novelties from "./novelties";
import Lead from "./lead";
import Artists from "./artists";
import Interiors from "./interiors";


class Home extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <main className="layout">
        <Meta />
        <div className="p-home">
          <Banners />
          <Novelties />
          <Lead />
          <Artists />
          <Subscription source="home" />
          <Interiors />
          <Posts withLink />
          <Reasons />
        </div>
      </main>
    );
  }
}

Home.propTypes = {
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withBodyClass('app-home'),
  withNotice(),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Home)
