import { RSAA } from "../middlewares/redux-api-middleware";

import { API } from "../config";
import {
  PAINTING_OFFER,
  PAINTING_OFFER_CACHED,
  PAINTING_OFFER_CLEAR
} from "../constants/paintingOfferConstants";

//import { serialize } from "object-to-formdata";


export function createItem(values) {
  /*var form = serialize({ painting_offer: {
    //"images[]": values.files,
    name: values.name,
    artist: values.author,
    property_id: values.technique,
    length: values.length,
    width: values.width,
    height: values.height,
    collections: values.collections,
    exhibitions: values.exhibitions,
    publications: values.publications,
    examinations: values.examinations,
    comment: values.comment
  }});*/

  const form = { painting_offer: {
    "images[]": values.files,
    name: values.name,
    artist: values.author,
    year: values.year,
    property_id: values.technique,
    length: values.length,
    width: values.width,
    height: values.height,
    collections: values.collections,
    exhibitions: values.exhibitions,
    publications: values.publications,
    examinations: values.examinations,
    comment: values.comment
  }, user: {
    name: values.username,
    phone: values.phone,
    email: values.email
  }}
  return function(dispatch) {

    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/painting_offers`,
        method: 'POST',
        body: form,
        multipartFormData: true,
        types: PAINTING_OFFER.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      return {}
    }
  }
}

export function createCachedItem(values) {
  return function(dispatch) {
    return dispatch({
      type: PAINTING_OFFER_CACHED,
      payload: values
    });
  }
}

export function clearCachedItem() {
  return function(dispatch) {
    return dispatch({
      type: PAINTING_OFFER_CLEAR,
      payload: {}
    });
  }
}