import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isNil from "lodash/isNil";

import i18n from "../../../../translations/i18n";
import {
  signUp,
  confirmPhone,
  resendCode,
  fetchItem,
  resetAuthForm as reset
} from "../../../../actions/userActions";
import {
  openModal,
  closeModal
} from "../../../../actions/modalActions";
import {
  disableAppointment
} from "../../../../actions/appointmentActions";
import { getAccount, makeAccountModificationStatusSelector } from "../../../../selectors/accounts";
import BaseModal from "../../../ui/modal/base";
import RegistrationForm from "./form";
import PhoneConfirmationForm from "./phone_confirmation_form";
import {
  REGISTRATION_MODAL,
  LOGIN_MODAL
} from "../../../../constants/modalConstants";
import {
  TrackRegistrationCloseClick,
  TrackRegistrationSubmitSuccess
} from "../../../../helpers/analytics";


class RegistrationModal extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.reset();
  }

  componentDidUpdate(prevProps, nextProps) {
    const { fetchStatus } = this.props;

    if (prevProps.fetchStatus !== fetchStatus && fetchStatus.isSuccess) {
      this.fetchAccount();
    }
  }

  fetchAccount = () => {
    const { userLoggedIn, fetchItem } = this.props;

    if (userLoggedIn) {
      fetchItem();
    }
  };

  handleClose = (event) => {
    const { closeModal, disableAppointment } = this.props;
    event.preventDefault();

    TrackRegistrationCloseClick();

    closeModal();
    disableAppointment();
  }

  handleResendCode = () => {
    this.props.resendCode();
  }

  handleConfirm = (params) => {
    this.props.confirmPhone(params);
  }

  handleOpenLogin = (event) => {
    event.preventDefault()
    this.props.openModal({name: LOGIN_MODAL});
  }

  handleSubmit = (params) => {
    const { signUp } = this.props;

    TrackRegistrationSubmitSuccess();
    signUp(params);
  }

  render () {
    const { auth, modal, userLoggedIn, account } = this.props;

    let confirmed = false;
    let phoneConfirmed = false;
    if (account) {
      confirmed = account.confirmed;
      phoneConfirmed = account.phone_confirmed;
    }

    return (
      <BaseModal
        className="modal modal-registration"
        isOpen={modal.name === REGISTRATION_MODAL}
        onClose={this.handleClose}
      >
        <div className="modal__title">
          {i18n.t('pages.registration.title')}
        </div>
        <div className="modal__form">
          <RegistrationForm
            initialValues={{
              email: !isNil(account) ? account.email : '',
              phone: !isNil(account) ? account.unconfirmed_phone : '',
              password: '',
              name: !isNil(account) ? account.name : '',
              role: 'enthusiast',
              subscribe_to_news: true}
            }
            auth={auth}
            userLoggedIn={userLoggedIn}
            phoneConfirmed={phoneConfirmed}
            onSubmit={this.handleSubmit}
          />
          {userLoggedIn && !confirmed && !phoneConfirmed &&
            <PhoneConfirmationForm
              initialValues={{code: ''}}
              auth={auth}
              onResend={this.handleResendCode}
              onSubmit={this.handleConfirm}
            />
          }
          <div className="modal__reg-tip">
            {i18n.t('pages.registration.form.login.title')}
            <a
              href=""
              className="modal__reg-tip-link"
              onClick={this.handleOpenLogin}
            >
              {i18n.t('pages.registration.form.login.link')}
            </a>
          </div>
          <div
            className="modal__terms"
            dangerouslySetInnerHTML={{__html: i18n.t('pages.registration.form.terms.title')}}
          />
        </div>
      </BaseModal>
    );
  }
}

RegistrationModal.propTypes = {
  signUp: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

const fetchStatus = makeAccountModificationStatusSelector();

function mapStateToProps(state) {
  const { auth, currentUser, layout } = state;

  return {
    auth,
    currentUser,
    userLoggedIn: !!currentUser.token,
    modal: layout.modal,
    account: getAccount(state, currentUser.id),
    fetchStatus: fetchStatus(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    signUp,
    confirmPhone,
    resendCode,
    fetchItem,
    reset,
    openModal,
    closeModal,
    disableAppointment,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(RegistrationModal)
