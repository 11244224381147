import React from "react";
import PropTypes from "prop-types";

import { ARTIST_SHAPE } from "../../../constants/aritstConstants";
import Truncated from "../../ui/trancated";
import Link from "../link";


class Description extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { artist, imgClassName, moreClassName, withLink } = this.props;
    const artistPath = `/artists/${artist.slug}`;

    return (
      <>
        {artist.biography &&
          <Truncated
            annotation={artist.annotation}
            text={artist.biography}
            image={artist.avatar}
            link={artistPath}
            imgClassName={imgClassName}
            moreClassName={moreClassName}
          />
        }
        {withLink &&
          <Link artist={artist} />
        }
      </>
    );
  }
}

Description.propTypes = {
  artist: PropTypes.shape(ARTIST_SHAPE).isRequired,
  imgClassName: PropTypes.string,
};

Description.defaultProps = {
  withLink: false,
};

export default Description