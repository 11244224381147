import "core-js/stable";
import "regenerator-runtime/runtime";
import "url-search-params-polyfill";
import "whatwg-fetch";

import "../public/stylesheets/layout"

require.context('../public/images', true);
require.context('../public/fonts', true);

// icons for sprite
const importAll = (r) => { r.keys().map(r); };
importAll(require.context('../public/images/icons', true, /\.svg$/));

require("@rails/ujs").start();

// initialize main app
import React from "react";
import { render } from "react-dom";
import App from "../public/javascripts/app";

document.addEventListener("DOMContentLoaded", () => {
  render(
    <App />,
    document.getElementById('react-root')
  );
});
