import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";


class Button extends Component {

  render() {
    const { children, disabled, className, type, restProps } = this.props;

    return(
      <button type={type} className={cn("button", className)} disabled={disabled}
        {...restProps}>
        {children}
      </button>
    )
  }
}

Button.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Button
