import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";

import { fetchItem } from "../../actions/seoActions";
import { getSeo } from "../../selectors/seo";
import { SEO_SHAPE } from "../../constants/seoConstants";


export function withSeo(WrappedComponent) {

    const WithSeo = class extends Component {

      componentDidMount() {
        this.fetchSeo();
      }

      fetchSeo = () => {
        const { location, fetchItem } = this.props;

        fetchItem(location.pathname);
      };

      render() {
        const { seo, fetchStatus, ...restProps } = this.props;

        return (
          <>
            <WrappedComponent
              {...restProps}
              seo={seo}
              fetchSeo={this.fetchSeo}
            />
            {
              seo && seo.robots_blocked &&
              <Helmet>
                <meta name="robots" content="noindex"/>
              </Helmet>
            }
          </>
        )
      }
    };

    WithSeo.propTypes = {
      seo: PropTypes.shape(SEO_SHAPE),
    };

    function mapStateToProps(state, {location}) {
      return {
        seo: getSeo(state, location.pathname),
      }
    }

    function mapDispatchToProps(dispatch) {
      return bindActionCreators({
        fetchItem,
      }, dispatch)
    }

    return compose(
      withRouter,
      connect(mapStateToProps, mapDispatchToProps)
    )(WithSeo)
  }
