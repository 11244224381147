import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import cn from "classnames";


class BaseModal extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { className, children, onOpen, onClose, ...restProps } = this.props;

    return (
      <ReactModal
        onAfterOpen={onOpen}
        onRequestClose={onClose}
        className={className}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        {...restProps}
      >
        <div className="modal__container">
          <div className="modal__inner">
            <a
              href=""
              className="modal__close"
              onClick={onClose}
            >
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L15.8492 15.8492" stroke="black" strokeWidth="2"/>
                <path d="M16 1L1.15076 15.8492" stroke="black" strokeWidth="2"/>
              </svg>
            </a>
            <div className="modal__content">
              {children}
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
}

BaseModal.propTypes = {
  className: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default BaseModal
