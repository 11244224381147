import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { withBanners } from "../../../hocs/entities/withBanners";
import { BANNER_SHAPE } from "../../../constants/bannerConstants";
import Gallery from "./gallery";


class Banners extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchBanners();
  }

  render () {
    const { banners } = this.props;

    return (
      <div className="b-hero-carousel">
        {!isEmpty(banners) && <Gallery images={banners} />}
      </div>
    );
  }
}

Banners.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.shape(BANNER_SHAPE)),
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withBanners,
  connect(mapStateToProps, mapDispatchToProps)
)(Banners)