import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import { SERVICE_SHAPE } from "../../../constants/serviceConstants";
import Link from "../../../components/ui/link";

class Services extends React.Component {

  constructor(props) {
    super(props);
  }

  renderImage(service) {
    return(
      <>
        {service.image &&
          <img
            src={service.image.full}
            className='about__services-item-image'
            alt={service.name}
            title={service.name}
          />
        }
      </>
    )
  }

  renderServices() {
    const { services } = this.props;

    return services.map((service) =>
      <div key={service.id} className="p-about__services-item">
        {service.published
          ? <>
              <Link
                to={`/about/${service.slug}`}
                className="p-about__services-item-image-wrapper"
              >
                {this.renderImage(service)}
              </Link>
              <Link
                to={`/about/${service.slug}`}
                className="p-about__services-item-title"
              >
                {service.name}
              </Link>
            </>
          : <>
              <span className="p-about__services-item-image-wrapper">
                {this.renderImage(service)}
              </span>
              <span className="p-about__services-item-title">
                {service.name}
              </span>
            </>
        }
        <div className="p-about__services-item-description">
          {service.description}
        </div>
      </div>
    );
  }

  render () {
    return (
      <div className="p-about__services" id="#services">
        <div className="p-about__services">
          <div className="container">
            <div className="p-about__services-title">
              {i18n.t('pages.about.services.title')}
            </div>
            <div className="p-about__services-list">
              {this.renderServices()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Services.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape(SERVICE_SHAPE)).isRequired,
};

export default Services;
