import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { SERVICE_PREVIEWS } from "../../../constants/serviceConstants";
import { makeCollectionsReducer } from "../collectionsReducer";
import { servicePreviewSchema } from "../../../schemas";

export const DEFAULT_COLLECTION = "index";

export default combineReducers({
  byId: makeByIdReducer(servicePreviewSchema.key, [SERVICE_PREVIEWS.SUCCESS]),
  collections: makeCollectionsReducer(SERVICE_PREVIEWS.typeNames()),
});