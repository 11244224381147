import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";

export const FAVORITE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  favorable_id: PropTypes.number,
  favorable_type: PropTypes.string,
  name: PropTypes.string
}

export const FAVORITE_PREVIEWS = createRequestActionsFor("FAVORITE_PREVIEWS");

export const FAVORITE_ITEM_CREATE = createRequestActionsFor("FAVORITE_ITEM_CREATE");
export const FAVORITE_ITEM_DELETE = createRequestActionsFor("FAVORITE_ITEM_DELETE");
