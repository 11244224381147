import { createSelector } from "reselect";

export const ENTITIES_NAMESPACE = "entities";
const BY_ID_KEY = "byId";

export const getEntitiesState = (state) => state[ENTITIES_NAMESPACE];

export const getEntities = createSelector(
  getEntitiesState,
  (entities) => Object.keys(entities)
    .reduce((allEntities, entityName) => ({
      ...allEntities,
      [entityName]: entities[entityName][BY_ID_KEY] || {},
    }), {})
);

export const makeEntitiesBySchemaSelector = (schema) => createSelector(
  getEntitiesState,
  (entitiesState) => entitiesState[schema.key],
);