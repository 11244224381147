import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { serviceSchema } from "../../../schemas";
import { SERVICE_ITEM } from "../../../constants/serviceConstants";

export default combineReducers({
  byId: makeByIdReducer(
    serviceSchema.key,
    [SERVICE_ITEM.SUCCESS],
    [SERVICE_ITEM.FAILED]
  ),
})