import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import { USER_SHAPE } from "../../../../constants/userConstants";
import { MODIFICATION_SHAPE } from "../../../../selectors/modifications";
import { PASSWORD_MIN_LENGTH } from "../../../../config";

import FormGroup from "../../../../components/ui/form/group";
import FormActions from "../../../../components/ui/form/actions";
import FormError from "../../../../components/ui/form/error";
import CheckboxField from "../../../../components/ui/form/checkbox_field";
import Button from "../../../../components/ui/form/button";


class ChangeSubscriptionForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchStatus, setSubmitting } = this.props;

    if (prevProps.fetchStatus !== fetchStatus && !fetchStatus.isFetching) {
      setSubmitting(false);
    }
  }

  render() {
    const {
      account,
      isSubmitting,
      handleSubmit,
    } = this.props;

    return(
      <div className="p-settings__form">
        <Form>
          <Field name="subscribe_to_news" component={CheckboxField}>
            {i18n.t('pages.account.form.subscribe_to_news.label')}
          </Field>

          <Field name="subscribe_to_world_art_news" component={CheckboxField}>
            {i18n.t('pages.account.form.subscribe_to_world_art_news.label')}
          </Field>

          <FormActions className="button-wrapper p-settings__form-button-wrapper">
            <FormError>
              {account.error}
            </FormError>
            <Button
              className="p-settings__form-button"
              type="submit"
              disabled={isSubmitting}
              onSubmit={handleSubmit}
            >
              {i18n.t("pages.account.subscription.form.submit.title")}
            </Button>
          </FormActions>
        </Form>

      </div>
    )
  }
}

const ChangeSubscriptionFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};
    return errors;
  },
})(ChangeSubscriptionForm);

ChangeSubscriptionFormWithFormik.propTypes = {
  account: PropTypes.shape(USER_SHAPE).isRequired,
  fetchStatus: PropTypes.shape(MODIFICATION_SHAPE).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangeSubscriptionFormWithFormik
