import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";
import { EXPOSITION_SHAPE, EXPOSITION_PRICE_SHAPE } from "./expositionConstants";

export const CART_ITEM_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  count: PropTypes.number,
  cartable_id: PropTypes.number,
  cartable_type: PropTypes.string,
  cartable: PropTypes.shape(EXPOSITION_SHAPE),
  price_id: PropTypes.number,
  price: PropTypes.shape(EXPOSITION_PRICE_SHAPE),
}

export const CART_FORM_SHAPE = {
  accept_terms: PropTypes.bool.isRequired,
  accept_rules: PropTypes.bool.isRequired
}

export const CART_ITEM_EXPOSITION_TYPE = 'Exposition';
export const CART_ITEM_TOUR_TYPE = 'Tour';

export const CART_ITEM_PREVIEWS = createRequestActionsFor("CART_ITEM_PREVIEWS");
export const CART_ITEM_CREATE = createRequestActionsFor("CART_ITEM_CREATE");
export const CART_ITEM_UPDATE = createRequestActionsFor("CART_ITEM_UPDATE");
export const CART_ITEM_DELETE = createRequestActionsFor("CART_ITEM_DELETE");
