import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../translations/i18n";
import {
  openModal,
  closeModal,
} from "../../../../actions/modalActions";
import BaseModal from "../../../../components/ui/modal/base";
import { FAVORITE_MODAL } from "../../../../constants/modalConstants";


class FavoriteWarningModal extends Component {

  constructor(props) {
    super(props);
  }

  handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.closeModal();
  }

  render() {
    const { modal } = this.props;

    return (
      <BaseModal
        className="modal modal-message"
        isOpen={modal.name === FAVORITE_MODAL}
        onClose={this.handleClose}
      >
        <div className="modal__title">
          {i18n.t('modal.favorite.title')}
        </div>
        <div className="modal__form">
          <div className="modal__form-button-wrapper">
            <a
              href="/login"
              className="button modal__form-button-auth"
            >
              {i18n.t('modal.login.title')}
            </a>
            <div className="modal__form-button-divider">
              {i18n.t('modal.divider.title')}
            </div>
            <a
              href="/signup"
              className="button modal__form-button-auth"
            >
              {i18n.t('modal.register.title')}
            </a>
          </div>
        </div>
      </BaseModal>
    )
  }
}

FavoriteWarningModal.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {
  return {
    modal: layout.modal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    closeModal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteWarningModal)