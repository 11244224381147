import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";

import { API, ARTISTS_PER_PAGE } from "../config";
import {
  ARTIST_PREVIEWS,
  ARTIST_ITEM,
  ARTIST_OPTIONS,
  ARTIST_LETTERS_PREVIEWS,
} from "../constants/aritstConstants";
import {
  artistPreviewSchema,
  artistSchema,
  artistOptionsSchema,
} from "../schemas";
import { DEFAULT_COLLECTION } from "../selectors/artistPreviews";
import { FUNC_REPLACE } from "../reducers/entities/collectionsReducer";


export function fetchItems(q = null, topArtists, page, perPage = ARTISTS_PER_PAGE, fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE,
  };

  let endpoint = `${API}/artists?page=${page}&per_page=${perPage}`;
  if (q) {
    endpoint = `${API}/artists?q=${encodeURIComponent(q)}&page=${page}&per_page=${perPage}`
  }
  if (topArtists) {
    endpoint += `&top=true`
  }

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: endpoint,
        types: ARTIST_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { artists, meta } = await res.json();

      return {
        ...normalize(artists, [artistPreviewSchema]),
        pagination: meta,
      };
    }
  };
}


export function fetchItem(id, fetchOptions = {}) {
  const meta = {
    key: id,
  };
  const include = fetchOptions.include || {};

  return function fetchItemThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/artists/${id}`,
        types: ARTIST_ITEM.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { artist } = await res.json();

      return {
        ...normalize(artist, artistSchema),
      }
    }
  }
}


export function fetchArtistOptions(fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/options/artists`,
        types: ARTIST_OPTIONS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { artists } = await res.json();

      return {
        ...normalize(artists, [artistOptionsSchema])
      };
    }
  };
}


export function fetchArtistLetters(fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/artists/letters`,
        types: ARTIST_LETTERS_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { letters } = await res.json();

      return {
        letters
      };
    }
  };
}
