import PropTypes from "prop-types";

import i18n from "../translations/i18n";
import { createRequestActionsFor } from "./apiFetch";

export const ACCOUNT_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  email: PropTypes.string,
  phone: PropTypes.string,
  confirmed: PropTypes.bool,
  avatar: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  role_human_name: PropTypes.string,
  subscribe_to_news: PropTypes.bool,
  subscribe_to_world_art_news: PropTypes.bool,
}

export const USER_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  email: PropTypes.string,
};

export const USER_SESSIONS_SHAPE = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
}

export const USER_RESTORE_PASSWORD_SHAPE = {
  email: PropTypes.string.isRequired,
}

export const USER_AUTH_SHAPE = {
  error: PropTypes.string,
}

export const USER_SUBSCRIPTION_SHAPE = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export const USER_PHONE_CONFIRMATION_SHAPE = {
  code: PropTypes.string.isRequired,
}

export const USER_MENU_SELECT_OPTIONS = [
  { value: '/account/settings', label: i18n.t('pages.account.menu.profile.title') },
  { value: '/account/change_email', label: i18n.t('pages.account.menu.email.title') },
  { value: '/account/change_phone', label: i18n.t('pages.account.menu.phone.title') },
  { value: '/account/change_password', label: i18n.t('pages.account.menu.password.title') },
  { value: '/account/subscription', label: i18n.t('pages.account.menu.subscription.title') },
  { value: '/account/delete', label: i18n.t('pages.account.menu.delete.title') },
]

export const USER_ROLE_OPTIONS = [
  { value: i18n.t('roles.enthusiast.value'), label: i18n.t('roles.enthusiast.label') },
  { value: i18n.t('roles.collector.value'), label: i18n.t('roles.collector.label') },
  { value: i18n.t('roles.expert.value'), label: i18n.t('roles.expert.label') },
]

export const USER_TECHNIQUE_OPTIONS = [
  { value: i18n.t('techniques.painting.value'), label: i18n.t('techniques.painting.label') },
  { value: i18n.t('techniques.graphic.value'), label: i18n.t('techniques.graphic.label') },
]

export const USER_GENRE_OPTIONS = [
  { value: i18n.t('genres.scenery.value'), label: i18n.t('genres.scenery.label') },
  { value: i18n.t('genres.portrait.value'), label: i18n.t('genres.portrait.label') },
  { value: i18n.t('genres.still_life.value'), label: i18n.t('genres.still_life.label') },
  { value: i18n.t('genres.household.value'), label: i18n.t('genres.household.label') },
  { value: i18n.t('genres.interior.value'), label: i18n.t('genres.interior.label') },
  { value: i18n.t('genres.animalistic.value'), label: i18n.t('genres.animalistic.label') },
  { value: i18n.t('genres.battle.value'), label: i18n.t('genres.battle.label') },
  { value: i18n.t('genres.historical.value'), label: i18n.t('genres.historical.label') },
  { value: i18n.t('genres.seascape.value'), label: i18n.t('genres.seascape.label') },
  { value: i18n.t('genres.nude.value'), label: i18n.t('genres.nude.label') },
]

export const USER_YEAR_OPTIONS = [
  { value: i18n.t('years.1820_1869.value'), label: i18n.t('years.1820_1869.label') },
  { value: i18n.t('years.1870_1923.value'), label: i18n.t('years.1870_1923.label') },
  { value: i18n.t('years.1924_1960.value'), label: i18n.t('years.1924_1960.label') },
]

export const USER_REGISTRATION_SOURCE = "registration_popup";
export const USER_SUBSCRIBER_SOURCE = "subscription_form";

export const USER_SIGN_IN = createRequestActionsFor("USER_SIGN_IN");
export const USER_SIGN_UP = createRequestActionsFor("USER_SIGN_UP");
export const USER_CONFIRM_PHONE = createRequestActionsFor("USER_CONFIRM_PHONE");
export const USER_RESET_PHONE_CHANGE = createRequestActionsFor("USER_RESET_PHONE_CHANGE");
export const USER_RESEND_CODE = createRequestActionsFor("USER_RESEND_CODE");
export const USER_SUBSCRIBE = createRequestActionsFor("USER_SUBSCRIBE");
export const USER_RESTORE_PASSWORD = createRequestActionsFor("USER_RESTORE_PASSWORD");
export const USER_CHANGE_PASSWORD = createRequestActionsFor("USER_CHANGE_PASSWORD");
export const USER_RESTORE_ACCOUNT = createRequestActionsFor("USER_RESTORE_ACCOUNT");
export const USER_CHECK_TOKEN = createRequestActionsFor("USER_CHECK_TOKEN");

export const USER_ITEM = createRequestActionsFor("USER_ITEM");
export const USER_ITEM_UPDATE = createRequestActionsFor("USER_ITEM_UPDATE");
export const USER_ITEM_DELETE = createRequestActionsFor("USER_ITEM_DELETE");

export const USER_SIGN_OUT = createRequestActionsFor("USER_SIGN_OUT");

export const USER_RESET_AUTH_FORM = "USER_RESET_AUTH_FORM";
