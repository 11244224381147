import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import i18n from "../../../../translations/i18n";
import { withFavoriteArtists } from "../../../../hocs/entities/withFavoriteArtists";
import { ARTIST_SHAPE } from "../../../../constants/aritstConstants";
import { FAVORITE_ARTISTS_PER_PAGE } from "../../../../config";
import Empty from "../../../../components/account/empty";
import List from "./list";


class FavoriteArtists extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    }
  }

  componentDidMount() {
    this.props.fetchFavoriteArtists();
  }

  handleLoad = () => {
    const { pagination, fetchFavoriteArtists } = this.props;
    if (event) {
      event.preventDefault();
    }

    if (!isNil(pagination.next_page)) {
      this.setState({currentPage:  pagination.next_page});
      fetchFavoriteArtists(pagination.next_page);
    }
  }

  handleFavorite = () => {
    const { currentPage } = this.state;

    this.props.fetchFavoriteArtists(1, currentPage * FAVORITE_ARTISTS_PER_PAGE);
  }

  render () {
    const { artists, pagination } = this.props;

    return (
      <div className="p-profile__painters">
        <h3 className="p-profile__title">
          {i18n.t("pages.account.artists.title")}
        </h3>
        {isEmpty(artists)
          ? <Empty
              to="/artists"
              title={i18n.t('pages.account.artists.empty.title')}
              button={i18n.t('pages.account.artists.empty.button')}
            />
          : <>
              <List
                items={artists}
                onFavorite={this.handleFavorite}
              />
              {!isNil(pagination.next_page) &&
                <div className="button__wrapper">
                  <a
                    href="#"
                    className="button button-more"
                    title={i18n.t('pages.account.artists.more.title')}
                    onClick={this.handleLoad}
                  >
                    {i18n.t('pages.account.artists.more.title')}
                  </a>
                </div>
              }
            </>
        }
      </div>
    );
  }
}

FavoriteArtists.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.shape(ARTIST_SHAPE)),
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withFavoriteArtists,
  connect(mapStateToProps, mapDispatchToProps)
)(FavoriteArtists)