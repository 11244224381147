import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { ARTIST_PREVIEWS } from "../../../constants/aritstConstants";
import { makeCollectionsReducer } from "../collectionsReducer";
import { artistPreviewSchema } from "../../../schemas";
import filter from "./filter";


export default combineReducers({
  byId: makeByIdReducer(artistPreviewSchema.key, [ARTIST_PREVIEWS.SUCCESS]),
  collections: makeCollectionsReducer(ARTIST_PREVIEWS.typeNames()),
  filter,
});