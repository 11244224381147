import React, { Component, createRef } from "react";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import SelectInput from "../../../../components/ui/form/select_input";
import FormGroup from "../../../../components/ui/form/group";
import FormError from "../../../../components/ui/form/error";
import InputField from "../../../../components/ui/form/input_field";
import MaskInputField from "../../../../components/ui/form/mask_input_field";
import {
  GA_ORDERS_ABOUT_SOURCE,
  TrackChooseServiceClick,
  TrackOrderSubmitClick,
  TrackOrderSubmitSubmitError,
  TrackFieldError,
  TrackFieldValid,
} from "../../../../helpers/analytics";
import emailDomainIsInvalid from "../../../../helpers/invalidEmailDomains";
import phoneIsLandline from "../../../../helpers/landlinePhoneCodes";


class OrderForm extends Component {

  constructor(props) {
    super(props);
    this.orderRef = createRef();
  }

  gaTrackChooseServiceClick() {
    const { source } = this.props;
    TrackChooseServiceClick(source)
  }

  render() {
    const {
      touched,
      services,
      order,
      userLoggedIn,
      serviceOptions,
      notSelectedOptions,
      handleClearOrder,
      handleSelect,
      handleAdditionalSelect,
      selectedServices,
      handleSubmit,
      source
    } = this.props;

    const canBeSent = selectedServices.length > 0;
    const sendButtonDisabled = canBeSent ? undefined : "disabled";

    return (
      <>
        <div ref={this.orderRef}></div>
        <div className="p-about__order" id="order">
          <div className={source == GA_ORDERS_ABOUT_SOURCE ? "container" : ""}>
            <div className={source == GA_ORDERS_ABOUT_SOURCE ? "p-about__order-content" : "p-about__order-content embedded"}>
              <div className="p-about__order-title">
                {i18n.t('pages.about.order.title')}
              </div>
              <div className="p-about__order-form">
                <Form>
                  { !order.submitted && (
                    <>
                      {/* Уже выбранные услуги */}
                      { selectedServices.map((serviceId, index) =>
                        <div className="p-about__order-field-wrapper" key={serviceId}>
                          <SelectInput
                            options={notSelectedOptions(services)}
                            classNamePrefix="react-select"
                            defaultValue={serviceOptions(services).filter(option => serviceId === option.value)[0]}
                            onChange={(e) => handleSelect(e, index)}
                          />
                        </div>
                      )}

                      {/* Добавить еще одну */}
                      { notSelectedOptions.length > 0 && (
                        <div className="p-about__order-field-wrapper" key={0}>
                          <SelectInput
                            options={notSelectedOptions(services)}
                            value={null}
                            placeholder={(selectedServices.length > 0) ? i18n.t('pages.about.order.select.add_service') : i18n.t('pages.about.order.select.choose')}
                            onClick={this.gaTrackChooseServiceClick()}
                            onChange={handleAdditionalSelect}
                          />
                        </div>
                      )}

                      { selectedServices.length > 0 && (
                        <>
                          {/* Оставить комментарий */}
                          <div className="p-about__order-field-wrapper" key="comment">
                            <FormGroup
                              className="form__group"
                              label={i18n.t('pages.about.order.select.comment')}
                              touched={touched.comment || (this.props.values.comment && this.props.values.comment.length > 0)}>
                              <Field
                                component={InputField}
                                className="form__field p-about__order-field"
                                name="comment"
                                component="textarea"
                              />
                            </FormGroup>
                          </div>

                          { !userLoggedIn && (
                            <>
                              <br/>
                              <br/>
                              {/* Данные пользователя */}
                              <div className="p-partners__form-section">
                                  <div className="section-title p-partners__form-section-title">
                                    {i18n.t('pages.about.order.user.title')}
                                  </div>

                                  <div className="p-about__order-field-wrapper" key="username">
                                    <FormGroup
                                      className="form__group"
                                      label={i18n.t('pages.partners.form.username.label')}
                                      touched={touched.username}>
                                      <Field
                                        component={InputField}
                                        className="form__field p-about__order-field"
                                        name="username"
                                        required
                                      />
                                    </FormGroup>
                                  </div>

                                  <div className="p-about__order-field-wrapper" key="phone">
                                    <FormGroup
                                      className="form__group"
                                      label={i18n.t('pages.about.order.select.phone')}
                                      touched={touched.phone}>
                                      <Field
                                        component={MaskInputField}
                                        className="form__field p-about__order-field"
                                        mask="+7 (999) 999-99-99"
                                        name="phone"
                                      />
                                    </FormGroup>
                                  </div>

                                  <div className="p-about__order-field-wrapper" key="email">
                                    <FormGroup
                                      className="form__group"
                                      label={i18n.t('pages.about.order.select.email')}
                                      touched={touched.email}>
                                      <Field
                                        component={InputField}
                                        className="form__field p-about__order-field"
                                        name="email"
                                        required
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                            </>
                          )}

                          <FormError>
                            {order.error}
                          </FormError>

                          {/* Кнопка отправить */}
                          <div className="p-about__order-button-wrapper" key="send">
                            <button
                              className="button p-about__order-button"
                              disabled={sendButtonDisabled}
                              type="submit"
                              onSubmit={handleSubmit}
                              // onClick={() => this.gaTrackSubmitClick(this.props.values)}
                            >
                              {i18n.t('shared.button.send')}
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  ) || (
                    <>
                      {/* Заказ успешно отправлен + заказать еще */}
                      <div className="p-about__order-success" key="success">
                        <div dangerouslySetInnerHTML={{ __html: i18n.t('pages.about.order.success')}} className="p-about__order-success-text"/>
                        <div className="p-about__order-success-link-wrapper">
                          <a className="p-about__order-success-link" title={i18n.t('pages.about.order.another_service')} onClick={handleClearOrder}>
                            {i18n.t('pages.about.order.another_service')}
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const OrderWithFormik = withFormik({
  handleSubmit: (values, {props: {handleSubmit}}) => handleSubmit(values),
  validate: (values, props) => {
    let errors = {};

    TrackOrderSubmitClick(values.source);

    const requiredUserFields = ['username', 'email']

    if (!props.userLoggedIn) {
      requiredUserFields.map((field) => {
        if (isEmpty(values[field])) {
          TrackFieldError(field, 'empty', values.source);
          errors[field] = i18n.t("validation.field.required");
        } else {
          TrackFieldValid(field, values.source);
        }
      });

      if (!isEmpty(values.phone) && (!/^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/i.test(values.phone))) {
        TrackFieldError('phone', 'empty', values.source);
        errors.phone = i18n.t("validation.phone.invalid");
      } else if (!isEmpty(values.phone) && phoneIsLandline(values.phone)) {
        TrackFieldError('phone', 'invalid', values.source);
        errors.phone = i18n.t("validation.phone.landline");
      } else {
        TrackFieldValid(field, values.source);
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        TrackFieldError('email', 'empty', values.source);
        errors.email = i18n.t("validation.email.invalid");
      } else if (emailDomainIsInvalid(values.email)) {
        TrackFieldError('email', 'invalid', values.source);
        errors.email = i18n.t("validation.email.invalid");
      } else {
        TrackFieldValid(field, values.source);
      }
    }

    if (!isEmpty(errors)) {
      TrackOrderSubmitSubmitError(values.source);
    }

    return errors;
  }
})(OrderForm);

export default OrderWithFormik
