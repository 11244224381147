import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";

import {
  API,
  FAVORITE_ARTISTS_PER_PAGE,
  FAVORITE_PAINTINGS_PER_PAGE,
  FAVORITE_POSTS_PER_PAGE,
} from "../config";
import {
  FAVORITE_ITEM_CREATE,
  FAVORITE_ITEM_DELETE,
} from "../constants/favoriteConstants";
import { PAINTING_PREVIEWS } from "../constants/paintingConstants";
import { ARTIST_PREVIEWS } from "../constants/aritstConstants";
import { POST_PREVIEWS } from "../constants/postConstants";
import {
  artistPreviewSchema,
  paintingPreviewSchema,
  postPreviewSchema,
} from "../schemas";
import { DEFAULT_COLLECTION as DEFAULT_ARTISTS_COLLECTION } from "../selectors/artistPreviews";
import { DEFAULT_COLLECTION as DEFAULT_PAINTINGS_COLLECTION } from "../selectors/paintingPreviews";
import { DEFAULT_COLLECTION as DEFAULT_POSTS_COLLECTION } from "../selectors/postPreviews";
import { DEFAULT_MODIFICATION } from "../selectors/favorites";
import { FUNC_REPLACE, FUNC_APPEND } from "../reducers/entities/collectionsReducer";


export function fetchFavoriteArtists(page = 1, perPage = FAVORITE_ARTISTS_PER_PAGE, fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_PAINTINGS_COLLECTION,
    func: page === 1 ? FUNC_REPLACE : FUNC_APPEND,
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/artists?page=${page}&per_page=${perPage}`,
        types: ARTIST_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { artists, meta } = await res.json();

      return {
        ...normalize(artists, [artistPreviewSchema]),
        pagination: meta,
      };
    }
  };
}


export function fetchFavoritePaintings(page = 1, perPage = FAVORITE_PAINTINGS_PER_PAGE, fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_ARTISTS_COLLECTION,
    func: page === 1 ? FUNC_REPLACE : FUNC_APPEND,
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/paintings?page=${page}&per_page=${perPage}`,
        types: PAINTING_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { paintings, meta } = await res.json();

      return {
        ...normalize(paintings, [paintingPreviewSchema]),
        pagination: meta,
      };
    }
  };
}


export function fetchFavoritePosts(page = 1, perPage = FAVORITE_POSTS_PER_PAGE, fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_POSTS_COLLECTION,
    func: page === 1 ? FUNC_REPLACE : FUNC_APPEND,
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/posts?page=${page}&per_page=${perPage}`,
        types: POST_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { posts, meta } = await res.json();

      return {
        ...normalize(posts, [postPreviewSchema]),
        pagination: meta,
      };
    }
  };
}


export function createItem(id, type, path) {
  return function(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/favorites`,
        method: 'POST',
        body: {
          favorite: {
            favorable_id: id,
            favorable_type: type,
          },
          path: path,
        },
        types: FAVORITE_ITEM_CREATE.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      return {}
    }
  }
}


export function deleteItem(id, fetchOptions = {}) {
  return async function removeItemThunk(dispatch) {
    const meta = {
      modification: fetchOptions.modification || DEFAULT_MODIFICATION,
    };

    return dispatch({
      [RSAA]: {
        method: "DELETE",
        endpoint: `${API}/account/favorites/${id}`,
        types: FAVORITE_ITEM_DELETE.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      return {};
    }
  }
}
