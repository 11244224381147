import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../translations/i18n";
import { POST_SHAPE } from "../../../../constants/postConstants";
import Favorite from "../../../../components/layout/favorite";
import Link from "../../../../components/ui/link";


class Item extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item, onFavorite } = this.props;
    const postPath = `/posts/${item.slug}`

    return (
      <div className="p-journal__post">
        <div className="p-journal__post-image-wrapper">
          <Link to={postPath}>
            <img
              src={item.poster.medium}
              className="p-journal__post-image"
              alt={item.name_without_word_breaks}
              title={item.name_without_word_breaks}
            />
          </Link>
        </div>
        <div className="p-journal__post-info">
          <div className="p-journal__post-date">
            {item.date}
          </div>
          <h2 className="p-journal__post-title">
            <Link to={postPath}>
              <span dangerouslySetInnerHTML={{__html: item.name}} />
            </Link>
          </h2>
          <p
            className="p-journal__post-description"
            dangerouslySetInnerHTML={{__html: item.annotation}} />
          <div className="p-journal__post-media">
            <div className="p-journal__post-media-read-more-wrapper">
              <Link
                to={postPath}
                className="p-journal__post-media-read-more"
                title={i18n.t('pages.posts.actions.read.title')}
              >
                {i18n.t('pages.posts.actions.read.title')}
              </Link>
            </div>
            <div className="p-journal__post-media-favorite-wrapper">
              <Favorite
                favorite={item.favorite}
                isShort={true}
                onFavorite={onFavorite}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  item: PropTypes.shape(POST_SHAPE).isRequired,
  onFavorite: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)
