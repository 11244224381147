import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ru } from "./ru.json";

export const LOCALE_RU = "ru";
export const DEFAULT_LANGUAGE = "ru";

i18n
  .use(initReactI18next)
  .init({
    resources: { ru },
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: process.env.RAILS_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

export default i18n;