import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import i18n from "../../../../translations/i18n";
import { fetchItem, signOut } from "../../../../actions/userActions";
import { getAccount } from "../../../../selectors/accounts";
import { USER_SHAPE } from "../../../../constants/userConstants";
import Link from "../../../../components/ui/link";


class Account extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { userLoggedIn, fetchItem } = this.props;

    if (userLoggedIn) {
      fetchItem();
    }
  }

  handleSignOut = (event) => {
    event.preventDefault();
    this.props.signOut();
  }

  render () {
    const { account, userLoggedIn } = this.props;

    return (
      <>
        { userLoggedIn && account &&
          <div className="header__account">
            <div className="header__account-avatar">
              {account.avatar &&
                <Link to="/account">
                  <img alt="" src={account.avatar} />
                </Link>
              }
            </div>
            <div className="header__account-name">
              <Link to="/account">
                {account.name}
              </Link>
            </div>
            <div className="header__account-link">
              <Link
                to="/account/settings"
              >
                {i18n.t('header.settings.title')}
              </Link>
            </div>
            <div className="header__account-link">
              <a
                href="#"
                onClick={this.handleSignOut}
              >
                {i18n.t('header.sign_out.title')}
              </a>
            </div>
          </div>
        }
      </>
    );
  }
}

Account.propTypes = {
  account: PropTypes.shape(USER_SHAPE),
  fetchItem: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const currentUser = state.currentUser;

  return {
    currentUser: currentUser,
    account: getAccount(state, currentUser.id),
    userLoggedIn: !!currentUser.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchItem,
    signOut
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Account)