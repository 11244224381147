import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { withInteriors } from "../../../hocs/entities/withInteriors";
import { INTERIOR_SHAPE } from "../../../constants/interiorConstants";
import Gallery from "./gallery";


class Interiors extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchInteriors();
  }

  render () {
    const { interiors, fetchInteriors } = this.props;

    return (
      <>
        {!isEmpty(interiors) &&
          <div className="b-interior-carousel">
            <div className="section-title">
              {i18n.t("pages.home.interiors.title")}
            </div>
            <Gallery
              images={interiors}
              onAppointment={fetchInteriors} />
          </div>
        }
      </>
    );
  }
}

Interiors.propTypes = {
  interiors: PropTypes.arrayOf(PropTypes.shape(INTERIOR_SHAPE)),
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withInteriors,
  connect(mapStateToProps, mapDispatchToProps)
)(Interiors)
