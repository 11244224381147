import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import compact from "lodash/compact";

import { withSeo } from "../../../hocs/entities/withSeo";
import { ARTIST_SHAPE } from "../../../constants/aritstConstants";
import { SEO_SHAPE } from "../../../constants/seoConstants";
import { APP_URL } from "../../../config";


class Meta extends Component {

  constructor(props) {
    super(props);
  }

  buildJson = () => {
    const { seo, items, location } = this.props;

    if (seo && seo.schema_org) {
      const json = {
        ...seo.schema_org,
        url: `${APP_URL}${location.pathname}`,
        itemListElement: compact((items || []).map((item, index) => this.renderItem(item, index))),
      }

      return JSON.stringify(json)
    }
  }

  renderItem = (item, index) => {
    const { seo } = this.props;

    if (seo && item.schema_org) {
      const json = {
        ...item.schema_org,
        "@type": ["Person", "ListItem"],
        position: index.toString(),
      }

      return json
    }
  }

  render() {
    const { seo } = this.props;

    return(
      <>
        {seo &&
          <Helmet encodeSpecialCharacters={false}>
            <meta charSet="utf-8" />
            <title>{seo.title}</title>
            <link rel="canonical" href={seo.canonical} />
            <meta name="description" content={seo.description} />
            <meta name="keywords" content={seo.keywords} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:url" content={`${APP_URL}${location.pathname}`} />
            {seo.schema_org &&
              <meta property="og:image" content={seo.schema_org.image} />
            }
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <script type="application/ld+json">
              {this.buildJson()}
            </script>
          </Helmet>
        }
      </>
    )
  }
}

Meta.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ARTIST_SHAPE)),
  seo: PropTypes.shape(SEO_SHAPE),
};

export default compose(
  withSeo,
)(Meta)
