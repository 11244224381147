import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { GA_TRACKING_ID } from "../../../config";


class Analytics extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { pathname, search, history } = location;

    if ((pathname !== prevProps.location.pathname) || search !== prevProps.location.search) {
      setTimeout(() => {
        if (this.props.history.action === 'PUSH' && typeof(gtag) === 'function') {
          gtag('config', GA_TRACKING_ID, {
            'page_title': document.title,
            'page_location': window.location.href,
            'page_path': location.pathname
          });
        }
      }, 100);

      setTimeout(() => {
        if (this.props.history.action === 'PUSH' && typeof(ym) === 'function') {
          ym('hit', location.pathname);
        }
      }, 100);
    }
  }

  render() {
    return null;
  }
}

Analytics.propTypes = {
};

export default withRouter(Analytics);