import React, { Component } from "react";

import Notice from "../../components/layout/notice";


export function withNotice() {
  return function(WrappedComponent) {

    const WithNotice = class extends Component {

      render() {
        const { ...restProps } = this.props;

        return (
          <>
            <WrappedComponent
              {...restProps}
            />
            <Notice />
          </>
        )
      }
    };

    return WithNotice;
  }
}