import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import cn from "classnames";

import i18n from "../../../../translations/i18n";
import { withHeader } from "../../../../hocs/layout/withHeader";


class Loading extends Component {

  render() {
    const { isLoading, className, message } = this.props;

    if (isLoading) {
      return (
        <div className={cn('layout', className)}>
          <div className="loading__message">
            {message ? message : i18n.t("helpers.loading.title")}
          </div>
        </div>
      )
    } else {
      return null;
    }
  }

}

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  message: PropTypes.string,
};

Loading.defaultProps = {
  className: "",
};

export default compose(
  withHeader({transparent: false}),
)(Loading)