import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../translations/i18n";
import {
  closeModal,
} from "../../../../actions/modalActions";
import { EXPOSITION_ORDER_TICKET_MODAL } from "../../../../constants/modalConstants";
import BaseModal from "../../../ui/modal/base";


class ExpositionOrderTicketModal extends Component {

  constructor(props) {
    super(props);
  }

  handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.closeModal();
  }

  render() {
    const { modal } = this.props;

    return (
      <BaseModal
        className="modal"
        isOpen={modal.name === EXPOSITION_ORDER_TICKET_MODAL}
        onClose={this.handleClose}
      >
        <div
          className="modal-ecursion__title1"
          dangerouslySetInnerHTML={{__html: i18n.t('modal.ticket.success.title')}}
        />
        <div className="modal__form">
          <div className="modal__form-button-wrapper">
            <a
              href=""
              className="button modal__form-button-success modal__form-button-ok"
              onClick={this.handleClose}
            >
              {i18n.t('modal.ticket.success.button.title')}
            </a>
          </div>
        </div>
      </BaseModal>
    )
  }
}

ExpositionOrderTicketModal.propTypes = {
  modal: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {
  return {
    modal: layout.modal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpositionOrderTicketModal)
