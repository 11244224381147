import React, { Component } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import Item from "./item";
import PersonalTour from "../../../../components/layout/personal_tour";
import { EXPOSITION_SHAPE } from "../../../../constants/expositionConstants";


class Tours extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { exposition, currentDay, items, source, onPersonalTour } = this.props;

    return(
      <div className="p-exhibition__tickets">
        {(items || []).map((item) =>
          <Item
            key={item.id}
            currentDay={currentDay}
            item={item}
          />
        )}
        <div className="p-exhibition__ticket __individual">
          <div className="p-exhibition__ticket-body">
            <h3>{i18n.t('pages.exposition.personal_tour.title')}</h3>
            <p>{i18n.t('pages.exposition.personal_tour.description')}</p>
            <div className="p-exhibition__ticket-button-wrap">
              <PersonalTour
                className="p-exhibition__ticket-button button"
                available={true}
                currentDay={currentDay}
                personal_tour={exposition.personal_tour}
                source={source}
                onPersonalTour={onPersonalTour}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Tours.propTypes = {
  exposition: PropTypes.shape(EXPOSITION_SHAPE).isRequired,
};

export default Tours
