import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../translations/i18n";
import { APP_URL } from "../../../config";
import Icon from "../../ui/icon";

import { TrackExpositionShare } from "../../../helpers/analytics";

const SOCIAL = [
  {
    icon: "facebook",
    link: "https://www.facebook.com/sharer/sharer.php?u=",
    event_tag: "FB"
  },
  {
    icon: "vk",
    link: "https://vk.com/share.php?url=",
    event_tag: "VK"
  },
  {
    icon: "telegram",
    link: "https://t.me/share/url?url=",
    event_tag: "TG"
  },
  {
    icon: "mail",
    link: "mailto:?body=",
    event_tag: "email"
  }
]



class Share extends React.Component {

  constructor(props) {
    super(props);
  }

  gaTrackClick = (social, title) => {
    if (title) {
      TrackExpositionShare(social.event_tag, title)
    }
  }

  render () {
    const { slug, collection, title } = this.props;
    const shareUrl = `${APP_URL}/${collection}/${slug}`

    return (
      <>
        {shareUrl &&
          <div className="social-group">
            {SOCIAL.map((social, index) =>
              <div
                key={index}
                className="social-group__item"
              >
                <a
                  href={`${social.link}${shareUrl}`}
                  className="social-group__link"
                  target="_blank"
                  title={i18n.t('helpers.share.title')}
                  onClick={(e) => this.gaTrackClick(social, title)}
                  rel="noopener"
                >
                  <Icon
                    icon={social.icon}
                    className={`social-group__icon social-group__icon-${social.icon}`}
                  />
                </a>
              </div>
            )}
          </div>
        }
      </>
    )
  }
}

Share.propTypes = {
  slug: PropTypes.string.isRequired,
  collection: PropTypes.string,
  title: PropTypes.string
};

Share.defaultProps = {
  collection: 'expositions',
};

export default Share
