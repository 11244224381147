import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";

import { API } from "../config";
import {
  SEO_ITEM,
} from "../constants/seoConstants";
import {
  seoSchema,
} from "../schemas";


export function fetchItem(path, fetchOptions = {}) {
  const meta = {
    key: path,
  };
  const include = fetchOptions.include || {};

  return function fetchItemThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/seo?path=${path}`,
        types: SEO_ITEM.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { seo } = await res.json();

      return {
        ...normalize(seo, seoSchema),
      }
    }
  }
}
