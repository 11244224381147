import React from "react";
import PropTypes from "prop-types";

import { PROPERTY_OPTION_SHAPE } from "../../../../constants/propertyConstants";


class Options extends React.Component {

  constructor(props) {
    super(props);
  }

  itemClassName = (option) => {
    const { selected } = this.props;

    if (selected.filter((s) => s.id === option.id).length > 0) {
      return "state-selected";
    } else {
      "stub";
    }
  }

  handleClick = (event) => {
    event.preventDefault();
  }

  render () {
    const { title, options, selected, onToggle } = this.props;

    const counter = selected.length;
    const isSelected =  counter > 0;

    return (
      <li>
        <a href="#" onClick={this.handleClick}>
          {title}
          {isSelected &&
            <span className="p-paintings__filter-figure">{counter}</span>
          }
        </a>
        <ul>
          {options.map((option) =>
            <li key={option.id} className={this.itemClassName(option)}>
              <a href="#" title={option.name} onClick={(event) => onToggle(event, option)}>
                {option.name}
              </a>
            </li>
          )}
        </ul>
      </li>
    );
  }
}

Options.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(PROPERTY_OPTION_SHAPE)).isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape(PROPERTY_OPTION_SHAPE)),
  onToggle: PropTypes.func.isRequired,
};

export default Options