import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as QueryString from "query-string";

import Paginator from "./paginator";


export function getPage(location) {
  const params = QueryString.parse(location.search, {arrayFormat: 'bracket'});
  return parseInt(params.page) || 1;
}

class Pagination extends Component {

  componentDidMount() {
    const { location, onChange, fetchOnMount } = this.props;
    if (onChange && fetchOnMount) {
      onChange(getPage(location));
    }
  }

  componentDidUpdate(prevProps) {
    const { location, onChange } = this.props;
    const currentPage = getPage(location);
    if (getPage(prevProps.location) !== location && onChange) {
      onChange(currentPage);
    }
  }

  handlePageChange = (page) => {
    event.preventDefault();

    const { history } = this.props;
    const { pathname, search } = history.location;
    let params = new URLSearchParams(search);
    params.set("page", page);
    history.push(`${pathname}?${params.toString()}`);
    window.scrollTo(0, 0)
  };

  render() {
    const { location, totalPages, disabled, className } = this.props;

    let currentPage = getPage(location);
    let paginationTotalPages = totalPages;
    if (totalPages < currentPage) {
      paginationTotalPages = currentPage;
    }

    return (
      <>
        { paginationTotalPages > 1 &&
          <Paginator
            currentPage={currentPage}
            totalPages={paginationTotalPages}
            disabled={disabled}
            wrapperClassName={className}
            onChange={this.handlePageChange}
          />
        }
      </>
    )
  }

}

Pagination.propTypes = {
  history: PropTypes.object.isRequired,
  queryString: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  fetchOnMount: PropTypes.bool,
};

Pagination.defaultProps = {
  fetchOnMount: true
};

function mapStateToProps({ router: { location: { search } } }) {
  return { queryString: search };
}

export default withRouter(
  connect(mapStateToProps)(Pagination)
);
