import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  fetchItems,
  defaultArtists,
  defaultProperties,
  defaultNovelties,
} from "../../actions/paintingActions";
import {
  makeViewAnyKeySelector,
  makePaintingPreviewsSelector,
  makePaintingPreviewsAnyKeySelector,
  makePaintingPreviewsFetchStatusSelector,
} from "../../selectors/paintingPreviews";
import { PAINTING_SHAPE } from "../../constants/paintingConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withPaintings(WrappedComponent) {

  const WithPaintings = class extends Component {

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    fetchPaintings = (artists = defaultArtists, properties = defaultProperties, novelties = defaultNovelties, century = null, currentPage = 1) => {
      const { fetchItems, fetchStatus } = this.props;

      if (!fetchStatus.isFetching) {
        fetchItems(artists, properties, novelties, century, currentPage);
      }
    };

    render() {
      const { paintings, pagination, selectedArtists, selectedProperties, novelties, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    paintings={paintings}
                    pagination={pagination}
                    selectedArtists={selectedArtists}
                    selectedProperties={selectedProperties}
                    novelties={novelties}
                    fetchStatus={fetchStatus}
                    fetchPaintings={this.fetchPaintings}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithPaintings.propTypes = {
    paintings: PropTypes.arrayOf(PropTypes.shape(PAINTING_SHAPE)),
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const paintingsSelector = makePaintingPreviewsSelector();
  const paintingsPaginationSelector = makePaintingPreviewsAnyKeySelector('pagination');
  const paintingsFetchStatusSelector = makePaintingPreviewsFetchStatusSelector();
  const propertiesSelectedSelector = makeViewAnyKeySelector('filter');

  function mapStateToProps(state) {
    return {
      paintings: paintingsSelector(state),
      pagination: paintingsPaginationSelector(state) || {},
      fetchStatus: paintingsFetchStatusSelector(state),
      selectedProperties: propertiesSelectedSelector(state).properties || [],
      selectedArtists: propertiesSelectedSelector(state).artists || [],
      novelties: propertiesSelectedSelector(state).novelties,
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItems,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithPaintings);
}
