import React, { Component, Fragment } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import i18n from "../../translations/i18n";
import { withNotice } from "../../hocs/layout/withNotice";
import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import { withPosts } from "../../hocs/entities/withPosts";
import {
  POST_ARTICLE_KIND,
  POST_PHOTOREPORT_KIND,
  POST_YOUTUBE_KIND,
} from "../../constants/postConstants";
import Article from "../../components/posts/articles/item";
import Photoreport from "../../components/posts/photoreports/item";
import Youtube from "../../components/posts/youtubes/item";
import Pagination from "../../components/layout/pagination";
import Subscription from "../../components/layout/subscription";
import Meta from "./meta";


class Posts extends Component {

  constructor(props) {
    super(props);
  }

  handlePageChange = (page) => {
    if (event) {
      event.preventDefault();
    }
    this.props.fetchPosts(page);
  }

  handleFavorite = () => {
    const { pagination, fetchPosts } = this.props;

    fetchPosts(pagination.current_page);
  }

  render () {
    const { posts, pagination, fetchStatus } = this.props;

    return (
      <main className="layout">
        <Meta
          items={posts}
          pagination={pagination}
        />
        <div className="p-journal">
          <div className="container">
            <div
              className="p-journal__title"
              dangerouslySetInnerHTML={{__html: i18n.t('pages.posts.title')}}
            />
            <div className="p-journal__content" id="articles">
              {(posts || []).map((item) =>
                <Fragment key={item.id}>
                    {item.kind == POST_ARTICLE_KIND &&
                      <Article
                        item={item}
                        onFavorite={this.handleFavorite}
                      />
                    }
                    {item.kind == POST_PHOTOREPORT_KIND &&
                      <Photoreport
                        item={item}
                        onFavorite={this.handleFavorite}
                      />
                    }
                    {item.kind == POST_YOUTUBE_KIND &&
                      <Youtube
                        item={item}
                        onFavorite={this.handleFavorite}
                      />
                    }
                </Fragment>
              )}
            </div>
            <Pagination
              className="p-journal__pagination"
              totalPages={pagination.total_pages || 1}
              disabled={fetchStatus.isFetching}
              onChange={this.handlePageChange}
            />
          </div>
        </div>
        <Subscription source="posts" />
      </main>
    );
  }
}

Posts.propTypes = {
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withPosts,
  withNotice(),
  withBodyClass('app-posts'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Posts)
