import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { POST_PREVIEWS } from "../../../constants/postConstants";
import { makeCollectionsReducer } from "../collectionsReducer";
import { postPreviewSchema } from "../../../schemas";


export default combineReducers({
  byId: makeByIdReducer(postPreviewSchema.key, [POST_PREVIEWS.SUCCESS]),
  collections: makeCollectionsReducer(POST_PREVIEWS.typeNames()),
});