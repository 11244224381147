import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchItems } from "../../actions/expositionActions";
import {
  makeExpositionPreviewsSelector,
  makeExpositionPreviewsAnyKeySelector,
  makeExpositionPreviewsFetchStatusSelector,
} from "../../selectors/expositionPreviews";
import { EXPOSITION_SHAPE, EXPOSITION_KIND_ACTUAL } from "../../constants/expositionConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withExpositions(WrappedComponent) {

  const WithExpositions = class extends Component {

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    fetchExpositions = (kind = EXPOSITION_KIND_ACTUAL, currentPage = 1, perPage, fetchOptions = {}) => {
      const { fetchItems, fetchStatus } = this.props;

      if (!fetchStatus.isFetching) {
        fetchItems(kind, currentPage, perPage, fetchOptions);
      }
    };

    render() {
      const { expositions, pagination, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    expositions={expositions}
                    pagination={pagination}
                    fetchStatus={fetchStatus}
                    fetchExpositions={this.fetchExpositions}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithExpositions.propTypes = {
    expositions: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_SHAPE)),
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const expositionsSelector = makeExpositionPreviewsSelector();
  const expositionsPaginationSelector = makeExpositionPreviewsAnyKeySelector('pagination');
  const expositionsFetchStatusSelector = makeExpositionPreviewsFetchStatusSelector();

  function mapStateToProps(state) {
    return {
      expositions: expositionsSelector(state),
      pagination: expositionsPaginationSelector(state) || {},
      fetchStatus: expositionsFetchStatusSelector(state),
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItems,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithExpositions);
}
