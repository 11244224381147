import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchArtistLetters as fetchItems } from "../../actions/artistActions";
import {
  makeViewAnyKeySelector,
  makeArtistPreviewsFetchStatusSelector,
} from "../../selectors/artistPreviews";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withArtistsLetters(WrappedComponent) {

  const WithArtistsLetters = class extends Component {

    componentDidMount() {
      this.fetchArtistsLetters();
    }

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    fetchArtistsLetters = () => {
      this.props.fetchItems();
    };

    render() {
      const { letters, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    letters={letters}
                    fetchStatus={fetchStatus}
                    fetchArtistsLetters={this.fetchArtistsLetters}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithArtistsLetters.propTypes = {
    letters: PropTypes.array,
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const artistsFilterSelector = makeViewAnyKeySelector('filter');
  const artistsFilterFetchStatusSelector = makeArtistPreviewsFetchStatusSelector();

  function mapStateToProps(state) {
    return {
      letters: artistsFilterSelector(state).letters || [],
      fetchStatus: artistsFilterFetchStatusSelector(state),
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItems,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithArtistsLetters);
}