import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import { PAINTING_INFORMATION_SHAPE } from "../../../../constants/paintingConstants";


class Conclusion extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { title, items, className, id } = this.props;

    return (
      <>
        { !isEmpty(items) &&
          <div className={className} id={`#${id}`}>
            <h3 className="p-picture__info-description-title">
              <span>{title}</span>
            </h3>
            {items.map((item) =>
              <div
                key={item.id}
                className="p-picture__info-description-conclusion-item"
              >
                <div className="p-picture__info-description-conclusion-image-wrapper">
                  <img src={item.image} />
                </div>
                <div className="p-picture__info-description-conclusion-text">
                  <p dangerouslySetInnerHTML={{__html: item.description}} />
                </div>
              </div>
            )}
          </div>
        }
      </>
    )
  }
}

Conclusion.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(PAINTING_INFORMATION_SHAPE)),
  class: PropTypes.string,
  id: PropTypes.string,
};

export default Conclusion