import React, { createRef } from "react";
import i18n from "../../../translations/i18n";

class Instruction extends React.Component {

  render () {

    return (
      <>
        <div className="p-partners__instruction">
          <div className="section-title">
            {i18n.t('pages.partners.instruction.title')}
          </div>
          <div className="container">
            <div className="p-partners__instruction-row row">
              <div className="p-partners__instruction-item">
                <div className="p-partners__instruction-item-arrow">
                  <span/>
                </div>
                <div className="p-partners__instruction-item-info">
                  <div className="p-partners__instruction-item-title">
                    {i18n.t('pages.partners.instruction.item.one.title')}
                  </div>
                  <div className="p-partners__instruction-item-description">
                    {i18n.t('pages.partners.instruction.item.one.description')}
                  </div>
                </div>
              </div>
              <div className="p-partners__instruction-item">
                <div className="p-partners__instruction-item-arrow">
                  <span/>
                </div>
                <div className="p-partners__instruction-item-info">
                  <div className="p-partners__instruction-item-title">
                    {i18n.t('pages.partners.instruction.item.two.title')}
                  </div>
                  <div className="p-partners__instruction-item-description">
                    {i18n.t('pages.partners.instruction.item.two.description')}
                  </div>
                </div>
              </div>
              <div className="p-partners__instruction-item">
                <div className="p-partners__instruction-item-arrow">
                  <span/>
                </div>
                <div className="p-partners__instruction-item-info">
                  <div className="p-partners__instruction-item-title">
                    {i18n.t('pages.partners.instruction.item.three.title')}
                  </div>
                  <div className="p-partners__instruction-item-description">
                    {i18n.t('pages.partners.instruction.item.three.description')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Instruction;
