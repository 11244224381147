import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";


class Loader extends Component {

  render() {
    const { isLoading, className } = this.props;

    if (isLoading) {
      return (
        <div className={cn('loader', className)} />
      )
    } else {
      return null;
    }
  }

}

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: "",
};

export default Loader