import { artistOptionsSchema } from "../schemas";
import {
  makeAnyKeySelector,
  makeCollectionSelector,
  makeAnyKeyCollectionSelector,
  makeFetchStatusSelector,
} from "./collections";

export const DEFAULT_COLLECTION = "index";

export function makeArtistOptionsSelector(collection = DEFAULT_COLLECTION) {
  return makeCollectionSelector(collection, artistOptionsSchema);
}

export function makeArtistOptionsFetchStatusSelector(collection = DEFAULT_COLLECTION) {
  return makeFetchStatusSelector(collection, artistOptionsSchema);
}