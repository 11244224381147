import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import cn from "classnames";

const OPTION_SHAPE = {
  label: PropTypes.string,
  value: PropTypes.string,
}


class Select extends Component {

  constructor(props) {
    super(props);
  }

  handleChange = (option) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(option);
    }
  }

  render() {
    const { options, defaultValue, className, restProps } = this.props;

    return (
      <ReactSelect
        options={options}
        defaultValue={defaultValue}
        onChange={this.handleChange}
        className={cn("select-styled", className)}
        classNamePrefix="react-select"
        {...restProps}
      />
    )
  }
}

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape(OPTION_SHAPE)).isRequired,
  defaultValue: PropTypes.shape(OPTION_SHAPE).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Select