import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import { USER_SHAPE } from "../../../../constants/userConstants";
import { MODIFICATION_SHAPE } from "../../../../selectors/modifications";

import FormGroup from "../../../../components/ui/form/group";
import FormActions from "../../../../components/ui/form/actions";
import FormError from "../../../../components/ui/form/error";
import MaskInputField from "../../../../components/ui/form/mask_input_field";
import Button from "../../../../components/ui/form/button";
import phoneIsLandline from "../../../../helpers/landlinePhoneCodes";


class ChangePhoneForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchStatus, setSubmitting } = this.props;

    if (prevProps.fetchStatus !== fetchStatus && !fetchStatus.isFetching) {
      setSubmitting(false);
    }
  }

  render() {
    const {
      account,
      actionsEnabled,
      hintTitle,
      submitTitle,
      isSubmitting,
      handleSubmit,
    } = this.props;

    return(
      <Form>
        <FormGroup
          label={i18n.t("pages.account.form.phone.label")}
          hint={account.unconfirmed_phone ?
            i18n.t("pages.account.change_phone.unconfirmed.message", {phone: account.unconfirmed_phone}) :
            hintTitle
          }
          touched={true}
        >
          <Field
            component={MaskInputField}
            mask="+7 (999) 999-99-99"
            name="phone"
            required
          />
        </FormGroup>

        {actionsEnabled &&
          <FormActions className="button-wrapper p-settings__form-button-wrapper">
            <FormError>
              {account.error}
            </FormError>
            <Button
              className="p-settings__form-button"
              type="submit"
              disabled={isSubmitting}
              onSubmit={handleSubmit}
            >
              {submitTitle}
            </Button>
          </FormActions>
        }
      </Form>
    )
  }
}

const ChangePhoneFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values, {initialValues}) => {
    let errors = {};

    if (isEmpty(values.phone)) {
      errors.phone = i18n.t("validation.field.required");
    } else if (initialValues.phone === values.phone) {
      // hidden error – prevent form submittion
      errors.hidden = i18n.t("validation.phone.theSame");
    } else if (!/^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/i.test(values.phone)) {
      errors.phone = i18n.t("validation.phone.invalid");
    } else if (phoneIsLandline(values.phone)) {
      errors.phone = i18n.t("validation.phone.landline");
    }

    return errors;
  },
})(ChangePhoneForm);

ChangePhoneFormWithFormik.propTypes = {
  account: PropTypes.shape(USER_SHAPE).isRequired,
  actionsEnabled: PropTypes.bool,
  fetchStatus: PropTypes.shape(MODIFICATION_SHAPE).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ChangePhoneFormWithFormik.defaultProps = {
  hintTitle: i18n.t("pages.account.form.phone.hint"),
  submitTitle: i18n.t("pages.account.change_phone.form.submit.title")
}

export default ChangePhoneFormWithFormik
