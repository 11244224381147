import React, { Component } from "react";
import ReactGA from 'react-ga';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { GA_TRACKING_ID } from "./config";
import Routes from "./routes";
import { store, persistor } from "./store";

ReactGA.initialize(GA_TRACKING_ID);

class App extends Component {

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {Routes}
        </PersistGate>
      </Provider>
    )
  }
}

export default App
