import React from "react";
import PropTypes from "prop-types";
import { PAINTING_POSTER_SHAPE } from "../../../../constants/paintingConstants";


class Slide extends React.Component {

  constructor(props) {
    super(props);
  }

  handleClick = (event) => {
    const { index, currentSlide, onClick } = this.props;
    event.preventDefault();

    if (index === currentSlide) {
      onClick(index);
    }
  }

  render () {
    const { item } = this.props;

    return (
      <div className="p-picture__gallery-item">
        <div
          className="p-picture__gallery-item-image-wrap"
          onClick={this.handleClick}
        >
          <img
            src={item.medium}
            className="p-picture__gallery-item-image"
          />
          <div className="p-picture__gallery-button-plus button-plus" />
        </div>
      </div>
    )
  }
}

Slide.propTypes = {
  item: PropTypes.shape(PAINTING_POSTER_SHAPE).isRequired,
  index: PropTypes.number.isRequired,
  currentSlide: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Slide
