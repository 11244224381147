import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import i18n from "../../../../translations/i18n";
import { withFavoritePosts } from "../../../../hocs/entities/withFavoritePosts";
import { POST_SHAPE } from "../../../../constants/postConstants";
import { FAVORITE_POSTS_PER_PAGE } from "../../../../config";
import Empty from "../../../../components/account/empty";
import List from "./list";


class FavoritePosts extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    }
  }

  componentDidMount() {
    this.props.fetchFavoritePosts();
  }

  handleLoad = () => {
    const { pagination, fetchFavoritePosts } = this.props;
    if (event) {
      event.preventDefault();
    }

    if (!isNil(pagination.next_page)) {
      this.setState({currentPage:  pagination.next_page});
      fetchFavoritePosts(pagination.next_page);
    }
  }

  handleFavorite = () => {
    const { currentPage } = this.state;

    this.props.fetchFavoritePosts(1, currentPage * FAVORITE_POSTS_PER_PAGE);
  }

  render () {
    const { posts, pagination } = this.props;

    return (
      <div className="p-profile__journal">
        <h3 className="p-profile__title">
          {i18n.t("pages.account.posts.title")}
        </h3>
        {isEmpty(posts)
          ? <Empty
              to="/posts"
              title={i18n.t('pages.account.posts.empty.title')}
              button={i18n.t('pages.account.posts.empty.button')}
            />
          : <>
              <List
                items={posts}
                onFavorite={this.handleFavorite}
              />
              {!isNil(pagination.next_page) &&
                <div className="button__wrapper">
                  <a
                    href="#"
                    className="button button-more"
                    title={i18n.t('pages.account.posts.more.title')}
                    onClick={this.handleLoad}
                  >
                    {i18n.t('pages.account.posts.more.title')}
                  </a>
                </div>
              }
            </>
        }
      </div>
    );
  }
}

FavoritePosts.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape(POST_SHAPE)),
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withFavoritePosts,
  connect(mapStateToProps, mapDispatchToProps)
)(FavoritePosts)