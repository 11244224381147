import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cn from "classnames";
import isNil from "lodash/isNil";

import i18n from "../../../../../translations/i18n";
import { createItem } from "../../../../../actions/cartActions";
import Prices from "../../prices";


class Item extends Component {

  constructor(props) {
    super(props);
  }

  handleSubmit = (values) => {
    event.preventDefault();
    const { userLoggedIn, createItem } = this.props;

    if (userLoggedIn) {
      createItem(values);
    }
  }

  render() {
    const { currentDay, item } = this.props;

    return(
      <div className={cn('p-exhibition__ticket fade-in', { '__free': item.free })}>
        <div className="p-exhibition__ticket-top">
          <div className="p-exhibition__ticket-head">
            <div className="p-exhibition__ticket-head-info">
              <div className="p-exhibition__ticket-head-time">
                {item.start_at}
              </div>
              <div className="p-exhibition__ticket-head-amount">
                <ul>
                  <li>{i18n.t('pages.exposition.tickets.title')}</li>
                  <li>{item.tickets_count}</li>
                  <li>{item.tickets_available}</li>
                </ul>
              </div>
              {(item.adults_only || item.age_restriction) &&
                <div className="p-exhibition__ticket-head-age">
                  <>
                    { item.adults_only
                      ? <>18+</>
                      : <>
                          {!isNil(item.age_restriction) && <>{item.age_restriction}+</>}
                        </>
                    }
                  </>
                </div>
              }
            </div>
          </div>
          <div className="p-exhibition__ticket-body">
            {item.guide &&
              <h3>
                {item.guide.occupation}:&nbsp;
                <span>{item.guide.name}</span>
              </h3>
            }
            <div dangerouslySetInnerHTML={{__html: item.description}} />
          </div>
        </div>
        <Prices
          currentDay={currentDay}
          exposition={item}
          items={item.prices}
        />
      </div>
    )
  }
}

Item.propTypes = {
  createItem: PropTypes.func.isRequired,
};

function mapStateToProps({currentUser}) {
  return {
    userLoggedIn: !!currentUser.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createItem,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Item)

