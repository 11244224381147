import { GA_TRACKING_ENABLED } from "../../../config";

export const GA_LOGIN_FORM_SOURCE = "login form";

export function TrackLoginClick() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'вступить в клуб'",
    "click",
    "login form"
  )
}

export function TrackLoginCloseClick() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "X button_decline",
    "click",
    "login form"
  )
}

export function TrackLoginSubmitError() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'вступить в клуб'",
    "user_failure",
    "login form"
  )
}

export function TrackLoginSubmitSuccess() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'вступить в клуб'",
    "success",
    "login form"
  )
}
