import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isNil from "lodash/isNil";

import i18n from "../../../translations/i18n";
import { withAccount } from "../../../hocs/entities/withAccount";
import {
  openModal,
  closeModal
} from "../../../actions/modalActions";
import {
  updateItem,
  resendCode,
  confirmPhone,
  resetPhoneChange
} from "../../../actions/userActions";
import Form from "./form";
import PhoneConfirmationForm from "./phone_confirmation_form";


class Phone extends React.Component {

  constructor(props) {
    super(props);
  }

  handleSubmit = (values) => {
    const { fetchStatus, updateItem } = this.props;

    if (!fetchStatus.isFetching) {
      updateItem(values);
    }
  }

  handleResendCode = () => {
    this.props.resendCode();
  }

  handleReset = () => {
    this.props.resetPhoneChange();
  }

  handleConfirm = (params) => {
    this.props.confirmPhone(params);
  }

  render () {
    const { account, fetchStatus, hintTitle, submitTitle } = this.props;

    const hasPhone = !!account.phone || !!account.unconfirmed_phone;
    const phoneConfirmed = !!account.phone_confirmed;
    const actionsEnabled = (isNil(account.phone) && isNil(account.unconfirmed_phone)) || phoneConfirmed

    return (
      <>
        <Form
          initialValues={{phone: account.unconfirmed_phone || account.phone}}
          account={account}
          actionsEnabled={actionsEnabled}
          hintTitle={hintTitle}
          submitTitle={submitTitle}
          fetchStatus={fetchStatus}
          onSubmit={this.handleSubmit}
        />
        {hasPhone && !phoneConfirmed &&
          <PhoneConfirmationForm
            initialValues={{code: ''}}
            account={account}
            onResend={this.handleResendCode}
            onCodeReset={this.handleReset}
            onSubmit={this.handleConfirm}
          />
        }
      </>
    );
  }
}

Phone.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

function mapStateToProps({auth, currentUser, layout}) {
  return {
    auth,
    appointment: layout.appointment,
    modal: layout.modal,
    userLoggedIn: !!currentUser.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    closeModal,
    updateItem,
    resendCode,
    confirmPhone,
    resetPhoneChange,
  }, dispatch)
}

export default compose(
  withAccount,
  connect(mapStateToProps, mapDispatchToProps)
)(Phone)