import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import cn from "classnames";
import isNil from "lodash/isNil";

import i18n from "../../../translations/i18n";
import {
  createItem,
  enablePersonalTour,
  disablePersonalTour,
} from "../../../actions/personalTourActions";
import {
  openModal,
  closeModal,
} from "../../../actions/modalActions";
import { getAccount } from "../../../selectors/accounts";
import { PERSONAL_TOUR_SHAPE } from "../../../constants/personalTourConstants";
import {
  PERSONAL_TOUR_MODAL,
  LOGIN_MODAL,
  PHONE_MODAL
} from "../../../constants/modalConstants";
import { TrackAddPersonalTourLogin } from "../../../helpers/analytics";


class PersonalTour extends Component {

  constructor(props) {
    super(props);
  }

  openPersonalTourModal = () => {
    const { currentDay, personal_tour, source, onPersonalTour, openModal } = this.props;

    openModal({
      name: PERSONAL_TOUR_MODAL,
      expositionId: personal_tour.exposition_id,
      paintingName: personal_tour.name,
      source: source,
      onPersonalTour: onPersonalTour,
    });
  }

  handleAddPersonalTour = (event) => {
    const { account, personal_tour, onPersonalTour, userLoggedIn, openModal, enablePersonalTour, source } = this.props;
    event.preventDefault();

    // пользователь подтвердил номер телефона
    if (userLoggedIn && account.phone_confirmed && !isNil(account.phone)) {
      this.openPersonalTourModal();
    // номер не подтверджен: откладываем запись, подтверждаем номер
    } else if (userLoggedIn) {
      enablePersonalTour({
        expositionId: personal_tour.exposition_id,
        onPersonalTour: onPersonalTour,
      });
      openModal({name: PHONE_MODAL});
    // гость: откладываем запись, вход/регистрация
    } else {
      enablePersonalTour({
        expositionId: personal_tour.exposition_id,
        onPersonalTour: onPersonalTour,
      });
      TrackAddPersonalTourLogin();
      openModal({
        name: LOGIN_MODAL,
        source: 'форма "Регистрация" в записи на индивидуальную экскурсию ' + personal_tour.name
      });
    }
  }

  handleCloseModal = (event) => {
    const { closeModal, disablePersonalTour } = this.props;
    if (event) {
      event.preventDefault();
    }
    closeModal();
    disablePersonalTour();
  }

  renderAdded = () => {
    const { className } = this.props;
    return (
      <a
        href=""
        disabled
        title={i18n.t('helpers.personal_tour.added.title')}
        className={cn(className, '__added')}
        onClick={(event) => event.preventDefault()}
      >
        {i18n.t('helpers.personal_tour.added.title')}
      </a>
    )
  }

  renderAdd = () => {
    const { className } = this.props;

    return (
      <a
        href=""
        title={i18n.t('helpers.personal_tour.add.title')}
        className={className}
        onClick={this.handleAddPersonalTour}
      >
        {i18n.t('helpers.personal_tour.add.title')}
      </a>
    )
  }

  render() {
    const { available, personal_tour, className } = this.props;

    return (
      <>
        { available
          ? <>
              {personal_tour && personal_tour.id
                ? this.renderAdded()
                : this.renderAdd()
              }
          </>
        : <span
            className={`${className} disabled`}
          >
            {i18n.t('helpers.personal_tour.not_available.title')}
          </span>
        }
      </>
    )
  }
}

PersonalTour.propTypes = {
  available: PropTypes.bool.isRequired,
  personal_tour: PropTypes.shape(PERSONAL_TOUR_SHAPE),
  className: PropTypes.string,
  onPersonalTour: PropTypes.func.isRequired,
  createItem: PropTypes.func.isRequired,
  enablePersonalTour: PropTypes.func.isRequired,
  disablePersonalTour: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const currentUser = state.currentUser;
  return {
    userLoggedIn: !!currentUser.token,
    account: getAccount(state, currentUser.id),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createItem,
    enablePersonalTour,
    disablePersonalTour,
    openModal,
    closeModal,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PersonalTour)
