import React from "react";
import PropTypes from "prop-types";


class Ended extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { title } = this.props;

    return (
      <>
        {title &&
          <>
            {title}
            &nbsp;·&nbsp;
          </>
        }
      </>
    );
  }
}

Ended.propTypes = {
  title: PropTypes.string,
};

export default Ended