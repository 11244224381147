import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import i18n from "../../../translations/i18n";
import { withHeader } from "../../../hocs/layout/withHeader";
import { withFooter } from "../../../hocs/layout/withFooter";
import { withBodyClass } from "../../../hocs/layout/withBodyClass";
import { withAccount } from "../../../hocs/entities/withAccount";
import { withNotice } from "../../../hocs/layout/withNotice";
import Meta from "../../../components/layout/meta";
import Menu from "../../../components/account/menu";
import Phone from "../../../components/account/phone";


class ChangePhone extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <main className="layout">
        <Meta />
        <div className="p-settings">
          <div className="container">
            <div className="p-settings__title">
              <span>{i18n.t('pages.account.settings.title')}</span>
            </div>
            <div className="p-settings__content">
              <Menu />
              <div className="p-settings__section p-settings__phone">
                <div className="p-settings__form">
                  <Phone />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

ChangePhone.propTypes = {
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withAccount,
  withNotice(),
  withBodyClass('app-account'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(ChangePhone)