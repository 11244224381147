import React from "react";

const Icon = ({ icon, className, iconPrefix = 'icon__' }) => {
  return (
    <svg className={className}>
      <use xlinkHref={`#${iconPrefix}${icon}`} />
    </svg>
  );
};

export default Icon;
