import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import { USER_SHAPE } from "../../../../constants/userConstants";
import { MODIFICATION_SHAPE } from "../../../../selectors/modifications";

import FormGroup from "../../../../components/ui/form/group";
import FormActions from "../../../../components/ui/form/actions";
import FormError from "../../../../components/ui/form/error";
import InputField from "../../../../components/ui/form/input_field";
import Button from "../../../../components/ui/form/button";
import emailDomainIsInvalid from "../../../../helpers/invalidEmailDomains";


class ChangeEmailForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchStatus, setSubmitting } = this.props;

    if (prevProps.fetchStatus !== fetchStatus && !fetchStatus.isFetching) {
      setSubmitting(false);
    }
  }

  render() {
    const {
      account,
      isSubmitting,
      handleSubmit,
    } = this.props;

    return(
      <div className="p-settings__form">
        <Form>
          <FormGroup
            label={i18n.t("pages.account.form.email.label")}
            hint={account.unconfirmed_email ? i18n.t("pages.account.change_email.unconfirmed.message", {email: account.unconfirmed_email}) : i18n.t("pages.account.form.email.hint")}
            touched={true}
          >
            <Field
              component={InputField}
              name="email"
              required
            />
          </FormGroup>

          <FormActions className="button-wrapper p-settings__form-button-wrapper">
            <FormError>
              {account.error}
            </FormError>
            <Button
              className="p-settings__form-button"
              type="submit"
              disabled={isSubmitting}
              onSubmit={handleSubmit}
            >
              {i18n.t("pages.account.change_email.form.submit.title")}
            </Button>
          </FormActions>
        </Form>

      </div>
    )
  }
}

const ChangeEmailFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};

    if (isEmpty(values.email)) {
      errors.email = i18n.t("validation.field.required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
      errors.email = i18n.t("validation.email.invalid");
    } else if (emailDomainIsInvalid(values.email)) {
      errors.email = i18n.t("validation.email.invalid");
    }

    return errors;
  },
})(ChangeEmailForm);

ChangeEmailFormWithFormik.propTypes = {
  account: PropTypes.shape(USER_SHAPE).isRequired,
  fetchStatus: PropTypes.shape(MODIFICATION_SHAPE).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangeEmailFormWithFormik
