import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { withPosts } from "../../../hocs/entities/withPosts";
import { HOME_PAGE_POSTS_LIMIT } from "../../../config";
import { POST_SHAPE } from "../../../constants/postConstants";
import Gallery from "../gallery";


class Posts extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchPosts();
  }

  render () {
    const { posts, postsLimit, withLink } = this.props;

    return (
      <>
        {!isEmpty(posts) &&
          <div className="b-journal-carousel">
            <div className="section-title">
              {i18n.t("pages.home.posts.title")}
            </div>
            <Gallery
              posts={posts.slice(0, postsLimit)}
              withLink={withLink}
            />
          </div>
        }
      </>
    );
  }
}

Posts.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape(POST_SHAPE)),
  postsLimit: PropTypes.number.isRequired,
  withLink: PropTypes.bool
};

Posts.defaultProps = {
  postsLimit: HOME_PAGE_POSTS_LIMIT,
  withLink: false,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withPosts,
  connect(mapStateToProps, mapDispatchToProps)
)(Posts)
