import { GA_TRACKING_ENABLED } from "../../../config";

export const GA_PROFILE_SOURCE = "profile section_user account";

export function TrackChangePasswordClick() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Изменить'",
    "click",
    "profile section_user account"
  )
}

export function TrackSubmitPasswordError() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Изменить'",
    "user_failure",
    "thank you page_account_filling in the password field"
  )
}

export function TrackSubmitPasswordSuccess() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Изменить'",
    "success",
    "thank you page_account_filling in the password field"
  )
}


export function TrackSubmitSettingsClick() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "translation missing: ru.analytics.settings.user.click.category",
    "translation missing: ru.analytics.settings.user.click.action",
    "translation missing: ru.analytics.settings.user.click.label"
  )
}

export function TrackSubmitSettingsError() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Сохранить'",
    "user_failure",
    "profile section_user account"
  )
}

export function TrackSubmitSettingsSuccess() {
  if (GA_TRACKING_ENABLED) window.ga(
    'send', 'event',
    "button 'Сохранить'",
    "success",
    "profile section_user account"
  )
}
