import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../translations/i18n";
import {
  closeModal,
} from "../../../../actions/modalActions";
import { EXPOSITION_ORDER_ITEM_SUCESS_MODAL } from "../../../../constants/modalConstants";
import {
  EXPOSITION_ORDER_ITEM_EXPOSITION_TYPE,
} from "../../../../constants/expositionOrderConstants";
import BaseModal from "../../../ui/modal/base";
import Info from "../../../exposition/info";


class ExpositionOrderItemSuccessModal extends Component {

  constructor(props) {
    super(props);
  }

  handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.closeModal();
  }

  render() {
    const { modal } = this.props;
    const { orderItem } = modal;

    const isExposition = orderItem && orderItem.type === EXPOSITION_ORDER_ITEM_EXPOSITION_TYPE;

    return (
      <BaseModal
        className="modal modal-message modal-success"
        isOpen={modal.name === EXPOSITION_ORDER_ITEM_SUCESS_MODAL}
        onClose={this.handleClose}
      >
        {orderItem &&
          <>
            <div className="modal-ecursion__title4">
              {isExposition
                ? i18n.t('modal.exposition_order_item.success.title.exposition')
                : i18n.t('modal.exposition_order_item.success.title.tour')
              }
            </div>
            <div>
              {orderItem.name}
            </div>
            <div>
              {i18n.t('pages.exposition.tickets.count.key', {count: orderItem.count || 1})}
              ,&nbsp;
              <Info
                date={orderItem.info.date}
                place={orderItem.info.place}
              />
            </div>
            <div>
              <a
                href="#"
                className="button"
                onClick={this.handleClose}
              >
                {i18n.t('modal.exposition_order_item.success.button.title')}
              </a>
            </div>
          </>
        }
      </BaseModal>
    )
  }
}

ExpositionOrderItemSuccessModal.propTypes = {
  modal: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {
  return {
    modal: layout.modal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpositionOrderItemSuccessModal)
