import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { withHeader } from "../../hocs/layout/withHeader";
import { withFooter } from "../../hocs/layout/withFooter";
import { withBodyClass } from "../../hocs/layout/withBodyClass";
import { withPropertyOptions } from "../../hocs/entities/withPropertyOptions"
import { withArtists } from "../../hocs/entities/withArtists"
import { TOP_ARTISTS_PER_PAGE } from "../../config";

import Head from "./head"
import Instruction from "./instruction"
import PaintingOffer from "./painting_offer"
import Reasons from "./reasons"
import Painters from "./painters"
import Meta from "../../components/layout/meta";


class Partners extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getArtists()
  }

  getArtists() {
    const { fetchArtists } = this.props;
    fetchArtists(null, true, 1, TOP_ARTISTS_PER_PAGE);
  }

  render () {
    const { propertyOptions, artists } = this.props;
    return (
      <main className="layout">
        <Meta />
        <div className="p-partners">
          <Head/>
          <Instruction/>
          <PaintingOffer
            properties={propertyOptions}
          />
          <Reasons/>
          <Painters
            artists={artists}
            onFavorite={() => this.getArtists()}
          />
        </div>
      </main>
    );
  }
}

function mapStateToProps({currentUser, paintingOffer}) {
  return {
    userLoggedIn: !!currentUser.token,
    paintingOffer: paintingOffer
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withArtists,
  withPropertyOptions,
  withBodyClass('app-partners'),
  withHeader({transparent: false}),
  withFooter(),
  connect(mapStateToProps, mapDispatchToProps)
)(Partners)