import { RSAA } from "../middlewares/redux-api-middleware";
import { normalize } from "normalizr";

import { API } from "../config";
import {
  SERVICE_PREVIEWS,
  SERVICE_ITEM,
} from "../constants/serviceConstants";
import {
  servicePreviewSchema,
  serviceSchema
} from "../schemas";
import { DEFAULT_COLLECTION } from "../selectors/servicePreviews";
import { FUNC_APPEND, FUNC_REPLACE } from "../reducers/entities/collectionsReducer";


export function fetchItems(fetchOptions = {}) {
  const meta = {
    collection: fetchOptions.collection || DEFAULT_COLLECTION,
    func: FUNC_REPLACE
  };

  return function fetchItemsThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/services`,
        types: SERVICE_PREVIEWS.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const r = await res.json()
      const { services } = r;

      return {
        ...normalize(services, [servicePreviewSchema])
      };
    }
  };
}


export function fetchItem(id, fetchOptions = {}) {
  const meta = {
    key: id,
  };
  const include = fetchOptions.include || {};

  return function fetchItemThunk(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/services/${id}`,
        types: SERVICE_ITEM.types({
          all: { meta },
          success: {
            payload: parseResponse,
          },
          failed: {
            payload: errorHandler
          }
        }),
      }
    });

    async function errorHandler(action, state, res) {
      return {};
    }

    async function parseResponse(action, state, res) {
      const { service } = await res.json();

      return {
        ...normalize(service, serviceSchema),
      }
    }
  }
}