import { applyMiddleware, compose, createStore } from "redux";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import thunkMiddleware from "redux-thunk";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import { createLogger } from "redux-logger";

import createRootReducer from "../reducers";
import { apiMiddleware } from "../middlewares/redux-api-middleware/middleware";
import { apiFetchMiddleware } from "../middlewares/apiFetch";

import { ENTITIES_NAMESPACE } from "../selectors/entities";
import { DEFAULT_COLLECTION as ARTISTS_DEFAULT_COLLECTION } from "../selectors/artistPreviews";
import { DEFAULT_COLLECTION as BANNERS_DEFAULT_COLLECTION } from "../selectors/bannerPreviews";
import { DEFAULT_COLLECTION as INTERIORS_DEFAULT_COLLECTION } from "../selectors/interiorPreviews";
import { DEFAULT_COLLECTION as PAINTINGS_DEFAULT_COLLECTION } from "../selectors/paintingPreviews";
import { DEFAULT_COLLECTION as POSTS_DEFAULT_COLLECTION } from "../selectors/postPreviews";
import { DEFAULT_COLLECTION as SEARCH_DEFAULT_COLLECTION } from "../selectors/searchPreviews";
import { DEFAULT_COLLECTION as EXPOSITIONS_DEFAULT_COLLECTION } from "../selectors/expositionPreviews";
import { DEFAULT_COLLECTION as EXPOSITION_ORDERS_DEFAULT_COLLECTION } from "../selectors/expositionOrderPreviews";
import { DEFAULT_COLLECTION as CART_DEFAULT_COLLECTION } from "../selectors/cartPreviews";
import migrate, { VERSION } from "./migrations";

export const history = createBrowserHistory();

const logMiddleware = createLogger({
  predicate: () => process.env.RAILS_ENV !== 'production'
});

const middlewares = applyMiddleware(
  routerMiddleware(history),
  thunkMiddleware,
  apiFetchMiddleware,
  apiMiddleware,
  // logMiddleware,
  loadingBarMiddleware({
    promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
  }),
)

const blacklistFilter = createBlacklistFilter(
  ENTITIES_NAMESPACE,
  [
    `artistPreviews.collections.${ARTISTS_DEFAULT_COLLECTION}`,
    `bannerPreviews.collections.${BANNERS_DEFAULT_COLLECTION}`,
    `interiorPreviews.collections.${INTERIORS_DEFAULT_COLLECTION}`,
    `paintingPreviews.collections.${PAINTINGS_DEFAULT_COLLECTION}`,
    `postPreviews.collections.${POSTS_DEFAULT_COLLECTION}`,
    `searchPreviews.collections.${SEARCH_DEFAULT_COLLECTION}`,
    `expositionPreviews.collections.${EXPOSITIONS_DEFAULT_COLLECTION}`,
    `expositionOrderPreviews.collections.${EXPOSITION_ORDERS_DEFAULT_COLLECTION}`,
    `cartPreviews.collections.${CART_DEFAULT_COLLECTION}`,
    'order'
  ]
);

export const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth', 'layout'],
  transforms: [
    blacklistFilter
  ],
  migrate: migrate,
  version: VERSION,
}
const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(persistedReducer, composeEnhancers(middlewares))
export const persistor = persistStore(store)
