import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import YouTube from "react-youtube";
import PropTypes from "prop-types";

import i18n from "../../../../translations/i18n";
import { POST_SHAPE } from "../../../../constants/postConstants";
import Favorite from "../../../../components/layout/favorite";
import Share from "../../../../components/post/share";
import Author from "../../../../components/post/author";

// https://developers.google.com/youtube/player_parameters
const YOUTUBE_PLAYER_OPTIONS = {
  // height: '100%',
  // width: '100%',
  playerVars: {
    autoplay: 1,
    color: 'white',
  }
}


class Content extends Component {

  constructor(props) {
    super(props);
  }

  handleFavorite = () => {
    this.props.fetchPost();
  }

  renderNode(node, index) {
    if (node.type === 'tag' && node.name === 'figure') {
      const attachment = JSON.parse(node.attribs['data-trix-attachment']);
      const attributes = JSON.parse(node.attribs['data-trix-attributes']);

      return (
        <figure key={index} className={node.attribs.class}>
          {attachment && attachment.url &&
            <img src={attachment.url} />
          }
          {attributes && attributes.caption &&
            <figcaption className="attachment__caption">
              {attributes.caption}
            </figcaption>
          }
        </figure>
      )
    }
  }

  render () {
    const { item } = this.props;

    return (
      <div className="p-youtube__list">
        <div className="container">
          {item.lead &&
            <p
              className="p-youtube__list-description"
              dangerouslySetInnerHTML={{__html: item.lead}}
            />
          }
          {item.shortcode &&
            <YouTube
              videoId={item.shortcode}
              className="p-youtube__list-youtube"
              containerClassName="p-youtube__list-youtube-wrap"
              opts={YOUTUBE_PLAYER_OPTIONS}
            />
          }
          {item.text &&
            <div className="p-youtube__list-item">
              {ReactHtmlParser(item.text, {
                  transform: (node, index) => this.renderNode(node, index)
                }
              )}
            </div>
          }

          <div className="p-youtube__list-media">
            <div className="p-post__media-favorite">
              <Favorite
                favorite={item.favorite}
                onFavorite={this.handleFavorite}
              />
            </div>
            <div className="p-post__media-share">
              <div className="p-post__media-share-name">
                {i18n.t('helpers.share.title')}
              </div>
              <Share slug={item.slug} />
            </div>
          </div>
          <Author author={item.author} />
        </div>
      </div>
    )
  }
}

Content.propTypes = {
  item: PropTypes.shape(POST_SHAPE).isRequired,
  fetchPost: PropTypes.func.isRequired,
};

export default Content