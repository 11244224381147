import PropTypes from "prop-types";

import i18n from "../translations/i18n";
import { createRequestActionsFor } from "./apiFetch";

export const APPOINTMENT_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  painting_id: PropTypes.number,
  name: PropTypes.string
}

export const APPOINTMENT_PURPOSE_SHAPE = {
  purpose: PropTypes.array,
}

export const APPOINTMENT_PURPOSE_OPTIONS = [
  { value: i18n.t('purposes.purchase.value'), label: i18n.t('purposes.purchase.label') },
  { value: i18n.t('purposes.exchange.value'), label: i18n.t('purposes.exchange.label') },
  { value: i18n.t('purposes.sell.value'), label: i18n.t('purposes.sell.label') },
]

export const ENABLE_APPOINTMENT = "ENABLE_APPOINTMENT";
export const DISABLE_APPOINTMENT = "DISABLE_APPOINTMENT";

export const APPOINTMENT_ITEM_CREATE = createRequestActionsFor("APPOINTMENT_ITEM_CREATE");
