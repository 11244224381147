import { expertPreviewSchema } from "../schemas";
import {
  makeAnyKeySelector,
  makeCollectionSelector,
  makeAnyKeyCollectionSelector,
  makeFetchStatusSelector,
} from "./collections";

export const DEFAULT_COLLECTION = "index";

export function makeViewAnyKeySelector(key, collection) {
  return makeAnyKeySelector(key, collection, expertPreviewSchema);
}

export function makeExpertPreviewsSelector(collection = DEFAULT_COLLECTION) {
  return makeCollectionSelector(collection, expertPreviewSchema);
}

export function makeExpertPreviewsAnyKeySelector(key, collection = DEFAULT_COLLECTION) {
  return makeAnyKeyCollectionSelector(key, collection, expertPreviewSchema);
}

export function makeExpertPreviewsFetchStatusSelector(collection = DEFAULT_COLLECTION) {
  return makeFetchStatusSelector(collection, expertPreviewSchema);
}