import { combineReducers } from "redux";

import accounts from "./accounts";
import artistPreviews from "./artistPreviews";
import artists from "./artists";
import paintingPreviews from "./paintingPreviews";
import paintings from "./paintings";
import servicePreviews from "./servicePreviews";
import services from "./services";
import interiorPreviews from "./interiorPreviews";
import postPreviews from "./postPreviews";
import posts from "./posts";
import expositionPreviews from "./expositionPreviews";
import expositions from "./expositions";
import expositionOrderPreviews from "./expositionOrderPreviews";
import expositionOrders from "./expositionOrders";
import expositionOrderItems from "./expositionOrderItems";
import bannerPreviews from "./bannerPreviews";
import order from "./orders"
import favoritePreviews from "./favoritePreviews";
import expertPreviews from "./expertPreviews";
import searchPreviews from "./searchPreviews";
import seo from "./seo";
import cartPreviews from "./cartPreviews";

import artistOptions from "./artistOptions";
import propertyOptions from "./propertyOptions";

export default combineReducers({
  accounts,
  artistPreviews,
  artists,
  paintingPreviews,
  paintings,
  interiorPreviews,
  servicePreviews,
  services,
  postPreviews,
  posts,
  expositionPreviews,
  expositions,
  expositionOrderPreviews,
  expositionOrders,
  expositionOrderItems,
  bannerPreviews,
  order,
  favoritePreviews,
  expertPreviews,
  searchPreviews,
  seo,
  cartPreviews,
  artistOptions,
  propertyOptions,
})
