import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";

import FormActions from "../../../components/ui/form/actions";
import FormError from "../../../components/ui/form/error";
import CheckboxField from "../../../components/ui/form/checkbox_field";
import Button from "../../../components/ui/form/button";
import {
  CART_FORM_SHAPE,
  CART_ITEM_SHAPE
} from "../../../constants/cartConstants";
import { CART_MAX_COUNT } from "../../../config";


class CartForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchStatus, setSubmitting } = this.props;

    if (prevProps.fetchStatus !== fetchStatus && !fetchStatus.isFetching) {
      setSubmitting(false);
    }
  }

  render() {
    const { items, errors, isSubmitting, handleSubmit } = this.props;
    let amount = items.reduce((sum, item) => (sum + (item.price.amount * item.count)), 0);
    const isFree = !isEmpty(items) && amount === 0;

    return (
      <Form>
        <div className="p-exhibitions__cart-total">
          <div className="p-exhibitions__cart-total-agree">
            <Field name="accept_terms" component={CheckboxField}>
              {i18n.t('pages.cart.form.accept_terms.label')}
            </Field>
            <Field name="accept_rules" component={CheckboxField}>
              {i18n.t('pages.cart.form.accept_rules.label')}
            </Field>
            <FormError>
              {errors.required}
            </FormError>
          </div>
          <div className="p-exhibitions__cart-total-value">
            <div>
              {i18n.t("pages.cart.form.total.title")}
            </div>
            <div className="p-exhibitions__cart-total-number">
              {i18n.t("pages.cart.form.total.amount", {amount: amount})}
            </div>
          </div>
        </div>
        <FormActions className="p-exhibitions__cart-button-wrap">
          <FormError>
            {errors.count}
          </FormError>
          <Button
            className="p-exhibitions__cart-button button"
            type="submit"
            disabled={isSubmitting}
            onSubmit={handleSubmit}
          >
            {isFree ? i18n.t("pages.cart.form.book.title") : i18n.t("pages.cart.form.submit.title")}
          </Button>
        </FormActions>
      </Form>
    )
  }
}

const CartFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {items, onSubmit}}) => onSubmit(items),
  validate: (values) => {
    let errors = {};

    if (values.count > CART_MAX_COUNT) {
      errors.count = i18n.t("pages.cart.form.validation.cart_max_count")
    }

    if (values.accept_terms == false || values.accept_rules == false) {
      errors.required = i18n.t("pages.cart.form.validation.field.required");
    }

    return errors;
  },
})(CartForm);

CartFormWithFormik.propTypes = {
  initialValues: PropTypes.shape(CART_FORM_SHAPE).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(CART_ITEM_SHAPE)),
  onSubmit: PropTypes.func.isRequired,
};

export default CartFormWithFormik
