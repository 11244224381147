import { denormalize } from "normalizr";

import { getEntities } from "./entities";
import { artistSchema } from "../schemas";

export const DEFAULT_MODIFICATION = "form";

export function getArtist(state, id) {
  return denormalize(id, artistSchema, getEntities(state));
}
