import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { EXPOSITION_ORDER_PREVIEWS } from "../../../constants/expositionOrderConstants";
import { makeCollectionsReducer } from "../collectionsReducer";
import { expositionOrderPreviewSchema } from "../../../schemas";


export default combineReducers({
  byId: makeByIdReducer(expositionOrderPreviewSchema.key, [EXPOSITION_ORDER_PREVIEWS.SUCCESS]),
  collections: makeCollectionsReducer(EXPOSITION_ORDER_PREVIEWS.typeNames()),
});
