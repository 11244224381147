import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { artistSchema } from "../../../schemas";
import { ARTIST_ITEM } from "../../../constants/aritstConstants";

export default combineReducers({
  byId: makeByIdReducer(
    artistSchema.key,
    [ARTIST_ITEM.SUCCESS],
    [ARTIST_ITEM.FAILED]
  ),
})