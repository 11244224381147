import { RSAA } from "../middlewares/redux-api-middleware";

import { API } from "../config";
import {
  ORDER_SEND,
  ORDER_SEND_CACHED,
  ORDER_SEND_CLEAR
} from "../constants/orderConstants";


export function createItem({selectedServices, comment, username, phone, email, path}) {
  return function(dispatch) {
    return dispatch({
      [RSAA]: {
        endpoint: `${API}/account/orders`,
        method: 'POST',
        body: {
          order: {
            comment: comment, service_ids: selectedServices
          },
          user: {
            name: username, phone: phone, email: email
          },
          path: path
        },
        types: ORDER_SEND.types({
          success: {
            payload: parseResponse
          },
          failed: {
            payload: errorHandler
          }
        }),
      },
    });

    async function errorHandler(action, state, res) {
      return { error: (await res.json()).error };
    }

    async function parseResponse(action, state, res) {
      return { submitted: true }
    }
  }
}

export function createCachedItem({selectedServices, comment}) {
  return function(dispatch) {
    return dispatch({
      type: ORDER_SEND_CACHED,
      payload: {selectedServices, comment}
    });
  }
}

export function clearCachedItem() {
  return function(dispatch) {
    return dispatch({
      type: ORDER_SEND_CLEAR,
      payload: {}
    });
  }
}

