import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import {
  EXPOSITION_SHAPE,
} from "../../../constants/expositionConstants";
import {
  POST_ARTICLE_KIND,
  POST_PHOTOREPORT_KIND,
  POST_YOUTUBE_KIND
} from "../../../constants/postConstants";
import Article from "../../../components/posts/articles/item";
import Photoreport from "../../../components/posts/photoreports/item";
import Youtube from "../../../components/posts/youtubes/item";


class Publications extends Component {

  constructor(props) {
    super(props);
  }

  handleFavorite = () => {
    this.props.fetchExposition()
  }

  render() {
    const { item } = this.props;
    const withPublications = !isEmpty(item.posts);

    return(
      <div className="p-exhibition__body-section p-exhibition__body-materials" id="#publications">
        {withPublications &&
          <div>
            <h3 className="p-exhibition__body-title">
              <span>{i18n.t('pages.exposition.aside.publications.title')}</span>
            </h3>
            {(item.posts || []).map((item) =>
              <Fragment key={item.id}>
                  {item.kind == POST_ARTICLE_KIND &&
                    <Article
                      item={item}
                      onFavorite={this.handleFavorite}
                    />
                  }
                  {item.kind == POST_PHOTOREPORT_KIND &&
                    <Photoreport
                      item={item}
                      onFavorite={this.handleFavorite}
                    />
                  }
                  {item.kind == POST_YOUTUBE_KIND &&
                    <Youtube
                      item={item}
                      onFavorite={this.handleFavorite}
                    />
                  }
              </Fragment>
            )}
          </div>
        }
      </div>
    )
  }
}

Publications.propTypes = {
  item: PropTypes.shape(EXPOSITION_SHAPE).isRequired,
  fetchExposition: PropTypes.func.isRequired,
};

export default Publications
