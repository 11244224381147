import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import cn from "classnames";

import i18n from "../../../../translations/i18n";
import Item from "./item";
import {
  createItem,
} from "../../../../actions/cartActions";
import {
  openModal
} from "../../../../actions/modalActions";
import { EXPOSITION_PRICE_SHAPE } from "../../../../constants/expositionConstants";
import { LOGIN_MODAL } from "../../../../constants/modalConstants";


class Prices extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
    }
  }

  handleReload = () => {
    this.props.fetchExposition()
  }

  handleChange = (item, count) => {
    const { selectedItems } = this.state;

    if (count > 0) {
      item['count'] = count;
      if (selectedItems.filter((s) => s.id === item.id).length > 0) {
        selectedItems.splice(selectedItems.findIndex((s) => s.id === item.id), 1, item);
      } else {
        selectedItems.push(item);
      }
    } else {
      selectedItems.splice(selectedItems.findIndex((s) => s.id === item.id), 1);
    }
  }

  handleAddToCart = (event) => {
    event.preventDefault()
    const { currentDay, exposition, userLoggedIn, createItem, openModal } = this.props;
    const { selectedItems } = this.state;

    const cartItems = selectedItems.map((item) => {
      return {
        count: item.count,
        date: currentDay.cell.date,
        price_id: item.id
      }
    });

    if (userLoggedIn) {
      createItem(cartItems);
    } else {
      openModal({
        name: LOGIN_MODAL,
        source: 'форма "Регистрация" покупка билета на выставку ' + exposition.name
      });
    }
  }

  render() {
    const { currentDay, items } = this.props;
    const isDaySelected = !isEmpty(currentDay);
    const isDisabled = isDaySelected && currentDay.cell.past;

    return(
      <>
        {isDaySelected &&
          <div className="p-exhibition__ticket-selector">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div className="p-exhibition__ticket-selector-left">
                      <div className="p-exhibition__ticket-selector-type">
                        {i18n.t("pages.exposition.prices.table.type.title")}
                        <span>
                          {i18n.t("pages.exposition.prices.table.type.price")}
                        </span>
                      </div>
                      <div className="p-exhibition__ticket-selector-cost">
                        {i18n.t("pages.exposition.prices.table.amount.title")}
                      </div>
                    </div>
                  </th>
                  <th>
                    {i18n.t("pages.exposition.prices.table.count.title")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {(items || []).map((item) =>
                  <Item
                    key={item.id}
                    item={item}
                    isDisabled={isDisabled}
                    onChange={this.handleChange}
                  />
                )}
              </tbody>
            </table>
            <div className="p-exhibition__ticket-button-wrap">
              <button
                className={cn('p-exhibition__ticket-button button', {'disabled': isDisabled})}
                disabled={isDisabled}
                onClick={(event) => this.handleAddToCart(event)}
              >
                {i18n.t("pages.exposition.prices.buy.title")}
              </button>
            </div>
          </div>
        }
      </>
    )
  }
}

Prices.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_PRICE_SHAPE)),
  currentDay: PropTypes.object,
};

function mapStateToProps({currentUser}) {
  return {
    userLoggedIn: !!currentUser.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    createItem,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Prices)
