import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchFavoriteArtists as fetchItems } from "../../actions/favoriteActions";
import {
  makeArtistPreviewsSelector,
  makeArtistPreviewsAnyKeySelector,
  makeArtistPreviewsFetchStatusSelector,
} from "../../selectors/artistPreviews";
import { ARTIST_SHAPE } from "../../constants/aritstConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withFavoriteArtists(WrappedComponent) {

  const WithFavoriteArtists = class extends Component {

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    fetchFavoriteArtists = (currentPage = 1, perPage) => {
      const { fetchItems, fetchStatus } = this.props;

      if (!fetchStatus.isFetching) {
        fetchItems(currentPage, perPage);
      }
    };

    render() {
      const { artists, pagination, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    artists={artists}
                    pagination={pagination}
                    fetchStatus={fetchStatus}
                    fetchFavoriteArtists={this.fetchFavoriteArtists}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithFavoriteArtists.propTypes = {
    artists: PropTypes.arrayOf(PropTypes.shape(ARTIST_SHAPE)),
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const artistsSelector = makeArtistPreviewsSelector();
  const artistsPaginationSelector = makeArtistPreviewsAnyKeySelector('pagination');
  const artistsFetchStatusSelector = makeArtistPreviewsFetchStatusSelector();

  function mapStateToProps(state) {
    return {
      artists: artistsSelector(state),
      pagination: artistsPaginationSelector(state) || {},
      fetchStatus: artistsFetchStatusSelector(state),
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItems,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithFavoriteArtists);
}