import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { EXPERT_PREVIEWS } from "../../../constants/expertConstants";
import { makeCollectionsReducer } from "../collectionsReducer";
import { expertPreviewSchema } from "../../../schemas";

export const DEFAULT_COLLECTION = "index";

export default combineReducers({
  byId: makeByIdReducer(expertPreviewSchema.key, [EXPERT_PREVIEWS.SUCCESS]),
  collections: makeCollectionsReducer(EXPERT_PREVIEWS.typeNames()),
});