import { combineReducers } from "redux";

import { makeByIdReducer } from "../byIdReducer";
import { postSchema } from "../../../schemas";
import { POST_ITEM } from "../../../constants/postConstants";

export default combineReducers({
  byId: makeByIdReducer(
    postSchema.key,
    [POST_ITEM.SUCCESS],
    [POST_ITEM.FAILED]
  ),
})