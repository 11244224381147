import { postPreviewSchema } from "../schemas";
import {
  makeAnyKeySelector,
  makeCollectionSelector,
  makeAnyKeyCollectionSelector,
  makeFetchStatusSelector,
} from "./collections";

export const DEFAULT_COLLECTION = "index";

export function makeViewAnyKeySelector(key, collection) {
  return makeAnyKeySelector(key, collection, postPreviewSchema);
}

export function makePostPreviewsSelector(collection = DEFAULT_COLLECTION) {
  return makeCollectionSelector(collection, postPreviewSchema);
}

export function makePostPreviewsAnyKeySelector(key, collection = DEFAULT_COLLECTION) {
  return makeAnyKeyCollectionSelector(key, collection, postPreviewSchema);
}

export function makePostPreviewsFetchStatusSelector(collection = DEFAULT_COLLECTION) {
  return makeFetchStatusSelector(collection, postPreviewSchema);
}