import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchArtistOptions as fetchItems } from "../../actions/artistActions";
import {
  makeArtistOptionsSelector,
  makeArtistOptionsFetchStatusSelector,
} from "../../selectors/artistOptions";
import { ARTIST_OPTION_SHAPE } from "../../constants/aritstConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withArtistOptions(WrappedComponent) {

  const WithArtistOptions = class extends Component {

    componentDidMount() {
      this.fetchArtistOptions();
    }

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    fetchArtistOptions = () => {
      const { fetchItems, fetchStatus } = this.props;

      if (!fetchStatus.isFetching) {
        fetchItems();
      }
    };

    render() {
      const { artistOptions, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    artistOptions={artistOptions}
                    fetchStatus={fetchStatus}
                    fetchArtistOptions={this.fetchArtistOptions}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithArtistOptions.propTypes = {
    artistOptions: PropTypes.arrayOf(PropTypes.shape(ARTIST_OPTION_SHAPE)),
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const artistOptionsSelector = makeArtistOptionsSelector();
  const artistOptionsFetchStatusSelector = makeArtistOptionsFetchStatusSelector();

  function mapStateToProps(state) {
    return {
      artistOptions: artistOptionsSelector(state),
      fetchStatus: artistOptionsFetchStatusSelector(state),
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItems,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithArtistOptions);
}