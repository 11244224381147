import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { withSimilarPosts } from "../../../hocs/entities/withSimilarPosts";
import { POST_SHAPE } from "../../../constants/postConstants";
import Gallery from "../../../components/posts/gallery";


class Similar extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchPosts();
  }

  render () {
    const { posts } = this.props;
    const isSimilar = !isEmpty(posts) && posts.length >= 3

    return (
      <>
        { isSimilar &&
          <div className="b-journal-carousel">
            <div className="section-title">
              {i18n.t('pages.post.similar.title')}
            </div>
            <Gallery posts={posts} />
          </div>
        }
      </>
    )
  }
}

Similar.propTypes = {
  post: PropTypes.shape(POST_SHAPE).isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withSimilarPosts,
  connect(mapStateToProps, mapDispatchToProps)
)(Similar)