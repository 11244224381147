import React from "react";
import PropTypes from "prop-types";

import { EXPOSITION_PLACE_SHAPE } from "../../../constants/expositionConstants";
import Badge from "../badge";
import Schedule from "../schedule";


class Place extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item, place } = this.props;

    return (
      <>
        { place &&
          <div className="p-exhibitions__list-item-contacts">
            <Schedule schedule={item.schedule} />
            {place.address}, &nbsp;{place.name}
            <Badge
              adultsOnly={item.adults_only}
              ageRestriction={item.age_restriction}
            />
          </div>
        }
      </>
    );
  }
}

Place.propTypes = {
  place: PropTypes.shape(EXPOSITION_PLACE_SHAPE).isRequired,
  adultsOnly: PropTypes.bool,
  ageRestriction: PropTypes.number,
};

export default Place
