import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cn from "classnames";

import i18n from "../../../translations/i18n";
import {
  CART_ITEM_SHAPE,
  CART_ITEM_EXPOSITION_TYPE,
  CART_ITEM_TOUR_TYPE
} from "../../../constants/cartConstants";
import Link from "../../../components/ui/link";
import Icon from "../../../components/ui/icon";
import Info from "../../../components/exposition/info";


class Item extends Component {

  constructor(props) {
    super(props);

    this.state = {
      count: props.item.count,
    }
  }

  handleDelete = () => {
    const { item, onDelete } = this.props;

    onDelete(item.id);
  }

  handleMinus = (event) => {
    event.preventDefault();
    const { item, onUpdate } = this.props;
    const { count } = this.state;

    let newValue = count - 1;
    if (newValue >= 1) {
      this.setState({count: newValue})
      onUpdate(item.id, newValue)
    }
  }

  handlePlus = (event) => {
    event.preventDefault();
    const { item, onUpdate } = this.props;
    const { count } = this.state;

    let newValue = count + 1;
    if (newValue <= 4) {
      this.setState({count: newValue})
      onUpdate(item.id, newValue)
    }
  }

  render () {
    const { item } = this.props;
    const { count } = this.state;

    const isExposition = item.cartable_type === CART_ITEM_EXPOSITION_TYPE;
    const isTour = item.cartable_type === CART_ITEM_TOUR_TYPE;
    const minusDisabled = count === 1;
    const plusDisable = count >= 4;

    return (
      <div className="p-exhibitions__cart-item">
        <ul>
          <li className="p-exhibitions__cart-item-type">
            <div className="p-exhibitions__cart-item-title">
              {isExposition &&
                <>
                  <span>
                    {i18n.t('pages.cart.item.type.exposition', {title: item.price.title})}
                  </span>
                  {item.cartable.name}
                </>
              }
              {isTour &&
                <>
                  <span>
                    {i18n.t('pages.cart.item.type.tour', {title: item.price.title})}
                  </span>
                  {item.cartable.name}
                </>
              }
            </div>
            {item.info &&
              <div className="p-exhibitions__cart-item-info">
                {isTour &&
                  <>
                    <span>{item.guide.occupation}:&nbsp;</span>
                    {item.guide.name}
                    ,&nbsp;
                  </>
                }
                <Info
                  date={item.info.date}
                  place={item.info.place}
                />
              </div>
            }
          </li>
          <li className="p-exhibitions__cart-item-quantity">
            <div className="p-exhibition__ticket-quantity">
              <a
                href="#"
                className={cn('p-exhibition__ticket-quantity-button p-exhibition__ticket-quantity-minus', { 'state-past': minusDisabled })}
                onClick={this.handleMinus}
              >
                <Icon icon="arrow-minus" />
              </a>
              <div className="p-exhibition__ticket-quantity-num">
                {count}
              </div>
              <a
                href="#"
                className={cn('p-exhibition__ticket-quantity-button p-exhibition__ticket-quantity-plus', { 'state-past': plusDisable })}
                onClick={this.handlePlus}
              >
                <Icon icon="arrow-plus" />
              </a>
            </div>
          </li>
          <li className="p-exhibitions__cart-item-cost">
            <div className="p-exhibitions__cart-item-cost-number">
              {i18n.t('pages.cart.item.price', {amount: item.price.amount})}
            </div>
            <div
              className="p-exhibitions__cart-item-remove tooltip-top tooltip-mobile"
              data-tooltip={i18n.t('helpers.cart.delete')}
              onClick={this.handleDelete}
            >
              <Icon icon="close" />
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

Item.propTypes = {
  item: PropTypes.shape(CART_ITEM_SHAPE).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)
