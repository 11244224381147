import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import i18n from "../../../translations/i18n";
import { EXPOSITION_SHAPE } from "../../../constants/expositionConstants";
import Poster from "../../../components/exposition/poster";
import Place from "../../../components/exposition/place";
import Link from "../../../components/ui/link";


class Item extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { item, className } = this.props;
    const expositionPath = `/expositions/${item.slug}`;

    return(
      <Link
        className={className}
        to={expositionPath}
        title={item.name}
      >
        <div className="p-exhibitions__list-item-info">
          <div className="p-exhibitions__list-item-date">
            {item.period}
          </div>
          <div className="p-exhibitions__list-item-title">
            {item.name}
          </div>
          {item.actual &&
            <div className="p-exhibitions__list-item-button button">
              {i18n.t('pages.exposition.tickets.buy.title')}
            </div>
          }
          <Place
            item={item}
            place={item.place}
          />
        </div>
        <Poster poster={item.poster} />
      </Link>
    );
  }
}

Item.propTypes = {
  item: PropTypes.shape(EXPOSITION_SHAPE).isRequired,
  className: PropTypes.string,
};

Item.defaultProps = {
  className: 'p-exhibitions__list-item',
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)
