import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import PropTypes from "prop-types";
import cn from "classnames";

import i18n from "../../../../../translations/i18n";
import { closeHeaderSearch } from "../../../../../actions/layoutActions";
import SearchForm from "../form";
import SearchHistory from "../history";


class SearchModal extends Component {

  constructor(props) {
    super(props);
  }

  handleSearch = (values) => {
    const { history, closeHeaderSearch } = this.props;

    if (values.q) {
      closeHeaderSearch();
      history.push(`/search?q=${values.q}`)
    }
  }

  render () {
    const { headerSearch } = this.props;

    return (
      <>
        <BrowserView>
          <div className="header__search-button">
            {i18n.t('header.search.title')}
          </div>
        </BrowserView>
        <MobileView>
          <Link
            to="/search"
            className="header__search-button"
          >
            {i18n.t('header.search.title')}
          </Link>
        </MobileView>
        <div
          className={cn('header__search-modal', { 'state-active': headerSearch.visible})}
        >
          <SearchForm
            initialValues={{q: ""}}
            onSubmit={this.handleSearch}
          />
          <SearchHistory />
        </div>
      </>
    );
  }
}

SearchModal.propTypes = {
  history: PropTypes.object.isRequired,
  closeHeaderSearch: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {
  return {
    headerSearch: layout.headerSearch,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeHeaderSearch
  }, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SearchModal)