import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../../translations/i18n";
import {
  USER_SESSIONS_SHAPE,
  USER_AUTH_SHAPE
} from "../../../../../constants/userConstants";
import { PASSWORD_MIN_LENGTH } from "../../../../../config";

import FormGroup from "../../../../ui/form/group";
import FormActions from "../../../../ui/form/actions";
import FormError from "../../../../ui/form/error";
import InputField from "../../../../ui/form/input_field";
import Button from "../../../../ui/form/button";
import {
  GA_LOGIN_FORM_SOURCE,
  TrackFieldError,
  TrackFieldValid,
  TrackLoginSubmitError
} from "../../../../../helpers/analytics";


class LoginForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth, setSubmitting } = this.props;

    if (prevProps.auth !== auth && auth.error) {
      setSubmitting(false);
    }
  }

  render() {
    const {
      auth,
      touched,
      isSubmitting,
      onRestorePassword,
      handleSubmit,
    } = this.props;

    return(
      <Form>
        <div className="modal__form-fields-group">
          <FormGroup label={i18n.t("pages.login.form.email.label")} touched={touched.email}>
            <Field
              component={InputField}
              name="email"
              required
            />
          </FormGroup>
          <FormGroup label={i18n.t("pages.login.form.password.label")} touched={touched.password}>
            <Field
              component={InputField}
              name="password"
              type="password"
              required
            />
          </FormGroup>
        </div>

        <div className="modal__form-fieldset">
          <div className="checkbox">
            <input id="remember" type="checkbox" defaultChecked />
            <label htmlFor="remember">{i18n.t('pages.login.form.remember.title')}</label>
          </div>
          <a
            href=""
            onClick={onRestorePassword}
          >
            {i18n.t('pages.login.form.restore.title')}
          </a>
        </div>

        <FormActions className="modal__form-button-wrapper">
          <FormError>
            {auth.error}
          </FormError>
          <Button
            className="modal__form-button"
            type="submit"
            disabled={isSubmitting}
            onSubmit={handleSubmit}
          >
            {i18n.t("pages.login.form.submit.title")}
          </Button>
        </FormActions>
      </Form>
    )
  }
}

const LoginFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};

    if (isEmpty(values.email)) {
      TrackFieldError('email', 'empty', GA_LOGIN_FORM_SOURCE);

      errors.email = i18n.t("validation.field.required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
      TrackFieldError('email', 'invalid', GA_LOGIN_FORM_SOURCE);

      errors.email = i18n.t("validation.email.invalid");
    } else {
      TrackFieldValid('email', GA_LOGIN_FORM_SOURCE);
    }

    if (isEmpty(values.password)) {
      TrackFieldError('password', 'empty', GA_LOGIN_FORM_SOURCE);

      errors.password = i18n.t("validation.field.required");
    } else if (values.password.length < PASSWORD_MIN_LENGTH) {
      TrackFieldError('password', 'invalid', GA_LOGIN_FORM_SOURCE);

      errors.password = i18n.t("validation.password.toShort", {length: PASSWORD_MIN_LENGTH});
    } else {
      TrackFieldValid('password', GA_LOGIN_FORM_SOURCE);
    }

    if (!isEmpty(errors)) {
      TrackLoginSubmitError();
    }

    return errors;
  },
})(LoginForm);

LoginFormWithFormik.propTypes = {
  initialValues: PropTypes.shape(USER_SESSIONS_SHAPE).isRequired,
  auth: PropTypes.shape(USER_AUTH_SHAPE).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default LoginFormWithFormik
