import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import i18n from "../../../../translations/i18n";
import {
  closeModal
} from "../../../../actions/modalActions";
import {
  disableAppointment
} from "../../../../actions/appointmentActions";
import { LOGIN_MODAL } from "../../../../constants/modalConstants";
import BaseModal from "../../../ui/modal/base";
import Login from "../../auth/login";
import { TrackLoginCloseClick } from "../../../../helpers/analytics";


class LoginModal extends React.Component {

  constructor(props) {
    super(props);
  }

  handleClose = (event) => {
    const { closeModal, disableAppointment } = this.props;
    event.preventDefault();

    TrackLoginCloseClick();

    disableAppointment();
    closeModal();
  }

  render () {
    const { modal } = this.props;

    return (
      <BaseModal
        className="modal modal-login"
        isOpen={modal.name === LOGIN_MODAL}
        onClose={this.handleClose}
      >
        <Login source={modal.source} />
      </BaseModal>
    );
  }
}

LoginModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {
  return {
    modal: layout.modal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    disableAppointment,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(LoginModal)
