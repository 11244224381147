import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchFavoritePosts as fetchItems } from "../../actions/favoriteActions";
import {
  makePostPreviewsSelector,
  makePostPreviewsAnyKeySelector,
  makePostPreviewsFetchStatusSelector,
} from "../../selectors/postPreviews";
import { POST_SHAPE } from "../../constants/postConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../selectors/collections";
import LoadableCollection from "../../components/ui/loadable/collection";


export function withFavoritePosts(WrappedComponent) {

  const WithFavoritePosts = class extends Component {

    shouldComponentUpdate(prevProps) {
      if (prevProps.fetchStatus && prevProps.fetchStatus.isFetching) {
        return false
      } else {
        return true
      }
    }

    fetchFavoritePosts = (currentPage = 1, perPage) => {
      const { fetchItems, fetchStatus } = this.props;

      if (!fetchStatus.isFetching) {
        fetchItems(currentPage, perPage);
      }
    };

    render() {
      const { posts, pagination, fetchStatus, children, ...restProps } = this.props;

      return (
        <LoadableCollection fetchStatus={fetchStatus}>
          {
            () => <WrappedComponent
                    {...restProps}
                    posts={posts}
                    pagination={pagination}
                    fetchStatus={fetchStatus}
                    fetchFavoritePosts={this.fetchFavoritePosts}
                  />
          }
        </LoadableCollection>
      )
    }
  };

  WithFavoritePosts.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape(POST_SHAPE)),
    fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  };

  const postsSelector = makePostPreviewsSelector();
  const postsPaginationSelector = makePostPreviewsAnyKeySelector('pagination');
  const postsFetchStatusSelector = makePostPreviewsFetchStatusSelector();

  function mapStateToProps(state) {
    return {
      posts: postsSelector(state),
      pagination: postsPaginationSelector(state) || {},
      fetchStatus: postsFetchStatusSelector(state),
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchItems,
    }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithFavoritePosts);
}