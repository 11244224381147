import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import {
  USER_SHAPE,
  USER_PHONE_CONFIRMATION_SHAPE
} from "../../../../constants/userConstants";

import FormGroup from "../../../../components/ui/form/group";
import FormActions from "../../../../components/ui/form/actions";
import FormError from "../../../../components/ui/form/error";
import InputField from "../../../../components/ui/form/input_field";
import Button from "../../../../components/ui/form/button";


class PhoneConfirmationForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchStatus, setSubmitting } = this.props;

    if (prevProps.fetchStatus !== fetchStatus && !fetchStatus.isFetching) {
      setSubmitting(false);
    }
  }

  handleResend = (event) => {
    event.preventDefault();
    this.props.onResend()
  }

  handleReset = (event) => {
    event.preventDefault();
    this.props.onCodeReset()
  }

  render() {
    const {
      account,
      touched,
      isSubmitting,
      handleSubmit,
    } = this.props;

    return (
      <Form className="fade-in">
        <FormGroup label={i18n.t("pages.phone_confirmation.form.code.label")} touched={touched.code}>
          <Field
            component={InputField}
            name="code"
            required
          />
          <div className="p-settings__form-actions">
            <a
              href="#"
              title={i18n.t("pages.phone_confirmation.form.resend.title")}
              onClick={this.handleResend}
            >
              {i18n.t("pages.phone_confirmation.form.resend.title")}
            </a>
            <a
              href="#"
              title={i18n.t("pages.phone_confirmation.form.reset.title")}
              onClick={this.handleReset}
            >
              {i18n.t("pages.phone_confirmation.form.reset.title")}
            </a>
          </div>
        </FormGroup>

        <FormActions className="button-wrapper p-settings__form-button-wrapper">
          <FormError>
            {account.error}
          </FormError>
          <Button
            className="p-settings__form-button"
            type="submit"
            disabled={isSubmitting}
            onSubmit={handleSubmit}
          >
            {i18n.t("pages.phone_confirmation.form.submit.title")}
          </Button>
        </FormActions>
      </Form>
    )
  }
}

const PhoneConfirmationFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};

    if (isEmpty(values.code)) {
      errors.name = i18n.t("validation.field.required");
    }

    return errors;
  },
})(PhoneConfirmationForm);

PhoneConfirmationFormWithFormik.propTypes = {
  initialValues: PropTypes.shape(USER_PHONE_CONFIRMATION_SHAPE).isRequired,
  account: PropTypes.shape(USER_SHAPE).isRequired,
  onResend: PropTypes.func.isRequired,
  onCodeReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PhoneConfirmationFormWithFormik
