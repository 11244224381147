import React, { Component } from "react";
import Footer from "../../components/layout/footer";


export function withFooter() {
  return function(WrappedComponent) {

    const WithFooter = class extends Component {

      render() {
        const { ...restProps } = this.props;

        return (
          <>
            <WrappedComponent
              {...restProps}
            />
            <Footer />
          </>
        )
      }
    };

    return WithFooter;
  }
}