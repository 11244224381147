import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import i18n from "../../../../translations/i18n";
import { withExpositionOrders } from "../../../../hocs/entities/withExpositionOrders";
import { EXPOSITION_ORDER_SHAPE } from "../../../../constants/expositionOrderConstants";
import Empty from "../../../../components/account/empty";
import List from "./list";


class ExpositionOrders extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    }
  }

  componentDidMount() {
    this.props.fetchExpositionOrders();
  }

  handleLoad = () => {
    const { pagination, fetchExpositionOrders } = this.props;
    if (event) {
      event.preventDefault();
    }

    if (!isNil(pagination.next_page)) {
      this.setState({currentPage:  pagination.next_page});
      fetchExpositionOrders(pagination.next_page);
    }
  }

  render() {
    const { expositionOrders, pagination } = this.props;

    return (
      <div className="p-profile__exposition-orders">
        <h3 className="p-profile__title">
          {i18n.t("pages.account.exposition_orders.title")}
        </h3>
        {isEmpty(expositionOrders)
          ? <Empty
              to="/expositions"
              title={i18n.t('pages.account.exposition_orders.empty.title')}
              button={i18n.t('pages.account.exposition_orders.empty.button')}
            />
          : <>
              <List
                items={expositionOrders}
              />
              {!isNil(pagination.next_page) &&
                <div className="button__wrapper">
                  <a
                    href="#"
                    className="button button-more"
                    title={i18n.t('pages.account.exposition_orders.more.title')}
                    onClick={this.handleLoad}
                  >
                    {i18n.t('pages.account.exposition_orders.more.title')}
                  </a>
                </div>
              }
            </>
        }
      </div>
    );
  }
}

ExpositionOrders.propTypes = {
  expositionOrders: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_ORDER_SHAPE)),
};

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withExpositionOrders,
  connect(mapStateToProps, mapDispatchToProps)
)(ExpositionOrders)
