import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import i18n from "../../../translations/i18n";
import { withExpositions } from "../../../hocs/entities/withExpositions";
import {
  makeExpositionPreviewsSelector,
  makeExpositionPreviewsAnyKeySelector,
  makeExpositionPreviewsFetchStatusSelector,
} from "../../../selectors/expositionPreviews";
import { EXPOSITION_SHAPE, EXPOSITION_KIND_FUTURE } from "../../../constants/expositionConstants";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../../selectors/collections";
import Pagination from "../../../components/layout/pagination";
import Item from "../../../components/expositions/item";


class Future extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchExpositions(EXPOSITION_KIND_FUTURE, 1, 2);
  }

  handlePageChange = (page) => {
    if (event) {
      event.preventDefault();
    }
    this.props.fetchExpositions(EXPOSITION_KIND_FUTURE, page, 2);
  }

  handleFavorite = () => {
    const { pagination, fetchExpositions } = this.props;

    fetchExpositions(EXPOSITION_KIND_FUTURE, pagination.current_page);
  }

  render() {
    const { expositions, pagination, fetchStatus } = this.props;

    const expositionsPresent = expositions && expositions.length > 0;
    if (!expositionsPresent) {
      return null;
    }

    return (
      <div className="p-exhibitions__list">
        <div className="p-exhibitions__title">
          {i18n.t('pages.expositions.future.title')}
        </div>

        <div className="p-exhibitions__list-inner">
          {(expositions || []).map((item) =>
            <Item
              className="p-exhibitions__list-item __future"
              key={item.id}
              item={item}
            />
          )}
        </div>

        <Pagination
          className="p-exhibitions__pagination"
          totalPages={pagination.total_pages || 1}
          disabled={fetchStatus.isFetching}
          onChange={this.handlePageChange}
          fetchOnMount={false}
        />
      </div>
    );
  }
}

Future.propTypes = {
  expositions: PropTypes.arrayOf(PropTypes.shape(EXPOSITION_SHAPE)),
  fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
};

const expositionsSelector = makeExpositionPreviewsSelector('future');
const expositionsPaginationSelector = makeExpositionPreviewsAnyKeySelector('pagination');
const expositionsFetchStatusSelector = makeExpositionPreviewsFetchStatusSelector();

function mapStateToProps(state) {
  return {
    expositions: expositionsSelector(state),
    pagination: expositionsPaginationSelector(state) || {},
    fetchStatus: expositionsFetchStatusSelector(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  withExpositions,
  connect(mapStateToProps, mapDispatchToProps)
)(Future)
