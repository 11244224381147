import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";

export const EXPERT_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.shape(EXPERT_IMAGE_SHAPE).isRequired
}

export const EXPERT_IMAGE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  thumb: PropTypes.string,
}


export const EXPERT_PREVIEWS = createRequestActionsFor("EXPERT_PREVIEWS");
