import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../translations/i18n";
import { PAINTING_SHAPE } from "../../../constants/paintingConstants";
import List from "../../../components/paintings/list";
import { GA_APPOINTMENT_ARTIST_SOURCE } from "../../../helpers/analytics";


class Paintings extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { paintings, onAppointment, onFavorite } = this.props;

    return (
      <>
        { !isEmpty(paintings) &&
          <div className="b-paintings">
            <div className="section-title">
              {i18n.t('pages.artist.paintings.title')}
            </div>
            <div className="container">
              <div className="b-paintings__content">
                <List
                  items={paintings}
                  source={GA_APPOINTMENT_ARTIST_SOURCE}
                  onAppointment={onAppointment}
                  onFavorite={onFavorite}
                />
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

Paintings.propTypes = {
  paintings: PropTypes.arrayOf(PropTypes.shape(PAINTING_SHAPE)),
  onAppointment: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired,
};

export default Paintings
