import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../../translations/i18n";
import Loading from "../loading";
import { COLLECTION_FETCH_STATUS_SHAPE } from "../../../../selectors/collections";

/*
Usage:

<LoadableCollection fetchStatus={fetchStatus}>
  <SomeList />
</LoadableCollection>

or

<LoadableCollection fetchStatus={fetchStatus}>
  {() => renderCollection()}
</LoadableCollection>
*/

function LoadableCollection({fetchStatus, renderLoading, renderError, children}) {
  if (fetchStatus.isFetching) {
    if (renderLoading) {
      return renderLoading()
    } else {
      return <Loading isLoading={true} />
    }
  } else if (fetchStatus.isFailed) {
    if (renderError) {
      return renderError();
    } else {
      return (
        <div className="container">
          {i18n.t("helpers.loading.error")}
        </div>
      );
    }
  } else {
    if (children) {
      if (typeof children === "function") {
        return children();
      } else {
        return children;
      }
    } else {
      return null;
    }
  }
}

LoadableCollection.propTypes = {
  children: PropTypes.any,
  fetchStatus: PropTypes.shape(COLLECTION_FETCH_STATUS_SHAPE).isRequired,
  renderLoading: PropTypes.func,
  renderError: PropTypes.func,
};

export default LoadableCollection
