import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { PAINTING_DETAILS_KIND } from "../../../../constants/paintingConstants";


class Nav extends React.Component {

  constructor(props) {
    super(props);
  }

  handleClick = (event) => {
    const { index, onNav } = this.props;
    event.preventDefault();

    onNav(index);
  }

  render () {
    const { title, kind, index, currentSlide, detailIdx } = this.props;

    const navClassName = cn('p-picture__gallery-menu-link', {
      'state-active': index === currentSlide ||
        kind === PAINTING_DETAILS_KIND && (detailIdx || []).includes(currentSlide)
    });

    return (
      <div className="p-picture__gallery-menu-item">
        <a
          href="#"
          title={title}
          className={navClassName}
          onClick={this.handleClick}
        >
          {title}
        </a>
      </div>
    )
  }
}

Nav.propTypes = {
  title: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  detailIdx: PropTypes.array,
  currentSlide: PropTypes.number.isRequired,
  onNav: PropTypes.func.isRequired,
};

export default Nav