import React from "react";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { history } from "../store";

import Home from "../screens/home";
import Login from "../screens/sessions/login";
import Signup from "../screens/sessions/signup";
import RestorePassword from "../screens/sessions/restore_password";
import ChangePassword from "../screens/sessions/change_password";
import Restore from "../screens/sessions/restore";
import Search from "../screens/search";
import Paintings from "../screens/paintings";
import Painting from "../screens/painting";
import Artists from "../screens/artists";
import Artist from "../screens/artist";
import Posts from "../screens/posts";
import Post from "../screens/post";
import Expositions from "../screens/expositions";
import Exposition from "../screens/exposition";
import About from "../screens/about";
import Service from "../screens/service";
import Contacts from "../screens/contacts";
import Partners from "../screens/partners";
import Account from "../screens/account";
import AccountSettings from "../screens/account/settings";
import AccountChangeEmail from "../screens/account/email";
import AccountChangePhone from "../screens/account/phone";
import AccountChangePassword from "../screens/account/password";
import AccountSubscription from "../screens/account/subscription";
import AccountDelete from "../screens/account/delete";
import Cart from "../screens/cart";

import Privacy from "../screens/static/privacy";
import Terms from "../screens/static/terms";
import NotFound from "../screens/static/not_found";

import Check from "../components/layout/check";
import ScrollToTop from "../components/layout/scrollTop";
import Analytics from "../components/layout/analytics";


export default (
  <ConnectedRouter history={history}>
    {/* <ScrollToTop /> */}
    <Check />
    <Analytics />

    <Switch>
      <Route path="/" exact component={Home} />

      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/password/new" component={RestorePassword} />
      <Route path="/password/edit" component={ChangePassword} />
      <Route path="/restore" component={Restore} />

      <Route path="/paintings" exact component={Paintings} />
      <Route path="/paintings/19_century" exact render={(props) => <Paintings {...props} century="century_19" />} />
      <Route path="/paintings/20_century" exact render={(props) => <Paintings {...props} century="century_20" />} />
      <Route path="/paintings/:id" exact component={Painting} />

      <Route path="/artists" exact component={Artists} />
      <Route path="/artists/:id" exact component={Artist} />

      <Route path="/posts" exact component={Posts} />
      <Route path="/posts/:id" exact component={Post} />

      <Route path="/expositions" exact component={Expositions} />
      <Route path="/expositions/:id" exact component={Exposition} />

      <Route path="/search" exact component={Search} />

      <Route path="/about" exact component={About} />
      <Route path="/about/:id" exact component={Service} />

      <Route path="/contacts" exact component={Contacts} />
      <Route path="/partners" exact component={Partners} />

      <Route path="/account" exact component={Account} />
      <Route path="/account/settings" exact component={AccountSettings} />
      <Route path="/account/change_email" exact component={AccountChangeEmail} />
      <Route path="/account/change_phone" exact component={AccountChangePhone} />
      <Route path="/account/change_password" exact component={AccountChangePassword} />
      <Route path="/account/subscription" exact component={AccountSubscription} />
      <Route path="/account/delete" exact component={AccountDelete} />

      <Route path="/cart" exact component={Cart} />

      <Route path="/privacy" exact component={Privacy} />
      <Route path="/terms" exact component={Terms} />

      <Route component={NotFound} status={404} />
    </Switch>
  </ConnectedRouter>
)
