import React, { Component, Fragment } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { withSeo } from "../../../hocs/entities/withSeo";
import { SEO_SHAPE } from "../../../constants/seoConstants";
import { APP_URL } from "../../../config";


class Meta extends Component {

  constructor(props) {
    super(props);
  }

  buildJson = () => {
    const { seo } = this.props;

    if (seo && seo.schema_org) {
      const json = {
        ...seo.schema_org,
        url: `${APP_URL}${location.pathname}`,
      }

      return JSON.stringify(json)
    }
  }

  render() {
    const { seo } = this.props;

    return(
      <>
        {seo &&
          <Helmet encodeSpecialCharacters={false}>
            <meta charSet="utf-8" />
            <title>{seo.title}</title>
            <link rel="canonical" href={seo.canonical} />
            <meta name="description" content={seo.description} />
            <meta name="keywords" content={seo.keywords} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:url" content={`${APP_URL}${location.pathname}`} />
            {seo.schema_org && seo.schema_org.image &&
              <meta property="og:image" content={seo.schema_org.image} />
            }
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

            {seo.letteros && seo.letteros.product_img &&
              <meta name="letteros-product-img" content={seo.letteros.product_img} />
            }
            {seo.letteros && seo.letteros.product_title &&
              <meta name="letteros-product-title" content={seo.letteros.product_title} />
            }
            {seo.letteros && seo.letteros.product_time &&
              <meta name="letteros-product-time" content={seo.letteros.product_time} />
            }
            {seo.letteros && seo.letteros.product_size &&
              <meta name="letteros-product-size" content={seo.letteros.product_size} />
            }

            <script type="application/ld+json">
              {this.buildJson()}
            </script>
          </Helmet>
        }
      </>
    )
  }
}

Meta.propTypes = {
  seo: PropTypes.shape(SEO_SHAPE),
};

export default compose(withSeo)(Meta)
