import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, withFormik } from "formik";
import { BrowserView, MobileView } from "react-device-detect";
import isEmpty from "lodash/isEmpty";

import i18n from "../../../../translations/i18n";
import {
  USER_ROLE_OPTIONS,
  USER_GENRE_OPTIONS,
  USER_TECHNIQUE_OPTIONS,
  USER_YEAR_OPTIONS,
  USER_SHAPE,
} from "../../../../constants/userConstants";
import { MODIFICATION_SHAPE } from "../../../../selectors/modifications";

import FormGroup from "../../../../components/ui/form/group";
import FormActions from "../../../../components/ui/form/actions";
import FormError from "../../../../components/ui/form/error";
import InputField from "../../../../components/ui/form/input_field";
import RadioGroupField from "../../../../components/ui/form/radio_group";
import CheckboxGroupField from "../../../../components/ui/form/checkbox_group";
import Button from "../../../../components/ui/form/button";
import Select from "../../../../components/ui/form/select";
import {
  GA_PROFILE_SOURCE,
  TrackFieldError,
  TrackFieldValid,
  TrackSubmitSettingsClick,
  TrackSubmitSettingsError,
  TrackSubmitSettingsSuccess,
} from "../../../../helpers/analytics";


class AccountForm extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchStatus, setSubmitting } = this.props;

    if (prevProps.fetchStatus !== fetchStatus && !fetchStatus.isFetching) {
      setSubmitting(false);
    }
  }

  handleChangeRole = (option) => {
    const { setFieldValue } = this.props;
    setFieldValue('role', option.value);
  }

  render() {
    const {
      account,
      isSubmitting,
      fetchStatus,
      handleSubmit,
    } = this.props;
    const isDisabled = fetchStatus.isFetching || isSubmitting;

    return(
      <div className="p-settings__form">
        <Form>
          <FormGroup
            label={i18n.t("pages.account.form.name.label")}
            touched={true}
          >
            <Field
              component={InputField}
              name="name"
              required
            />
          </FormGroup>

          <FormGroup>
            <label className="radio-group__label">
              {i18n.t('pages.account.form.role.label')}
            </label>
            <BrowserView>
              <Field
                name="role"
                component={RadioGroupField}
                options={USER_ROLE_OPTIONS}
                disabled={isSubmitting}
              />
            </BrowserView>
            <MobileView>
              <div className="modal__select">
                <Select
                  name="role"
                  defaultValue={USER_ROLE_OPTIONS.filter((r) => r.value === account.role)[0]}
                  options={USER_ROLE_OPTIONS}
                  onChange={this.handleChangeRole}
                />
              </div>
            </MobileView>
          </FormGroup>

          <p className="p-settings__form-title">
            {i18n.t("pages.account.form.preferences.label")}
          </p>

          <FormGroup className="form__group-techniques">
            <label className="checkbox-group__label">
              {i18n.t('pages.account.form.techniques.label')}
            </label>
            <Field
              name="subscribe_to_techniques"
              component={CheckboxGroupField}
              options={USER_TECHNIQUE_OPTIONS}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup className="form__group-years">
            <label className="checkbox-group__label">
              {i18n.t('pages.account.form.years.label')}
            </label>
            <Field
              name="subscribe_to_years"
              component={CheckboxGroupField}
              options={USER_YEAR_OPTIONS}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup className="form__group-genres">
            <label className="checkbox-group__label">
              {i18n.t('pages.account.form.genres.label')}
            </label>
            <Field
              name="subscribe_to_genres"
              component={CheckboxGroupField}
              options={USER_GENRE_OPTIONS}
              disabled={isDisabled}
            />
          </FormGroup>

          <FormActions className="button-wrapper p-settings__form-button-wrapper">
            <FormError>
              {account.error}
            </FormError>
            <Button
              className="p-settings__form-button"
              type="submit"
              disabled={isDisabled}
              onSubmit={handleSubmit}
            >
              {i18n.t("pages.account.settings.form.submit.title")}
            </Button>
          </FormActions>
        </Form>

      </div>
    )
  }
}

const AccountFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => initialValues,
  handleSubmit: (values, {props: {onSubmit}}) => onSubmit(values),
  validate: (values) => {
    let errors = {};
    let isTrackable = values.isTrackable;

    if (isTrackable) TrackSubmitSettingsClick();

    if (isEmpty(values.name)) {
      if (isTrackable) TrackFieldError('name', 'empty', GA_PROFILE_SOURCE);

      errors.name = i18n.t("validation.field.required");
    } else {
      if (isTrackable) TrackFieldValid('name', GA_PROFILE_SOURCE);
    }

    if (!isEmpty(errors)) {
      if (isTrackable) TrackSubmitSettingsError();
    }

    return errors;
  },
})(AccountForm);

AccountFormWithFormik.propTypes = {
  account: PropTypes.shape(USER_SHAPE).isRequired,
  fetchStatus: PropTypes.shape(MODIFICATION_SHAPE).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AccountFormWithFormik
