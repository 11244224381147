import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import i18n from "../../../../../translations/i18n";
import { openHeaderSearch, closeHeaderSearch } from "../../../../../actions/layoutActions";


class SearchLink extends Component {

  constructor(props) {
    super(props);
  }

  handleClick = (event) => {
    const { headerSearch, openHeaderSearch, closeHeaderSearch } = this.props;
    event.preventDefault();

    if (headerSearch.visible) {
      closeHeaderSearch();
    } else {
      openHeaderSearch();
    }
  }

  render () {
    return (
      <a
        href="#"
        onClick={this.handleClick}
      >
        {i18n.t('header.search.title')}
      </a>
    );
  }
}

SearchLink.propTypes = {
  headerSearch: PropTypes.object.isRequired,
  openHeaderSearch: PropTypes.func.isRequired,
  closeHeaderSearch: PropTypes.func.isRequired,
};

function mapStateToProps({layout}) {
  return {
    headerSearch: layout.headerSearch,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openHeaderSearch,
    closeHeaderSearch,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SearchLink)