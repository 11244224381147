import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../../translations/i18n";
import { POST_SHAPE } from "../../../../constants/postConstants";
import Favorite from "../../../../components/layout/favorite";
import Share from "../../../../components/post/share";


class Head extends React.Component {

  constructor(props) {
    super(props);
  }

  handleFavorite = () => {
    this.props.fetchPost();
  }

  render () {
    const { item } = this.props;

    return (
      <div className="p-post__head">
        <div className="p-post__head-content">
          <div className="p-post__head-info">
            <div className="p-post__head-info-top">
              <div className="p-post__head-info-date">
                <div
                  className="p-post__head-info-date-item"
                  dangerouslySetInnerHTML={{__html: i18n.t('pages.posts.title')}}
                />
                <div className="p-post__head-info-date-item">
                  {item.date}
                </div>
              </div>
              <h1 
                className="p-post__head-info-title"
                dangerouslySetInnerHTML={{__html: item.name}}
              />
            </div>
            <div className="p-post__head-info-media">
              <div className="p-post__head-info-media-favorite">
                <Favorite
                  favorite={item.favorite}
                  onFavorite={this.handleFavorite}
                />
              </div>
              <div className="p-post__head-info-media-share">
                <div className="p-post__head-info-media-share-name">
                  {i18n.t('helpers.share.title')}
                </div>
                <Share slug={item.slug} title={item.name}/>
              </div>
            </div>
          </div>
          <div className="p-post__head-image-wrapper">
            <div>
              <img
                src={item.poster.big}
                className="p-post__head-image"
                alt={item.name_without_word_breaks}
                title={item.name_without_word_breaks}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Head.propTypes = {
  item: PropTypes.shape(POST_SHAPE).isRequired,
  fetchPost: PropTypes.func.isRequired,
};

export default Head