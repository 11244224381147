import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import isNil from "lodash/isNil";

import i18n from "../../../translations/i18n";
import {
  createItem,
  enableAppointment,
  disableAppointment,
} from "../../../actions/appointmentActions";
import {
  openModal,
  closeModal,
} from "../../../actions/modalActions";
import { getAccount } from "../../../selectors/accounts";
import { APPOINTMENT_SHAPE } from "../../../constants/appointmentConstants";
import {
  APPOINTMENT_MODAL,
  LOGIN_MODAL,
  PHONE_MODAL
} from "../../../constants/modalConstants";
import { TrackAddAppointmentLogin } from "../../../helpers/analytics";


class Appointment extends Component {

  constructor(props) {
    super(props);
  }

  openAppointmentModal = () => {
    const { appointment, source, onAppointment, openModal } = this.props;

    openModal({
      name: APPOINTMENT_MODAL,
      paintingId: appointment.painting_id,
      paintingName: appointment.name,
      source: source,
      onAppointment: onAppointment,
    });
  }

  handleAddAppointment = (event) => {
    const { account, appointment, onAppointment, userLoggedIn, openModal, enableAppointment, source } = this.props;
    event.preventDefault();

    // пользователь подтвердил номер телефона
    if (userLoggedIn && account.phone_confirmed && !isNil(account.phone)) {
      this.openAppointmentModal();
    // номер не подтверджен: откладываем запись, подтверждаем номер
    } else if (userLoggedIn) {
      enableAppointment({
        paintingId: appointment.painting_id,
        onAppointment: onAppointment,
      });
      openModal({name: PHONE_MODAL});
    // гость: откладываем запись, вход/регистрация
    } else {
      enableAppointment({
        paintingId: appointment.painting_id,
        onAppointment: onAppointment,
      });
      TrackAddAppointmentLogin();
      openModal({
        name: LOGIN_MODAL,
        source: 'форма "Регистрация" в записи на просмотр ' + appointment.name
      });
    }
  }

  handleCloseModal = (event) => {
    const { closeModal, disableAppointment } = this.props;
    if (event) {
      event.preventDefault();
    }
    closeModal();
    disableAppointment();
  }

  renderAdded = () => {
    const { className } = this.props;
    return (
      <a
        href=""
        disabled
        title={i18n.t('helpers.appointment.added.title')}
        className={className}
        onClick={(event) => event.preventDefault()}
      >
        {i18n.t('helpers.appointment.added.title')}
      </a>
    )
  }

  renderAdd = () => {
    const { className } = this.props;

    return (
      <a
        href=""
        title={i18n.t('helpers.appointment.add.title')}
        className={className}
        onClick={this.handleAddAppointment}
      >
        {i18n.t('helpers.appointment.add.title')}
      </a>
    )
  }

  render() {
    const { available, appointment, className } = this.props;

    return (
      <>
        { available
          ? <>
              {appointment && appointment.id
                ? this.renderAdded()
                : this.renderAdd()
              }
          </>
        : <span
            className={`${className} disabled`}
          >
            {i18n.t('helpers.appointment.not_available.title')}
          </span>
        }
      </>
    )
  }
}

Appointment.propTypes = {
  available: PropTypes.bool.isRequired,
  appointment: PropTypes.shape(APPOINTMENT_SHAPE),
  className: PropTypes.string,
  onAppointment: PropTypes.func.isRequired,
  createItem: PropTypes.func.isRequired,
  enableAppointment: PropTypes.func.isRequired,
  disableAppointment: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const currentUser = state.currentUser;
  return {
    userLoggedIn: !!currentUser.token,
    account: getAccount(state, currentUser.id),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createItem,
    enableAppointment,
    disableAppointment,
    openModal,
    closeModal,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Appointment)
