import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";

import i18n from "../../../translations/i18n";
import {
  EXPOSITION_SHAPE,
} from "../../../constants/expositionConstants";
import {
  EXPOSITION_CONTENT_IMAGES_MODAL
} from "../../../constants/modalConstants";
import Figure from "../../../components/ui/figure";
import Modal from "./modal";


class Images extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { item } = this.props;
    const withImages = !isEmpty(item.images);
    const hasOneImage = item.images && item.images.length === 1;
    const galleryClassName = classNames("attachment-gallery", {
      "attachment-gallery--1": hasOneImage,
      "attachment-gallery--2": !hasOneImage,
    })

    return(
      <div className="p-exhibition__body-section p-exhibition__body-materials" id="#images">
        {withImages &&
          <div>
            <h3 className="p-exhibition__body-title">
              <span>{i18n.t('pages.exposition.aside.images.title')}</span>
            </h3>
            <div className={galleryClassName}>
              {(item.images || []).map((attachment) =>
                <Figure
                  key={attachment.id}
                  className="attachment attachment--preview"
                  attachment={attachment}
                  images={item.images}
                  modalName={EXPOSITION_CONTENT_IMAGES_MODAL}
                />
              )}
            </div>
            <Modal />
          </div>
        }
      </div>
    )
  }
}

Images.propTypes = {
  item: PropTypes.shape(EXPOSITION_SHAPE).isRequired,
};

export default Images
