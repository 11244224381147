import React from "react";
import { getIn } from "formik";

import Input from "../mask_input";
import { withErrorMessage } from "../../../../hocs/withErrorMessage";


const InputWithErrorMessage = withErrorMessage(Input);

export default function InputField({ field, form: {touched, errors, setFieldValue, setFieldTouched, submitCount}, required, onChange, onBlur, onFocus, ...restProps }) {
  const error = getIn(errors, field.name);
  const isTouched = getIn(touched, field.name);
  const hasError = (isTouched || submitCount > 0) && !!error;

  const value = (field.value || field.value === 0) ? field.value : "";

  const handleChange = (event) => {
    const newValue = event.target.value;
    setFieldValue(field.name, newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleBlur = (event) => {
    if (!event.target.value) {
      setFieldTouched(field.name, false, true)
    }

    if (onBlur && typeof onBlur == 'function') {
      onBlur
    }
  }

  const handleFocus = (event) => {
    setFieldTouched(field.name, true, true);

    if (onFocus && typeof onFocus == 'function') {
      onFocus
    }
  }

  return (
    <InputWithErrorMessage
      {...field}
      {...restProps}
      error={hasError ? error : null}
      required={required}
      id={field.name}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      value={value}
    />
  )
}