import { RSAA } from "../middlewares/redux-api-middleware";

export function createRequestActionsFor(namespace) {
  return {
    REQUEST: `${namespace}_FETCH_REQUEST`,
    SUCCESS: `${namespace}_FETCH_SUCCESS`,
    FAILED: `${namespace}_FETCH_FAILED`,
    DEFAULT: {
      FAILED: {
        type: `${namespace}_FETCH_FAILED`,
        meta: (action, state, res) => {
          return {
            timestamp: (new Date()).getTime(),
            action: action[RSAA],
          };
        },
      },
    },
    toArray() {
      return [this.REQUEST, this.SUCCESS, this.DEFAULT.FAILED]
    },
    typeNames() {
      return [this.REQUEST, this.SUCCESS, this.FAILED]
    },
    types({ request = {}, success = {}, failed = {}, all = {} } = { request: {}, success: {}, failed: {}, all: {} }) {
      return [
        {
          type: this.REQUEST,
          ...all,
          ...request,
        },
        {
          type: this.SUCCESS,
          ...all,
          ...success,
        },
        {
          ...this.DEFAULT.FAILED,
          ...all,
          ...failed,
          meta: (...args) => ({ ...this.DEFAULT.FAILED.meta(...args), ...all.meta, ...failed.meta })
        },
      ]
    }
  };
}