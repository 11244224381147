import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Route, Redirect, Link } from "react-router-dom";

import i18n from "../../../translations/i18n";
import { withHeader } from "../../../hocs/layout/withHeader";
import { withNotice } from "../../../hocs/layout/withNotice";
import {
  signIn,
  resetAuthForm as reset
} from "../../../actions/userActions";
import LoginForm from "./form";
import Meta from "../../../components/layout/meta";
import {
  TrackRegistrationClick,
  TrackLoginCloseClick,
  TrackLoginSubmitSuccess,
} from "../../../helpers/analytics";


class Login extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.reset();
  }

  handleSubmit = (params) => {
    const { signIn } = this.props;

    TrackLoginSubmitSuccess();
    signIn(params);
  }

  gaTrackRegistrationClick = () => {
    TrackRegistrationClick();
  }

  gaTrackCloseClick = () => {
    TrackLoginCloseClick();
  }

  render() {
    const { auth, userLoggedIn, redirectBackToOrder, redirectBackToPaintingOffer } = this.props;

    if (redirectBackToOrder && userLoggedIn) {
      return (
        <Redirect to="/about"/>
      )
    }

    if (redirectBackToPaintingOffer && userLoggedIn) {
      return (
        <Redirect to="/partners"/>
      )
    }

    return (
      <Route render={({location}) => {
        return userLoggedIn
          ? <Redirect to="/" />
          : <main className="layout auth">
              <Meta />
              <div className="modal modal-login">
                <div className="modal__container">
                  <div className="modal__inner">
                    <Link
                      to="/"
                      className="modal__close"
                      onClick={() => this.gaTrackCloseClick()}
                    >
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L15.8492 15.8492" stroke="black" strokeWidth="2"/>
                        <path d="M16 1L1.15076 15.8492" stroke="black" strokeWidth="2"/>
                      </svg>
                    </Link>
                    <div className="modal__content">
                      <div className="modal__title">
                        {i18n.t('pages.login.title')}
                      </div>
                      <div className="modal__form">
                        <LoginForm
                          initialValues={{email: '', password: ''}}
                          auth={auth}
                          onSubmit={this.handleSubmit}
                        />
                        {/* TODO: add omniauth
                          <div className="modal__separator">
                            {i18n.t('pages.login.form.social.title')}
                          </div>
                          <div className="modal__social-auth">
                            <Button className="modal__social-auth-button modal__social-auth-facebook">
                              {i18n.t('pages.login.form.social.facebook.title')}
                            </Button>
                            <Button className="modal__social-auth-button modal__social-auth-vkontakte">
                              {i18n.t('pages.login.form.social.vk.title')}
                            </Button>
                          </div>
                        */}
                        <div className="modal__reg-tip">
                          {i18n.t('pages.login.form.signup.title')}
                          <Link
                            to="/signup"
                            className="modal__reg-tip-link"
                            onClick={() => this.gaTrackRegistrationClick()}
                          >
                            {i18n.t('pages.login.form.signup.link')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
        }}
      />
    );
  }
}

Login.propTypes = {
  signIn: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

function mapStateToProps({auth, currentUser, entities, paintingOffer}) {
  const redirectBackToOrder = entities.order.redirected;
  return {
    auth,
    userLoggedIn: !!currentUser.token,
    redirectBackToOrder,
    redirectBackToPaintingOffer: paintingOffer.redirected
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    signIn,
    reset
  }, dispatch)
}

export default compose(
  withNotice(),
  withHeader({transparent: true}),
  connect(mapStateToProps, mapDispatchToProps)
)(Login)
