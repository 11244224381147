import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";

import { openModal } from "../../../../actions/modalActions";
import { PAINTING_CONTENT_IMAGES_MODAL } from "../../../../constants/modalConstants";
import Figure from "../../../../components/ui/figure";


class Description extends Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [],
    }
  }

  renderNode(node, index) {
    const { images } = this.state;

    if (node.type === 'tag' && node.name === 'figure') {
      const attachment = JSON.parse(node.attribs['data-trix-attachment']);
      const attributes = JSON.parse(node.attribs['data-trix-attributes']);

      if (images.filter((image) => image.url === attachment.url).length === 0) {
        images.push(attachment);
      }

      return (
        <Figure
          key={index}
          className={node.attribs.class}
          attachment={attachment}
          attributes={attributes}
          images={images}
          modalName={PAINTING_CONTENT_IMAGES_MODAL}
        />
      )
    }
  }

  render () {
    const { description } = this.props;

    return (
      <>
        {description &&
          <div
            className="p-picture__info-description-about"
            id="#about"
          >
            {ReactHtmlParser(description, {
                transform: (node, index) => this.renderNode(node, index)
              }
            )}
          </div>
        }
      </>
    )
  }
}

Description.propTypes = {
  description: PropTypes.string,
  openModal: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Description)