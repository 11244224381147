import PropTypes from "prop-types";

import { createRequestActionsFor } from "./apiFetch";

export const PROPERTY_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
}

export const PROPERTY_OPTION_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  kind: PropTypes.string,
}

export const PROPERTY_GENRE_KIND = 'genre';
export const PROPERTY_TECHNIQUE_KIND = 'technique';
export const PROPERTY_MATERIAL_KIND = 'material';
export const PROPERTY_ORIENTATION_KIND = 'orientation';
export const PROPERTY_SIZE_KIND = 'size';

export const PROPERTY_OPTIONS = createRequestActionsFor("PROPERTY_OPTIONS");
