import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import i18n from "../../../translations/i18n";
import { SEARCH_FACET_SHAPE } from "../../../constants/searchConstants";
import Select from "../../../components/ui/form/select";
import Link from "../../../components/ui/link";


class Aside extends Component {

  constructor(props) {
    super(props);
  }

  handleClick = (event) => {
    const { onFilter } = this.props;

    // TODO: wtf?
    setTimeout(() => {
      onFilter();
    }, 0)
  }

  handleChangeType = (option) => {
    const { onFilter } = this.props;

    // TODO: wtf?
    setTimeout(() => {
      onFilter({type: option.value});
    }, 0)
  }

  render () {
    const { items, currentQuery, currentType } = this.props;
    const total = items.reduce((sum, item) => sum + item.count, 0);
    const defaultSearchPath = `/search?q=${currentQuery}&page=1`;

    const defaultOption = {
      value: null,
      label: i18n.t('pages.search.facets.all.title', {count: total}),
      className: cn('p-search__results-menu-link', {'state-selected': currentType == null}),
      url: defaultSearchPath,
    }

    let options = [defaultOption]

    items.map((item) => {
      options.push({
        value: item.type,
        label: i18n.t(`pages.search.facets.${item.type}.title`, {count: item.count}),
        className: cn('p-search__results-menu-link', {'state-selected': currentType == item.type}),
        url: `${defaultSearchPath}&type=${item.type}`,
      })
    })

    return (
      <div className="p-search__results-sidebar">
        <div className="p-search__results-menu">
          <ul className="p-search__results-menu-list">
            {options.map((option, index) =>
              <li
                key={index}
                className="p-search__results-menu-item"
              >
                <Link
                  to={option.url}
                  className={option.className}
                  onClick={this.handleClick}
                >
                  {option.label}
                </Link>
              </li>
            )}
          </ul>
          <Select
            name="type"
            defaultValue={options.filter((o) => o.value === currentType)[0] || defaultOption}
            options={options}
            className="select"
            onChange={this.handleChangeType}
          />
        </div>
      </div>
    )
  }
}

Aside.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(SEARCH_FACET_SHAPE)),
  currentQuery: PropTypes.string,
  currentType: PropTypes.string,
  onFilter: PropTypes.func.isRequired,
};

export default Aside;