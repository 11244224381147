import React, { Component } from "react";
import ReactSelect from "react-select";
import PropTypes from "prop-types";
import cn from "classnames";

const OPTION_SHAPE = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}


class SelectInput extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }
  }

  handleChange = (event, index) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(event, index || 0);
    }
  };

  handleMenuOpen = () => {
    const { onClick } = this.props;

    this.setState({isOpen: true});
    if (onClick) {
      onClick();
    }
  }

  handleMenuClose = () => {
    this.setState({isOpen: false});
  }

  render() {
    const { options, defaultValue, ...restProps } = this.props;
    const { isOpen } = this.state;
    const selectClassNames = cn('select-styled', { active: isOpen });

    return (
      <ReactSelect
        options={options}
        defaultValue={defaultValue}
        onChange={(e) => this.handleChange(e, index)}
        onMenuOpen={this.handleMenuOpen}
        onMenuClose={this.handleMenuClose}
        className={selectClassNames}
        classNamePrefix="react-select"
        {...restProps}
      />
    )
  }

}

SelectInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape(OPTION_SHAPE)).isRequired,
  defaultValue: PropTypes.shape(OPTION_SHAPE),
  onClick: PropTypes.func
};

export default SelectInput;