import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Route, Redirect, Link } from "react-router-dom";
import isNil from "lodash/isNil";

import i18n from "../../../translations/i18n";
import { withNotice } from "../../../hocs/layout/withNotice";
import { withHeader } from "../../../hocs/layout/withHeader";
import {
    signUp,
    confirmPhone,
    resendCode,
    fetchItem,
    resetAuthForm as reset
} from "../../../actions/userActions";
import { getAccount, makeAccountModificationStatusSelector } from "../../../selectors/accounts";
import RegistrationForm from "./form";
import PhoneConfirmationForm from "./phone_confirmation_form";
import Meta from "../../../components/layout/meta";
import {
    TrackLoginClick,
    TrackRegistrationCloseClick,
    TrackRegistrationSubmitSuccess
} from "../../../helpers/analytics";


class Signup extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.reset();
    }

    componentDidUpdate(prevProps, nextProps) {
        const { fetchStatus } = this.props;

        if (prevProps.fetchStatus !== fetchStatus && fetchStatus.isSuccess) {
            this.fetchAccount();
        }
    }

    fetchAccount = () => {
        const { userLoggedIn, fetchItem } = this.props;

        if (userLoggedIn) {
            fetchItem();
        }
    };

    handleResendCode = () => {
        this.props.resendCode();
    }

    handleConfirm = (params) => {
        this.props.confirmPhone(params);
    }

    handleSubmit = (params) => {
        const { signUp } = this.props;

        TrackRegistrationSubmitSuccess();
        signUp(params);
    }

    gaTrackLoginClick = () => {
        TrackLoginClick();
    }

    gaTrackCloseClick = () => {
        TrackRegistrationCloseClick();
    }

    render() {
        const {
            auth,
            userLoggedIn,
            account,
            redirectBackToOrder,
            redirectBackToPaintingOffer
        } = this.props;

        if (redirectBackToOrder && userLoggedIn) {
            return (
                <Redirect to="/about" />
            )
        }
        if (redirectBackToPaintingOffer && userLoggedIn) {
            return (
                <Redirect to="/partners" />
            )
        }

        let confirmed = false;
        let phoneConfirmed = false;
        if (account) {
            confirmed = account.confirmed;
            phoneConfirmed = account.phone_confirmed;
        }

        return (
            <Route render={({ location }) => {
                return userLoggedIn && confirmed && phoneConfirmed
                    ? <Redirect to="/" />
                    : <main className="layout auth">
                        <Meta />
                        <div className="modal modal-registration">
                            <div className="modal__container">
                                <div className="modal__inner">
                                    <Link
                                        to="/"
                                        className="modal__close"
                                        onClick={() => this.gaTrackCloseClick()}
                                    >
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L15.8492 15.8492" stroke="black" strokeWidth="2" />
                                            <path d="M16 1L1.15076 15.8492" stroke="black" strokeWidth="2" />
                                        </svg>
                                    </Link>
                                    <div className="modal__content">
                                        <div className="modal__title">
                                            {i18n.t('pages.registration.title')}
                                        </div>
                                        <div className="modal__form">
                                            <RegistrationForm
                                                initialValues={{
                                                    email: !isNil(account) ? account.email : '',
                                                    phone: !isNil(account) ? account.unconfirmed_phone : '',
                                                    password: '',
                                                    name: !isNil(account) ? account.name : '',
                                                    role: 'enthusiast',
                                                    subscribe_to_news: true
                                                }
                                                }
                                                auth={auth}
                                                userLoggedIn={userLoggedIn}
                                                phoneConfirmed={phoneConfirmed}
                                                onSubmit={this.handleSubmit}
                                            />
                                            {userLoggedIn && !confirmed && !phoneConfirmed &&
                                                <PhoneConfirmationForm
                                                    initialValues={{ code: '' }}
                                                    auth={auth}
                                                    onResend={this.handleResendCode}
                                                    onSubmit={this.handleConfirm}
                                                />
                                            }
                                            <div className="modal__reg-tip">
                                                {i18n.t('pages.registration.form.login.title')}
                                                <Link
                                                    to="/login"
                                                    className="modal__reg-tip-link"
                                                    onClick={() => this.gaTrackLoginClick()}
                                                >
                                                    {i18n.t('pages.registration.form.login.link')}
                                                </Link>
                                            </div>
                                            <div
                                                className="modal__terms"
                                                dangerouslySetInnerHTML={{ __html: i18n.t('pages.registration.form.terms.title') }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
            }}
            />
        );
    }
}

Signup.propTypes = {
    signUp: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
};

const fetchStatus = makeAccountModificationStatusSelector();

function mapStateToProps(state) {
    const { auth, currentUser, entities, paintingOffer } = state

    return {
        auth,
        currentUser,
        userLoggedIn: !!currentUser.token,
        account: getAccount(state, currentUser.id),
        fetchStatus: fetchStatus(state),
        redirectBackToOrder: entities.order.redirected,
        redirectBackToPaintingOffer: paintingOffer.redirected
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        signUp,
        confirmPhone,
        resendCode,
        fetchItem,
        reset
    }, dispatch)
}

export default compose(
    withNotice(),
    withHeader({ transparent: true }),
    connect(mapStateToProps, mapDispatchToProps)
)(Signup)
